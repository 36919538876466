import React from 'react';
import { CSpinner } from '@coreui/react';

const CustLoader = props => {
  const { size, isShow, variant, color } = props;

  if (isShow) {
    return (
      <div className="text-center">
        <CSpinner
          size={size || 'sm'}
          variant={variant || 'grow'}
          color={color || 'primary'}
        />
      </div>
    );
  }
  return null;
};

export default CustLoader;
