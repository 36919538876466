import React, { useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CDataTable,
  CForm,
  CFormGroup,
  CCol,
  CLabel,
  CInput,
} from '@coreui/react';
import { CustButton } from 'src/reusable';
import appConstants from 'src/core/configs/Constants.config';
import { capitalizeFirstLetter } from 'src/utils/common';
import zIndex from '@material-ui/core/styles/zIndex';
import '../views/messages/style.css';
const AddUpdateGroupModal = props => {
  const {
    open,
    empList,
    handleCancel,
    handleOnChange,
    handleAddUpdateGroup,
    handleOnSelectGroupMemeber,
    selectedGroupMembers,
    isLoadingAddUpdateGroup,
    groupType,
    groupDesc,
    groupName,
    groupAction,
  } = props;

  const fields = [
    {
      label: 'S.No.',
      key: 'sno',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
    { label: 'Name', key: 'fullname' },
    { label: 'Emp Id', key: 'emp_id' },
    { label: 'Action', key: 'action', sorter: false, filter: false },
  ];

  const renderGroupTypeOptions = () => {
    return appConstants.groupTypes.map(elem => {
      if (elem === 'braodcast') return '';
      return <option value={elem}>{capitalizeFirstLetter(elem)}</option>;
    });
  };
  return (
    <div className="">
      <CModal
        show={open}
        onClose={handleCancel}
        size="lg"
        backdrop={false}
        className={'overlap-modal'}
      >
        <CModalHeader closeButton>
          <CModalTitle>Create Group</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="row">
            <div className="col-sm-12">
              <CForm
                action=""
                encType="multipart/form-data"
                className="form-horizontal"
              >
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel>
                      Group Name <span className="required-field">*</span>
                    </CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <CInput
                      onChange={e => handleOnChange(e)}
                      name="group-name"
                      placeholder="Enter Group Name"
                      required={true}
                      value={groupName}
                    />
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel>
                      Group Type <span className="required-field">*</span>
                    </CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <select
                      name="group-type"
                      value={groupType}
                      onChange={handleOnChange}
                      className="form-control"
                      placeholder=""
                    >
                      {renderGroupTypeOptions()}
                    </select>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel>
                      Group Description{' '}
                      <span className="required-field">*</span>
                    </CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <CInput
                      onChange={e => handleOnChange(e)}
                      name="group-desc"
                      placeholder="Enter Group Description"
                      required={true}
                      value={groupDesc}
                    />
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel>
                      Group Memebers <span className="required-field">*</span>
                    </CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <span>{selectedGroupMembers?.length || 0}</span>
                  </CCol>
                </CFormGroup>
              </CForm>
            </div>
            <div className="col-sm-12">
              <CDataTable
                items={empList}
                fields={fields}
                loading={false}
                hover={false}
                columnFilter
                sorter
                pagination
                scopedSlots={{
                  sno: (item, index) => <td>{index + 1}</td>,
                  action: (item, index) => (
                    <td>
                      <CustButton
                        text={
                          selectedGroupMembers?.indexOf(item.id) > -1
                            ? 'Selected'
                            : 'Select'
                        }
                        type="button"
                        name="connect"
                        size="sm"
                        color={
                          selectedGroupMembers?.indexOf(item.id) > -1
                            ? 'success'
                            : 'primary'
                        }
                        onClick={() => handleOnSelectGroupMemeber(item.id)}
                        className={'btn-loading btn-ladda'}
                        icon=""
                        isDisabled={false}
                        isLoading={false}
                      />
                    </td>
                  ),
                }}
              />
            </div>
          </div>
        </CModalBody>

        <CModalFooter>
          <CustButton
            type="button"
            name="ok"
            text="Close"
            color="danger"
            onClick={handleCancel}
            className="btn-loading btn-ladda mr-3"
            icon=""
            isDisabled={false}
            isLoading={false}
          />
          <CustButton
            type="button"
            name={groupAction === 'update' ? 'update' : 'create'}
            text={groupAction === 'update' ? 'Update' : 'Create'}
            color="primary"
            onClick={handleAddUpdateGroup}
            className="btn-loading btn-ladda mr-3"
            icon=""
            isDisabled={isLoadingAddUpdateGroup}
            isLoading={isLoadingAddUpdateGroup}
          />
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default AddUpdateGroupModal;
