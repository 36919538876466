import React, { useState, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import {
  CBadge,
  CNav,
  CInputRadio,
  CNavItem,
  CNavbar,
  CListGroup,
  CListGroupItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CProgress,
  CProgressBar,
  CRow,
  CLabel,
  CInput,
  CForm,
  CFormGroup,
  CFormText,
  CSelect,
  CCallout,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from 'sweetalert';
import Alert from 'src/alert/alert';
import { toast, ToastContainer } from 'react-toastify';

const BankDetails = props => {
  let {
    getEmployeeDetailStatus,
    empStatus,
    empDetails,
    onClickActiveOnboarding,
    onClickActive,
  } = props;
  const api = new API();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [BankDetails, setBankDetails] = useState({
    emp_bank_name: '',
    emp_bank_account_holder_name: '',
    emp_bank_account_number: '',
    emp_bank_branch_name: '',
    emp_ifsc_code: '',
    emp_nominee: '',
    emp_nominee_relation: '',
  });
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isValidation ,setISValidation]=useState(false)

  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };
  useEffect(() => {
    getBankDetails();
    console.log(
      '((((((((((((((((((((((((((((((((((())))))))))))))))))))',
      empDetails,
    );
  }, []);
  const getBankDetails = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getEmployeeBankDetails, true, data);
    if (result && result.code === 200) {
      if (empStatus && empStatus.bank_details_status === 1) {
        setIsValid(true);
        setIsSubmit(false);
        if (result.data.length > 0) {
          setBankDetails(result.data[0]);
        }
      }
    } else {
      // alert(result && result.message);
    }
  };
  const saveBankDetails = async () => {
    BankDetails.user_id = empDetails && empDetails.id;
    let data = BankDetails;
    console.log('bank detals data is', data);
    if (!data.user_id) {
      delete data.user_id;
    }
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you Sure to add Bank Details `,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.bankdetails, data);
        if (result.code === 200) {
          toast.success(result && result.message);
          // onClickActive(6);
          getEmployeeDetailStatus();
          setIsSubmit(false);
          getBankDetails();
        } else {
          toast.error(result && result.message);
        }
      }
    });
  };

  const updateBankDetails = async () => {
    let data = BankDetails;
    console.log(BankDetails,"BankDetails")
   
   swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you Sure to update Bank Details `,
      icon: 'success',
      buttons: ['No, cancel it!', 'Update'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updatebankdetails, data);
        if (result.code === 200) {
          toast.success(result && result.message);
          getEmployeeDetailStatus();
          setIsSubmit(false);
          getBankDetails();
        } else {
          toast.error(result && result.message);
        }
      }
    });
  
  
  };

  const getDetailsByifsc = async code => {
    let data = { ifsc: code };
    const result = await api.post(config.getBankDetailsByIfsc, data);
    if (result.code === 200) {
      setBankDetails({
        ...BankDetails,
        emp_ifsc_code: result.data.IFSC,
        emp_bank_name: result.data.BANK,
        emp_bank_branch_name: result.data.BRANCH,
      });
    } else {
      setIsValid(false);
      setBankDetails({
        ...BankDetails,
        emp_ifsc_code: code,
        emp_bank_name: '',
        emp_bank_branch_name: '',
      });
    }
  };
  // const onChangeInputBankDetails = async e => {
  //   const { name, value } = e.target;
  //   const regexName = /^[a-zA-Z ]*$/;
  //   console.log(value)
  //   let employeeNominee = document.getElementsByClassName('emp_nominee1');
  //   console.log(value,employeeNominee,"employeeNominee")
  //   employeeNominee.addEventListener('input', e => {
  //     const error = document.getElementById('error');
  //     const value = e.target.value;
  //     if (regexName.test(value)) {
  //       error.textContent = '';
  //     } else {
  //       error.textContent = 'Numeric and Special Characters are not allowed';
  //       employeeNominee.value = value.slice(0, value.length - 1);
  //     }
  //   });
  //   setBankDetails({
  //     ...BankDetails,
  //     [name]: value,
  //   });
  // };
  const onChangeInputBankDetails = async e => {
    const { name, value } = e.target;
    const regexName = /^[a-zA-Z ]*$/;

    let employeeNomineeElements = document.getElementsByClassName('validation1');
    let employeeNomineeElements2 = document.getElementsByClassName('validation2');
    
    // Iterate through each element in the collection
    for (let i = 0; i < employeeNomineeElements.length; i++) {
      // Attach input event listener to each element
      employeeNomineeElements[i].addEventListener('input', inputHandler);
    }
    for (let i = 0; i < employeeNomineeElements2.length; i++) {
      // Attach input event listener to each element
      employeeNomineeElements2[i].addEventListener('input', inputHandler2);
    }
  
    function inputHandler(e) {
      const error = document.getElementById('error');
      const value = e.target.value;
      if (regexName.test(value)) {
        error.textContent = '';
      } else {
        error.textContent = 'Numeric and Special Characters are not allowed';
        e.target.value = value.slice(0, value.length - 1);
      }
    }
    function inputHandler2(e) {
      const error = document.getElementById('error2');
      const value = e.target.value;
      if (regexName.test(value)) {
        error.textContent = '';
      } else {
        error.textContent = 'Numeric and Special Characters are not allowed';
        e.target.value = value.slice(0, value.length - 1);
      }
    }
  
    setBankDetails({
      ...BankDetails,
      [name]: value,
    });
  };

  const checkIFSCCodeValid = e => {
    let IFSCCode = e.target.value;
    setBankDetails({ ...BankDetails, [e.target.name]: IFSCCode });
    let regExp = '^[A-Z]{4}[0][A-Z0-9]{6}$';
    const error = document.getElementById('error0');
    console.log(IFSCCode.length,"IFSCCode.length")
    if (IFSCCode.match(regExp)) {
      error.textContent = '';
      setIsValid(true);
      getDetailsByifsc(IFSCCode);
    } 
    else if(!IFSCCode.match(regExp)){
      error.textContent = 'Enter a valid IFSC Code like IDFB0021019';
        setIsValid(false);
        setBankDetails({
          ...BankDetails,
          [e.target.name]: IFSCCode,
          emp_bank_name: '',
          emp_bank_branch_name: '',
        });
    }
    // else if (IFSCCode.length > 10 && IFSCCode.length !== 11) {
    //   error.textContent = 'Enter a valid IFSC Code like IDFB0021019';
    //   setIsValid(false);
    //   setBankDetails({
    //     ...BankDetails,
    //     emp_bank_name: '',
    //     emp_bank_branch_name: '',
    //   });
    // }
     else {
      setIsValid(false);
    }
  };

  return (
    <div>
      <form>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label for="exampleInputEmail1">
                IFSC Code <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="name"
                className="form-control"
                id="ifsc"
                
                value={BankDetails.emp_ifsc_code}
                onChange={e => checkIFSCCodeValid(e)}
                name="emp_ifsc_code"
                maxLength="11"
                placeholder="Enter IFSC Code"
              />
              <small style={{ color: 'red' }} id="error0"></small>
              
            </div>

            <div className="form-group">
              <label for="exampleInputEmail1">
                Bank Name <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                disabled={isValid ? true : false}
                type="name"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="emp_bank_name"
                placeholder="Enter Bank Name"
                value={BankDetails.emp_bank_name}
                onChange={onChangeInputBankDetails}
                readOnly
              />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">
                Branch Name <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                disabled={isValid ? true : false}
                type="name" 
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="emp_bank_branch_name"
                value={BankDetails.emp_bank_branch_name}
                onChange={onChangeInputBankDetails}
                placeholder="Enter Branch Name"
                readOnly
              />
            </div>
            <div className="form-group">
              <label for="exampleInputEmail1">
                Relationship with Nominee{' '}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                name="emp_nominee_relation"
                onChange={onChangeInputBankDetails}
                placeholder=""
                value={BankDetails.emp_nominee_relation}
              >
                <option hidden>Select Relationship with nominee</option>
                <option value="father">Father</option>
                <option value="mother">Mother</option>
                <option value="guardian">Guardian</option>
                <option value="grandparents">Grand Parents</option>
                <option value="spouse">Spouse</option>
                <option value="children">Children</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label for="exampleInputPassword1">
                Bank Account Holder Name <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="name"
                className="form-control validation1"
                id="exampleInputPassword1"
                value={BankDetails.emp_bank_account_holder_name}
                onChange={onChangeInputBankDetails}
                name="emp_bank_account_holder_name"
                placeholder="Enter Bank Account Holder Name"
                
              />
              <small style={{ color: 'red' }} id="error"></small>
            </div>

            <div className="form-group">
              <label for="exampleInputPassword1">
                Bank Account No. <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="exampleInputPassword1"
                value={BankDetails.emp_bank_account_number}
                onChange={onChangeInputBankDetails}
                name="emp_bank_account_number"
                placeholder="Enter Bank Account No."
              />
            </div>

            <div className="form-group">
              <label for="exampleInputEmail1">
                Full Name of Nominee <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="name"
                className="form-control validation2"
                id="emp_nominee"
                aria-describedby="emailHelp"
                name="emp_nominee"
                value={BankDetails.emp_nominee}
                onChange={onChangeInputBankDetails}
                placeholder="Enter Name of Nominee"
              />
             <small style={{ color: 'red' }} id="error2"></small>
            </div>

            <div className="">
              {isSubmit ? (
                <button
                  className="btn btn-primary float-right"
                  style={{ marginTop: '130px' }}
                  type="button"
                  onClick={saveBankDetails}
                >
                  Submit
                </button>
              ) : (
                <>
                  <button
                    className="btn btn-success float-right ml-1"
                    style={{ marginTop: '130px' }}
                    type="button"
                    onClick={e => onClickActive(8)}
                  >
                    Next{' '}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>
                  <button
                    className="btn btn-info float-right"
                    style={{ marginTop: '130px' }}
                    type="button"
                    onClick={updateBankDetails}
                  >
                    Update
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
      <Alert
        isAlert={isAlert}
        isAlertToggle={isAlertToggle}
        alertMessage={alertMessage}
        isAlertToggleClose={isAlertToggleClose}
      />
      <ToastContainer style={{marginTop:"40px"}}/>
    </div>
  );
};
export default BankDetails;