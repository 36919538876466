import React from 'react';
import { convertDate } from 'src/utils/common';
import { NoRecords } from 'src/reusable';

const CoffAppliedList = props => {
  const { data, type, renderDataStatus } = props;
  let list = null;
  if (data?.length) {
    list = data.map((item, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{convertDate(item?.date)}</td>
          <td>{item?.comp_off_status || ''}</td>
          <td>{renderDataStatus(item, type)}</td>
          <td>{convertDate(item.created_at, 'YYYY-MM-DD')}</td>
        </tr>
      );
    });
  }
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Comp Off Date</th>
          <th scope="col">Comp Off Status</th>
          <th scope="col">Approval Status</th>
          <th scope="col">Created Date</th>
        </tr>
      </thead>
      <tbody>{list || <NoRecords tag="tr" colSpan={6} />}</tbody>
    </table>
  );
};

export default CoffAppliedList;
