
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
    CTextarea
} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'


const JustificationModal = (props) => {
    const { isOpenJustification, isJustificationModalOpen, rejectMrf } = props

    const [isDeclinedComment, setIsDeclinedComment] = useState("")
    // const [isModalOpen, isModalOpen] = useState(false)
    useEffect(() => {

    }, [])
    // const isModalOpen = () => {

    // }
    const rejectMrfFun = () => {
        rejectMrf(isDeclinedComment)
    }
    return (
        <div className="">
            <CModal
                show={isOpenJustification}
                onClose={isJustificationModalOpen}
                color="warning"
                style={{paddingTop:"5%"}}
            >
                <CModalHeader closeButton>
                    <CModalTitle>Are you sure you wanna proceed</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CForm action="" encType="multipart/form-data" className="form-horizontal">

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel ><strong>Kindly justify</strong></CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CTextarea

                                    name="isDeclinedComment"
                                    value={isDeclinedComment}
                                    onChange={(e) => setIsDeclinedComment(e.target.value)}
                                    rows="3"
                                    placeholder="Comment..." required
                                /><CFormText>Please enter the justification</CFormText>
                            </CCol>
                        </CFormGroup>

                    </CForm>
                </CModalBody>
                <CModalFooter>
                    <CButton color="success" onClick={rejectMrfFun}><i className="fa fa-paper-plane mr-2" aria-hidden="true"></i>Submit</CButton>{' '}
                    <CButton color="danger" onClick={isJustificationModalOpen}><i className="fa fa-times" aria-hidden="true"></i>
</CButton>

                </CModalFooter>
            </CModal>

        </div>

    )
}
export default JustificationModal




