/* eslint-disable default-case */
import React, { useState } from 'react'
import API from 'src/utils/apiCalling'
import { config } from 'src/utils/apiUrl'
import {

  CModal,

  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,

  CButton,


} from '@coreui/react'
import { toast, ToastContainer } from 'react-toastify';

const ModalForItDeclaration = (props) => {
  const api = new API()
  const { isOpen, isViewModalOpen, getAllInvestment } = props
  const [stepId, setStepId] = useState(1)
  const [subCategories, setSubCategories] = useState([
    {
      "subCategoriesName": "",
      "isMaxLimit": "",
      "maxLimit": ""
    }
  ])
  const [categories, setCategories] = useState({

    "category": "",
    "max_limit": "",
    "amount": "",
    "is_eligible_for_old":0,
    "is_eligible_for_new":0

  })
  const [categoriesId, setCategoriesId] = useState()
  const handleCategogies = (e) => {
    const { name, value } = e.target
    if(name=="is_eligible_for_old" || name=="is_eligible_for_new"){
      let eligibility = e.target.checked==true ? 1 : 0;
      setCategories({ ...categories, [name]: eligibility })
    }else{
      setCategories({ ...categories, [name]: value })
    }
  }
  const saveForm = async (id) => {

    let count = stepId + 1

    switch (id) {
      case 1:
        let data = categories;
        let result = await api.post(config.addInvestmentCategory, data)
        if (result && result.code === 200) {
          setCategoriesId(result.data && result.data && result.data.category_id)
          setStepId(count)
        } else {
          toast.warning(result && result?.message)
        }
        break
      case 2:
        setStepId(count)
        break
      //
      case 3:
        let subCategoriesData = {
          "category_id": categoriesId,
          "sub_category": JSON.stringify(subCategories)

        }
        let result2 = await api.post(config.addsubcategory, subCategoriesData)
        if (result2 && result2.code === 200) {
          setStepId(count)
          isViewModalOpen(!isOpen);
          getAllInvestment();
          toast.success("IT declaration added successfully")
        }


        break

      //
    }



  }
  const addSubCategories = () => {

    setSubCategories([...subCategories, { "subCategoriesName": "", "isMaxLimit": "", "maxLimit": "" }])
  }
  const deleteSubCategories = (id) => {
    let newSubCategories = subCategories.filter((item, index) => (index != id))
    setSubCategories([...newSubCategories])
  }
  const handleSubCategories = (e, index) => {
    const { name, value } = e.target
    let newSubCategories = [...subCategories]
    let obj = newSubCategories[index]
    obj = { ...obj, [name]: value }
    newSubCategories[index] = obj
    setSubCategories([...newSubCategories])



  }
  return (
<>
    <CModal
      show={isOpen}
      onClose={() => isViewModalOpen(!isOpen)}
      size="xl"
    >
      <CModalHeader closeButton>
        <CModalTitle><strong>Add IT Declaration</strong></CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="modal-body">
          {stepId === 1 &&
            <div>
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: "0%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Name Of IT Declaration</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter name of IT declaration" name="category" value={categories.category} onChange={(e) => handleCategogies(e)} />
                  <small id="emailHelp" className="form-text text-muted">Please Enter name of IT declaration Example 80CC,80D etc.</small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Has Max. Limit ?</label>
                  <div className="custom-control custom-radio custom-control">
                    <input type="radio" id="customRadioInline1" name="max_limit" value={true} onChange={(e) => handleCategogies(e)} className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="customRadioInline1">Yes</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline2" name="max_limit" value={false} onChange={(e) => handleCategogies(e)} className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="customRadioInline2">No</label>
                  </div>

                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Enter Max. Limit</label>
                  <input type="number" className="form-control" id="exampleInputEmail1" name='amount' value={categories.amount} onChange={(e) => handleCategogies(e)} aria-describedby="emailHelp" placeholder="Enter Max Limit...." />
                  <small id="emailHelp" className="form-text text-muted">Enter maximun limit of this declaration.</small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Eligibility for Tax Regime ?</label>
                  <div className="custom-checkbox" style={{paddingLeft:"2.5%"}}>
                    <input type="checkbox" id="customCheckboxInline1" name='is_eligible_for_old' checked={categories?.is_eligible_for_old==1 ? true : false} onClick={(e) => handleCategogies(e)} className="custom-control-input" />
                    <label className="custom-control-label" htmlFor='customCheckboxInline1'>Old Regime</label>
                  </div>
                  <div className="custom-checkbox" style={{paddingLeft:"2.5%"}}>
                    <input type="checkbox" id="customCheckboxInline2" name='is_eligible_for_new' checked = {categories?.is_eligible_for_new==1 ? true : false} onClick={(e) => handleCategogies(e)} className="custom-control-input" />
                    <label className="custom-control-label" htmlFor='customCheckboxInline2'>New Regime</label>
                  </div>
                </div>
              </form>

            </div>
          }
          {stepId === 2 &&
            <div>
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: "50%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
              <table className="table">


                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Has Max Limit</th>
                    <th scope="col">Amount Of Max. Limit</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    subCategories && subCategories.length > 0 && subCategories.map((item, index) => (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <textarea className='form-control' type="textarea" name="subCategoriesName" value={item.subCategoriesName} onChange={(e) => handleSubCategories(e, index)}></textarea >
                        </td>
                        <td>  <select className='form-control' type="select" name="isMaxLimit" onChange={(e) => handleSubCategories(e, index)}>
                          <option  >Select</option>
                          <option value={true} >Yes</option>
                          <option value={false}>No</option>
                        </select>
                        </td>
                        <td>
                          <input className='form-control' name="maxLimit" type="number" disabled={item.isMaxLimit == "false"} value={item.isMaxLimit == "false" ? "" : item.maxLimit} onChange={(e) => handleSubCategories(e, index)}></input></td>
                        <td>
                          {index > 0 &&
                            <i className="fa fa-trash" aria-hidden="true" onClick={() => deleteSubCategories(index)}></i>
                          }

                        </td>
                      </tr>
                    ))
                  }

                </tbody>
              </table>
              <i className="fa fa-plus-circle fa-2x" onClick={addSubCategories} aria-hidden="true"></i>
            </div>
          }
          {
            stepId === 3 &&
            <div>
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: "100%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>
              <p style={{ textAlign: "center", marginTop: "10px", fontWeight: "bold" }}>80 CC Investment <span className='badge badge-danger' style={{ color: "white", width: "150px", height: "30px", fontSize: "15px", textAlign: 'center', fontWeight: "bolder" }}>`Max Limit: ${categories.amount}`</span></p>
              <hr />
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">S No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Max. Limit</th>
                    <th scope="col">Amount(Max limit)</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    subCategories && subCategories.length > 0 && subCategories.map((item, index) => (

                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.subCategoriesName}</td>
                        <td>{item.isMaxLimit}</td>
                        <td><p className='form-control' disabled>{item.maxLimit}</p></td>
                      </tr>

                    ))}



                </tbody>
              </table>
            </div>
          }
          


        </div>



      </CModalBody>
      <CModalFooter>
        

  {stepId<=2&&
  <CButton type="button" className="btn btn-primary" onClick={()=>saveForm(stepId)}>Next</CButton>
 }

       {         stepId===3&&
         <CButton type="button" className="btn btn-primary" onClick={()=>saveForm(stepId)}>Submit</CButton>

       }

      </CModalFooter>
    </CModal>
    <ToastContainer />
</>
  )
}

export default ModalForItDeclaration