import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from '@coreui/react';
import { withCookies } from 'react-cookie';
import CIcon from '@coreui/icons-react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from 'sweetalert';
const TheHeaderDropdown = props => {
  const api = new API();
  const { cookies } = props;
  const [profileImageShow, setProfileImageShow] = useState('');

  useEffect(() => {
    getProfileImage();
  }, []);

  const logging_out = () => {
    swal({
      html: true,
      title: 'Are you sure to logout?',
      icon: 'success',
      buttons: ['No, cancel it!', 'Yes'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        window.location.href = '/#/';
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        cookies.remove('token');
        cookies.remove('user');
      }
    });
  };
  const getProfileImage = async () => {
    let result = await api.get(config.getEmployeeProfilePic);
    // console.log("u+++++++++++++++++++++_______________________", result)
    if (result && result.code == 200) {
      // console.log("_______________", result.data.root_path+"/"+result.data.data[0].filename)
      setProfileImageShow(
        result.data.root_path + '/' + result.data.data[0].filename,
      );
    } else {
      // console.log(result && result.message);
    }
  };
  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={profileImageShow ? profileImageShow : 'avatars/6.jpg'}
            width={'700'}
            height={'600'}
            className="c-avatar-img"
            // alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Account</strong>
        </CDropdownItem> */}
        {/* <CDropdownItem>
           <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-envelope-open" className="mfe-2" />
          Messages
          <CBadge color="success" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-task" className="mfe-2" />
          Tasks
          <CBadge color="danger" className="mfs-auto">42</CBadge>
        </CDropdownItem> */}
        {/* <CDropdownItem>
          <CIcon name="cil-comment-square" className="mfe-2" />
          <Link to="/dashboard/my_info"><strong>My Info</strong></Link>
        </CDropdownItem> */}
        {/* <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" />
         <strong> Settings</strong>
        </CDropdownItem> */}
        <CDropdownItem>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          <Link to="/dashboard/ChangePassword">
            <strong>Change Password</strong>
          </Link>
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem onClick={logging_out}>
          <CIcon name="cil-input" className="mfe-2" />
          <strong> Log Out </strong>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default withCookies(TheHeaderDropdown);
