import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  CRow,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CButton,
  CCardFooter,
  CCol,
  CForm,
  CFormGroup,
} from '@coreui/react';

import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';

const ViewCandidateModal = props => {
  let api = new API();
  const { toggleCandidateModal, candidateModalOpen, selectedItem } = props;
  const [candidateResume, setCandidateResume] = useState();

  useEffect(() => {
    setCandidateResume();
    getResume();
  }, [selectedItem]);

  const getResume = async () => {
    let data = {
      candidate_id: selectedItem?.candidate_id,
    };
    let result = await api.get(config.getCandidateResume, true, data);
    if (result && result.code === 200) {
      if (result.data[0].resume) {
        setCandidateResume(result.data[0].path + '/' + result.data[0].resume);
      }
    } else {
    }
  };

  const downloadResume = async () => {
    if (!candidateResume) {
      alert('Resume Not Available');
    }
  };

  return (
    <div className="">
      <CModal
        show={candidateModalOpen}
        onClose={() => toggleCandidateModal()}
        size="xl"
      >
        <CModalHeader closeButton>
          <CModalTitle>Candidate Profile</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="">
            <CForm
              action=""
              encType="multipart/form-data"
              className="form-horizontal"
            >
              <CRow>
                <CCol md="6">
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Candidate ID</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.candidate_id || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Name</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.name || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Email</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.c_email || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>DOB</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.dob || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Designation</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.designation || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Mobile</td>
                    </CCol>

                    <CCol xs="12" md="9">
                      <th>{selectedItem?.c_mobile || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Address</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>
                        {selectedItem?.address
                          ? selectedItem?.address
                          : selectedItem?.current_location
                          ? selectedItem?.current_location
                          : 'N/A'}
                      </th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Pin Code</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.pincode || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Experience</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.experience || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Technology</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.technology_knowledge || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                </CCol>
                <CCol md="6">
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Education</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.education || 'N/A'}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>MRF ID</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>{selectedItem?.mrf_id}</th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>MRF For</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>
                        {selectedItem &&
                        selectedItem.mrf_applying_for === 'vacancy'
                          ? 'New Hiring'
                          : 'Replacement'}
                      </th>
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <td>MRF Raised By</td>
                    </CCol>

                    <CCol xs="12" md="9">
                      <th>{selectedItem && selectedItem.mrf_raised_by}</th>
                    </CCol>
                  </CFormGroup>

                  <CFormGroup row>
                    <CCol md="3">
                      <td>Created By</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>
                        {selectedItem && selectedItem.candidate_created_by}
                      </th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Interview Letter</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>
                        {selectedItem &&
                        selectedItem.send_interview_letter_status === 0
                          ? 'Not Send'
                          : 'Sent'}
                      </th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Overall Status</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>
                        {selectedItem &&
                        selectedItem.overall_interview_status === 0
                          ? 'Not Selected'
                          : 'Selected'}
                      </th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Offer Letter</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>
                        {selectedItem &&
                        selectedItem.is_offer_letter_status === 0
                          ? 'Not Send'
                          : 'Sent'}
                      </th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Offer Letter Sent</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>
                        {selectedItem && selectedItem.offer_letter_send_date
                          ? moment(
                              selectedItem &&
                                selectedItem.offer_letter_send_date,
                            ).format('DD MMMM YYYY hh:mm A')
                          : 'N/A'}
                      </th>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol md="3">
                      <td>Created At</td>
                    </CCol>
                    <CCol xs="12" md="9">
                      <th>
                        {moment(
                          selectedItem && selectedItem.candidate_created_at,
                        ).format('DD MMMM YYYY hh:mm A')}
                      </th>
                    </CCol>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CCardFooter>
                {candidateResume && (
                  <CButton
                    data-toggle="tooltip"
                    data-placement="top"
                    color="info"
                    onClick={downloadResume}
                  >
                    <a
                      href={candidateResume}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      <b style={{ color: 'white' }}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                        &nbsp;Download Resume
                      </b>
                    </a>
                  </CButton>
                )}
              </CCardFooter>
            </CForm>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};
export default ViewCandidateModal;
