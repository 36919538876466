import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CDataTable,
  CBadge,
  CForm,
  CFormGroup,
  CFormText,
  CInput,
  CInputFile,
  CInputRadio,
  CLabel,
  CSelect,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import ModalOnboardingDocuments from 'src/admin-dashboard/accounts/documentsAdminSection/modalOnboardingDocuments';
import { getPermissions, isPermission } from 'src/utils/common';

const MyInfoTopSection = () => {

  const api = new API()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {

  }, [])
  const isViewModalOpen = () => {
    setIsOpen(!isOpen)
  }


  return (
    <>
      <CCardHeader>
    <div className="main-dd" style={{minHeight:"35px"}}>
      <div className="name">
         <h4> <strong>Profile Info</strong></h4>&nbsp;&nbsp;
         {isPermission('edit_document') &&
         <div className="btn btn-success" style={{float:"right",marginLeft:'auto'}} onClick={isViewModalOpen}>Edit Documents</div>
  }
      </div>
     </div>
      </CCardHeader>
      <ModalOnboardingDocuments
        isOpen={isOpen}
        isViewModalOpen={isViewModalOpen}
        // empDetails={empDetails}
        hideSection={true}
      />
    </>
  )
}
export default MyInfoTopSection
