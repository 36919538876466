import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import Hr_Dashboard from './hr-manager-dashboard';
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CLabel,
  CSwitch,
  CInputFile,
  CRow,
  CDataTable,
  CCollapse,
} from '@coreui/react';
import SendOfferLaterModal from './common/SendOfferLaterModal';
import Ctc from './common/ctc';
import ModalPopup from './common/modal';
import CondidateModal from './condidate/modal';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import swal from 'sweetalert';
import { isPermission } from 'src/utils/common';
const ListOfSelectedCondidate = () => {
  let api = new API();
  const [large, setLarge] = useState(false);
  const [candidate, setCandidate] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [SelectedCondidate, setSelectedCondidate] = useState();
  const [details, setDetails] = useState([]);

  const fields = [
    { label: 'S.N.', key: 'id', _style: { width: '5%' } },
    { label: 'ID', key: 'id', _style: { width: '5%' } },
    { label: 'Name', key: 'name', _style: { width: '10%' } },
    { label: 'Email', key: 'email', _style: { width: '10%' } },
    { label: 'DOB', key: 'dob', _style: { width: '10%' } },
    { label: 'Designation', key: 'designation', _style: { width: '10%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
  ];
  useEffect(() => {
    selectedCandidateFun();
  }, []);

  const selectedCandidateFun = async candidate => {
    let result = await api.get(config.getSelectedCandidateByHrRecruiter);
    // console.log("condidate selectd", result)
    if (result && result.code == 200) {
      // console.log("get selected", result)
      setSelectedCondidate(result.data);
      return result.data.length;
    } else {
      // alert(result.message)
      // console.log(result.message)
      setSelectedCondidate();
    }
  };

  const isModalOpen = condidate => {
    setLarge(!large);
    setCandidate(condidate);
  };
  const isViewModalOpen = condidate => {
    setIsOpen(!isOpen);
    setCandidate(condidate);
  };
  const createNewCondidate = candidate => {
    setIsModal(!isModal);
  };

  const toggleDetails = index => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const finalAcceptCandidate = async candidate => {
    // alert("final accept by hr manager" + candidate.id)
    // console.log(candidate)
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to Approve  ${candidate.name}`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Approve'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let data = {
          candidate_id: candidate.id,
        };
        let result = await api.post(config.approvalForSelectedCandidate, data);
        // console.log("approve", result)
        if (result && result.code == 200) {
          // console.log("candidate approved by hr-manager", result)
          selectedCandidateFun();
        } else {
          alert(result.message);
        }
      }
    });
  };

  const finalDeclineCandidate = candidate => {
    // alert("final decline by hr manager")
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to Decline  ${candidate.name}`,
      icon: 'warning',
      buttons: ['No, cancel it!', 'Decline'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let data = {
          candidate_id: candidate.id,
        };
        let result = await api.post(config.declineSelectedCandidate, data);
        if (result && result.code == 200) {
          // console.log("candidate approved by hr-manager", result)
          selectedCandidateFun();
        } else {
          alert(result.message);
        }
      }
    });
  };
  return (
    <div className="">
      <div className="container">
        <h3 className="text-uppercase text-center mt-3 mb-4"><u>
          {' '}
          List Of Selected Candidate
          </u>
        </h3>
        {/* <button className="btn btn-primary" style={{ float: "right" }} onClick={createNewCondidate}>Create New Condidate</button> */}
        <CCard>
          <CCardBody>
            <CDataTable
              items={SelectedCondidate}
              fields={fields}
              tableFilter
              itemsPerPageSelect
              itemsPerPage={10}
              hover
              sorter
              pagination
              scopedSlots={{
                id: (item, index) => {
                    return (
                    <td>
                     {index+1}
                    </td>
                  );
                },
                show_details: (item, index) => {
                  return (
                    <td className="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        shape="square"
                        size="sm"
                        onClick={() => {
                          toggleDetails(index);
                        }}
                      >
                        {details.includes(index) ? 'Hide' : 'Show'}
                      </CButton>
                    </td>
                  );
                },
                details: (item, index) => {
                  return (
                    <CCollapse show={details.includes(index)}>
                      <p className="text-muted">
                        MRF Type: {item.mrf_applying_for==='replacement' ? 'Replacement' : 'New Hiring'}
                      </p>
                      <CCardBody>
                        {isPermission('view_selected_candidate_hr_manager') &&
                        <CButton
                          data-toggle="tooltip"
                          data-placement="top"
                          title="View"
                          className="btn btn-primary ml-1"
                          onClick={() => isViewModalOpen(candidate)}
                        >
                          View
                        </CButton>
                       }
                       {isPermission('approve_selected_candidate_hr_manager') &&
                        <CButton
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Accept"
                          className="btn btn-success ml-1"
                          onClick={() => finalAcceptCandidate(candidate)}
                        >
                          Approve
                        </CButton>
                }
                {isPermission('reject_selected_candidate_hr_manager') &&
                        <CButton
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Decline"
                          className="btn btn-danger ml-1"
                          onClick={() => finalDeclineCandidate(candidate)}
                        >
                          Reject
                        </CButton>
                }
                      </CCardBody>
                    </CCollapse>
                  );
                },
              }}
            />
          </CCardBody>
        </CCard>
        <SendOfferLaterModal
          isModalOpen={isModalOpen}
          large={large}
          candidate={candidate}
        />

        <ModalPopup
          isOpen={isOpen}
          isViewModalOpen={isViewModalOpen}
          candidate={candidate}
          popupFor={'selectedCondidate'}
          modalHeader="View Candidate"
        />

        <CondidateModal isModalOpen={createNewCondidate} isModal={isModal} />

        {/* <Ctc/> */}
      </div>
    </div>
  );
};
export default ListOfSelectedCondidate;
