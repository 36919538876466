import { createReducer } from 'redux-act';
import actions from './attendanceActions';

const initialState = {
  isGetAttendanceBasicDetailsPending: false,
  getAttendanceBasicDetailsRes: null,
  getAttendanceBasicDetailsResError: null,
};

//
const handleGetAttendanceBasicDetailsRequest = state => {
  return {
    ...state,
    ...{
        isGetAttendanceBasicDetailsPending: true,
        getAttendanceBasicDetailsRes: null,
        getAttendanceBasicDetailsResError: null,
    },
  };
};
const handleGetAttendanceBasicDetailsSuccess = (state, payload) => {
  return {
    ...state,
    ...{
        isGetAttendanceBasicDetailsPending: false,
        getAttendanceBasicDetailsRes: payload,
        getAttendanceBasicDetailsResError: null,
    },
  };
};
const handleGetAttendanceBasicDetailsError = (state, payload) => ({
  ...state,
  ...{
    isGetAttendanceBasicDetailsPending: false,
    getAttendanceBasicDetailsRes: null,
    getAttendanceBasicDetailsResError: payload,
  },
});

//

export default createReducer(
  {
    [actions.getAttendanceBasicDetailsRequest]: handleGetAttendanceBasicDetailsRequest,
    [actions.getAttendanceBasicDetailsSuccess]: handleGetAttendanceBasicDetailsSuccess,
    [actions.getAttendanceBasicDetailsError]: handleGetAttendanceBasicDetailsError,
  },
  initialState,
);
