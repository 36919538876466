
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import ViewSelectedComponents from '../common/viewSelectedComponents';
import { toast, ToastContainer } from 'react-toastify';


const ModalAccessManagement = (props) => {
    const { isModalOpen, message, isViewModalOpen, candidateDetails, addEmployeeEmpId, generatedEmpId, generateEmployeeId,generateEmpEmailType,generatedEmailType } = props
    const [popopFor, setPopupFor] = useState("")
    const [officialEmpId, setOfficialEmpId] = useState()
    const [generateEmpIdType, setGenerateEmpIdType] = useState("")
    const [selectedCandidateId, setSelectedCandidateId] = useState("")
    const [generateEmailType, setGenerateEmailType] = useState("")

    useEffect(() => {
        // alert(generatedEmpId)
        // if (generatedEmpId) {
        //     setOfficialEmpId(generatedEmpId)
        // } else {
            if(candidateDetails && candidateDetails.email == selectedCandidateId ){
                setOfficialEmpId(generatedEmpId)
                setGenerateEmailType(generatedEmailType)
                // return
            } else {
                setOfficialEmpId("")
                setSelectedCandidateId(candidateDetails && candidateDetails.email)
                setGenerateEmpIdType("")
                setGenerateEmailType("")
            }
        // }
    }, [candidateDetails, generatedEmpId])

    const generateEmp = () => {
        if(!generateEmpIdType) {
            toast.warning("Please select Emp ID type")
            return
        }
        if(!generateEmailType){
            toast.warning("Please select Email type")
            return
        }
        generateEmployeeId(generateEmpIdType)
        generateEmpEmailType(generateEmailType)
    }

    return (
        <div className="">
            <CModal
                show={isModalOpen}
                onClose={() => isViewModalOpen()}
                size="lg"
            >
                <CModalHeader closeButton>
                    <CModalTitle>{message}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form style={{width:"90%", margin:"auto"}}>
                        <div className="form-group">
                            <label for="emp_id">Employee Email ID</label>
                            <input type="text" disabled className="form-control" value={candidateDetails && candidateDetails.email} placeholder="" />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Employee Email Type</label>
                            <select type="select" className="form-control" value={generateEmailType} onChange={(e) => setGenerateEmailType(e.target.value)} id="generateEmailType"> 
                            <option value="">Please select email type</option>
                            <option value=".org">.org</option>
                            <option value=".com">.com</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Employee Emp ID</label>
                            <input type="text" className="form-control" value={officialEmpId} onChange={(e) => setOfficialEmpId(e.target.value)} id="emp_id" placeholder="Ex. QD1502 OR Q150 " />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Employee Emp ID</label>
                            <select type="select" className="form-control" value={generateEmpIdType} onChange={(e) => setGenerateEmpIdType(e.target.value)} id="generateEmpIdType"> 
                            <option value="">Please select Emp Id Type</option>
                            <option value="QD">QD</option>
                            <option value="Q">Q</option>
                            </select>
                        </div>

                    </form>
                </CModalBody>
                <CModalFooter>
                        <button type="button" className="btn btn-success float-right" onClick={() => addEmployeeEmpId(candidateDetails.id, officialEmpId,generateEmailType)}>Submit</button>
                    <CButton color="danger" onClick={() => isViewModalOpen()}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}
                </CModalFooter>
            </CModal>
            {/* <ToastContainer/> */}
        </div>
    )
}
export default ModalAccessManagement







