import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CForm,
  CFormGroup,
  CCol,
  CLabel,
  CInput,
} from '@coreui/react';
import { CustButton } from 'src/reusable';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';

const CallFeedbackAddModal = props => {
  let api = new API();
  const { open, handleCancel, selectedItem, setFeedbackAddModalOpen } = props;
  const [callEventsList, setCallEventsList] = useState(null);
  const [inputType, setInputType] = useState(null);
  const [feedback, setFeedback] = useState({
    ats_id: '',
    feedback_id: '',
    feedback_value: '',
    feedback_remark: '',
  });

  useEffect(() => {
    getCallEventsList();
  }, []);

  const onChangeInput = e => {
    let { name, value } = e.target;
    if (name === 'feedback_id') {
      let result = callEventsList.filter(
        val => parseFloat(val.id) === parseFloat(value),
      );
      setFeedback({ ...feedback, feedback_value: '' });
      if (result[0].input_type === 'datetime') {
        setInputType('datetime-local');
      } else {
        setInputType(result[0]?.input_type);
      }
    }
    setFeedback({ ...feedback, [name]: value, ats_id: selectedItem?.id });
  };

  const saveFeedbackFunc = async () => {
    let data = {
      ats_id: feedback?.ats_id || '',
      feedback_id: feedback?.feedback_id || '',
      feedback_value: feedback?.feedback_value || null,
      feedback_remark: feedback?.feedback_remark || '',
    };
    if (inputType && (!data?.feedback_value || data.feedback_value === '')) {
      toast.warning('Please input feedback value');
      return;
    }
    let result = await api.post(config.saveFeedback, data);
    if (result && result.code === 200) {
      toast.success(result?.message);
      setFeedbackAddModalOpen(!open);
    } else {
      console.log(result?.message);
    }
  };

  const getCallEventsList = async () => {
    let data = {
      type: '',
    };
    let result = await api.get(config.getCallFeedbackEvents, true, data);
    if (result && result.code === 200) {
      setCallEventsList(result.data);
    } else {
    }
  };

  const renderEventOption = () => {
    let options = null;
    if (callEventsList?.length) {
      options = callEventsList.map((val, key) => {
        return (
          <option key={key} value={val.id}>
            {val.category_key}
          </option>
        );
      });
    }
    return options;
  };

  const disableFeedbackButton = (feedback, inputType) => {
    if (!feedback?.feedback_id && !feedback?.feedback_remark) {
      return true || false;
    }
    if (!feedback?.feedback_remark) {
      return true || false;
    }
    if (
      inputType &&
      (!feedback?.feedback_value || feedback.feedback_value === '')
    ) {
      return true || false;
    }
  };

  return (
    <div className="">
      <CModal show={open} onClose={handleCancel} size="md">
        <CModalHeader className={'modal-header-info'}>
          <CModalTitle>Add Call Feedback</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {/* <CButton
            className="float-right"
            color="danger"
            onClick={() => handleCancel()}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton> */}
          <div>
            <CForm
              action=""
              encType="multipart/form-data"
              className="form-horizontal"
            >
              <CFormGroup row>
                <CCol md="4">
                  <CLabel>
                    Feedback Events <span className="required-field">*</span>
                  </CLabel>
                </CCol>

                <CCol xs="12" md="8">
                  <select
                    type="select"
                    onChange={e => onChangeInput(e)}
                    name="feedback_id"
                    placeholder=""
                    className="form-control"
                    required={true}
                  >
                    <option value="">Select Event</option>
                    {renderEventOption()}
                  </select>
                </CCol>
              </CFormGroup>
              {inputType && (
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel></CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <CInput
                      type={inputType}
                      onChange={e => onChangeInput(e)}
                      name="feedback_value"
                      placeholder="Enter Value"
                      required={true}
                    />
                  </CCol>
                </CFormGroup>
              )}
              <CFormGroup row>
                <CCol md="4">
                  <CLabel>
                    Remark <span className="required-field">*</span>
                  </CLabel>
                </CCol>
                <CCol xs="12" md="8">
                  <CInput
                    onChange={e => onChangeInput(e)}
                    name="feedback_remark"
                    placeholder="Enter Remark"
                    required={true}
                  />
                </CCol>
              </CFormGroup>
            </CForm>
          </div>
        </CModalBody>

        <CModalFooter>
          <CustButton
            type="button"
            name="ok"
            text="Submit"
            color="primary"
            onClick={saveFeedbackFunc}
            className="btn-loading btn-ladda mr-3"
            icon="fa-paper-plane"
            isDisabled={disableFeedbackButton(feedback, inputType)}
          />
        </CModalFooter>
      </CModal>
      <ToastContainer />
    </div>
  );
};
export default CallFeedbackAddModal;
