
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import swal from "sweetalert";
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast, ToastContainer } from 'react-toastify';

const ModalInterviewAssign = (props) => {
    const { sendInterviewLatter, isModalOpen2, message, candidateDetails, InterviewRoundDetails, getCreatedCandidate } = props
    const api = new API()
    const [date, setDate] = useState()
    const [time, setTime] = useState("09:00")
    const [interviewMeetingLink, setInterviewMeetingLink] = useState("")
    const [comment, setComment] = useState("")
    // const [InterviewRoundDetails, setInterviewRoundDetails] = useState()
    const [isInterviewerFullDetails, setIsInterviewerFullDetails] = useState(false)
    
    useEffect(() => {
        // getInterviewRoundDetails()
    }, [])

    // const getInterviewRoundDetails = async () => {
    //     alert("getting")
    //     let data = {
    //         mrf_id: candidateDetails && candidateDetails.mrf_id,
    //         applying_for: candidateDetails && candidateDetails.mrf_applying_for
    //     }
    //     let result = await api.get(config.getInterviewRoundForMrf, true, data)
        // console.log("sending interview admin card", result)
    //     if (result && result.code == 200) {
    //         alert("successfully getting interview Details")
    //         setInterviewRoundDetails(result.data)
    //     }
    //     else {
    //         alert(result.message)
    //     }
    // }
    // const isModalOpen = () => {

    // }
    const sendInterviewLatterFun = async () => {
        // console.log(candidateDetails)
        if (date == "" || date == undefined || time == "" || time == undefined) {
            alert("please fill date and time")
        } else {


            let data = {
                candidate_id: candidateDetails && candidateDetails.id,
                candidate_email: candidateDetails && candidateDetails.email,
                date: date,
                time: time,
                interview_meeting_link : interviewMeetingLink,
                comment : comment,
                designation: candidateDetails && candidateDetails.designation,
                mrf_applying_for: candidateDetails && candidateDetails.mrf_applying_for,
                mrf_id: candidateDetails && candidateDetails.mrf_id,
            }
            let result = await api.post(config.sendInterviewLetter, data)
            // console.log("sending interview admin card", result)
            if (result && result.code == 200) {
                alert("successfully sent interview latter")
                sendInterviewLatter()

            }
            else {
                alert(result.message)
            }
        }
    }

    const AssignInterview = async () => {

        let data = {
            mrf_id: candidateDetails && candidateDetails.mrf_id,
            applying_for: candidateDetails && candidateDetails.mrf_applying_for,
            candidate_id: candidateDetails && candidateDetails.id,
            date: date,
            time: time,
            interview_meeting_link : interviewMeetingLink,
            comment : comment,
        }
        //  console.log(data)   
        if (date == "" || date == undefined || time == "" || time == undefined) {
            // alert("please fill date and time")
            toast.warn("Please fill date and time")
        } else {
            swal({
                html: true,
                title: "Are you sure?",
                text: `Are you sure to assign Interview to ${InterviewRoundDetails && InterviewRoundDetails[0].interview_round}`,
                icon: "success",
                buttons: ["No, cancel it!", "Assign"],
                dangerMode: true,
            }).then(async function (isConfirm) {
                if (isConfirm) {
                    // console.log(candidateDetails)

                    let result = await api.post(config.assignCandidateIntervToInterviewer, data)
                    //    console.log("sending interview admin card", result)
                    if (result && result.code == 200) {
                        toast.success("successfully sent interview latter")
                        //alert("successfully sent interview latter")
                        sendInterviewLatter(candidateDetails)
                        getCreatedCandidate()
                        //    sendInterviewLatter()
                        //    }
                        //    else {
                        //    alert(result.message)
                        //    }
                    } else {
                        toast.error("unable to asign interview")
                        // alert("unable to asign interview")
                    }
                }
            })
        }
    }
    const interviewerFullDetails = () => {
        setIsInterviewerFullDetails(!isInterviewerFullDetails)
    }
    return (
        <div className="">

            <CModal
                show={isModalOpen2}
                onClose={() => sendInterviewLatter(candidateDetails)}
                size="lg"
            >

                <CModalHeader closeButton>
                    <CModalTitle>{message}</CModalTitle>
                </CModalHeader>
                <CModalBody>

                    <CForm action="" encType="multipart/form-data" className="form-horizontal">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Date</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput type="date" value={date} onChange={(e) => setDate(e.target.value)} name="date" placeholder="" />
                            </CCol>
                        </CFormGroup>
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Time</CLabel>
                            </CCol>


                            <CCol xs="12" md="9">
                                <CInput value={time} onChange={(e) => setTime(e.target.value)} name="time" placeholder="09:00" />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Assign Interview Round</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput value={InterviewRoundDetails && InterviewRoundDetails[0].interview_taken_by_name} name="time" placeholder="" />
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Meeting Link</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput onChange={(e)=>setInterviewMeetingLink(e.target.value)} value={interviewMeetingLink} name="interview_meeting_link" placeholder="Enter Meeting Link"/>
                            </CCol>
                        </CFormGroup>

                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel>Comment</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <CInput onChange={(e)=>setComment(e.target.value)} value={comment} name="comment" placeholder="Enter comment" />
                            </CCol>
                        </CFormGroup>
                        {isInterviewerFullDetails &&
                            <>
                                {InterviewRoundDetails && InterviewRoundDetails.map((data, index) => (
                                    <div className="card">
                                        <div className="card-header">
                                            <h6><b>Interview Round : {data.interview_round}</b></h6>
                                        </div>
                                        <div className="card-body">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Interviewer ID</td>
                                                        <td>{data.interview_taken_by_id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Interviewer Name</td>
                                                        <td>{data.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Interviewer Email</td>
                                                        <td>{data.interview_taken_by_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Interviewer Department</td>
                                                        <td>{data.interview_department}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Interviewer Designation</td>
                                                        <td>{data.interview_taken_by_role}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                        <CCardFooter>
                            <CButton type="button" className="btn btn-primary mr-3 mt-2" onClick={AssignInterview}>Assign Interview</CButton>
                            <CButton type="button" className="btn btn-info mr-3 mt-2" onClick={interviewerFullDetails}>{isInterviewerFullDetails === false ? "Interviewer Full Details" : <span style={{ color: "white" }}>Interviewer Full Details</span>}</CButton>
                        </CCardFooter>

                    </CForm>


                </CModalBody>
                <CModalFooter>
                    <CButton color="danger" onClick={() => sendInterviewLatter(candidateDetails)}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}

                </CModalFooter>
            </CModal>


            <ToastContainer />

        </div>

    )
}
export default ModalInterviewAssign;