import React, { useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CDataTable,
} from '@coreui/react';
import { CustButton } from 'src/reusable';
import '../views/messages/style.css';
import { useForceUpdate } from 'src/hooks';
import swal from 'sweetalert'
import { toast, ToastContainer } from 'react-toastify';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';

const AddConnectionModal = props => {
  const {
    open,
    handleCancel,
    empList,
  } = props;
  const api = new API()
  const forceUpdate = useForceUpdate();
  const [selectedEmp, setSelectedEmp] = useState([]);

  const handleOnSeleletEmp = userId => {
    const members = selectedEmp;
    const index = members.indexOf(userId);
    if (index > -1) {
      members.splice(index, 1);
    } else {
      members.push(userId);
    }
    setSelectedEmp(members);
    forceUpdate();
  };

  const handleSelectAll = () => {
    const members = selectedEmp;
    if (empList?.length) {
      let mIds = members;
      empList.map((item)=> {
        if (members?.length === empList?.length) {
          mIds = [];
        } else {
          mIds.push(item.emp_id);
        }
      });
      setSelectedEmp(mIds);
      console.log({mIds, members});
      forceUpdate();
    }
  }

  const handleOnSendCredentials = async () => {
    let data = {
      emp_ids: JSON.stringify(selectedEmp)
    };
    swal({
      html: true,
      title: "Are you sure?",
      text: `Are you sure to send credentials to selected employee`,
      icon: "success",
      buttons: ["No, cancel it!", "Send"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.sendCredentails,data);
        if (result && result.code === 200) {
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      }
    })
  };

  const fields = [
    {
      label: 'S.No.',
      key: 'sno',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
    { label: 'Emp ID', key: 'emp_id' },
    { label: 'Name', key: 'employee' },
    { label: 'Email', key: 'email' },
    { label: 'Action', key: 'action', sorter: false, filter: false },
  ];

  return (
    <div className="">
      <CModal
        show={open}
        onClose={handleCancel}
        size="xl"
        className={'overlap-modal'}
        backdrop={false}
      >
        <CModalHeader closeButton>
          <CModalTitle>Select Employee</CModalTitle>
        </CModalHeader>
        <CModalBody>
        <div className="col-sm-12 text-right" style={{paddingBottom:"5px",paddingRight:"initial"}}>
        <CustButton
            type="button"
            name="Send"
            text={selectedEmp?.length === empList?.length ? 'All Selected' : "Select All Employee"}
            color="info"
            onClick={handleSelectAll}
            className="btn-loading btn-ladda mr-3"
            icon=""
            isDisabled={false}
            isLoading={false}
          />
          </div>
        <div className="col-sm-12">
        
              <CDataTable
                items={empList}
                fields={fields}
                loading={false}
                hover={false}
                columnFilter
                sorter
                pagination
                scopedSlots={{
                  sno: (item, index) => <td>{index + 1}</td>,
                  action: (item, index) => (
                    <td>
                      <CustButton
                        text={
                          selectedEmp?.indexOf(item.emp_id) > -1
                            ? 'Selected'
                            : 'Select'
                        }
                        type="button"
                        name="connect"
                        size="sm"
                        color={
                          selectedEmp?.indexOf(item.emp_id) > -1
                            ? 'success'
                            : 'primary'
                        }
                        onClick={() => handleOnSeleletEmp(item.emp_id)}
                        className={'btn-loading btn-ladda'}
                        icon=""
                        isDisabled={false}
                        isLoading={false}
                      />
                    </td>
                  ),
                }}
              />
            </div>
        </CModalBody>

        <CModalFooter>
          {/* <CustButton
            type="button"
            name="ok"
            text="Close"
            color="danger"
            onClick={handleCancel}
            className="btn-loading btn-ladda mr-3"
            icon=""
            isDisabled={false}
            isLoading={false}
          /> */}
          <CustButton
            type="button"
            name="Send"
            text="Send"
            color="primary"
            onClick={handleOnSendCredentials}
            className="btn-loading btn-ladda mr-3"
            icon=""
            isDisabled={selectedEmp?.length > 0 ? false : true}
            isLoading={false}
          />
        </CModalFooter>
      </CModal>
      < ToastContainer/>
    </div>
  );
};
export default AddConnectionModal;
