import React, { useState, useEffect } from 'react';
import { TheFooter, TheHeader, TheSidebar } from 'src/containers';
import MrfDashboard from './mrfDashboard';



const LandingDashboard = () => {
 
  return (

    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <MrfDashboard/>
        
        </div>


        <TheFooter />
      </div>
   
    </div>

  )
}
export default LandingDashboard