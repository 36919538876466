import React, { useState, useEffect } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import './bgv.css';
import moment from 'moment';
import {toast, ToastContainer} from 'react-toastify';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import SignatureCanvas from 'react-signature-canvas';
const BgvAuthorizationConsent = props => {
  const { getSignFun, empDetails, policyData } = props;
  const api = new API();
  const currentDate = moment().format('DD-MM-YYYY');
  const [userName, setUserName] = useState('');
  const [signTrim, setSignTrim] = useState();
  const [sigPad, setsigPad] = useState('');
  const [bgvdata, setBGVdata] = useState('');
  const [userID, setUserID] = useState('');
  const [signType, setSignType] = useState('digital');
  const [base64Image, setBase64Image] = useState('');


  useEffect(() => {
    let userData = getLocalDataAsObject('user');
    console.log('user data is', userData);
    if (userData && userData.length > 0) {
      setUserName(userData[0].first_name + ' ' + userData[0].last_name);
      setUserID(userData[0].id);
    }
  }, []);

  const uploadImage = (e) => {
    const image = e.target.files[0]
    if (image) {
      const maxSize = (1024 * 1024) / 2; // 1 MB
      console.log(maxSize)
      if (image.size > maxSize) {
        toast.warning('File size exceeds the limit 500KB. Please choose a smaller file.');
      }
      else{
        const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Image(base64String);
        getSignFun(base64String)
      };
      reader.readAsDataURL(image);
      }
    }
    // if (image) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     const base64String = reader.result;
    //     setBase64Image(base64String);
    //     getSignFun(base64String)
    //   };
    //   reader.readAsDataURL(image);
    // }
};


  const trimSign = () => {
    let trim = sigPad.getTrimmedCanvas().toDataURL('image/png');
    setSignTrim(trim);
    getSignFun(trim);
  };
  const clearSign = () => {
    sigPad.clear();
    setSignTrim('');
  };

  const showBGVDetailsFun = async () => {
    let data = {
      emp_user_id: empDetails?.id ? empDetails?.id : userID,
    };
    let result = await api.get(config.showBGVDetails, true, data);
    if (result && result.code === 200) {
      setBGVdata(result.data[0]);
    } else {
      setBGVdata('');
    }
  };
  useEffect(() => {
    showBGVDetailsFun();
  }, [empDetails, userID]);

  return (
    <div className="bgvemail-container" style={{background: '#f1f1f1',
      borderLeft: '10px solid',
      borderRight: '10px solid',
      border: '10px solid',
      borderImageSlice : '1',
      borderWidth: '3px',
      backgroundRepeat: 'no-repeat',
      borderImageSource: 'linear-gradient(to right, #1e98d8,#a7cf4f)'}}>
      <center>
        <div style={{ margin: '0 auto', background: 'images/' }}>
          <img className="watermarked" src="images/UB.png" alt="" />
          <table
            align="center"
            role="presentation"
            cellSpacing="0"
            cellPadding="0"
            border="0"
            width="100%"
            style={{ margin: 'auto' }}
          >
            <img className="watermarked" src="images/UB.png" alt="" />
            <tr>
              <td
                valign="top"
                className="bg_white"
                style={{ padding: '1em 2.5em 0 2.5em' }}
              >
                <table
                  role="presentation"
                  border="0"
                  cellPadding="0"
                  cellSpacing="0"
                  width="100%"
                >
                  <tr>
                    <td className="logo" style={{ textAlign: 'center' }}>
                      <img src="images/glueple.png" alt="" style={{height:"10%",width:"20%"}}/>
                    </td>
                  </tr>
                </table>
                <hr className="line_rating" />
              </td>
            </tr>
          </table>

    <div>
            <h3>
              <strong>
                <u>POLICY ACKNOWLEDGEMENT </u>
              </strong>
            </h3>
          </div>
          <div className="bgvmiddletext">
            <strong>
              <b>To Whom It May Concern</b>
            </strong>
          </div>
          <div className="bgvmidtext" style={{ color: 'black' }}>
            <div>
            Please acknowledge your acceptance of these policies by signing and dating this document below. 
            Your adherence to these policies is essential in creating a respectful and productive work environment for all. 
            Failure to comply with these policies may result in disciplinary actions.
            </div>
            <br />
            <div>
            We appreciate your dedication to the success of <b>Unique Group</b> and look forward to your valuable contributions as a member of our team.
            </div>
           
            <br />

            <div className="bgvinput">
              <strong style={{ color: '#2a3c81' }}>
                Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{' '}
              </strong>
              &nbsp;{policyData?.first_name + ' ' + policyData?.last_name}
              <br />
              <strong style={{ color: '#2a3c81' }}>
                Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{' '}
              </strong>
              &nbsp;
              {policyData?.inserted_at ? policyData?.inserted_at : moment().format("DD-MM-YYYY")}
              <br />
              <strong style={{ color: '#2a3c81' }}>
                Place &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :{' '}
              </strong>
              &nbsp;{policyData?.location}
              <br />
              {((bgvdata?.emp_policy_status === 0) || (bgvdata?.emp_policy_status === undefined)) && (
              <div className='row'>
              <strong style={{ color: '#2a3c81',width:"15%"}}>Signature&nbsp; : </strong>
                                            <div className="form-group col-sm">
                                                <div className='row'>
                                                    <div className='col-sm-2' style={{marginLeft:"-25px"}}>
                                                        <input type="radio" checked={signType === "digital" ? true : false} name="signature" value={"digital"} onChange={(e) => setSignType(e.target.value)} id="digital" />
                                                    </div>
                                                    <div className='col-sm-2'>
                                                        <label htmlFor="digital" style={{marginLeft:"-20px"}}><b>Digital</b></label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="form-group col-sm">
                                                <div className='row'>
                                                    <div className='col-sm-2' style={{marginLeft:"-225px"}}>
                                                        <input type="radio" checked={signType === "image" ? true : false} name="signature" value={"image"} onChange={(e) => setSignType(e.target.value)} id="image" placeholder="" />
                                                    </div>
                                                    <div className='col-sm-2'>
                                                        <label htmlFor="image" style={{marginLeft:"-20px"}}><b>Image</b></label>
                                                    </div>
                                                </div>
                                            </div>
            </div>
              )}
              {bgvdata?.emp_policy_status === 1 ? (<>
                 <strong style={{ color: '#2a3c81',width:"12%"}}>Signature&nbsp; : </strong>
                <img src={bgvdata?.filename} height="40" width="90" alt="sign" />
                </>) : 
                signTrim && (
                  <img src={signTrim} height="40" width="90" alt="sign" />
                )
              }
              {signType === "digital" && <>
              {((bgvdata?.emp_policy_status === 0) || (bgvdata?.emp_policy_status === undefined)) && (
                <>
                  <div
                    style={{
                      border: '1px solid',
                      backgroundColor: 'lightgray',
                      width: '250px',
                      height: '150px',
                    }}
                  >
                    <SignatureCanvas
                      penColor="green"
                      canvasProps={{
                        width: 250,
                        height: 150,
                        className: 'sigCanvas',
                      }}
                      ref={ref => {
                        setsigPad(ref);
                      }}
                    />
                  </div>
                  <br />

                  <button
                    className="btn btn"
                    style={{backgroundColor:"#1e98d8"}}
                    type="button"
                    onClick={trimSign}
                  >
                    <b>Sign</b>
                  </button>
                  <button
                    className="btn btn ml-2"
                    style={{backgroundColor:"#a7cf4f"}}
                    type="button"
                    onClick={clearSign}
                  >
                    <b>Clear</b>
                  </button>
                </>
              )}
              </>}
              {signType === "image" && <>
              <div className='row'>
              {base64Image &&
              <div className='col-md-4'>
                <img alt='' style={{ width: "250px", height: "150px", borderRadius: "0%",border:"2px black solid",textAlign:"center"  }} src={base64Image}></img>
                                </div>
              }
              <div className='col-md-4' style={base64Image && base64Image ? {marginTop:"auto",marginLeft:"-35px"} : {marginTop:"auto"}}>
                    <input type="file" onChange={(e) => uploadImage(e)} />
              </div>
              {base64Image&&
                <div className='col-md-2' style={{marginTop:"auto"}}>
                <i style={{color:"red"}} className="fa fa-trash" onClick={()=>setBase64Image("")} aria-hidden="true" title="Delete uploaded photo"></i>
                </div>
              }
              </div>
              </>}
            </div>
            <hr className="line_rating" />
          </div>
          <div
            role="presentation"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            width="100%"
          >
            {/* <p
              className="text-footer1 pb10"
              style={{
                color: '#000',
                fontFamily: "'Work Sans', sans-serif",
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
                paddingBottom: '10px',
              }}
            >
              © Copyright (2021)Powered by{' '}
              <a href="https://www.qdegrees.com/">QDegrees</a>
            </p> */}

            <p
              className="text-footer2 pb20"
              style={{
                color: '#000',
                fontFamily: "'Work Sans' sans-serif",
                fontSize: '12px',
                lineHeight: '26px',
                textAlign: 'center',
                paddingBottom: '20px',
              }}
            >
              <img src="images/address.svg" alt="" style={{height:"20px"}}/>&nbsp;
              Unique Destination, 4th Floor, Tonk Rd, near Lakshmi Mandir Crossing, Jaipur, Rajasthan-302015
            </p>
          </div>
        </div>
      </center>
      <ToastContainer />
    </div>
  );
};

export default BgvAuthorizationConsent;
