
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile
} from '@coreui/react'
import './internship_letter.css'
import Moment from 'react-moment';


const InternshipLetter = (props) => {
  const { candidate, joiningDate, todayDate } = props

  const [isCTCFullDetails, setIsCTCFullDetails] = useState(false)

  // const [large, setLarge] = useState(false)
  useEffect(() => {

  }, [])

  // const isModalOpen = () => {
  //     setLarge(!large)
  // }
  const ctcFullDetails = () => {
    setIsCTCFullDetails(true)
  }

  return (

    <div>
      <h4 align="center">Internship Letter</h4>
      <div className="main-div">
        <center style={{ width: "100%", backgroundColor: "#f1f1f1" }}>
          <div style={{ maxWidth: "800px", margin: "0 auto", backgroundColor: "#fff" }} className="email-container">
            <div style={{ maxWidth: "800px", margin: "0 auto" }} className="image-hr" >
              <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style={{margin: "auto"}}>
                <tr>
                  <td valign="top" className="bg_white" style={{ padding: "0em 3.5em" }}>
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                      <tr>
                        <td width="40%" className="logo" style={{ textAlign: "center" }}>
                          <h1><img src="images/logo.png" height="60" width="80" /></h1>
                        </td>
                      </tr>
                    </table>
                    <hr className="line_rating" />
                  </td>
                </tr>
                <tr>
                  <td valign="top" className="bg_white" style={{ padding: "0em 2.5em" }}>
                  </td>
                </tr>
                <table>
                  <tr>
                    <td>
                      <div className="40%">
                        <h2 style={{ textAlign: "center" }}> <ins>Internship Letter</ins></h2>
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                            <td width="40%" className="logo" style={{ textAlign: "right" }}>
                              <p><strong>Date:-3rd June 2022</strong></p>
                            </td>
                          </tr>
                        </table>
                        <p style={{ textAlign: "justify", padding: "1em 2.5em" }}><strong>Dear {candidate && candidate.name},</strong></p>
                        <p style={{ textAlign: "justify", padding: "1em 2.5em" }}>We are pleased to offer you a full time paid internship opportunity with QDegrees as HR Intern at Jaipur, Rajasthan for six months starting 1st June 2021 to 30th Nov 2021.You shall  be paid a consolidate stipend of Rs._________ per month.</p>
                        <p style={{ textAlign: "justify", padding: "1em 2.5em" }}>Your mentor during the internship period will be Mr._________.Your working hours will be as per company’s policy.</p>
                        <p style={{ textAlign: "justify", padding: "1em 2.5em" }}>After successful completion of internship, you will be awarded a Certificate of internship with QDegrees, if you fail to complete the internship duration it will be considered as an incomplete internship and you will not be awarded any certificate.
                          If by any reason you decide to leave the intership you shall give a prior notice of 30 days in advance. Company may terminate yourinternship at any time, with or without cause and with or without notice. During your internship you may have access to confidential business data belonging to company or its client, by accepting this offer of internship you acknowledge that you must keep all of this information strictly confidential, and refrain from using it for your own purpose or from disclosing it to anyone outside the company.
                          In addition, you agree that, upon completion ofinternship you will immediately return to the company all of its property, equipment, and documents, including electronically stored information.</p>
                        <p style={{ textAlign: "justify", padding: "1em 2.5em" }}>By accepting this offer, you agree that throughout your internship, you will observe all policies and practices governing the conduct of our business and employees, including our policies prohibiting discrimination and harassment. This letter sets forth the complete offer we are extending to you and supersedes and replaces any prior inconsistent statements or discussions. It may be changed only by a subsequent written agreement.</p>
                        <p style={{ textAlign: "justify", padding: "1em 2.5em" }}>I hope that your association with the Company will be successful and rewarding. Please indicate your acceptance of this offer by signing below and returning it to Human Resource Department.</p>
                        <p style={{ paddingLeft: "2.5em", fontWeight: "700" }}>Yours Truly, Sonam Rara</p>
                        <p style={{ paddingLeft: "2.5em", fontWeight: "700" }}>Head – Human Resource (QDegrees.com)</p>
                        <p style={{ paddingLeft: "2.5em", fontFamily: "monospace" }}>I accept Internship with the Company on the terms and conditions set out in this letter.</p>
                      </div>
                    </td>
                  </tr>
                </table>
                <tr>
                  <td>
                    <table>
                      <tr>
                        <td valign="middle" width="20%">
                          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                              <td className="counter-text">
                                {/* <!-- <span className="num">20</span> --> */}
                                <span className="name">Name:</span>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td valign="middle" width="15%">
                          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                              <td className="counter-text">
                                {/* <!-- <span className="num">20</span> --> */}
                                <span className="name"><strong className="hr-color">:!Input</strong> </span>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td valign="middle" width="15%">
                          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                              <td className="counter-text">
                                {/* <!-- <span className="num">80</span> --> */}
                                <span className="name">Signature:</span>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td valign="middle" width="15%">
                          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                            <tr>
                              <td className="counter-text">
                                {/* <!-- <span className="num">980</span> --> */}
                                <span className="name"><strong className="hr-color">:!Input</strong> </span>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <td valign="middle" width="15%">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                          <tr>
                            <td className="counter-text">
                              {/* <!-- <span className="num">980</span> --> */}
                              <span className="name">Date:</span>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td valign="middle" width="15%">
                        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                          <tr>
                            <td className="counter-text">
                              {/* <!-- <span className="num">980</span> --> */}
                              <span className="name"><strong className="hr-color">:!Input</strong> </span>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="40%">
                      <p style={{ padding: "1em 2.5em", fontFamily: "Georgia, 'Times New Roman', Times, serif", fontWeight: "700", textAlign: "justify" }}>Disclaimer: This document is a confidential and is explicitly reserved to QDegrees. QDegrees is only authorized to modify any clause mentioned in the document at any point of time.</p>
                      <p style={{ paddingLeft: "2.5em", textAlign: "left", fontFamily: "monospace", fontWeight: "700" }}>For QDegrees Services Pvt. Ltd.</p>
                      <p style={{ paddingLeft: "2.5em", textAlign: "left" }}>Mahalaxmi Nagar, behind WTP, Jaipur-302006 0141 - CIN No. U27900RJ2017PTC056940 <br />
                        4911882, info@qdegrees.com, www.qdegrees.com</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </center>
      </div>

    </div>


  )
}
export default InternshipLetter







