import React, { useState, useEffect, useRef } from 'react';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation';
import { Button, Label } from 'reactstrap';
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
  CCardFooter,
  CCol,
  CFormGroup,
  CLabel,
} from '@coreui/react';
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import swal from 'sweetalert';
import { PDFExport } from '@progress/kendo-react-pdf';
import OfferLetterQSPL from '../common/offerLatterCtc/offer_letter_qspl';
import OfferLetterQDS from '../common/offerLatterCtc/offer_letter_qds';
import InternshipLetter from '../common/offerLatterCtc/internship_letter';
import AgreementLetter from '../common/offerLatterCtc/agreement_letter';
import Ctc from '../common/offerLatterCtc/ctc';
import { getInputPrefix, getOutputPrefix } from '../../utils/common';
import moment from 'moment';
import { FilePreviewModal } from '../../modals';
import { CustButton } from 'src/reusable';
import CtcGenerate from './CtcGenerate';
import { toastAlert } from 'src/utils/common';
import { ToastContainer } from 'react-toastify';

const SendOfferLaterModal = props => {
  const {
    sendOfferLatter,
    large,
    candidate,
    offerletterTypes,
    selectedCandidateFun,
    getCandidateSelectedInInterview,
    isRevised,
  } = props;
  console.log('dhshdjshdhsahdahdas67', props);

  let api = new API();
  const [preSalaryData, setPreSalaryData] = useState(null);
  const [isCTCFullDetails, setIsCTCFullDetails] = useState(false);
  const [ctc, setCtc] = useState();
  const [ctcResult, setCtcResult] = useState();
  const [joiningDate, setJoiningDate] = useState();
  const [todayDate, setTodayDate] = useState();
  const [benefits, setBenefits] = useState([]);
  const [noticePeriod, setNoticePeriod] = useState(30);
  const [probationPeriod, setProbationPeriod] = useState(3);
  const [grossAmount, setGrossAmount] = useState(1);
  const [includeBonus, setIncludeBonus] = useState(false);
  const [includeMealAllowance, setIncludeMealAllowance] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [currentDatePlusOneYear, setCurrentDatePlusOneYear] = useState(
    moment()
      .add('years', 1)
      .format('YYYY-MM-DD'),
  );
  const [docRequiredDetails, setDocRequiredDetails] = useState([]);
  const [docRequired, setDocRequired] = useState({
    doc_name: ' ',
    is_required: '',
  });
  const [isPF, setIsPF] = useState(false);
  const [isEsi, setIsEsi] = useState(false);
  const [isShowOfferLetter, setIsShowOfferLetter] = useState('');
  const [offerLetterFileData, setOfferLetterFileData] = useState('');
  const [offerLetterType, setOfferLetterType] = useState('');
  const [isGettingOfferLetter, setIsGettingOfferLetter] = useState(false);
  const [remark, setRemark] = useState('');

  // const [large, setLarge] = useState(false)

  const inputEl = useRef(null);

  useEffect(() => {
    DateNow();
  }, []);

  const onButtonPdfClick = () => {
    inputEl.current.save();
  };
  // const isModalOpen = () => {
  //     setLarge(!large)
  // }
  const ctcFullDetails = () => {
    setIsCTCFullDetails(true);
    ctcCalculator();
  };

  const ctcCalculator = async () => {
    // console.log("candidate full details offerlateer", candidate)
    let data = {
      ctc_amount: ctc,
    };
    let result = await api.post(config.ctcGenerator, data);
    if (result && result.code == 200) {
      setCtcResult(result.data);
    } else {
    }
  };
  const DateNow = () => {
    setTodayDate(Date.now());
  };

  const onClickFun = async e => {
    let data = {
      doc_name: e.target.name,
      is_required: e.target.checked,
    };
    let filterResult = await docRequiredDetails.filter(
      val => val.doc_name !== data.doc_name,
    );
    // console.log(filterResult)
    filterResult.push(data);

    setDocRequiredDetails([...filterResult]);
    // console.log(docRequiredDetails)
  };

  const handleOnChange = e => {
    const { name, value } = e.target;
    switch (name) {
      case 'offer_letter_type':
        setOfferLetterType(value);
        break;
      case 'monthly_ctc':
        setCtc(value);
        break;
      case 'joining_date':
        setJoiningDate(value);
        break;
      case 'benefits':
        const result = value != '' ? value.split(',') : [];
        setBenefits(result);
        break;
      case 'notice_period':
        setNoticePeriod(value);
        break;
      case 'probation_period':
        setProbationPeriod(value);
        break;
      case 'gross_amount':
        setGrossAmount(value);
        break;
      case 'is_include_bonus':
        setIncludeBonus(e.target.checked);
        break;
      case 'meal_allowance':
        setIncludeMealAllowance(value);
        break;
      case 'remark':
        setRemark(value);
        break;
      case 'is_pf':
        setIsPF(e.target.checked);
        break;
      case 'is_esi':
        setIsEsi(e.target.checked);
        break;
      default:
        console.log('');
        break;
    }
  };
  const handleOnPreviewOfferLetter = async data => {
    const dataParams = {
      ...data,
      offer_letter_type: offerLetterType,
      benefits: JSON.stringify(benefits) || JSON.stringify([]),
      notice_period: noticePeriod || 30,
      probation_period: probationPeriod || 3,
      gross_amount: grossAmount || 1,
      is_include_bonus: includeBonus ? 1 : 0,
      remark: remark || '',
      isRevised: isRevised || false,
      is_pf: isPF ? 1 : 0,
      is_esi: isEsi ? 1 : 0,
    };
    console.log({ dataParams });
    if (!dataParams.offer_letter_type) {
      toastAlert('Please select type of offer letter', { type: 'error' });
      return;
    }
    if (!candidate.joining_date && !joiningDate) {
      toastAlert('Please select date of joining', { type: 'error' });
      return;
    }
    if (isRevised && (!remark || remark === '')) {
      toastAlert('Please enter remark for revised offer letter', {
        type: 'error',
      });
      return;
    }
    let result = await api.post(config.addEmployeeOfferLetter, dataParams);
    console.log('--handleOnPreviewOfferLetter--', { result });
    if (result && result.code === 200) {
      setPreSalaryData({ ...dataParams, salary_id: result.data[0].id });
      toastAlert(result?.message, { type: 'success' });
    } else if (result?.message !== '') {
      toastAlert(result?.message || '', { type: 'error' });
    }
  };

  useEffect(() => {
    if (preSalaryData) handleOnSendOfferLetter(null, true);
  }, [preSalaryData]);

  const handleOnSendOfferLetter = (e, isPreview = false) => {
    console.log('--setPreSalaryData--', preSalaryData, isPreview);

    let data = {
      is_preview: isPreview,
      benefits: JSON.stringify(benefits) || JSON.stringify([]),
      is_include_bonus: includeBonus ? 1 : 0,
      is_pf: isPF ? 1 : 0,
      is_esi: isEsi ? 1 : 0,
      notice_period: noticePeriod || 30,
      probation_period: probationPeriod || 3,
      gross_amount: grossAmount || 1,
      salary_id: preSalaryData?.salary_id || 4,
      candidate_email: candidate && candidate.email,
      emp_id: candidate && candidate.id,
      name: candidate && candidate.name,
      mobile: candidate && candidate.mobile,
      joining_date: candidate && candidate.joining_date ? candidate.joining_date : joiningDate,
      ctc: ctc,
      offerLetterType: offerLetterType,
      type: candidate && candidate.type || 'ops_select',
      docRequired: JSON.stringify(docRequiredDetails),
    };
    if (data.is_preview) {
      offerLetterApiCall(data);
      return;
    } else {
      swal({
        html: true,
        title: `Are you sure to Send Offer Letter to  ${candidate.name}`,
        icon: 'success',
        buttons: ['No, cancel it!', 'Send'],
        dangerMode: true,
      }).then(function(isConfirm) {
        if (isConfirm) {
          offerLetterApiCall(data);
        }
      });
    }
  };

  const offerLetterApiCall = async data => {
    setIsGettingOfferLetter(true);
    let result = await api.post(config.sendOfferLetterToCandidate, data);
    console.log('--handleOnSendOfferLetter--', { result });
    if (result && result.code == 200) {
      setIsShowOfferLetter(data.is_preview);
      setOfferLetterFileData({
        filename: '',
        payload: result?.data?.filePath || '',
        mime: 'pdf',
      });
      toastAlert(result.message, { type: 'success' });
    } else {
      toastAlert(result?.message || 'Something went wrong', { type: 'error' });
    }
    setIsGettingOfferLetter(false);
  };
  return (
    <div className="">
      <CModal show={large} onClose={() => sendOfferLatter()} size="xl">
        <CModalHeader closeButton>
          <CModalTitle>CTC Calculator</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <AvForm
            //onValidSubmit={ctcFullDetails}
            action=""
            encType="multipart/form-data"
            className="form-horizontal"
          >
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Select offer letter type</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <select
                  type="select"
                  value={offerLetterType}
                  onChange={handleOnChange}
                  name="offer_letter_type"
                  placeholder=""
                  className="form-control"
                >
                  <option value="">--Select offer letter--</option>
                  {offerletterTypes &&
                    offerletterTypes.map(type => (
                      <>
                        <option value={type.emp_id_start}>{type.name}</option>
                      </>
                    ))}
                </select>
              </CCol>
            </CFormGroup>
            {/* <CFormGroup row>
              <CCol md="3">
                <CLabel>Enter CTC</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvField
                  type="number"
                  value={ctc}
                  onChange={e => setCtc(e.target.value)}
                  name="ctc"
                  placeholder="Enter yearly CTC"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'CTC/Stipend is required',
                    },
                  }}
                />
              </CCol>
            </CFormGroup> */}
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Enter Joining Date</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <input
                  type="date"
                  format="DD-MM-YYYY"
                  onChange={handleOnChange}
                  min={currentDate}
                  max={currentDatePlusOneYear}
                  value={candidate && candidate.joining_date ? candidate.joining_date : joiningDate}
                  name="joining_date"
                  placeholder="Enter Joining Date"
                  required
                  className="form-control"
                />
              </CCol>
            </CFormGroup>

            <CFormGroup row>
              <CCol md="3">
                <CLabel>Mandatory Documents Required</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      onClick={onClickFun}
                      name="driving_license"
                    />{' '}
                    Driving License
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      onClick={onClickFun}
                      name="passport"
                      trueValue="Yes, I'm in!"
                      falseValue="NOPE!"
                    />{' '}
                    Passport
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      onClick={onClickFun}
                      name="aadhar"
                      trueValue="Yes, I'm in!"
                      falseValue="NOPE!"
                    />{' '}
                    Aadhar Card
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      onClick={onClickFun}
                      name="pan"
                      trueValue="Yes, I'm in!"
                      falseValue="NOPE!"
                    />{' '}
                    Pan Card
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      onClick={onClickFun}
                      name="cheque"
                      trueValue="Yes, I'm in!"
                      falseValue="NOPE!"
                    />{' '}
                    Cancel Cheque
                  </Label>
                </AvGroup>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Extra Comma Separated Benefits</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <input
                  type="textarea"
                  onChange={handleOnChange}
                  name="benefits"
                  placeholder="Enter Extra Benefits (Mobile Allowance 250 rupees,Other Allowance 150 rupees)"
                  className="form-control"
                />
              </CCol>
            </CFormGroup>
            {isRevised && (
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Remark</CLabel>
                </CCol>

                <CCol xs="12" md="9">
                  <input
                    type="textarea"
                    onChange={handleOnChange}
                    name="remark"
                    placeholder="Enter Remark for Revised Offer Letter"
                    className="form-control"
                    required
                  />
                </CCol>
              </CFormGroup>
            )}
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Notice Period</CLabel>
              </CCol>

              <CCol xs="12" md="6">
                <input
                  type="textarea"
                  onChange={handleOnChange}
                  name="notice_period"
                  placeholder="Enter Notice period (30 or 60..)"
                  className="form-control"
                />
              </CCol>
              <CCol md="3">Days</CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Probation Period</CLabel>
              </CCol>

              <CCol xs="12" md="6">
                <select
                  type="select"
                  onChange={handleOnChange}
                  name="probation_period"
                  placeholder="Enter probation period"
                  className="form-control"
                >
                  <option value="">--Select Probation Period--</option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                  <option value={4}>Four</option>
                  <option value={5}>Five</option>
                  <option value={6}>Six</option>
                  <option value={7}>Seven</option>
                  <option value={8}>Eight</option>
                  <option value={9}>Nine</option>
                  <option value={10}>Ten</option>
                  <option value={11}>Eleven</option>
                  <option value={12}>Twelve</option>
                </select>
              </CCol>
              <CCol md="3">Month</CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Gross Amount</CLabel>
              </CCol>
              <CCol xs="12" md="6">
                <select
                  type="select"
                  onChange={handleOnChange}
                  name="gross_amount"
                  placeholder="Enter gross amount to be deducted"
                  className="form-control"
                >
                  <option value="">
                    --Select gross amount to be deducted--
                  </option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                  <option value={4}>Four</option>
                  <option value={5}>Five</option>
                  <option value={6}>Six</option>
                  <option value={7}>Seven</option>
                  <option value={8}>Eight</option>
                  <option value={9}>Nine</option>
                  <option value={10}>Ten</option>
                  <option value={11}>Eleven</option>
                  <option value={12}>Twelve</option>
                </select>
              </CCol>
              <CCol md="3">Month</CCol>
            </CFormGroup>

            <CtcGenerate
              employeeDetail={candidate}
              handleOnChange={handleOnChange}
              handleOnPreviewOfferLetter={handleOnPreviewOfferLetter}
              isGettingOfferLetter={isGettingOfferLetter}
            />
            {/* {isCTCFullDetails == false && (
              <CCardFooter>
                <div className="text-right">
                  <CustButton
                    type="button"
                    name="pdf-view"
                    text="Preview"
                    color="primary"
                    onClick={handleOnPreviewOfferLetter}
                    className="btn-loading btn-ladda mr-3"
                    icon="fa-paper-plane"
                    isDisabled={false}
                    isLoading={false}
                  />
                  <CustButton
                    type="submit"
                    name="save-ctc"
                    text="Submit"
                    color="success"
                    onClick={() => sendOfferLatter()}
                    className="btn-loading btn-ladda"
                    icon="fa-paper-plane"
                    isDisabled={false}
                    isLoading={false}
                  />
                </div>
              </CCardFooter>
            )} */}
          </AvForm>
        </CModalBody>

        <FilePreviewModal
          isOpen={isShowOfferLetter}
          toggleModal={() => {
            setIsShowOfferLetter(false);
          }}
          fileData={offerLetterFileData}
          showSendOfferLetterBtn={true}
          handleSendOfferLetter={handleOnSendOfferLetter}
        />
        {/* <CModalFooter> */}
        {/* <CButton color="success" onClick={onButtonPdfClick}>PDF Download</CButton> */}
        {/* {isCTCFullDetails == true && (
            <CButton
              data-toggle="tooltip"
              data-placement="top"
              title="Send Offer Letter"
              color="success"
              onClick={finalSendOfferLatter}
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </CButton>
          )} */}
        {/* </CModalFooter> */}
      </CModal>
      {/* <button onClick={()=>setLarge(!large)}>modal</button> */}
      <ToastContainer />
    </div>
  );
};
export default SendOfferLaterModal;
