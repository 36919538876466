import React, { useState } from 'react';
import { CDataTable } from '@coreui/react';
import { convertDate } from 'src/utils/common';
import { isPermission } from 'src/utils/common';
import ViewLeaveDateModal from './ViewLeaveDateModal';
const LeaveApprovalList = props => {
  const {
    data,
    type,
    renderDataStatus,
    renderDataActionBtn,
    getLeaveForApproval,
  } = props;
  const [viewLaeveModal, setViewLeaveModal] = useState(false);
  const [leaveData, setLeaveData] = useState();
  const handleLeaveModal = item => {
    setLeaveData(item);
    setViewLeaveModal(!viewLaeveModal);
  };
  function allLeaveStatus(arr) {
    let status = '';
    let p = 0;
    let a = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].is_approve_status === 0 && arr[i].is_rejected_status === 0 && arr[i].is_cancel === 0) {
        p++;
      } else {
        a++;
      }
    }
    if (arr.length === a) {
      return (
        <span>
          <i
            className="fa fa-circle danger"
            style={{ color: '#96f77c' }}
            aria-hidden="true"
          ></i>{' '}
          Completed{' '}
        </span>
      );
    }
    else {
      return (
      <span>
        <i
          className="fa fa-circle danger"
          style={{ color: '#f5d99a' }}
          aria-hidden="true"
        ></i>{' '}
        Pending
      </span>
      );
    }
  }

  const fields = [
    {
      label: 'Emp ID',
      key: 'emp_id',
      sorter: false,
      filter: true,
    },
    {
      label: 'Employee Name',
      key: 'employee_name',
      sorter: false,
      filter: true,
    },
    {
      label: 'Leave From',
      key: 'leave_from',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave To',
      key: 'leave_to',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave Date',
      key: 'leave_date',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave Status',
      key: 'leave_status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Leave Type',
      key: 'leave_type',
      sorter: false,
      filter: false,
    },
    {
      label: 'Reason',
      key: 'reason',
      sorter: false,
      filter: true,
    },
    {
      label: 'Cancel Reason',
      key: 'cancel_reason',
      sorter: false,
      filter: false,
    },
    {
      label: 'Cancel By',
      key: 'cancel_by',
      sorter: false,
      filter: false,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Created Date',
      key: 'created_at',
      sorter: true,
      filter: true,
    },
    // {
    //   label: '',
    //   key: 'action',
    //   sorter: true,
    //   filter: true,
    // },
    {
      label: 'Action',
      key: 'view',
      sorter: true,
      filter: true,
    },
  ];

  return (
    <>
      <CDataTable
        items={data}
        fields={fields}
        tableFilter
        itemsPerPageSelect
        itemsPerPage={10}
        hover
        sorter
        pagination
        scopedSlots={{
          leave_from: item => (
            <td>
              {convertDate(item?.leaveDateArray[0].leave_date, 'DD-MM-YYYY')}
            </td>
          ),
          leave_to: item => (
            <td>
              {item.leaveDateArray.length > 0
                ? convertDate(
                    item?.leaveDateArray[item.leaveDateArray.length - 1]
                      .leave_date,
                    'DD-MM-YYYY',
                  )
                : convertDate(item?.leaveDateArray[0].leave_date, 'DD-MM-YYYY')}
            </td>
          ),
          status: item => <td>{allLeaveStatus(item?.leaveDateArray)}</td>,

          // status: item => <td>{renderDataStatus(item, type)}</td>,
          leave_date: item => (
            <td>{convertDate(item.leave_date, 'YYYY-MM-DD')}</td>
          ),
          cancel_reason: item => (
            <td>{item.cancel_reason ? item.cancel_reason : ''}</td>
          ),
          cancel_by: item => <td>{item.cancel_by ? item.cancel_by : ''}</td>,
          created_at: item => (
            <td>{convertDate(item.created_at, 'YYYY-MM-DD')}</td>
          ),
          view: item => (
            <td onClick={() => handleLeaveModal(item)}>
              <i className="fa fa-tasks" aria-hidden="true"></i>
            </td>
          ),
          action: item => (
            <td>
              {isPermission('approve_reject_leave_request') && (
                <>{renderDataActionBtn(item, type)}</>
              )}
            </td>
          ),
        }}
      />
      <ViewLeaveDateModal
        getLeaveForApproval={getLeaveForApproval}
        leaveData={leaveData}
        viewLaeveModal={viewLaeveModal}
        handleLeaveModal={handleLeaveModal}
      />
    </>
  );
};

export default LeaveApprovalList;
