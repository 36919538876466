import React, { useState, useEffect } from 'react'
import {
  TheSidebar,
  TheFooter,
  TheHeader
} from '../containers/index'
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CDataTable,
  CTabPane,
  CTabs,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow
  } from '@coreui/react'
import ViewDetails from './modal';
import ModalforUpdateDate from './updateDate'
import ModalforIssueDate from './IssueDate'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import DateRangePicker from 'src/views/common/dateRangePicker';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import { isPermission } from 'src/utils/common';
const Admin_Dashboard = () => {
  const api = new API()
  const [active, setActive] = useState(0)
  const [iDCardDetails, setIDCardDetails] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen1, setIsOpen1] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [empUserID, setEmpUserID] = useState()
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const fields = [
    { label: "Employee ID", key: "emp_id", _style: { width: "5%" } },
    { label: "Employee Name", key: "employee_name", _style: { width: "10%" } },
    { label: "Contact No.", key: "mobile", _style: { width: "5%" } },
    { label : "Department", key: 'department', _style: { width: '10%' } },
    { label: "Request Assigned By", key: "assigned_by", _style: { width: "10%" } },
    { label: "Request Assigned At", key: "assigned_to_admin_at", _style: { width: "10%" } }
];

const fields1 = [
  { label: "Employee ID", key: "emp_id", _style: { width: "5%" } },
  { label: "Employee Name", key: "employee_name", _style: { width: "10%" } },
  { label: "Contact No.", key: "mobile", _style: { width: "5%" } },
  { label :"Department", key: 'department', _style: { width: '10%' } },
  { label: "Request Assigned At", key: "assigned_to_admin_at", _style: { width: "10%" } },
  { label: "Expected Issue Date", key: "expected_issue_date", _style: { width: "10%" } }
];

const fields2 = [
  { label: "Employee ID", key: "emp_id", _style: { width: "5%" } },
  { label: "Employee Name", key: "employee_name", _style: { width: "10%" } },
  { label: "Contact No.", key: "mobile", _style: { width: "5%" } },
  { label :"Department", key: 'department', _style: { width: '10%' } },
  { label: "Issue Card At", key: "issued_at", _style: { width: "10%" } }
];
  useEffect(() => {
    getAllIDCardRequest()
    getAllFields()
}, [])
const getAllIDCardRequest = async () => {
    let result = await api.get(config.getAllIDCardDetails)
    if (result && result.code === 200) {
        setIDCardDetails(result.data)
    }
}
const getAllFields = () => {
  if(isPermission('view_id_card')){
    fields.push({
      key: "show_details",
      label: "View ID Card",
      _style: { width: "5%" },
  });
  fields1.push({
    key: "show_details",
    label: "View ID Card",
    _style: { width: "5%" },
  });
  fields2.push( {
    key: "show_details",
    label: "View ID Card",
    _style: { width: "5%" },
  });
  }
  if(isPermission('issue_id_card')){
    fields1.push( {
      key: 'assign',
      label: 'Issue Card',
      _style: { width: '1%' },
    });
  }
  if(isPermission('accept_id_card_request')){
    fields.push({
      key: 'assign',
      label: 'Accept Request',
      _style: { width: '1%' },
    });
  }
}

const isViewModalOpen = (emp_user_id) => {
  setEmpUserID(emp_user_id)
  setIsOpen(!isOpen)
}

const modalUpdateDate = (emp_user_id) => {
  setEmpUserID(emp_user_id)
  setIsOpen1(!isOpen1)
}

const modalIssueDate = (emp_user_id) => {
  setEmpUserID(emp_user_id)
  setIsOpen2(!isOpen2)
}



const downalodExcelReports = async () => {
  let data = {
    start_date: startDate,
    end_date: endDate,
  };
  let result = await api.get(config.downloadReportForIDCard,true,data);
  if (result && result.code === 200) {
    window.open(result.data.filePath, '_blank');
      toast.success(result.message)
  }
  else{
      toast.warning(result.message)
  }
};


  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />

        <div className="c-body">
      <CRow>
        <CCol xs="12" >
          <CCard>
            <CCardHeader>
              <strong>Admin Dashboard</strong>
            </CCardHeader>
            {isPermission('id_card_report') &&
            <div className="btn btn-group float-center" >
            <DateRangePicker DateStart={setStartDate} DateEnd={setEndDate}/>
                  <div className="btn btn-group float-center" style={{display:"initial"}}>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={downalodExcelReports}
                  >
                      Download ID Card Report 
                    <i className="fa fa-download" aria-hidden="true"></i>
                  </button>
                </div>
                </div>
            }
            <CCardBody>
              <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                <CNav variant="tabs">
                  <CNavItem>
                    <CNavLink>
                      <b>Pending ID Card Request</b>
                      {active === 0}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink>
                      <b>Accepted ID Card Request</b>
                      {active === 1}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink>
                      <b>Completed ID Card</b>
                      {active === 2}
                    </CNavLink>
                  </CNavItem>
                </CNav>
                <CTabContent>
                  <CTabPane>
                  <CDataTable
                         items={iDCardDetails && 
                          iDCardDetails.filter(candidate => candidate.assigned_to_admin_status === 1 && candidate.expected_issue_status === 0).map((data, index) => ( 
                          data
                         ))}
                        fields={fields}
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={10}
                        hover
                        pagination
                        scopedSlots={{
                        show_details: (item, index) => {
                        return (
                            <td>
                                  <CButton data-toggle="tooltip" data-placement="top" title="View" block className="btn btn-primary" onClick={() => isViewModalOpen(item.emp_user_id)} ><i className="fa fa-eye" aria-hidden="true"></i></CButton>
                            </td>
                        );
                            },
                        assign: (item, index) => {
                              return (
                                <td>
                                    <CButton data-toggle="tooltip" data-placement="top" title="Accept Request" block className="btn btn-success" onClick={() => modalUpdateDate(item.emp_user_id)} ><i className="fa fa-paper-plane" aria-hidden="true"></i></CButton>
                                </td>                 
                              )
                                }
                              }}
                            />
                  </CTabPane>
                  <CTabPane>
                  <CDataTable
                     items={iDCardDetails && 
                      iDCardDetails.filter(candidate => candidate.expected_issue_status === 1 && candidate.issue_status === 0).map((data, index) => ( 
                      data
                     ))} 
                fields={fields1}
                tableFilter
                itemsPerPageSelect
                itemsPerPage={10}
                hover
                pagination
                scopedSlots={{
                          show_details: (item, index) => {
                            return (
                                <td>
                                      <CButton data-toggle="tooltip" data-placement="top" title="View" block className="btn btn-primary" onClick={() => isViewModalOpen(item.emp_user_id)} ><i className="fa fa-eye" aria-hidden="true"></i></CButton>
                                </td>
                                );
                                },
                            assign : (item, index) => {
                                  return (
                                    <td>
                                        <CButton data-toggle="tooltip" data-placement="top" title="Issue Card" block className="btn btn-success" onClick={() => modalIssueDate(item.emp_user_id)} ><i className="fa fa-paper-plane" aria-hidden="true"></i></CButton>
                                    </td>
                                  )}
                        }}
                            />
                  </CTabPane>
                  <CTabPane>
                  <CDataTable
                items={iDCardDetails && 
                  iDCardDetails.filter(candidate => candidate.issue_status === 1).map((data, index) => ( 
                  data
                 ))}
                fields={fields2}
                tableFilter
                itemsPerPageSelect
                itemsPerPage={10}
                hover
                pagination
                scopedSlots={{
                          show_details: (item, index) => {
                            return (
                                <td>
                                      <CButton data-toggle="tooltip" data-placement="top" title="View" block className="btn btn-primary" onClick={() => isViewModalOpen(item.emp_user_id)} ><i className="fa fa-eye" aria-hidden="true"></i></CButton>
                                </td>
                                );
                                }
                        }}
                            />
                  </CTabPane>
                </CTabContent>
              </CTabs>

            </CCardBody>
          </CCard>

        </CCol>
      </CRow>
      <ViewDetails isOpen={isOpen} isViewModalOpen={isViewModalOpen} empUserID={empUserID} getAllIDCardRequest={getAllIDCardRequest}/>

      <ModalforUpdateDate isOpen1={isOpen1} modalUpdateDate={modalUpdateDate} empUserID={empUserID} getAllIDCardRequest={getAllIDCardRequest} />

      <ModalforIssueDate isOpen2={isOpen2} modalIssueDate={modalIssueDate} empUserID={empUserID} getAllIDCardRequest={getAllIDCardRequest}/>

      </div>
        <TheFooter />
      </div>
      <ToastContainer />
    </div>
  )
}

export default Admin_Dashboard
