const getIsChatPopupOpen = state => state.messages.isChatPopupOpen;

const getIsMessagesPending = state => state.messages.isGetMessagesPending;
const getMessagesRes = state => state.messages.getMessagesRes;
const getMessagesError = state => state.messages.getMessagesResError;

const getIsConnectedUsersPending = state =>
  state.messages.isGetConnectedUsersPending;
const getConnectedUsersRes = state => state.messages.getConnectedUsersRes;
const getConnectedUsersResError = state =>
  state.messages.getConnectedUsersResError;

  const getIsConnectedGroupsPending = state =>
  state.messages.isGetConnectedGroupsPending;
const getConnectedGroupsRes = state => state.messages.getConnectedGroupsRes;
const getConnectedGroupsResError = state =>
  state.messages.getConnectedGroupsResError;

const getIsGetUnreadMessageCountsPending = state => state.messages.isGetUnreadMessageCountsPending;
const getUnreadMessageCountsRes = state => state.messages.getUnreadMessageCountsRes;
const getUnreadMessageCountsResError = state => state.messages.getUnreadMessageCountsResError;

export default {
  getIsChatPopupOpen,
  
  getIsMessagesPending,
  getMessagesRes,
  getMessagesError,

  getIsConnectedUsersPending,
  getConnectedUsersRes,
  getConnectedUsersResError,

  getIsConnectedGroupsPending,
  getConnectedGroupsRes,
  getConnectedGroupsResError,

  getIsGetUnreadMessageCountsPending,
  getUnreadMessageCountsRes,
  getUnreadMessageCountsResError,
};
