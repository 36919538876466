/* eslint-disable react/no-unknown-property */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
} from '@coreui/react';

const CallRecordingPlay = props => {
  const { open, handleCancel, selectedItem } = props;

  return (
    <div className="">
      <CModal show={open} onClose={handleCancel} size="md">
        <CModalHeader className={'modal-header-info'} closeButton>
          <CModalTitle>Call Recording</CModalTitle>
        </CModalHeader>
        <CModalBody style={{ textAlign: 'center' }}>
          <div>
            <audio useRef={'audio_tag'} src={selectedItem} controls autoPlay />
          </div>
        </CModalBody>

        <CModalFooter></CModalFooter>
      </CModal>
      <ToastContainer />
    </div>
  );
};

export default CallRecordingPlay;
