import React, { useState, useEffect } from 'react'
import swal from "sweetalert";
import {
    CRow,
    CButton,
    CCardFooter,
    CCol,
    CFormGroup,
    CLabel} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import moment from 'moment';

const CreateNewCondidate = (props) => {
    const { mrfDetails, create_NewCondidate } = props
    let api = new API()
    const [fileUpload, setFileUpload] = useState()
    const [dynamicMrfDetails, setDynamicMrfDetails] = useState();
    const [allSources, setAllSources] = useState();
    const [condidateDetails, setCondidateDetails] = useState({
        first_name: "",
        last_name: "",
        email: "",
        dob: "",
        designation: mrfDetails && mrfDetails.designation,
        mobile: "",
        experience: "",
        work_location: "",
        current_location: "",
        remarks: "",
        source:"",
        mrf_id: mrfDetails && mrfDetails.mrf_id,
        mrf_applying_for: mrfDetails && mrfDetails.applying_for
    })

    const createNewCondidate = async () => {
        const formData = new FormData()
        formData.append("candidate_resume", fileUpload)
        formData.append("first_name", condidateDetails.first_name)
        formData.append("last_name", condidateDetails.last_name)
        formData.append("email", condidateDetails.email.trim())
        formData.append("dob", condidateDetails.dob)
        formData.append("designation", mrfDetails && mrfDetails.designation)
        formData.append("mobile", condidateDetails.mobile)
        formData.append("experience", condidateDetails.experience)
        formData.append("source", condidateDetails.source)
        formData.append("work_location", condidateDetails.work_location)
        formData.append("current_location", condidateDetails.current_location)
        formData.append("remarks", condidateDetails.remarks)
        formData.append("mrf_id", mrfDetails && mrfDetails.mrf_id)
        formData.append("mrf_applying_for", mrfDetails && mrfDetails.applying_for)
        swal({
            html: true,
            title: "Are you sure?",
            text: `Are you sure to create candidate ${condidateDetails.email}`,
            icon: "success",
            buttons: ["No, cancel it!", "Create"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.putPostFileFun(config.createCandidate, formData)
                if (result && result.code === 200) {
                    alert(result.message)
                    create_NewCondidate(mrfDetails)
                } else {
                    alert(result.error.response.data.message)
                }
            }
        });
    }
    const onChangeFunction = (e) => {
        setCondidateDetails({
            ...condidateDetails,
            [e.target.name]: e.target.value
        })
        // console.log("_________________________", condidateDetails)
    }
    const onChangeFunctionUploadResume = (e) => {
        let files = e.target.files
        setFileUpload(files[0]);
    }

    const getDynamicDetails = async () => {
        let result = await api.get(config.mrfdynamicdetailsform);
        if (result && result.code === 200) {
          setDynamicMrfDetails(result.data[0]);
        }
    };

    const getAllSources = async () => {
        let result = await api.get(config.sources);
        if (result && result.code === 200) {
        setAllSources(result.data);
        }
    };

    useEffect(() => {
        getDynamicDetails();
        getAllSources();
    }, [])

    return (
        <div className="">

            <AvForm onValidSubmit={createNewCondidate} action="" encType="multipart/form-data" className="form-horizontal">
                <CRow>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >First Name <b style={{color:"red"}}>*</b></CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <AvInput required value={condidateDetails.first_name} onChange={onChangeFunction} name="first_name" placeholder="Enter Candidate First Name" />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Last Name</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <AvInput value={condidateDetails.last_name} onChange={onChangeFunction} name="last_name" placeholder="Enter Candidate Last Name" />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Email <b style={{color:"red"}}>*</b></CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <AvInput required value={condidateDetails.email} onChange={onChangeFunction} name="email" placeholder="Enter Email" />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Mobile No. <b style={{color:"red"}}>*</b></CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <AvInput required value={condidateDetails.mobile} onChange={onChangeFunction} name="mobile" placeholder="Enter Mobile No." />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Designation</CLabel>
                            </CCol>

                            <CCol xs="12" md="9">
                                <AvInput disabled style={{color:"black"}}value={mrfDetails && mrfDetails.designation} onChange={onChangeFunction} name="designation" placeholder="Enter Designation" />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Current Location</CLabel>
                            </CCol>

                            <CCol xs="12" md="9">
                                <AvInput value={condidateDetails.current_location} onChange={onChangeFunction} name="current_location" placeholder="Enter Current Location" />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Work Location</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <AvInput value={condidateDetails.work_location} onChange={onChangeFunction} name="work_location" placeholder="Enter Preferred Work Location" />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >DOB</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <AvInput type="date" value={condidateDetails.dob} onChange={onChangeFunction} name="dob" placeholder="Enter DOB" max={moment().subtract(18,'years').format('YYYY-MM-DD')} />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Work Experience</CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                            <AvInput className="letter"
                            type="select"
                            value={condidateDetails.experience}
                            name="experience"
                            onChange={onChangeFunction}
                            id="experience"
                          >
                            <option hidden>Select experience . . .</option>
                            {dynamicMrfDetails && dynamicMrfDetails.Job_Experiences.map((data) => (
                                <option value={data.key}>
                                  {data.experience}
                                </option>
                              ))}
                          </AvInput>
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Source <b style={{color:"red"}}>*</b></CLabel>
                            </CCol>

                            <CCol xs="12" md="9">
                            <AvInput required className="letter"
                            type="select"
                            value={condidateDetails.source}
                            name="source"
                            onChange={onChangeFunction}
                            id="source"
                          >
                            <option hidden>Select source of hiring . . .</option>
                            {allSources && allSources.map((data) => (
                                <option value={data.id}>
                                  {data.category_key}
                                </option>
                              ))}
                              </AvInput>
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Select Resume <b style={{color:"red"}}>*</b></CLabel>
                            </CCol>
                            <CCol xs="12" md="9">
                                <input required className="form-control" onChange={onChangeFunctionUploadResume} type="file"></input>
                            </CCol>
                        </CFormGroup>
                    </CCol>
                    <CCol md="6">
                        <CFormGroup row>
                            <CCol md="3">
                                <CLabel >Remarks</CLabel>
                            </CCol>

                            <CCol xs="12" md="9">
                                <AvInput type="text" value={condidateDetails.remarks} onChange={onChangeFunction} name="remarks" placeholder="Enter Remarks if any...." />
                            </CCol>
                        </CFormGroup>
                    </CCol>
                </CRow>
                <CCol md="3">
                <CLabel style={{fontSize:"12px",color:"red"}}>* Indicates Mandatory Fields</CLabel>
            </CCol>

                <CCardFooter>
                    <CButton type="submit" className="btn btn-sm btn-primary mr-3 mt-2">Create Candidate</CButton>
                </CCardFooter>
            </AvForm>
        </div>

    )
}
export default CreateNewCondidate







