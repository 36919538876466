import { createAction } from 'redux-act';

const toggleSidebar = createAction('GET_MINIMIZE_SIDEBAR', data => data);

const handleClearCommonStore = createAction(
  'HANDLE_CLEAR_COMMON_STORE',
  data => data,
);

export default {
  toggleSidebar,
  handleClearCommonStore,
};
