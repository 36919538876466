import { getFomatedError } from '../../services/ApiService';
import { config } from '../../../utils/apiUrl';
import API from '../../../utils/apiCalling';
const api = new API();

const getAttendanceBasicDetails = params => {
  let apiRes = api
    .get(config.attendanceBasicDetails, params)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });

  return apiRes;
};

export default {
  getAttendanceBasicDetails,
};
