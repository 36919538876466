import { createReducer } from 'redux-act';
import actions from './commonActions';

const initialState = {
  sidebarOptions: { minimize: false, show: 'responsive' },
};

//
const handleToggleSidebar = (state, payload) => {
  return {
    ...state,
    ...{
      sidebarOptions: payload,
    },
  };
};

// clear existing data
const clearCommonStore = (state, payload) => {
  switch (payload) {
    case 'HANDLE_CLEAR':
      return {
        ...state,
        ...{
          sidebarOptions: { minimize: false, show: 'responsive' },
        },
      };
    default:
      return state;
  }
};
export default createReducer(
  {    
    [actions.toggleSidebar]: handleToggleSidebar,
    [actions.handleClearCommonStore]: clearCommonStore,
  },
  initialState,
);
