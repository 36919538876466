import { fork } from 'redux-saga/effects';
import { rootCommonSaga } from './common';
import { rootMessageSaga } from './messages';
import { rootAttendanceSaga } from './attendance';
import { rootMqttSaga } from './mqtt';

function* rootSaga() {
  yield fork(rootCommonSaga);
  yield fork(rootMessageSaga);
  yield fork(rootMqttSaga);
  yield fork(rootAttendanceSaga);
}

export default rootSaga;
