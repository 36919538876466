import React from 'react';
import { CButton, CSpinner } from '@coreui/react';

const CustButton = props => {
  const {
    type,
    text,
    name,
    size,
    color,
    isLoading,
    isDisabled,
    onClick,
    className,
    icon,
    style,
    title,
  } = props;
  const defaultClickHandler = () => {
    console.log('click');
  };
  const loader = isLoading || false;
  const disable = isDisabled || loader || false;
  return (
    <CButton
      name={name || 'button'}
      color={color || 'primary'}
      onClick={onClick || defaultClickHandler}
      className={`${className} ${disable ? 'disabled' : ''}`}
      style={style || {}}
      disabled={disable || loader || false}
      size={size || ''}
      type={type || 'button'}
      title={title || ''}
    >
      {icon ? (
        <>
          <i className={`fa ${icon}`} aria-hidden="true"></i>&nbsp;
        </>
      ) : (
        ''
      )}
      {text || ''}
      {loader && (
        <>
          &nbsp;&nbsp;
          <CSpinner size="sm" variant="grow" />
        </>
      )}
    </CButton>
  );
};

export default CustButton;
