/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import moment from "moment";
import Dashboard from 'src/views/dashboard/Dashboard';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CModalFooter,
  CModal,
  CModalHeader,
  CModalTitle,
  CFormGroup,
  CLabel,
  CInputRadio,
  CModalBody,
  CTextarea,
  CForm,
} from '@coreui/react';

import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import ModalCandidate from './modal';
import ViewInterviewStatus from './viewInterviewStatus';
import { isPermission } from 'src/utils/common';
const ViewSelectedInProgress = props => {
  const { MRFData, view_selected } = props;
  let api = new API();

  const [active, setActive] = useState(0);
  const [mrfRecord, setMrfRecord] = useState();
  const [createdCandidate, setCreatedCandidate] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [candidate, setCandidate] = useState();
  const [isInterviewStatus, setIsInterviewStatus] = useState(false);
  const [isAssesmentModalOpen, setisAssesmentModalOpen] = useState(false)
  const [candidateData, setcandidateData] = useState([])
  console.log("Helo", candidateData)
  const [roundAssesmentSubmit, setroundAssesmentSubmit] = useState()

  useEffect(() => {
    getCreatedCandidate();
  }, []);

  const getCreatedCandidate = async () => {
    let data = {
      mrf_id: MRFData && MRFData.mrf_id,
      mrf_applying_for: MRFData && MRFData.applying_for,
    };
    let result = await api.get(config.getCreatedCandidate, true, data);
    if (result && result.code == 200) {
      setCreatedCandidate(result.data);
    } else {
    }
  };
  
  const condidateFullDetails = candidate => {
    console.log("Hello",candidate)
    setCandidateDetails(candidate);
    setIsModalOpen(!isModalOpen);
  };

  const sendInterviewLatter = candidate => {
    setCandidateDetails(candidate);
    setIsModalOpen2(!isModalOpen2);
  };

  const selectCondidate = async candidate => {
    let data = {
      candidate_id: candidate.id,
    };
    let result = await api.post(config.selectCandidate, data);
    if (result && result.code == 200) {
      alert('selected');
      getCreatedCandidate();
    } else {
    }
  };

  const backButton = () => {
    view_selected();
  };

  const isInterviewStatusFun = candidate => {
    setCandidate(candidate);
    setIsInterviewStatus(!isInterviewStatus);
  };
  const isInterviewStatusFuns = async(candidate) => {
    setisAssesmentModalOpen(true)
    let data ={
      mrf_id:candidate.mrf,
      mrf_applying_for: candidate.mrf_applying_for,
      candidate_id: candidate.c_id,
    }
    let result = await api.get(config.getInterviewRoundAssesment, true, data)
    if(result && result.code == 200){
      setcandidateData(result.data[0])
    }
  };

  return (
    <div className="">
      <div className="container">
        <h3 className="text-uppercase text-center mt-3 mb-4">
          {' '}
          <u>Interveiw In-Progress</u>
        </h3>
        {isInterviewStatus == false ? (
          <CRow>
            <CCol xs="12">
              <CCard>
                <CCardBody>
                  <CTabs
                    activeTab={active}
                    onActiveTabChange={idx => setActive(idx)}
                  >
                    <CNav variant="tabs">
                      <CNavItem>
                        <CNavLink>Interview Status</CNavLink>
                      </CNavItem>
                    </CNav>

                    <CTabContent>
                      <CTabPane>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">S.No.</th>
                              <th scope="col">Candidate ID</th>
                              <th scope="col">Candidate Name</th>
                              <th scope="col">Candidate Email</th>
                              <th scope="col">Candidate Designation</th>
                              <th scope="col">Candidate Mobile No.</th>
                              <th scope="col">Interview Status</th>
                              <th scope="col" style={{textAlign:"center"}}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {createdCandidate &&
                              createdCandidate.map((candidate, index) => (
                                <tr>
                                  <th scope="row">{index + 1}</th>
                                  <td>{candidate.c_id}</td>
                                  <td>{candidate.name}</td>
                                  <td>{candidate.email}</td>
                                  <td>{candidate.designation}</td>
                                  <td>{candidate.mobile}</td>
                                  <td>
                                    {(candidate.interview_round === '1' && candidate.interview_selection_status === 1 && candidate.overall_interview_status === 0) ? 
                                      <span style={{ color: '#298f2c' }}>
                                        <b>1 Round Selected</b>
                                      </span>
                                     : (candidate.interview_round === '1' && candidate.interview_rejection_status === 1) ? 
                                     <span style={{ color: '#FF5722' }}>
                                       <b>1 Round Rejected</b>
                                     </span>
                                      : (candidate.overall_interview_status === 1) ?
                                      <span style={{ color: 'darkblue' }}>
                                        <b>Completed</b>
                                      </span>
                                    :  <span style={{ color: 'red' }}>
                                    <b>Pending</b>
                                      </span>
                                    }
                                  </td>
                                  <td>
                                      {isPermission(
                                        'mrf_details_interview_in_progress',
                                      ) && (
                                        <CButton
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="View Full details"
                                          className="btn btn-info pr-auto"
                                          onClick={() =>
                                            condidateFullDetails(candidate)
                                          }
                                        >
                                          <i
                                            className="fa fa-eye "
                                            aria-hidden="true"
                                          ></i>{' '}
                                        </CButton>
                                      )}
                               

                                      <CButton
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View Interview Feedback"
                                        className="btn btn-success ml-2 pr-auto"
                                        onClick={() =>
                                          isInterviewStatusFuns(candidate)
                                        }
                                      >
                                         <i
                                            className="fa fa-wpforms"
                                            aria-hidden="true"
                                          ></i>
                                      </CButton>
                                      </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </CTabPane>
                      
                {/* <CTabPane>
                    <h5> No Rejected Candidate</h5>
                </CTabPane> */}
                    </CTabContent>
                  </CTabs>
                  <CCardFooter>
                    <CButton
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Back"
                      color="success"
                      onClick={backButton}
                    >
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    </CButton>{' '}
                  </CCardFooter>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        ) : (
          <ViewInterviewStatus
            candidate={candidate}
            isInterviewStatusFun={isInterviewStatusFun}
          />
        )}
      </div>
      <ModalCandidate
        condidateFullDetails={condidateFullDetails}
        isModalOpen={isModalOpen}
        message={'Candidate Full Details'}
        candidateDetails={candidateDetails}
      />
      <CModal
        show={isAssesmentModalOpen}
        onClose={() => setisAssesmentModalOpen(!isAssesmentModalOpen)}
        size="lg"
        color="info"
      >
        <CModalHeader closeButton>
          <CModalTitle>Interview Assesment Form<br />
          </CModalTitle>
        </CModalHeader>
        <CCardHeader>
          {/* Pre-Assessment Test Score<br /><br /> */}
          <CModalBody>
          <CFormGroup row>
            <CCol md="3">
              <CLabel><b>Interview Round:</b></CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <p className="form-control-static">{candidateData && candidateData.interview_round}</p>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel><b>Candidate Name:</b></CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <p className="form-control-static"><b style={{ color: "blue" }}>{candidateData && candidateData.name}</b></p>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel><b>Date of Assesment:</b></CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <p className="form-control-static">{candidateData.assesment_date}</p>
            </CCol>
          </CFormGroup>
          </CModalBody>


        </CCardHeader>
        <CModalBody>
          <CForm encType="multipart/form-data" className="form-horizontal" >
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Appearance:</CLabel>
                </CCol>
                <CCol md="9">
                <p className="form-control-static">{candidateData.appearance}</p>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Bearing:</CLabel>
                </CCol>
                <CCol md="9">
                <p className="form-control-static">{candidateData.bearing}</p>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Communication skills:</CLabel>
                </CCol>
                <CCol md="9">
                <p className="form-control-static">{candidateData.appearance}</p>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Job knowledge:</CLabel>
                </CCol>
                <CCol md="9">
                <p className="form-control-static">{candidateData.job_knowledge}</p>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Motivation:</CLabel>
                </CCol>
                <CCol md="9">
                <p className="form-control-static">{candidateData.motivation}</p>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Education:</CLabel>
                </CCol>
                <CCol md="9">
                <p className="form-control-static">{candidateData.education_remarks}</p>
                </CCol>
              </CFormGroup>

            <CFormGroup row>
              <CCol md="3">
                <CLabel >Technical Skills(if any):</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <p className="form-control-static">{candidateData.technical_skills}</p>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel >Languages Known:</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <p className="form-control-static">{candidateData.languages_known}</p>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel >Comment:</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <p className="form-control-static">{candidateData.comments}</p>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel><strong>Overall Impression:</strong></CLabel>
              </CCol>
              <CCol md="9">
              <p className="form-control-static">{candidateData.overall_impression}</p>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel><strong>Decision:</strong></CLabel>
              </CCol>
              <CCol md="9">
              <p className="form-control-static">{candidateData.decision}</p>
              </CCol>
            </CFormGroup>
          </CForm>
        </CModalBody>
        <CModalFooter>
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default ViewSelectedInProgress;
