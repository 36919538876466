import React, { useState, lazy, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import "./dashboard.css";
import {
    TheContent,
    TheSidebar,
    TheFooter,
    TheHeader
  } from '../../containers/index'
import Lottie from 'react-lottie-player'
import {
  CBadge, CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CProgress,
  CCardGroup,
  CProgressBar,
  CRow,
  CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
  CCallout
} from '@coreui/react'
import {
  CChartDoughnut
} from '@coreui/react-chartjs'
import CIcon from '@coreui/icons-react'
import { withRouter } from "react-router-dom";
import ReactHighCharts from "react-highcharts";
import Highcharts from "highcharts";
import ChartLineSimple from '../charts/ChartLineSimple';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import MainChartExample from '../charts/MainChartExample.js'
import swal from "sweetalert";
import { ToastContainer } from 'react-toastify';
import { setRef } from '@material-ui/core';
import ChatBotComponent from '../common/chatbot';
import moment from 'moment';
import BirthdayComponent from './birthday_component/birthday_component'
import animationData from "../../views/animationData.json"
import animationDataGreen from "../../views/animationDataGreen.json"
const WidgetsDropdown = lazy(() => import('../widgets/WidgetsDropdown.js'))
const WidgetsBrand = lazy(() => import('../widgets/WidgetsBrand.js'))

// const { milliseconds, seconds, minutes, hours, days } = require('time-convert')
const DashboardPlain = (props) => {    
  const{withRouter}=props
  const api = new API()

  
  const defaultOptions = {
    loop: true,
    autoplay: true, 
   // animationData: "https://lottie.host/1be7f56e-ee63-458a-b943-ea5c9b2cdc18/epL9wE17va.json",
   animationData:{animationData},

    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // const test = () => {
  //   let res = msToHms(29817000) 
  //   console.log(res)
  // }
  // const msToHms = ms => (
  //   milliseconds.to(hours, minutes, seconds)(ms)
  //     .map(n => n < 10 ? '0' + n : n.toString()) // zero-pad
  //     .join(':')
  // )
  return (
    <>
    <div className="c-app c-default-layout">
 <TheSidebar />
 <div className="c-wrapper">
   <TheHeader />
    <div>
      <div>
        <div className="c-body">
          <main className="c-main">
            <div className="container-fluid" style={{padding: '0px', height: '100%'}}>
              <div className="fade show">
                <header className="card-header">
                  <div className="main-dd" style={{minHeight: '35px'}}>
                    <div className="name">
                      <h4 className='userclient mb-0'>
                        <span>Good Morning, <strong>User</strong>
                        </span>
                      </h4>
                      <div className="clndr_item" style={{float: 'right', display: 'flex'}}>
                        <div className='clndrit text-right'>
                          <p>Today:</p>
                          <date>2022-12-13 <span>To</span> 2022-12-13 </date>
                        </div> <div class="btn-group" role="group" aria-label="...">
                            <div class="btn-group profileDropSec" role="group"> <button type="button" class="border-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="../images/cal.svg" />
                                <span class="caret"></span>
                              </button>
                              <ul className="dropdown-menu py-2" aria-labelledby="dropdownMenuButton1">
                                <li>
                                  <a href="javacript:void(0);">Today</a>
                                </li>
                                <li>
                                  <a href="#">Yesterday</a>
                                </li>
                                <li>
                                  <a href="#">Last 7 Days</a>
                                </li>
                                <li>
                                  <a href="#">Last 30 Days</a>
                                </li>
                                <li>
                                  <a href="#">This Month</a>
                                </li>
                                <li>
                                  <a href="#">Select Months</a>
                                </li>
                                <li>
                                  <a href="#">Custom Range</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </header>
                <div className='dashBtns'>
                  <h4>HR 360 View Dashbaord</h4>
                  <div className='d-flex btnDashItems gap-2 flex-wrap justify-content-end'>
                    <button className='btn-border active'>Workforce Overview</button>
                    <button className='btn-border'>Time & Attendance</button>
                    <button className='btn-border'>Headcount</button>
                  </div>
                </div>
                <div className='employee_score'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <div className='boxItmsGraph'>
                        <h5 className='tagNamer'>Result</h5>
                        <div className='nourimtry'>
                         <b> <p>Employee Turnover</p> </b>
                          <div className='voulumtry'>
                            <p>
                              <span className='vougreen'></span>Voluntary
                            </p>
                            <p>
                              <span className='voured'></span>Involuntary
                            </p>
                          </div>
                        </div>
                        <div class="graphDesign graphDesign1">
                          <img src='../images/employeet-graph.svg' alt='Graph' />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='boxItmsGraph'>
                        <p>Employee Turnover Score</p>
                        <div className='maingraphDiv text-center'>
                          <div className='d-flex headgraphs justify-content-center'>
                            <h1>50%</h1>
                            <Lottie animationData={animationData} play style={{ width: 50, height: 50, transform: "rotate(270deg)"}} />
                          </div>
                          <div className='percntgraph text-center'>
                            <p className='mb-2 prtyke'>50/100</p>
                            <h5>Employees Turnover Yearly Data</h5>
                          </div>
                          <hr>
                          </hr>
                          <button className='grphBtn'>Download Report</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='employee_score'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='boxItmsGraph'>
                        <h5 className='tagNamer'>Cause</h5>
                        <p>Average Age</p>
                        <div className='graphDesign graphDesign2'>
                          <img src='../images/averageage-graph.svg' alt='Graph' />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='boxItmsGraph'>
                        <p>Retirement</p>
                        <div className='d-flex justify-content-center'>
                          <div className='maingraphDiv d-flex justify-content-center mx-2 flex-column align-items-center'>
                            <div className='d-flex headgraphs justify-content-center'>
                              <h1 className='mb-0'>64</h1>
                              <Lottie animationData={animationDataGreen} play style={{ width: 50, height: 50, transform: "rotate(270deg)"}} />
                            </div>
                            <div className='percntgraph text-center'>
                              <p className='mb-4'>Avg Retire Age</p>
                              <p className='voulumtry mb-1'>
                                <span class="voublue"></span>46 Actual
                              </p>
                              <p className='voulumtry mb-4'>
                                <span class="vougray"></span>33 Expected
                              </p>
                            </div>
                            <button className='grphBtn'>Download Report</button>
                          </div>
                          <div className='graphDesign graphDesign3 mx-2'>
                            <img src='../images/retirement-graph.svg' alt='Graph' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='boxItmsGraph'>
                        <p>Tenures</p>
                        <div className='d-flex flex-column align-items-center justify-content-center h-100' style={{paddingBottom:'30px'}}>
                          <div className='maingraphDiv text-center gap-4 d-flex justify-content-center'>
                            <div>
                              <div className='d-flex headgraphs justify-content-center'>
                                <h1 className='mb-0'>30</h1>
                                <Lottie animationData={animationDataGreen} play style={{ width: 50, height: 50, transform: "rotate(270deg)"}} />
                              </div>
                              <div className='percntgraph text-center'>
                                <p className='mb-5'>Tenures</p>
                              </div>
                            </div>
                            <div>
                              <div className='d-flex headgraphs justify-content-center'>
                                <h1 className='mb-0 numbertrsa'>49</h1>
                              </div>
                              <div className='percntgraph text-center'>
                                <p className='mb-5'>Awaiting Tenure</p>
                              </div>
                            </div>
                          </div>
                          <div className='text-center'>
                            <button className='grphBtn'>Download Report</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='employee_score'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='boxItmsGraph'>
                        <h5 className='tagNamer'>Effect</h5>
                        <p>Span of Control (Females in Mgt)</p>
                        <div className='d-flex justify-content-center'>
                          <div className='maingraphDiv d-flex mx-4 flex-column align-items-center'>
                            <div className='d-flex headgraphs justify-content-center'>
                              <h1 className='mb-0'>12.2%</h1>
                              <Lottie animationData={animationDataGreen} play style={{ width: 50, height: 50, transform: "rotate(270deg)"}} />
                            </div>
                            <div className='percntgraph text-center'>
                              <p className='mb-4'>Female Employess</p>
                              <p className='voulumtry mb-1'>
                                <span class="voublue"></span>Need To Hire
                              </p>
                              <p className='voulumtry mb-4'>
                                <span class="vougray"></span>Hired
                              </p>
                            </div>
                            <button className='grphBtn'>Download Report</button>
                          </div>
                          <div className='graphDesign mx-4 graphDesign4'>
                            <img src='../images/span-graph.svg' alt='Graph' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='boxItmsGraph'>
                        <h5 className='tagNamer'>Effect</h5>
                        <p>Length of Service (Loyalty)</p>
                        <div className='d-flex justify-content-center'>
                          <div className='graphDesign graphDesign5 mx-2 d-flex'>
                            <img src='../images/length-graph.svg' alt='Graph' />
                          </div>
                          <div className='maingraphDiv lengthItems mx-2 d-flex flex-column align-items-center'>
                            <div className='d-flex headgraphs justify-content-center'>
                              <h1 className='mb-0'>20</h1>
                            </div>
                            <div className='percntgraph text-center'>
                              <p className='mb-2'>Years Average</p>
                            </div>
                            <button className='grphBtn'>Download Report</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
    </div>
    </div>
    </>
  )
}

export default withRouter(DashboardPlain)
