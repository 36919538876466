
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'


import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast, ToastContainer } from 'react-toastify';
import { sweetAlertFun } from 'src/common/sweetAlert';



const ModalSendingWelcomeMail = (props) => {
    const { isModalOpen, isViewModalOpen, selectedCandidate,setSendEmailModal } = props
    const api = new API()
    const [name, setName] = useState("")
    const [empId, setEmpId] = useState("")
    const [email, setEmail] = useState("")
    const [designation, setDesignation] = useState("")
    const [showImage, setShowImage] = useState(false)
    const [department, setDepartment] = useState("")
    const [reportingManager, setReportingManager] = useState()
    const [location, setLocation] = useState()
    const [selectedImage, setSelectedImage] = useState()
    const [currenImg, setCurrenImg] = useState("")

    useEffect(() => {
        // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", selectedCandidate)
        setValueFun()
    }, [selectedCandidate])
    const setValueFun = () => {
        setName(selectedCandidate && selectedCandidate.first_name + " " + selectedCandidate.last_name)
        setEmpId(selectedCandidate && selectedCandidate.emp_id)
        setEmail(selectedCandidate && selectedCandidate.email)
        setDesignation(selectedCandidate && selectedCandidate.designation)
        setDepartment(selectedCandidate && selectedCandidate.department)
        setReportingManager(selectedCandidate && selectedCandidate.reporting_manager_first_name + " " + selectedCandidate.reporting_manager_last_name)
        setLocation(selectedCandidate && selectedCandidate.location)
        setCurrenImg(selectedCandidate && selectedCandidate.employee_passport_size_photo)
    }
    const sendWelcomeEmail = async (status) => {
        let sweetAlertCalling = await sweetAlertFun(`Are you sure to send welcome mail for ${name}`, "success")
        if (sweetAlertCalling) {
            const formData = new FormData()
            formData.append("isEmailSend", status)
            formData.append("type", "dynamic")
            formData.append("emp_id", empId)
            formData.append("email", email)
            formData.append("designation", designation)
            formData.append("reporting_manager", reportingManager)
            formData.append("emp_name", name)
            formData.append("department", department)
            formData.append("location", location)
            formData.append("attachedImage", selectedImage)
            // let data = {
            //     type: "dynamic",
            //     emp_id: empId,
            //     email: email,
            //     designation: designation,
            //     reporting_manager: reportingManager,
            //     emp_name: name,
            //     department: department,
            //     location: location
            // }

            // return
            let result = await api.putPostFileFun(config.sendWelcomeMail, formData)
            if (result && result.code == 200 && result.data) {
                toast.success(result.message)
                setSendEmailModal(false)
            } else {
                toast.warning(result && result.message)
            }
        } else {
            toast.warning("Sending mail failed")
        }
    }
    const uploadImage = (e) => {
        setSelectedImage(e.target.files[0])
        setShowImage(true)


    }
    const newCandidateAccessManagement = async () => {
        let result = await api.get(config.new_joiners_candidate_for_hr_recruiter)
        // console.log("pending requisition-----------------", result)
        if (result && result.code == 200) {
            //   setCandidateAccessManagement(result.data)
            setSelectedImage(result && result.data && result.data.employee_passport_size_photo)
        }
        else {
            // console.log("unable to get new candidate access management")
        }
    }
    useEffect(() => {
        newCandidateAccessManagement()

    }, [])
    return (
        <div className="">
            <CModal
                show={isModalOpen}
                onClose={() => isViewModalOpen()}
                size="lg"
            >
                <CModalHeader className="header-color" closeButton>
                    <CModalTitle>Send welcome email for {name}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form style={{ width: "90%", margin: "auto" }}>
                        <div className="form-group">
                            <label for="emp_id">Employee Name</label>
                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="" />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Emp Id </label>
                            <input type="text" className="form-control" value={empId} onChange={(e) => setEmpId(e.target.value)} placeholder="" />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Email</label>
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Designation</label>
                            <input type="text" className="form-control" value={designation} onChange={(e) => setDesignation(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Department</label>
                            <input type="text" className="form-control" value={department} onChange={(e) => setDepartment(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Reporting Manager</label>
                            <input type="text" className="form-control" value={reportingManager} onChange={(e) => setReportingManager(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Location</label>
                            <input type="text" className="form-control" value={location} onChange={(e) => setLocation(e.target.value)} />
                        </div>
                        <div className="row">
                            <div className='col-md'>
                                <label for="emp_id">Attach Profile Image</label>

                            </div>
                            <div className='col-md'>
                                <input type="file" onChange={(e) => uploadImage(e)} />
                            </div>
                            {selectedImage&&
                             <div className='col-md'>
                             <i className="fa fa-trash" onClick={()=>setSelectedImage("")} aria-hidden="true" title="Delete uploaded photo"></i>
                             </div>
                            }
                           

                        </div>
                        <br />
                      
                            <div className='row'>
                                <div className='col-md'>
                                    <img style={{ width: "250px", height: "250px", borderRadius: "50%",border:"2px black solid"  }} src={selectedImage ? URL.createObjectURL(selectedImage) : currenImg?currenImg:"public/images/logo.png"}></img>
                                </div>
                                {/* <div className='col-md'>
                                    <img style={{ width: "250px", height: "250px", borderRadius: "50%",border:"2px black solid" }} src={selectedImage ? URL.createObjectURL(selectedImage) : "https://thumbs.dreamstime.com/b/select-concept-hand-press-button-39838424.jpg"}></img>
                                </div> */}
                            </div>
              

                        <div className='row'>

                        </div>





                    </form>
                </CModalBody>
                <CModalFooter>
                    <button type="button" className="btn btn-success float-right" onClick={() => sendWelcomeEmail(0)}>Active without email</button>
                    <button type="button" className="btn btn-success float-right" onClick={() => sendWelcomeEmail(1)}>Active & Send Welcome Email</button>

                    <CButton color="danger" onClick={() => isViewModalOpen()}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}
                </CModalFooter>
            </CModal>
            {/* <ToastContainer/> */}
        </div>
    )
}
export default ModalSendingWelcomeMail







