
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import './dailyRatingSurvey.css'
import { config } from 'src/utils/apiUrl'
import API from 'src/utils/apiCalling'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DailyRatingSurvey = (props) => {
    const api = new API()
    const { isOpen, isViewModalOpen, setIsOpenRatingSurvey, employeeName } = props

    const [popopFor, setPopupFor] = useState("")
    const [rating, setRating] = useState("")
    const [remark, setRemark] = useState("")

    useEffect(() => {

    }, [])
    const isModalOpen = () => {

    }

    const submitDailyRatingSurvey = async () => {
        
        let data = {
            rating_survey: rating ? rating : 0,
            remark: remark
        }
        if (!data.remark) {
            delete data.remark
        }
        let result = await api.post(config.giveEmployeeRatingForSurvey, data)
        if (result && result.code == 200) {
            if(rating){
                toast.success("Submitted successfully!")
            }
            // setTimeout(() => {
                setIsOpenRatingSurvey(false)
            // }, 1000)
        } else {
            // console.log(result && result.message)
        }
    }
    return (
        <div className="" >
            {/* images/icons/confused-11.png */}
            <CModal style={{ background: "none", border:"none"}}
                show={isOpen}
                // show={true}

                onClose={() => !isOpen}
                size="lg"
            >
                {/* <CModalHeader closeButton>
                    <CModalTitle>{"Change Password"}</CModalTitle>
                </CModalHeader> */}
                <CModalBody>
                
                    <div className="container justify-content-center">
                        <div className="card rating_bg mt-5">
                            <div className="mt-2 text-center">
                                <h4 className="mb-0" style={{color:"#6f4e37"}}>Hi {employeeName }, How is your mood today?</h4>
                                {/* <small className="px-3">Please let us know about your chat experience.</small> */}
                                <div className="d-flex flex-row justify-content-center mt-2">
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md">
                                                <div className="box emoji_hover" style={rating === "1" ? {transform: "scale(1.5)",width:"115px"}:{width:"115px"}} onClick={() => setRating("1")}>
                                                    <img src="images/icons/emoji/frustered.gif" height={"90"} width={"90"}/>
                                                    <div className='text_reting mt-2'>
                                                    <h6>Frustrated</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div className="box emoji_hover" style={rating === "2" ? {transform: "scale(1.5)",width:"115px"}:{width:"115px"}} onClick={() => setRating("2")}>
                                                <img src="images/icons/emoji/tensed.gif" height={"90"} width={"90"}/>
                                                    <div className='text_reting mt-2'>
                                                    <h6>Tensed</h6>
                                                    </div>
                                                </div>
                                                

                                            </div>
                                            <div className="col-md">
                                                <div className="box emoji_hover" style={rating === "3" ? {transform: "scale(1.5)",width:"115px"}:{width:"115px"}} onClick={() => setRating("3")}>
                                                <img src="images/icons/emoji/at_ease.gif" height={"90"} width={"90"}/>
                                                    <div className='text_reting mt-2'>
                                                    <h6>At Ease</h6>
                                                    </div>
                                                </div>
                                                

                                            </div>
                                            <div className="col-md">
                                                <div className="box emoji_hover" style={rating === "4" ? {transform: "scale(1.5)",width:"115px"}:{width:"115px"}} onClick={() => setRating("4")}>
                                                <img src="images/icons/emoji/cheerfull.gif" height={"90"} width={"90"}/>
                                                    <div className='text_reting mt-2'>
                                                    <h6>Cheerfull</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md">
                                                <div className="box emoji_hover" style={rating === "5" ? {transform: "scale(1.5)",width:"115px"}:{width:"115px"}} onClick={() => setRating("5")}>
                                                <img src="images/icons/emoji/enthusiastic.gif" height={"90"} width={"90"}/>
                                                    <div className='text_reting mt-2'>
                                                    <h6>Enthusiastic</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                       <hr className='line_rating'></hr>  
                                    </div>
                                </div>
                                
                                <div className='container'>
                                    <div className="form-group" style={{width:"70%", margin:"auto", border:"1px lightblue solid"}}>
                                    <textarea value={remark} onChange={(e) => setRemark(e.target.value)} className="form-control" rows="3" placeholder="Would you like to tell us about it?"></textarea>
                                    </div>
                                </div>

                                {/* <p className="mt-3">Continue without sending feedback</p> */}
                                <div className="mt-2">
                                    <button type="button" onClick={submitDailyRatingSurvey} className="btn btn-success mb-3"><span>Share feedback</span></button>
                                    <button type="button" onClick={submitDailyRatingSurvey} className="btn btn-primary mb-3 ml-5"><span>Ignore</span></button>

                                </div>
                            </div>
                        </div>
                    
                    </div>

                </CModalBody>
                {/* <CModalFooter>
                    <CButton color="success" onClick={() => isViewModalOpen()}>Close</CButton>{' '}

                </CModalFooter> */}
            </CModal>
        </div>

    )
}
export default DailyRatingSurvey







