import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';

import ModalOfferAccept from './modal';
import UserInfoDoc from './user_info_doc';
import { isPermission } from 'src/utils/common';
const OfferAccept = props => {
  const {} = props;
  let api = new API();

  const [CandidateRecord, setCandidateRecord] = useState();
  const [active, setActive] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingRequisition, setPendingRequisition] = useState();
  const [isCreateCondidateModalOpen, setIsCreateCondidateModalOpen] = useState(
    false,
  );
  const [candidateDetails, setCandidateDetails] = useState();
  const [activeKey, setActiveKey] = useState(1);
  const [large, setLarge] = useState(false);
  const [candidate, setCandidate] = useState();
  const [offer_Accept, setOffer_Accept] = useState();
  const [offer_rejected, setOffer_Rejected] = useState();
  const [isViewDocument, setIsViewDocument] = useState(false);
  const [
    candidateFullDocumentDetails,
    setCandidateFullDocumentDetails,
  ] = useState();
  const [selectedCandidateData, setSelectedCandidateData] = useState();
  useEffect(() => {
    getOfferAcceptCandidate();
    getOfferRejectCandidate();
  }, []);

  const getOfferAcceptCandidate = async () => {
    let result = await api.get(config.totalOfferAcceptedFromCandidate);
    if (result && result.code == 200) {
      // console.log(" offer issued", result)
      setOffer_Accept(result.data);
    } else {
      // console.log("something wrong")
    }
  };
  const getOfferRejectCandidate = async () => {
    let result = await api.get(config.totalOfferRejectedFromCandidate);
    if (result && result.code == 200) {
      // console.log(" offer issued", result)

      setOffer_Rejected(result.data);
    } else {
    }
  };

  const isViewModalOpen = candidate => {
    setIsModalOpen(!isModalOpen);
    setCandidateDetails(candidate);
  };

  const isDocumentView = () => {
    setIsViewDocument(!isViewDocument);
  };

  const getDocumentDetails = async Data => {
    setSelectedCandidateData(Data);
    let data = {
      user_id: Data.emp_user_id,
      // candidate_email: "yogesh.mathur@qdegrees.com"
    };
    let result = await api.get(
      config.getEmployeeAllPersonalDetails,
      true,
      data,
    );
    if (result && result.code == 200) {
      setCandidateFullDocumentDetails(result.data);
      isDocumentView();
    } else {
    }
  };
  return (
    <div className="">
      <div className="container">
        <h3 className="text-uppercase text-center mt-3 mb-4">
          {' '}
          <u>Offer Accept Candidate</u>
        </h3>
        <CRow>
          <CCol xs="12">
            <CCard>
              <CCardBody>
                <CTabs
                  activeTab={active}
                  onActiveTabChange={idx => setActive(idx)}
                >
                  <CNav variant="tabs">
                    <CNavItem>
                      <CNavLink>Offer Accept</CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink>Offer Reject</CNavLink>
                    </CNavItem>
                  </CNav>
                  <CTabContent>
                    <CTabPane>
                      {isViewDocument == false ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <>
                                <th scope="col">#</th>
                                <th scope="col">ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Designation</th>
                                <th scope="col">MRF Id</th>
                                <th scope="col">MRF Applying For</th>
                                <th scope="col">Overall Interview Status</th>
                              </>
                            </tr>
                          </thead>
                          <tbody>
                            {offer_Accept &&
                              offer_Accept.map((data, index) => (
                                <tr>
                                  <th scope="row">{'1'}</th>
                                  <td>{data.id}</td>
                                  <td>{data.name}</td>
                                  <td>{data.email}</td>
                                  <td>{data.designation}</td>
                                  <td>{data.mrf_id}</td>
                                  <td className="letter">
                                    {data.mrf_applying_for == 'vacancy'
                                      ? 'New Hiring'
                                      : data.mrf_applying_for}
                                  </td>

                                  <td>
                                    {data.overall_interview_status == 1
                                      ? 'Selected'
                                      : 'Not Selected'}
                                  </td>
                                  <td>
                                    {isPermission(
                                      'candiate_details_offer_accept',
                                    ) && (
                                      <CRow>
                                        <CCol xs="12" md="4">
                                          <CButton
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="View"
                                            className="btn btn-primary mr-0"
                                            onClick={() =>
                                              isViewModalOpen(data)
                                            }
                                          >
                                            {' '}
                                            <i
                                              className="fa fa-eye"
                                              aria-hidden="true"
                                            ></i>
                                          </CButton>
                                        </CCol>
                                        <CCol xs="12" md="8">
                                          {/* <CButton
                                          className="btn btn-danger mr-0"
                                          onClick={() =>
                                            getDocumentDetails(data)
                                          }
                                        >
                                          View Documents
                                        </CButton> */}
                                        </CCol>
                                      </CRow>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <div>
                          {/* <h2>Document section</h2> */}
                          <UserInfoDoc
                            candidateFullDocumentDetails={
                              candidateFullDocumentDetails
                            }
                            selectedCandidateData={selectedCandidateData}
                          />
                        </div>
                      )}
                    </CTabPane>
                    <CTabPane>
                      <table className="table">
                        <thead>
                          <tr>
                            <>
                              <th scope="col">#</th>
                              <th scope="col">ID</th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Designation</th>
                              <th scope="col">MRF Id</th>
                              <th scope="col">MRF Applying For</th>
                              <th scope="col">Overall Interview Status</th>
                            </>
                          </tr>
                        </thead>
                        <tbody>
                          {offer_rejected &&
                            offer_rejected.map((data, index) => (
                              <tr>
                                <th scope="row">{'1'}</th>
                                <td>{data.id}</td>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.designation}</td>
                                <td>{data.mrf_id}</td>
                                <td className="letter">
                                  {data.mrf_applying_for == 'vacancy'
                                    ? 'New Hiring'
                                    : data.mrf_applying_for}
                                </td>

                                <td>
                                  {data.overall_interview_status == 1
                                    ? 'Selected'
                                    : 'Not Selected'}
                                </td>

                                <CRow>
                                  {isPermission(
                                    'candiate_details_offer_accept',
                                  ) && (
                                    <CCol xs="12" md="12">
                                      <CButton
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View"
                                        className="btn btn-primary mr-0"
                                        onClick={() => isViewModalOpen(data)}
                                      >
                                        <i
                                          className="fa fa-eye"
                                          aria-hidden="true"
                                        ></i>{' '}
                                      </CButton>
                                    </CCol>
                                  )}
                                  {/* <CCol xs="12" md="6">
                                        <CButton  block shape="pill" className="btn btn-danger mr-0" onClick={() => sendOfferLatter(data)}>Final Approval</CButton>
                                    </CCol>
                                     */}
                                </CRow>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </CTabPane>
                  </CTabContent>
                </CTabs>
                <CCardFooter>
                  {isViewDocument == true && (
                    <>
                      <CButton
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Back"
                        color="success"
                        onClick={isDocumentView}
                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                      </CButton>
                    </>
                  )}
                </CCardFooter>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <ModalOfferAccept
        isModalOpen={isModalOpen}
        message="Candidate Details"
        isViewModalOpen={isViewModalOpen}
        candidateDetails={candidateDetails}
      />
    </div>
  );
};
export default OfferAccept;
