
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';

import CreateNewCondidate from './createNewCondidate';

const CondidateModal = (props) => {
    const { isModal, isModalOpen } = props
    // const [isOpen, setIsOpen] = useState(false)

    
    
    useEffect(() => {

    }, [])
    // const isModalOpen = () => {
    //     setIsOpen(!isOpen)
    // }

    return (
        <div className="">
                <CModal
                    show={isModal}
                    onClose={() => isModalOpen()}
                    size="lg"
                >

                    <CModalHeader closeButton>
                        <CModalTitle>Create New Candidate</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <CreateNewCondidate/>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="danger" onClick={() => isModalOpen()}><i className="fa fa-times" aria-hidden="true"></i>   </CButton>{' '}

                    </CModalFooter>
                </CModal>


        </div>

    )
}
export default CondidateModal







