import React, { useState, useEffect } from 'react'
import {
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CButton} from '@coreui/react'

import { config } from '../../../src/utils/apiUrl'
import API from '../../../src/utils/apiCalling'


const ModalAccessManagement = (props) => {
    let api = new API()
    const { isModalOpen, message, isViewModalOpen, candidateDetails, addEmployeeEmpId, generatedEmpId,generatedEmailType } = props
    const [officialEmpId, setOfficialEmpId] = useState()
    const [companyId, setCompanyId] = useState('')
    const [selectedCandidateId, setSelectedCandidateId] = useState("")
    const [generateEmailType, setGenerateEmailType] = useState("")
    const [company, setCompany] = useState([]);
    useEffect(() => {
            if(candidateDetails && candidateDetails.email === selectedCandidateId){
                setOfficialEmpId(generatedEmpId)
                setGenerateEmailType(generatedEmailType)
            } else {
                setOfficialEmpId("")
                setSelectedCandidateId(candidateDetails && candidateDetails.email)
                setGenerateEmailType("")
            }
        // }
    }, [candidateDetails, generatedEmpId])


    const getEntities = async () => {
        let result = await api.get(config.getentities)
        if (result && result.code == 200) {
          setCompany(result.data)
        }
      }
      const getEmpId = async (item) => {
            let data = {
              emp_id_start: item
            }
            let result = await api.get(config.getEmpIdByCompany, true, data)
            if (result && result.code === 200) {
              setOfficialEmpId(result?.data);
              setCompanyId(item);
            } 
         
      }
    useEffect(() => {
        getEntities()
    }, [])

    return (
        <div className="">
            <CModal
                show={isModalOpen}
                onClose={() => isViewModalOpen()}
                size="lg"
            >
                <CModalHeader closeButton>
                    <CModalTitle>{message}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form style={{width:"90%", margin:"auto"}}>
                        <div className="form-group">
                            <label for="emp_id">Employee Email ID</label>
                            <input type="text" disabled className="form-control" value={candidateDetails && candidateDetails.email} placeholder="" />
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Employee ID <b style={{color:"red"}}>*</b></label>
                            <select type="select" className="form-control" value={companyId} onChange={(e) => getEmpId(e.target.value)} id="selectBranch" required> 
                            <option hidden>--Select ID Type--</option>
                            {company && company.map((data) => (
                                <option value={data.emp_id_start}>{data.emp_id_start}</option>
                            ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Employee Email Type <b style={{color:"red"}}>*</b></label>
                            <select type="select" className="form-control" value={generateEmailType} onChange={(e) => setGenerateEmailType(e.target.value)} id="generateEmailType" required> 
                            <option hidden>Select Email Type</option>
                            <option value=".in">.in</option>
                            <option value=".com">.com</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="emp_id">Employee Emp ID <b style={{color:"red"}}>*</b></label>
                            <input type="text" className="form-control" value={officialEmpId} id="emp_id" required disabled/>
                        </div>

                    </form>
                </CModalBody>
                <CModalFooter>
                        <button type="button" className="btn btn-success float-right" onClick={() => addEmployeeEmpId(candidateDetails.id,officialEmpId,generateEmailType)}>Submit</button>
                    <CButton color="danger" onClick={() => isViewModalOpen()}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}
                </CModalFooter>
            </CModal>
            {/* <ToastContainer/> */}
        </div>
    )
}
export default ModalAccessManagement







