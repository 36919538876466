/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { TheSidebar, TheHeader } from '../containers/index';
import OtpInput from 'react-otp-input';
import { CCard, CCardBody, CCol, CRow } from '@coreui/react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast, ToastContainer } from 'react-toastify';
import './guest_form.css';
import Select from 'react-select';
import { CustButton } from 'src/reusable';
import swal from 'sweetalert';
import TimeDropdown from '../../src/reusable/CustHourDropdown';
import ModalCaptureImage from './ModalCaptureImage';

const Reception = props => {
  const api = new API();
  const [allEmployee, setAllEmployee] = useState([]);
  const [employeeID, setEmployeeID] = useState();
  const [sessionID, setSessionID] = useState('');
  const [verified, setVerified] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [mobile, setMobile] = useState('');
  const [noPerson, setNoPerson] = useState('');
  const [button, setButton] = useState(false);
  const [buttonOTP, setButtonOTP] = useState(false);
  const [otp, setOtp] = useState('');
  const [guestName, setGuestName] = useState('');
  const [purpose, setPurpose] = useState('');
  const [tempCard, setTempCard] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [image, setImage] = useState('');

  const handleSelectTime = type => e => {
    if (type === 'hour') {
      setSelectedHour(parseInt(e.target.value));
    } else if (type === 'minute') {
      setSelectedMinute(parseInt(e.target.value));
    }
  };

  const saveEmployee = employee => {
    setEmployeeID(employee.value);
  };

  const getAllActiveEmployee = async () => {
    const result = await api.get(config.activeEmployee);
    if (result.code === 200) {
      setAllEmployee(result.data);
    }
  };

  const fillGuestForm = async () => {
    let data = {
      guest_name: guestName,
      mobile: mobile,
      purpose: purpose,
      no_of_person: noPerson || '',
      otp_verify: verified ? 1 : 0,
      meeting_with: employeeID,
      meeting_duration: selectedHour + ':' + selectedMinute || '',
      temp_card: tempCard || '',
      captureImage: image,
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure to submit form`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.guestDetails, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          ResetFun();
          setEmployeeID('');
        } else {
          toast.error(result.message);
        }
      }
    });
  };

  const verifiedUser = async () => {
    let data = {
      session_id: sessionID,
    };
    let result = await api.get(config.verifiedUser, true, data);
    if (result && result.code === 200) {
      setVerified(result?.data);
    } else {
      toast.error(result.message);
    }
  };

  useEffect(() => {
    getAllActiveEmployee();
  }, []);

  const ResetFun = async () => {
    setMobile('');
    setButton(false);
    setButtonOTP(false);
    setGuestName('');
    setPurpose('');
    setVerified('');
    setEmployeeID('');
    setSelectedHour('');
    setSelectedMinute('');
    setNoPerson('');
    setTempCard('');
    setOtp('');
    setImage('');
  };

  const checkMobile = async e => {
    setMobile(e);
    if (e.length === 10) {
      console.log(e);
      let data1 = {
        mobile_email: e,
      };
      let result = await api.post(config.sendOTP, data1);
      if (result && result.code === 200) {
        setSessionID(result?.data?.session_id);
        setButton(true);
        toast.success(result.message);
      } else {
        toast.error(result.message);
        setButton(false);
      }
    }
  };

  const checkOtp = async e => {
    setOtp(e);
    if (e.length === 6) {
      let data = {
        mobile_email: mobile,
        otp: e,
        session_id: sessionID,
      };
      let result = await api.get(config.verifyOTP, true, data);
      if (result && result.code === 200) {
        toast.success(result.message);
        verifiedUser();
        setButtonOTP(true);
      } else {
        toast.error(result.message);
        setButtonOTP(false);
      }
    }
  };

  const handleChange = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setTempCard(e.target.value);
    }
  };

  const toggleModal = data => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="container mt-3">
          <CRow>
            <CCol xs="12">
              <CCard>
                <CCardBody>
                  <div className="p-4">
                    <div className="text-center">
                      <img
                        src="images/qdegreesLogo.png"
                        alt=""
                        height="40px"
                        width="180px"
                      />
                    </div>
                    <br />
                    <div className="header_content text-center pb-5">
                      <strong className="h4">
                        <u>
                          <b>GUEST FORM</b>
                        </u>
                      </strong>
                    </div>

                    <div className="table">
                      <table className="tableempdetail" cellPadding="3">
                        <tbody>
                          <tr>
                            <th className="tableth1">
                              Guest Name <b style={{ color: 'red' }}>*</b>
                            </th>
                            <td className="tabletd1">
                              <input
                                required
                                style={{ width: '100%', color: 'black' }}
                                value={guestName}
                                onChange={e => setGuestName(e.target.value)}
                                name="guest_name"
                                className="form-control"
                                type="text"
                                placeholder="Enter Guest Name"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th className="tableth1">
                              Guest Mobile No. <b style={{ color: 'red' }}>*</b>
                            </th>
                            <td
                              className="tabletd1"
                              style={{
                                paddingTop: '1.5%',
                                paddingBottom: '1.5%',
                              }}
                            >
                              <OtpInput
                                inputStyle={{
                                  width: '70%',
                                  height: '200%',
                                  color: '#212529',
                                  backgroundClip: 'padding-box',
                                  border: '1px solid #ced4da',
                                  borderRadius: '0.375rem',
                                  backgroundColor: button
                                    ? 'lightgray'
                                    : '#fff',
                                }}
                                isDisabled={button}
                                value={mobile}
                                onChange={e => checkMobile(e)}
                                numInputs={10}
                                separator={<span></span>}
                                isInputNum={true}
                                renderInput={props => <input {...props} />}
                              />
                            </td>
                          </tr>
                          {button && (
                            <tr style={{ borderBottom: '1px solid #dbdbdb' }}>
                              <th className="tableth1">
                                Enter OTP <b style={{ color: 'red' }}>*</b>
                              </th>
                              <td
                                style={{
                                  paddingTop: '1.5%',
                                  paddingBottom: '1.5%',
                                }}
                              >
                                <OtpInput
                                  className="otp-spacing"
                                  inputStyle={{
                                    width: '44%',
                                    height: '200%',
                                    color: '#212529',
                                    backgroundClip: 'padding-box',
                                    border: '1px solid #ced4da',
                                    borderRadius: '0.375rem',
                                    backgroundColor: buttonOTP
                                      ? 'lightgray'
                                      : '#fff',
                                  }}
                                  isDisabled={buttonOTP}
                                  value={otp}
                                  onChange={e => checkOtp(e)}
                                  numInputs={6}
                                  separator={<span></span>}
                                  isInputNum={true}
                                  renderInput={props => <input {...props} />}
                                />
                              </td>
                            </tr>
                          )}

                          <tr style={{ borderBottom: '1px solid #dbdbdb' }}>
                            <th className="tableth1">
                              Purpose <b style={{ color: 'red' }}>*</b>
                            </th>
                            <td className="tabletd1">
                              <input
                                required
                                style={{ width: '100%', color: 'black' }}
                                value={purpose}
                                onChange={e => setPurpose(e.target.value)}
                                name="purpose"
                                className="form-control"
                                type="text"
                                placeholder="Enter Purpose"
                              />
                            </td>
                          </tr>

                          <tr style={{ borderBottom: '1px solid #dbdbdb' }}>
                            <th className="tableth1">
                              Meeting With <b style={{ color: 'red' }}>*</b>
                            </th>
                            <td className="tabletd1">
                              <div style={{ width: '100%' }}>
                                <Select
                                  required
                                  style={{ fontSize: '14px' }}
                                  placeholder="Select Meeting With..."
                                  onChange={saveEmployee}
                                  options={
                                    allEmployee &&
                                    allEmployee.length > 0 &&
                                    allEmployee.map(val => {
                                      val.label =
                                      val.employee + ' (' + val.emp_id + ')';
                                      val.value = val.id;
                                      return val;
                                    })
                                  }
                                />
                              </div>
                            </td>
                          </tr>

                          <tr style={{ borderBottom: '1px solid #dbdbdb' }}>
                            <th className="tableth1">No. of Person</th>
                            <td className="tabletd1">
                              <input
                                style={{ width: '100%', color: 'black' }}
                                value={noPerson}
                                onChange={e => setNoPerson(e.target.value)}
                                name="no_of_person"
                                className="form-control"
                                type="text"
                                placeholder="Enter No. of person"
                              />
                            </td>
                          </tr>

                          <tr>
                            <th className="tableth1">Meeting Duration</th>
                            <td className="tabletd1">
                              <div style={{ width: '100%' }}>
                                <TimeDropdown
                                  selectedHour={selectedHour}
                                  selectedMinute={selectedMinute}
                                  onSelectTime={handleSelectTime}
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <th className="tableth1">Temp Card No.</th>
                            <td className="tabletd1">
                              <input
                                style={{ width: '100%', color: 'black' }}
                                value={tempCard}
                                onChange={handleChange}
                                name="purpose"
                                className="form-control"
                                type="tel"
                                placeholder="Enter Temp Card No."
                                maxLength={3}
                              />
                            </td>
                          </tr>

                          <tr>
                            <th className="tableth1">Capture Your Image </th>
                            <td className="tabletd1">
                              {image ? (
                                <CustButton
                                  type="reset"
                                  name=""
                                  text=" Capture New"
                                  size="md"
                                  color="info"
                                  onClick={toggleModal}
                                  className="btn-loading btn-ladda ml-1 float-left"
                                  icon="fa fa-camera"
                                />
                              ) : (
                                <CustButton
                                  type="reset"
                                  name=""
                                  text=" Capture"
                                  size="md"
                                  color="primary"
                                  onClick={toggleModal}
                                  className="btn-loading btn-ladda ml-1 float-left"
                                  icon="fa fa-camera"
                                />
                              )}
                            </td>
                          </tr>
                          {image && (
                            <tr>
                              <th className="tableth1"></th>
                              <td className="tabletd1">
                                <div className="col-md-6">
                                  <img
                                    src={image}
                                    width={'250px'}
                                    height={'170px'}
                                  />
                                  <CustButton
                                    type="reset"
                                    name=""
                                    text=""
                                    size="md"
                                    color="danger"
                                    onClick={e => setImage('')}
                                    className="btn-loading btn-ladda ml-1 float-right"
                                    icon="fa fa-trash"
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="col-sm-12 text-right mt-3">
                        <CustButton
                          type="reset"
                          name="resetSearch"
                          text="Reset"
                          size="md"
                          color="danger"
                          onClick={ResetFun}
                          className="btn-loading btn-ladda ml-1"
                          icon=""
                        />
                        <CustButton
                          type="button"
                          name="btn"
                          text="Submit Form"
                          size="md"
                          color="primary"
                          onClick={fillGuestForm}
                          className="btn-loading btn-ladda ml-1"
                          icon=""
                          isDisabled={verified ? false : true}
                        />
                      </div>
                    </div>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
      </div>
      <ModalCaptureImage
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        setImage={setImage}
      />
      <ToastContainer />
    </div>
  );
};

export default Reception;
