
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile
} from '@coreui/react'
import './offer_later.css'
import Moment from 'react-moment';

const OfferLater = (props) => {
  const { condidate, joiningDate, todayDate } = props

  const [isCTCFullDetails, setIsCTCFullDetails] = useState(false)
  
  // const [large, setLarge] = useState(false)
  useEffect(() => {
    
  }, [])

  // const isModalOpen = () => {
  //     setLarge(!large)
  // }
  const ctcFullDetails = () => {
    setIsCTCFullDetails(true)
  }

  return (


    <div className>
      <center style={{ width: '100%', backgroundColor: '#f1f1f1' }}>
        <div style={{ maxWidth: '950px', margin: '0 auto', backgroundColor: '#fff' }} className="email-container">
          <div style={{ maxWidth: '950px', margin: '0 auto' }} className="image-hr">
            <table align="center" role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%" style={{ margin: 'auto' }}>
              <tbody><tr>
                <td valign="top" className="bg_white" style={{ padding: '0em 3.5em' }}>
                  <table role="presentation" border={0} cellPadding={0} cellSpacing={0} width="100%">
                    <tbody><tr>
                      <td width="40%" className="logo" style={{ textAlign: 'right' }}>
                        <h1>
                          <img src="images/qdegrees.png" />
                        </h1>
                      </td>
                    </tr>
                    </tbody></table>
                </td>
              </tr>
                <tr>
                  <td valign="top" className="bg_white" style={{ padding: '0em 2.5em' }}>
                    <table role="presentation" border={0} cellPadding={0} cellSpacing={0} width="100%">
                      <tbody><tr>
                        <td width="40%" className="logo" style={{ textAlign: 'left' }}>
                          <p>
                            <strong className="hr-color">!Date</strong>
                            <br />
                                    Ref No:<strong className="hr-color">!Entity</strong>/HR/OL/JPR/<strong className="hr-color"> !2020-21</strong>
                          </p>
                          <p>To</p>
                          <p><strong className="hr-color">{condidate && condidate.firstName} {condidate && condidate.lastName}</strong></p>
                        </td>
                      </tr>
                      </tbody></table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="40%">
                      <h2 style={{ textAlign: 'center' }}> <ins> Subject: Offer Letter</ins></h2>
                      <p style={{ textAlign: 'justify', padding: '1em 2.5em' }}>This has reference to your application and the subsequent interviews you had with us. We are pleased to make you an offer for the position of <strong className="hr-color">{condidate && condidate.Role}</strong> at <strong className="hr-color">{condidate && condidate.Location} </strong> location. You will be given a Letter of Appointment on joining.</p>
                      <p style={{ textAlign: 'justify', paddingLeft: '2.5em' }}>Attached is a copy giving details of your salary package. (Refer Annexure A)</p>
                      <p style={{ textAlign: 'justify', paddingLeft: '2.5em' }}>Your appointment is subject to:</p>
                      <p style={{ paddingLeft: '2.5em' }}><b>1.</b>  You’re joining the services of the company on or before<strong className="hr-color"> {joiningDate && joiningDate}</strong> </p>
                      <p style={{ paddingLeft: '2.5em' }}><b>2.</b> 	Your written acceptance of the offer letter.</p>
                      <p style={{ paddingLeft: '2.5em' }}><b>3.</b> 	The verification of your testimonials.</p>
                      <p style={{ paddingLeft: '2.5em' }}><b>4.</b> 	Us receiving satisfactory reports from your references.</p>
                      <p style={{ paddingLeft: '2.5em' }}><b>5.</b> 	You are found medically fit for the job.</p>
                      <p style={{ paddingLeft: '2.5em' }}><b>6.</b> 	Copy of police verification certificate.</p>
                      <p style={{ paddingLeft: '2.5em' }}><strong className="hr-color">90</strong> days of Notice period needs to be served before leaving the organization. Any sort of available leaves will not be adjusted against the notice period.
                                    In case you leave the organization before six months, you shall be liable to pay company the amount equivalent to three months gross salary. You will be on a probation period for six months.
                                  </p>
                      <p style={{ paddingLeft: '2.5em' }}>Please sign and return to us the duplicate of this letter as a token of your acceptance of the above terms and conditions.</p>
                      <p style={{ paddingLeft: '2.5em' }}>We look forward to you joining our team and hope it is the beginning of a mutually fulfilling association.</p>
                      <p style={{ paddingLeft: '2.5em', fontFamily: 'Georgia, "Times New Roman", Times, serif', fontWeight: 700 }}>Disclaimer: This document is a confidential and is explicitly reserved to QDegrees. QDegrees is only authorized to modify any clause mentioned in the document at any point of time.
                                  </p>
                      <p style={{ paddingLeft: '2.5em', fontFamily: 'monospace', fontWeight: 700 }}>For QDegrees Services Pvt. Ltd.</p>
                      <p style={{ paddingLeft: '2.5em', fontWeight: 700 }}>Sonam Rara </p>
                      <p style={{ paddingLeft: '2.5em', fontWeight: 700 }}>Head – Human Resource</p>
                      <p style={{ paddingLeft: '2.5em', fontFamily: 'monospace' }}>I have read and accept the terms and conditions:</p>
                    </div>
                  </td>
                </tr>
              </tbody></table>
            <table>
              <tbody><tr>
                <td valign="middle" width="20%">
                  <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                    <tbody><tr>
                      <td className="counter-text">
                        <span className="name">Date of Joining</span>
                      </td>
                    </tr>
                    </tbody></table>
                </td>
                <td valign="middle" width="20%">
                  <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                    <tbody><tr>
                      <td className="counter-text">
                        <span className="name"><strong className="hr-color">{joiningDate && joiningDate}</strong> </span>
                      </td>
                    </tr>
                    </tbody></table>
                </td>
                <td valign="middle" width="20%">
                  <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                    <tbody><tr>
                      <td className="counter-text">
                        <span className="name">Signature</span>
                      </td>
                    </tr>
                    </tbody></table>
                </td>
                <td valign="middle" width="20%">
                  <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                    <tbody><tr>
                      <td className="counter-text">
                        <span className="name"><strong className="hr-color">--------</strong> </span>
                      </td>
                    </tr>
                    </tbody></table>
                </td>
              </tr>
                <tr><td valign="middle" width="20%">
                  <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                    <tbody><tr>
                      <td className="counter-text">
                        <span className="name">Date </span>
                      </td>
                    </tr>
                    </tbody></table>
                </td>
                  <td valign="middle" width="20%">
                    <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                      <tbody><tr>
                        <td className="counter-text">
                          <span className="name"><strong className="hr-color">
                            <td><Moment format="YYYY/MM/DD">
                              {todayDate}
                            </Moment></td>
                          </strong> </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr></tbody>
            </table>
          </div>
        </div>
      </center>
    </div>


  )
}
export default OfferLater







