import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
  } from '@coreui/react';
  
  const DropDown = ({
    dropDrownContent,
    dropdownItems,
    toggleBtnColor,
    onClick,
    activeTab,
  }) => {
    return (
      <CDropdown placement="top">
        <CDropdownToggle color="primary">{activeTab}</CDropdownToggle>
        <CDropdownMenu>
          {dropdownItems &&
            dropdownItems.length > 0 &&
            dropdownItems.map((dropDrownContent, i) => (
              <CDropdownItem
                data-status={dropDrownContent?.role_name}
                data-type={activeTab || ''}
                onClick={e => onClick(e.target.role, dropDrownContent?.role_name)}
                role={dropDrownContent?.role_id}
              >
                {dropDrownContent?.role_name}
              </CDropdownItem>
            ))}
        </CDropdownMenu>
      </CDropdown>
    );
  };
  export default DropDown;
  