import React,{ useState , useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import logos from "../assets/qdegreesLogo.png";
import { Link } from "react-router-dom";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CDropdownItem,
  CBadge,
  CDropdownToggle,
  CDropdown,
  CDropdownMenu,
  CDropdownDivider,
  CLink
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

// routes config
import routes from '../routes'

import { 
  TheHeaderDropdown,
  // TheHeaderDropdownMssg,
  // TheHeaderDropdownNotif,
  // TheHeaderDropdownTasks
}  from './index'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { connect } from 'react-redux';
import { commonActions, commonSelectors } from 'src/core/resource/common';

const TheHeader = (props) => {
  const { navigateToToggleSidebar, getSidebarOptions } = props;
  const api = new API()
  const [emp_id, setemp_id] = useState('');
  const [email,setemail] = useState('');
  const [firstname,setfirstname] = useState('');
  const [profileImageShow, setProfileImageShow] = useState("")
  useEffect (()=>{
    userProfile()
    },[])
    
  const userProfile = async() => {

 let result = await api.get(config.profile)
 if(result && result.code===200) {
  //  console.log("successfully geting", result)
   setemail(result?.data[0]?.email)
   setemp_id(result?.data[0]?.emp_id)
   setfirstname(result?.data[0]?.first_name+" "+result?.data[0]?.last_name)
 }
}


  const dispatch = useDispatch()
  const sidebarShow = useSelector(state => state.sidebarShow)

  const toggleSidebar = () => {
    // const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    // dispatch({type: 'set', sidebarShow: val})
    const options = { 
      minimize: getSidebarOptions?.minimize ? false : true,
      show: 'responsive',
    }
    navigateToToggleSidebar(options);
    console.log('--toggleSidebar--');
  }

  const toggleSidebarMobile = () => {
    // const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    // dispatch({type: 'set', sidebarShow: val})
    const options = { 
      minimize: getSidebarOptions?.minimize ? false : true,
      show: getSidebarOptions?.minimize ? false : true,
    }
    navigateToToggleSidebar(options);
    console.log('--toggleSidebarMobile--');
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        {/* <CIcon name="logo" src={logos} alt="Logo"/> */}
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          {/* <CHeaderNavLink to="/dashboard">
          
          <CBreadcrumbRouter 
          className="border-0 c-subheader-nav m-0 px-0 px-md-3" 
          //routes={routes} 
        />
          </CHeaderNavLink> */}
        </CHeaderNavItem>

        {/* <CHeaderNavItem  className="px-3">
          <CHeaderNavLink to="/users">Users</CHeaderNavLink>
        </CHeaderNavItem> */}

        <CHeaderNavItem className="px-3"><CDropdown className="m-1">
              {/* <CDropdownToggle className="d-flex align-items-center">
          <CHeaderNavLink > Setting </CHeaderNavLink>
          </CDropdownToggle> */}
          <CDropdownMenu>
                {/* <CDropdownItem header>Header</CDropdownItem>
                <CDropdownItem disabled>Action Disabled</CDropdownItem> */}
                {/* <CDropdownItem> */}
                  <Link to="/dashboard/ChangePassword" >Change Password</Link>
                  {/* </CDropdownItem> */}
                {/* <CDropdownDivider />
                <CDropdownItem>Another Action</CDropdownItem> */}
              </CDropdownMenu>
            </CDropdown>
        </CHeaderNavItem>

      </CHeaderNav>

      <CHeaderNav className="px-2">  
      <h6>{firstname}</h6>
        <TheHeaderDropdown/>
      </CHeaderNav>

      {/* <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter 
          className="border-0 c-subheader-nav m-0 px-0 px-md-3" 
          routes={routes} 
        />
          <div className="d-md-down-none mfe-2 c-subheader-nav">
            <CLink className="c-subheader-nav-link"href="#">
              <CIcon name="cil-speech" alt="Settings" />
            </CLink>
            <CLink 
              className="c-subheader-nav-link" 
              aria-current="page" 
              to="/dashboard"
            >
              <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard
            </CLink>
            <CLink className="c-subheader-nav-link" href="#">
              <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings
            </CLink>
          </div>
      </CSubheader> */}
    </CHeader>
  )
}

const mapStateToProps = state => {
  return {
    getSidebarOptions: commonSelectors.getSidebarOptions(state),
  };
};
const mapDispatchToProps = dispatch => ({
  navigateToToggleSidebar: data => dispatch(commonActions.toggleSidebar(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TheHeader));
