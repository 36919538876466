import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import selectedCandidateFun from './ListOfSelectedCondidate';
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader,
} from '../containers/index';
import ListOfSelectedCondidate from './ListOfSelectedCondidate';
import './all-features.css';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';

const AllFeatures = props => {
  let api = new API();
  const {
    selectedTabSet,
    receivedr_equisition_count,
    assign_equisition_count,
  } = props;
  const [selectedTab, setSelectedTab] = useState('receivedRequisition');

  const [selectedCandidateCount, setSelectedCandidateCount] = useState(0);
  const [
    countAssignRequisitionCount,
    setCountAssignRequisitionCount,
  ] = useState(0);
  const [listOfCloseMrfCount, setListOfCloseMrfCount] = useState(0);
  const [profile_banklength, setprofile_banklength] = useState();

  const selectTab = select_type => {
    // alert(select_type)
    setSelectedTab(select_type);
    selectedTabSet(select_type);
  };

  useEffect(() => {
    selectedCandidateCountFun();
    assignRequisitionCountFun();
    ListOfCloseMrfCountFun();
    profile_bank();
    // alert(receivedr_equisition_count)
  }, []);

  // by ishika
  const profile_bank = async () => {
    let data = {
      type : "hr_manager"
    }
    let result = await api.get(config.profileBank,true,data);
    if (result && result.code === 200) {
      setprofile_banklength(result.data.length);
    } else {
      // console.log("unable to getting pending requisition")
    }
  };

  const selectedCandidateCountFun = async () => {
    let result = await api.get(config.getSelectedCandidateByHrRecruiter);
    if (result && result.code == 200)
      setSelectedCandidateCount(result.data.length);
  };
  const assignRequisitionCountFun = async () => {
    let result = await api.get(config.totalAssignedMrfToRecruiter);
    if (result && result.code == 200) {
      setCountAssignRequisitionCount(result.data?.length);
    }
  };

  const ListOfCloseMrfCountFun = async () => {
    let result = await api.get(config.getAllClosedMRFForHRManagerDashboard);
    if (result && result.code == 200) {
      setListOfCloseMrfCount(
        result.data.Replacement?.length + result.data.Vacancy?.length,
      );
    }
  };

  return (
    <div className="">
      <div className="container mt-2">
        <div className="row">
          <div className="col-md card_box">
            <div
              className="card card_box-backg"
              style={
                selectedTab == 'receivedRequisition'
                  ? { backgroundColor: '#2a3c81', color: '#fff' }
                  : { borderRadius: '4px', backgroundColor: '#2a3c81' }
              }
              onClick={() => selectTab('receivedRequisition')}
            >
              <div
                className="card-header_hr_manager card-header "
                style={
                  selectedTab == 'receivedRequisition'
                    ? {
                        backgroundColor: '#fff',
                        color: '#000',
                        border: 'none',
                        padding: '9px',
                      }
                    : { backgroundColor: '#fff', color: '#000' }
                }
              >
                <h4 className="hrmd_card_number">
                  {receivedr_equisition_count ? receivedr_equisition_count : 0}
                </h4>
              </div>
              <div
                className="card-body"
                style={{ padding: '12px', backgroundColor: '#eeeeee' }}
              >
                <h6 className="card-text box_title text-white">
                  Received Requisition
                </h6>
              </div>
            </div>
          </div>

          <div className="col-md card_box">
            <div
              className="card card_box-backg"
              onClick={() => selectTab('assignedRequisition')}
              style={
                selectedTab == 'assignedRequisition'
                  ? {
                      borderBottomLeftRadius: '25px',
                      borderBottomRightRadius: '25px',
                      borderBottomLeftRadius: '25px',
                      borderBottomRightRadius: '25px',
                      backgroundColor: '#2a3c81',
                      color: '#fff',
                      borderRadius: '4px',
                    }
                  : {
                      borderBottomLeftRadius: '25px',
                      borderBottomRightRadius: '25px',
                      backgroundColor: '#2a3c81',
                      color: '#fff',
                      borderRadius: '4px',
                    }
              }
            >
              <div
                className="card-header "
                style={
                  selectedTab == 'assignedRequisition'
                    ? { backgroundColor: '#fff', color: '#000', border: 'none' }
                    : { backgroundColor: '#fff', color: '#000', padding: '9px' }
                }
              >
                <h4 className="hrmd_card_number">
                  {countAssignRequisitionCount}
                </h4>
              </div>
              <div
                className="card-body"
                style={{ padding: '12px', backgroundColor: '#eeeeee' }}
              >
                <h6 className="card-text box_title text-white">
                  Assigned Requisition
                </h6>
              </div>
            </div>
          </div>

          <div className="col-md card_box">
            <div
              className="card card_box-backg "
              style={
                selectedTab == ''
                  ? {
                      borderBottomLeftRadius: '25px',
                      borderBottomRightRadius: '25px',
                      backgroundColor: '#2a3c81',
                      color: '#fff',
                      borderRadius: '4px',
                    }
                  : {
                      borderBottomLeftRadius: '25px',
                      borderBottomRightRadius: '25px',
                      backgroundColor: '#2a3c81',
                      color: '#000',
                      borderRadius: '4px',
                    }
              }
              onClick={() => selectTab('')}
            >
              <div
                className="card-header "
                style={
                  selectedTab == ''
                    ? { backgroundColor: '#fff', color: '#000', border: 'none' }
                    : { backgroundColor: '#fff', color: '#000', padding: '9px' }
                }
              >
                <h4 className="hrmd_card_number">{selectedCandidateCount}</h4>
              </div>
              <div
                className="card-body"
                style={{ padding: '12px', backgroundColor: '#eeeeee' }}
              >
                <h6 className="card-text box_title text-white">
                  Selected Candidate
                </h6>
              </div>
            </div>
          </div>

          <div className="col-md card_box">
            <div
              className="card card_box-backg"
              onClick={() => selectTab('profile_bank')}
              style={
                selectedTab == 'profile_bank'
                  ? {
                      backgroundColor: '#2a3c81',
                      color: '#fff',
                      borderRadius: '4px',
                      border: 'none',
                    }
                  : {
                      backgroundColor: '#2a3c81',
                      color: '#fff',
                      borderRadius: '4px',
                    }
              }
            >
              <div
                className="card-header"
                style={
                  selectedTab == 'profile_bank'
                    ? {
                        borderBottomLeftRadius: '25px',
                        borderBottomRightRadius: '25px',
                        backgroundColor: '#fff',
                        color: '#000',
                        border: 'none',
                      }
                    : { backgroundColor: '#fff', color: '#000', padding: '9px' }
                }
              >
                <h4 className="hrmd_card_number">{profile_banklength || 0}</h4>
              </div>
              <div
                className="card-body"
                style={{ padding: '12px', backgroundColor: '#eeeeee' }}
              >
                <h6 className="card-text box_title text-white">Profile Bank</h6>
              </div>
            </div>
          </div>

          {/* <div className="col-md-2 card_box">
            <div className="card card_ card_box-backgbox" style={selectedTab == "prefer#3c4b64_condidates" ? { borderRadius: "4px", border: "1px solid", borderColor: "#3c4b64" } : { borderRadius: "4px" }}>
              <div className="ca card_box-backgrd-header" style={selectedTab == "prefer#3c4b64_condidates" ? { backgroundColor: "gray", color: "white" } : { backgroundColor: "#efefef", color: "#000" }}>
                <h2 className="text-center">5</h2>
              </div>
              <div className="card-body" >
                <h5 className="card-text text-center box_title">Prefer Candidates</h5>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md card_box">
            <div
              className="card card_box-backg"
              style={
                selectedTab == 'reports'
                  ? {
                      backgroundColor: '#2a3c81',
                      color: '#fff',
                      borderRadius: '4px',
                      border: '1px solid',
                      borderColor: '#3c4b64',
                    }
                  : {
                      backgroundColor: '#2a3c81',
                      color: '#fff',
                      borderRadius: '4px',
                    }
              }
            >
              <div
                className="card-header "
                style={
                  selectedTab == 'reports'
                    ? {
                        borderBottomLeftRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderBottomLeftRadius: '25px',
                        borderBottomRightRadius: '25px',
                        backgroundColor: '#fff',
                        color: '#000',
                        border: '2px solid',
                        borderColor: '#eb7c45',
                      }
                    : { backgroundColor: '#fff', color: '#000', padding: '9px' }
                }
              >
                <h4 className="hrmd_card_number">0</h4>
              </div>
              <div
                className="card-body"
                style={{ padding: '12px', backgroundColor: '#eeeeee' }}
              >
                <h6 className="card-text box_title text-white">Reports</h6>
              </div>
            </div>
          </div> */}

          <div className="col-md card_box">
            <div
              className="card card_box-backg"
              onClick={() => selectTab('closeMrf')}
              style={
                selectedTab == 'close_mrf'
                  ? {
                      backgroundColor: '#2a3c81',
                      color: '#fff',
                      borderRadius: '4px',
                      border: '1px solid',
                      borderColor: '#3c4b64',
                    }
                  : {
                      backgroundColor: '#2a3c81',
                      color: '#fff',
                      borderRadius: '4px',
                    }
              }
            >
              <div
                className="card-header "
                style={
                  selectedTab == 'close_mrf'
                    ? {
                        borderBottomLeftRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderBottomLeftRadius: '25px',
                        borderBottomRightRadius: '25px',
                        backgroundColor: '#fff',
                        color: '#000',
                        border: '2px solid',
                        borderColor: '#eb7c45',
                      }
                    : { backgroundColor: '#fff', color: '#000', padding: '9px' }
                }
              >
                <h4 className="hrmd_card_number">{listOfCloseMrfCount}</h4>
              </div>
              <div
                className="card-body"
                style={{ padding: '12px', backgroundColor: '#eeeeee' }}
              >
                <h6 className="card-text box_title text-white">Closed MRF</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllFeatures;
