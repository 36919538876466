import { getFomatedError } from '../../services/ApiService';
import { config } from '../../../utils/apiUrl';
import API from '../../../utils/apiCalling';
const api = new API();

const getSample = params => {
  return null;
};

export default {
  getSample,
};
