import React, { useEffect, useState } from 'react';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
} from '@coreui/react';
import { CSVLink, CSVDownload } from 'react-csv';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import moment from 'moment';
import { isPermission } from 'src/utils/common';
const HrDashboardJoinedModal = props => {
  const [showViewDetails, setShowViewDetails] = useState(false);
  const [showMrf, setShowMrf] = useState(true);
  const [index, setIndex] = useState(0);
  const { isOpenJoined, isViewModalOpen, modalHeading, onclickMrfData } = props;
  const [getAllCandidate, setGetAllCandidate] = useState([]);

  const api = new API();
  useEffect(() => {
    // setShowViewDetails(false)
    setShowViewDetails(false);
    // console.log("____________+++++++++++++++++", onclickMrfData)
  }, [onclickMrfData]);

  const getCandidateByMrf = async item => {
    let data = {
      mrf_id: item.id,
      mrf_applying_for: item.applying_for,
      type: 'none',
      // type : type
    };
    let result = await api.post(config.view_candidate_on_mrf, data);
    if (result && result.code === 200) {
      // console.log("get candidate by mrf id", result)
      setGetAllCandidate(result && result.data);
    } else {
      // console.log(result && result.message)
    }
    setShowViewDetails(true);
    setShowMrf(false);
  };

  return (
    <div className="">
      <CModal
        show={isOpenJoined}
        onClose={() => isViewModalOpen('joined')}
        size="lg"
        style={{ marginTop: '100px' }}
      >
        <CModalHeader>
          <CModalTitle style={{ color: 'white' }}>{modalHeading}</CModalTitle>
          {isPermission('download_mrf_tab_report') && (
            <CSVLink
              filename={`${modalHeading}.csv`}
              data={onclickMrfData || [{}]}
            >
              <button type="button" className="btn btn-primary float-right m-3">
                <i className="fa fa-download" aria-hidden="true"></i> Download
                Data
              </button>
            </CSVLink>
          )}
        </CModalHeader>
        <CModalBody style={{ height: '500px', overflow: 'auto' }}>
          {showViewDetails === false && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">S.N.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Contact No</th>
                  <th scope="col">Location</th>

                  <th scope="col">MRF ID</th>
                  {/* <th scope="col">password</th> */}

                  <th scope="col">Emp. ID</th>

                  <th scope="col">Position Type</th>

                  {/* <th scope="col">reported_to</th>
                  <th scope="col">created_by</th> */}

                  {/* <th scope="col">Department</th>
                  <th scope="col">Supervisor</th>
                  <th scope="col">Asset</th> */}
                  <th scope="col">DOJ</th>
                  {/* <th scope="col">Ageing</th> */}
                </tr>
              </thead>
              <tbody>
                {onclickMrfData &&
                  onclickMrfData.length > 0 &&
                  onclickMrfData.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td scope="col">
                        {item.first_name} {item.last_name}
                      </td>
                      <td scope="col">{item.mobile}</td>
                      <td scope="col">{item.location}</td>
                      <td scope="col">{item.mrf_id}</td>

                      <td scope="col">{item.emp_id}</td>
                      {/* <td scope="col">{item.password}</td> */}

                      <td scope="col">
                        {item.mrf_applying_for === 'vacancy'
                          ? 'New Hiring'
                          : 'Replacement'}
                      </td>
                      {/* <td scope="col">{item.company_id}</td> */}
                      {/* <td scope="col">{item.department}</td> */}

                      {/* <td scope="col">{item.reported_to}</td>
                    <td scope="col">{item.created_by}</td> */}

                      {/* <td scope="col">{item.supervisor}</td>
                    <td scope="col">{item.assest_assigned}</td> */}
                      <td scope="col">
                        {moment(item.joining_date).format('DD-MM-YYYY')}
                      </td>
                      {/* <td scope="col">{item.ageing}</td> */}
                      {/* <td><i className="fa fa-eye" onClick={() => getCandidateByMrf(item)} aria-hidden="true"></i></td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}

          {/* {(showViewDetails === true && getAllCandidate && getAllCandidate.length > 0) ?
            <>
              <table className="table table-lg table-responsive">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Address</th>
                    <th scope="col">Candidate created by </th>
                    <th scope="col">Created at </th>
                    <th scope="col">Designation </th>
                    <th scope="col">Dob </th>
                    <th scope="col">Email</th>
                    <th scope="col">Experience </th>
                    <th scope="col">First name </th>

                    <th scope="col">Last_name </th>
                    <th scope="col">Mobile</th>
                    <th scope="col">MRF Raised by</th>
                    <th scope="col">Name</th>
                    <th scope="col">Pincode</th>
                    <th scope="col">Technology knowledge</th>
                    <th scope="col">Candidate resume</th>
                  </tr>
                </thead>
                <tbody>
                  {getAllCandidate && getAllCandidate.length > 0 && getAllCandidate.map((data, index) => (
                    <tr>
                      <td scope="row">{index}</td>
                      <td scope="col">{data.address}</td>
                      <td scope="col">{data.candidate_created_by}</td>
                      <td scope="col">{data.created_at}</td>
                      <td scope="col">{data.designation}</td>
                      <td scope="col">{data.dob}</td>
                      <td scope="col">{data.email}</td>
                      <td scope="col">{data.experience}</td>
                      <td scope="col">{data.first_name}</td>
                      <td scope="col">{data.last_name}</td>
                      <td scope="col">{data.mobile}</td>
                      <td scope="col">{data.mrf_raised_by}</td>
                      <td scope="col">{data.name}</td>
                      <td scope="col">{data.pincode}</td>
                      <td scope="col">{data.technology_knowledge}</td>
                      <td scope="col">{data.candidate_resume_filename}</td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </>
            :
            <div>
              <h3 align="center">Candidate not created for this MRF</h3>
            </div>
          } */}
        </CModalBody>
        <CModalFooter>
          {showViewDetails === true && (
            <CButton
              color="success"
              onClick={() => {
                setShowMrf(true);
                setShowViewDetails(false);
              }}
            >
              <i className="fa fa-backward" aria-hidden="true"></i>
            </CButton>
          )}
          <CButton color="success" onClick={() => isViewModalOpen('joined')}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton>{' '}
        </CModalFooter>
      </CModal>
    </div>
  );
};
export default HrDashboardJoinedModal;
