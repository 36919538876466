/* eslint-disable react/react-in-jsx-scope */
import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CFormText,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTextarea,
} from '@coreui/react';

const DeclineModal = ({
  declinePopup,
  setDeclinePopup,
  declineApprovedStatus,
  setIsDeclinedComment,
  isDeclinedComment,
}) => {
  return (
    <CModal
      show={declinePopup}
      onClose={() => setDeclinePopup(!declinePopup)}
      color="warning"
      style={{ paddingTop: '5%' }}
    >
      <CModalHeader closeButton>
        <CModalTitle>Are you sure you wanna proceed</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm
          action=""
          encType="multipart/form-data"
          className="form-horizontal"
        >
          <CFormGroup row>
            <CCol md="3">
              <CLabel>
                <strong>Kindly justify</strong>
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CTextarea
                name="isDeclinedComment"
                value={isDeclinedComment}
                onChange={e => setIsDeclinedComment(e.target.value)}
                rows="3"
                placeholder="Comment..."
                required
              />
              <CFormText>Please enter the justification</CFormText>
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton
          data-toggle="tooltip"
          data-placement="top"
          title="Submit"
          color="success"
          onClick={declineApprovedStatus}
        >
          <i className="fa fa-paper-plane mr-2" aria-hidden="true"></i>Submit{' '}
        </CButton>{' '}
        <CButton
          data-toggle="tooltip"
          data-placement="top"
          title="Close"
          color="danger"
          onClick={() => setDeclinePopup(!declinePopup)}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DeclineModal;
