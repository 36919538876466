import React, { useState, useEffect } from 'react';

import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CLabel,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from 'sweetalert';
import { useParams } from 'react-router';
import './MrfApprovedByEmail.css';
// import { Color } from '@progress/kendo-drawing';
// import { reduceRight } from 'core-js/core/array';
//import usersData from '../users/UsersData'
import { Base_URI_Web } from 'src/utils/apiUrl';

const MrfApprovedByEmail = () => {
  const api = new API();
  const { id, applying_for } = useParams();
  const [approvedSuccessfully, setApprovedSuccessfully] = useState(false);
  useEffect(() => {
    // console.log(id, applying_for)
    approveMrfByEmail(id, applying_for);
  }, []);

  const approveMrfByEmail = async (Id, Applying_for) => {
    let data = {
      id: Id,
      applying_for: Applying_for,
    };
    // console.log("data is", data)
    // return
    let result = await api.post(config.acceptapprovalmrf, data, true);
    // console.log("ddddddddddddddddddddddddddd", SurveySendMessage)
    if (result && result.code == 200) {
      // alert("Successfully approved")
      // console.log("successfully approved")
      setApprovedSuccessfully(true);
      // get_submittedmrfrecords()
    } else {
      alert(result && result.message);
    }
  };

  return (
    // <body style={{backgroundColor:"lightgray"}}>

    <div className="main-container">
      <div className="container">
        <div className="half-part bg-gradient container border-left-body position-relative">
          <div className="text-white text-center p-5">
            {/* <h3>Hello!!</h3> */}
            {approvedSuccessfully ? (
              <h2 align="center" style={{ marginTop: '10%' }}>
                Thanks for your approval, MRF successfully approved and moved to
                HR bucket
              </h2>
            ) : (
              <h2>
                <h2 align="center" style={{ marginTop: '10%' }}>
                  You have already approved this MRF
                </h2>
              </h2>
            )}
            {/* <h3 className="font-weight-light">Welcome To QDegrees...</h3>                     */}
          </div>
        </div>

        <div className="d-flex align-items-center bg-white half-part justify-content-center container border-left-body">
          <div className="">
            <img
              src="images/logo.svg"
              className="mb-3 col-lg-4 col-6 d-block mx-auto"
              alt=""
            />

            <a
              href={`${Base_URI_Web}`}
              className="btn btn-theme mb-3 col-lg-6 col-8 mx-auto d-block"
            >
              Redirect to Glueple
            </a>

            <p className="text-center text-secondary col-lg-9 mb-3  mx-auto">
              Glueple <br /> powered by{' '}
              <a
                href="https://www.qdegrees.com/"
                target="_blank"
                rel="noreferrer"
              >
                @ QDegrees Services Pvt. Ltd.
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MrfApprovedByEmail;
