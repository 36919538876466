import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from '../containers/index'
import AllFeaturesHr from './all-features_hr'
// import ListOfSelectedCondidate from './hr_select/ListOfSelectedCondidate'
import PendingRequisition from './pending_requisition/pendingRequisition'
// import InProgress from './in-progress/in-progress';
import ProfileBank from './profile_bank/profile_bank'

import OfferToSelection from './offer_to_selection/offer_to_selection'
import OpsSelect from './ops_select/ops_select'
// import AssignedRequisition from './InProgress'
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import HrShortList from './hr-shortlist/hr_shortlist'
import OfferIssued from './offer_issued/offer_issued'
import OfferAccept from './offer_accept/offer_accept'
import Report from './report/report'
import FinalJoined from './final_joined/final_joined'
import mrfjourney from './mrf_journey/mrf_journey'

// mrf journey 
import InProgress from './in-progress/in-progress'
import CandidateAccessManagment from './new_candidate_access_management'
import ApprovedRequestion from './common/ApprovedRequestion'
// import InProgress from './In-progress/in-progress'


const Hr_Dashboard = () => {

  let api = new API()
  const [assignedRequisition, setAssignedRequisition] = useState(false)
  const [type, setType] = useState("pendingRequisition")
  const [pendingRequisitionLenght, setPendingRequisitionLenght] = useState(0)
  const[profile_banklength, setprofile_banklength]= useState(0)
  const[inprogresslength, setinprogresslength] = useState(0)
  const[hrShortlistedlength, sethrShortlistedlength] = useState(0)
  const[opsSelectlength, setopsSelectlength] = useState(0)
  const[offerIssuedlength, setofferIssuedlength]= useState(0)
  const[offerAcceptlength, setofferAcceptlength] =useState(0)
  const[finalJoinedlength, setfinalJoinedlength]= useState(0)
  // const[approvedRequisitionlength, setapprovedRequisitionlength] =useState(0)
  const [candidateAccessManagement, setCandidateAccessManagement] = useState([])
  const [approvedRequestionCount, setApprovedRequestionCount] = useState();


  useEffect(() => {
    pendingRequisition()
    newCandidateAccessManagement()
    // by ishika
    profile_bank()
    inprogress()
    hrShortlisted()
    opsSelect()
    offerIssued()
    offerAccept()
    finalJoined()
    // approvedRequisition()
    
  }, [])

  const selectedTabSet = (selected_tab) => {
    setType(selected_tab)
  }

  const pendingRequisition = async () => {
    let result = await api.get(config.showAssignedMrfForRecruiter)
    // console.log("pending requisition-----------------", result)
    if (result && result.code == 200) {
      setPendingRequisitionLenght((result.data).length)
    }
    else {
      // console.log("unable to getting pending requisition")
    }
  }

 

  const newCandidateAccessManagement = async () => {
    let result = await api.get(config.new_joiners_candidate_for_hr_recruiter)
    // console.log("pending requisition-----------------", result)
    if (result && result.code == 200) {
      setCandidateAccessManagement(result.data)
    }
    else {
      // console.log("unable to get new candidate access management")
    }
  }

  // by ishika 
  const profile_bank = async () => {
    let result = await api.get(config.profileBank)
    if (result && result.code == 200) {
      setprofile_banklength((result.data).length)
      console.log(result.data.length);
    }
    else {
      // console.log("unable to getting pending requisition")
    }
  }

  const inprogress = async () => {
    let result = await api.get(config.showAssignedMrfForRecruiter)
    if (result && result.code == 200) {
      setinprogresslength((result.data).length)
    }
    else {
      // console.log("unable to getting pending requisition")
    }
  }

  const hrShortlisted = async () => {
    let result = await api.get(config.showAssignedMrfForRecruiter)
    if (result && result.code == 200) {
      sethrShortlistedlength((result.data).length)
    }
    else {
      // console.log("unable to getting pending requisition")
    }
  }

  const opsSelect = async () => {
    let result = await api.get(config.selectedCandidateForOps)
    if (result && result.code == 200) {
      setopsSelectlength((result.data).length)
    }
    else {
      // console.log("unable to getting pending requisition")
    }
  }

  const offerIssued = async () => {
    let result = await api.get(config.totalOfferIssued)
    if (result && result.code == 200) {

      setofferIssuedlength((result.data).length)
    }
    else {
      // console.log("unable to getting pending requisition")
    }
  }

  const offerAccept = async () => {
    let result = await api.get(config.totalOfferAcceptedFromCandidate)
    if (result && result.code == 200) {
      setofferAcceptlength((result.data).length)
    }
    else {
      // console.log("unable to getting pending requisition")
    }
  }

  const finalJoined = async () => {
    let result = await api.get(config.totalCandidateJoined)
    if (result && result.code == 200) {
      setfinalJoinedlength((result.data).length)
    }
    else {
      // console.log("unable to getting pending requisition")
    }
  }

  // const approvedRequisition = async () => {

  //   let result = await api.get(config.getMrfReceivedforHrManager)
  //   // console.log("pending requisition-----------------", result)
  //   if (result && result.code == 200) {

  //     setapprovedRequisitionlength((result.data).length)
  //   }
  //   else {
  //     // console.log("unable to getting pending requisition")
  //   }
  // }

  const getCountApprovedRequisition = (count) => {
    setApprovedRequestionCount(count)

  }


  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />

        <div className="c-body">

          <AllFeaturesHr selectedTabSet={selectedTabSet}
            approvedRequestionCount={approvedRequestionCount}
            pendingRequisitionLenght={pendingRequisitionLenght}
            profile_banklength= {profile_banklength}
            inprogresslength= {inprogresslength}
            hrShortlistedlength= {hrShortlistedlength}
            opsSelectlength = {opsSelectlength}
            offerIssuedlength= {offerIssuedlength}
            offerAcceptlength= {offerAcceptlength}
            finalJoinedlength = {finalJoinedlength}
            // approvedRequisitionlength = {approvedRequisitionlength}
            countCandidateAccessManagement={candidateAccessManagement.length} />
            {type=="approvedRequisition"&&<ApprovedRequestion getCountApprovedRequisition={getCountApprovedRequisition} />}


          {type == "pendingRequisition" && <PendingRequisition />}
          {type == "hrShortlisted" && <HrShortList />}
          {type == "inprogress" && <InProgress />}
          {/* {type == "offSelectedCondidate" && <ListOfSelectedCondidate />}  */}
          {type == "profile_bank" && <ProfileBank />}
          {/* {type == "offerToSelection" && <OfferToSelection/>} */}
          {type == "opsSelect" && <OpsSelect />}
          {type == "offerIssued" && <OfferIssued />}
          {type == "offerAccept" && <OfferAccept />}
          {type == "finalJoined" && <FinalJoined />}
          {type == "mrfjourney" && <mrfjourney/> }
          {type == "accessManagment" && <CandidateAccessManagment
            candidateAccessManagement={candidateAccessManagement}
            newCandidateAccessManagement={newCandidateAccessManagement} />}
          {type == "reports" && <Report />}


        </div>
        <TheFooter />
      </div>
    </div>

  )
}
export default Hr_Dashboard