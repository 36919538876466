
import React, { useState, useEffect } from 'react'
import './loader.css'
const Loader = (props) => {

    return (
        <div className="text-center mt-3">
       <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}
export default Loader







