import mqtt, { connect } from 'mqtt';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import io from 'socket.io-client';
import { Base_URI_Chat } from 'src/utils/apiUrl'

const socketConnect = () => {
  const socket = io(Base_URI_Chat, {
    'transports': ['websocket', 'polling'],
    'forceNew':true,
    autoConnect: true,
    reconnection: true,
    reconnectionAttempts: 10,  // Maximum 5 reconnection attempts
    reconnectionDelay: 1000 * 10,
    EIO:4
  });
  window.gluepleSocket = socket;
  return socket;
}
const mqttConnect = () => {
  const user = getLocalDataAsObject('user');
  const userId = user?.length ? user[0].id : Math.random().toString(16).substring(2, 8);
  console.log('--mqqt client--', {userId, user});
  const initialConnectionOptions = {
    // ws or wss
    protocol: 'ws',
    host: 'f51123c9.emqx.cloud',
    //host: 'of22e02b.ala.us-east-1.emqxsl.com',
    clientId: `glueple_${userId}`,
    // ws -> 8083; wss -> 8084
    port: 8083,
    /**
     * By default, EMQX allows clients to connect without authentication.
     * https://docs.emqx.com/en/enterprise/v4.4/advanced/auth.html#anonymous-login
     */
    username: 'bheem',
    password: '12345',
    clean: true,
  };
  const {
    protocol,
    host,
    clientId,
    port,
    username,
    password,
    clean,
  } = initialConnectionOptions;
  const url = `${protocol}://${host}:${port}/mqtt`;
  const options = {
    clientId,
    username,
    password,
    clean,
    reconnectPeriod: 1000, // ms
    connectTimeout: 30 * 1000, // ms
  };
  return connect(url, options);
};

export default { mqttConnect, socketConnect };

