import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import Admin_Dashboard from './admin-dashboard'

const AdminDashboard = () => {
  
  useEffect(()=> {

  },[])
  return (
    <div>
        <Admin_Dashboard/>
        
    </div>

  )
}
export default AdminDashboard