import { createAction } from 'redux-act';

const getAttendanceBasicDetailsRequest = createAction(
  'GET_ATTENDANCE_DETAILS_REQUEST',
  data => data,
);
const getAttendanceBasicDetailsSuccess = createAction(
  'GET_ATTENDANCE_DETAILS_SUCCESS',
  data => data,
);
const getAttendanceBasicDetailsError = createAction(
  'GET_ATTENDANCE_DETAILS_ERROR',
);

export default {
  getAttendanceBasicDetailsRequest,
  getAttendanceBasicDetailsSuccess,
  getAttendanceBasicDetailsError,
};
