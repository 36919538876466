import React from 'react';

const NoRecords = props => {
  const { message, colSpan } = props;
  return (
    <tr>
      <td className="text-center" colSpan={colSpan || ''}>
        {message || 'No Records Found'}
      </td>
    </tr>
  );
};

export default NoRecords;
