const getIsMqttInstancePending = state => state.mqtt.isMqttInstancePending;
const getMqttClientInstance = state => state.mqtt.mqttClientInstance;
const getMqttClientConnectStatus = state => state.mqtt.mqttClientConnectStatus;
const getMqttClientMessagePayload = state => state.mqtt.mqttClientMessagePayload;

export default {
  getIsMqttInstancePending,
  getMqttClientInstance,
  getMqttClientConnectStatus,
  getMqttClientMessagePayload,
};
