const getIsAttendanceBasicDetailsPending = state =>
state.attendance.isGetAttendanceBasicDetailsPending;
const getAttendanceBasicDetailsRes = state => state.attendance.getAttendanceBasicDetailsRes;
const getAttendanceBasicDetailsResError = state =>
state.attendance.getAttendanceBasicDetailsResError;

export default {
    getIsAttendanceBasicDetailsPending,
    getAttendanceBasicDetailsRes,
    getAttendanceBasicDetailsResError
}