
import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import OnboaridngDashboard from 'src/views/ONBOARDING_EMPLOYEE/onboarding_dashobard/onboarding_dashobard'
const ModalOnboardingDocuments = (props) => {
    const { isOpen, isViewModalOpen, empDetails, hideSection } = props
    useEffect(() => {

    }, [])

    return (
        <div className="">
  
            <CModal
                show={isOpen}
                onClose={() => isViewModalOpen(!isOpen)}
                size="xl"
            >
                <CModalHeader closeButton>
                    <CModalTitle>View for upload</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <OnboaridngDashboard empDetails={empDetails} hideSection={hideSection}/>
                </CModalBody>
                <CModalFooter>
                    <CButton color="danger" onClick={() => isViewModalOpen(!isOpen)}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}
                </CModalFooter>
            </CModal>
        </div>
    )
}
export default ModalOnboardingDocuments







