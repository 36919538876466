import React, { useState, useEffect, useRef } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from 'availity-reactstrap-validation';
import { Button, Label, FormGroup, CustomInput } from 'reactstrap';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import swal from 'sweetalert';
import { PDFExport } from '@progress/kendo-react-pdf';
import OfferLater from '../common/offerLatterCtc/offer_later';
import OfferLetterQSPL from '../common/offerLatterCtc/offer_letter_qspl';
import OfferLetterQDS from '../common/offerLatterCtc/offer_letter_qds';
import InternshipLetter from '../common/offerLatterCtc/internship_letter';
import AgreementLetter from '../common/offerLatterCtc/agreement_letter';
import Ctc from '../common/offerLatterCtc/ctc';
import Moment from 'react-moment';
import moment from 'moment';

const SendOfferLaterModal = props => {
  const {
    sendOfferLatter,
    large,
    candidate,
    selectedCandidateFun,
    getCandidateSelectedInInterview,
  } = props;

  let api = new API();
  const [isCTCFullDetails, setIsCTCFullDetails] = useState(false);
  const [ctc, setCtc] = useState();
  const [ctcResult, setCtcResult] = useState();
  const [joiningDate, setJoiningDate] = useState();
  const [todayDate, setTodayDate] = useState();
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [currentDatePlusOneYear, setCurrentDatePlusOneYear] = useState(
    moment()
      .add('years', 1)
      .format('YYYY-MM-DD'),
  );
  const [docRequiredDetails, setDocRequiredDetails] = useState([]);
  const [docRequired, setDocRequired] = useState({
    doc_name: ' ',
    is_required: '',
  });
  const [offerLetterType, setOfferLetterType] = useState('');
  // const [large, setLarge] = useState(false)

  const inputEl = useRef(null);

  useEffect(() => {
    DateNow();
  }, []);

  const onButtonPdfClick = () => {
    inputEl.current.save();
  };
  // const isModalOpen = () => {
  //     setLarge(!large)
  // }
  const ctcFullDetails = () => {
    setIsCTCFullDetails(true);
    ctcCalculator();
  };

  const ctcCalculator = async () => {
    // console.log("candidate full details offerlateer", candidate)
    let data = {
      ctc_amount: ctc,
    };
    let result = await api.post(config.ctcGenerator, data);
    if (result && result.code == 200) {
      setCtcResult(result.data);
    } else {
    }
  };
  const DateNow = () => {
    setTodayDate(Date.now());
  };

  const finalSendOfferLatter = async () => {
    // alert("sent")
    // console.log(candidate)
    let data = {
      candidate_email: candidate && candidate.email,
      emp_id: candidate && candidate.id,
      name: candidate && candidate.name,
      mobile: candidate && candidate.mobile,
      joining_date: joiningDate,
      ctc: ctc,
      offerLetterType: offerLetterType,
      docRequired: JSON.stringify(docRequiredDetails),
    };
    // console.log(data)
    // return
    swal({
      html: true,
      title: `Are you sure to Send Offer Letter to  ${candidate.name}`,
      // text: `Are you sure to Send Offer Letter to  ${candidate.name}`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Send'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.sendOfferLetterToCandidate, data);
        if (result && result.code == 200) {
          // console.log("offer latter sent successfully", result)
          sendOfferLatter();
          // selectedCandidateFun()
          getCandidateSelectedInInterview();
        } else {
          alert('something wrong');
        }
      }
    });
  };
  const onClickFun = async e => {
    let data = {
      doc_name: e.target.name,
      is_required: e.target.checked,
    };
    let filterResult = await docRequiredDetails.filter(
      val => val.doc_name !== data.doc_name,
    );
    // console.log(filterResult)
    filterResult.push(data);

    setDocRequiredDetails([...filterResult]);
    // console.log(docRequiredDetails)
  };
  return (
    <div className="">
      <CModal show={large} onClose={() => sendOfferLatter()} size="lg">
        <CModalHeader closeButton>
          <CModalTitle>CTC Calculator</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <AvForm
            onValidSubmit={ctcFullDetails}
            action=""
            encType="multipart/form-data"
            className="form-horizontal"
          >
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Select offer letter type</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvInput
                  type="select"
                  value={offerLetterType}
                  onChange={e => setOfferLetterType(e.target.value)}
                  name="offer_letter_type"
                  placeholder=""
                  validate={{
                    required: {
                      value: true,
                      errorMessage: '',
                    },
                  }}
                >
                  <option value="">Select offer letter</option>
                  <option value="offer_letter_qds">Offer Latter QDS</option>
                  <option value="offer_letter_qspl">Offer Latter QSPL</option>
                  <option value="internship_letter">Internship Letter</option>
                  <option value="agreement_letter">Agreement letter</option>
                </AvInput>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Enter CTC</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <AvField
                  type="number"
                  value={ctc}
                  onChange={e => setCtc(e.target.value)}
                  name="ctc"
                  placeholder="Enter yearly CTC"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'CTC/Stipend is required',
                    },
                  }}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Enter Joining Date</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <AvField
                  type="date"
                  format="DD-MM-YYYY"
                  onChange={e => setJoiningDate(e.target.value)}
                  min={currentDate}
                  max={currentDatePlusOneYear}
                  value={joiningDate}
                  name="joining_date"
                  placeholder="Enter Joining Date"
                  required
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Date is required',
                    },
                    dateRange: {
                      format: 'YYYY-MM-DD',
                      start: { value: currentDate },
                      end: { value: currentDatePlusOneYear },
                      value: true,
                      errorMessage: 'Date should be more than current date',
                    },
                  }}
                />
              </CCol>
            </CFormGroup>

            <CFormGroup row>
              <CCol md="3">
                <CLabel>Mandatory Documents Required</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      onClick={onClickFun}
                      name="driving_license"
                    />{' '}
                    Driving License
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      onClick={onClickFun}
                      name="passport"
                      trueValue="Yes, I'm in!"
                      falseValue="NOPE!"
                    />{' '}
                    Passport
                  </Label>
                </AvGroup>
              </CCol>
            </CFormGroup>

            {isCTCFullDetails && offerLetterType == 'offer_letter_qds' && (
              <div>
                <h5>offer latter qds</h5>
                <PDFExport
                  forcePageBreak=".page-break"
                  paperSize={'A4'}
                  margin=".5cm"
                  scale={0.9}
                  fileName={'Offer-Latter.pdf'}
                  ref={inputEl}
                >
                  <OfferLetterQDS
                    candidate={candidate}
                    joiningDate={joiningDate}
                    todayDate={todayDate}
                  />
                  {/* <OfferLater candidate={candidate} joiningDate={joiningDate} todayDate={todayDate} /> */}
                  <Ctc
                    candidate={candidate}
                    ctcResult={ctcResult}
                    joiningDate={joiningDate}
                    todayDate={todayDate}
                  />
                </PDFExport>
              </div>
            )}
            {isCTCFullDetails && offerLetterType == 'offer_letter_qspl' && (
              <div>
                <OfferLetterQSPL
                  candidate={candidate}
                  joiningDate={joiningDate}
                  todayDate={todayDate}
                />
                <Ctc
                  candidate={candidate}
                  ctcResult={ctcResult}
                  joiningDate={joiningDate}
                  todayDate={todayDate}
                />
              </div>
            )}
            {isCTCFullDetails && offerLetterType == 'internship_letter' && (
              <div>
                <InternshipLetter
                  candidate={candidate}
                  joiningDate={joiningDate}
                  todayDate={todayDate}
                />
                <Ctc
                  candidate={candidate}
                  ctcResult={ctcResult}
                  joiningDate={joiningDate}
                  todayDate={todayDate}
                />
              </div>
            )}
            {isCTCFullDetails && offerLetterType == 'agreement_letter' && (
              <div>
                <AgreementLetter
                  candidate={candidate}
                  joiningDate={joiningDate}
                  todayDate={todayDate}
                />
                <Ctc
                  candidate={candidate}
                  ctcResult={ctcResult}
                  joiningDate={joiningDate}
                  todayDate={todayDate}
                />
              </div>
            )}
            {isCTCFullDetails == false && (
              <CCardFooter>
                <Button
                  type="submit"
                  style={{ color: 'white' }}
                  className="btn btn-primary mr-3 mt-2"
                >
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>{' '}
                  Submit
                </Button>
              </CCardFooter>
            )}
          </AvForm>
        </CModalBody>

        <CModalFooter>
          {/* <CButton color="success" onClick={onButtonPdfClick}>PDF Download</CButton> */}
          {isCTCFullDetails == true && (
            <CButton
              data-toggle="tooltip"
              data-placement="top"
              title="Send Offer Letter"
              color="success"
              onClick={finalSendOfferLatter}
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </CButton>
          )}
          <CButton
            data-toggle="tooltip"
            data-placement="top"
            title="Close"
            color="danger"
            onClick={() => sendOfferLatter(!large)}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton>{' '}
        </CModalFooter>
      </CModal>
      {/* <button onClick={()=>setLarge(!large)}>modal</button> */}
    </div>
  );
};
export default SendOfferLaterModal;
