import React from 'react';
import { convertDate } from 'src/utils/common';
import { CDataTable } from '@coreui/react';
import { NoRecords } from 'src/reusable';
import moment from 'moment';

const LeaveAppliedList = props => {
  const { data, type, renderDataStatus, renderDataActionCancelBtn } = props;
  const fields = [
    {
      label: 'S.No.',
      key: 's_no',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave Date',
      key: 'leave_date',
      sorter: true,
      filter: true,
    },
    {
      label: 'Leave Status',
      key: 'leave_status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Leave Type',
      key: 'leave_type',
      sorter: false,
      filter: false,
    },
    {
      label: 'Reason',
      key: 'reason',
      sorter: false,
      filter: true,
    },
    {
      label: 'Cancel Reason',
      key: 'cancel_reason',
      sorter: false,
      filter: false,
    },
    {
      label: 'Cancel By',
      key: 'cancel_by',
      sorter: false,
      filter: false,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Created Date',
      key: 'raised_date',
      sorter: true,
      filter: true,
    },
    {
      label: '',
      key: 'action',
      sorter: true,
      filter: true,
    },
  ];

  return (
    <CDataTable
      items={data}
      fields={fields}
      tableFilter
      itemsPerPageSelect
      itemsPerPage={10}
      hover
      sorter
      pagination
      scopedSlots={{
        status: item => <td>{renderDataStatus(item, type)}</td>,
        leave_date: item => (
          <td>{moment(item.leave_date).format('DD-MM-YYYY')}</td>
        ),
        raised_date: item => (
          <td>{convertDate(item.created_at, 'YYYY-MM-DD')}</td>
        ),
        s_no: (item, index) => (
          <td>
            <b>{index + 1}</b>
          </td>
        ),
        reason: (item, index) => (
          <td>
            {item.is_cancel == 1
              ? item.cancel_reason
              : item.is_rejected_status == 1
              ? item.rejection_comment
              : item.reason}
          </td>
        ),
        cancel_reason: item => (
          <td>{item.cancel_reason ? item.cancel_reason : ''}</td>
        ),
        cancel_by: item => <td>{item.cancel_by ? item.cancel_by : ''}</td>,
        action: item => <td>{renderDataActionCancelBtn(item, type)}</td>,
      }}
    />
  );
};

export default LeaveAppliedList;
