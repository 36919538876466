import React from 'react';
import CIcon from '@coreui/icons-react';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';

let arrayRout = getLocalDataAsObject("urlData1") ? getLocalDataAsObject("urlData1") : [];

let pms_nav = [
      {
            show: true,
            _tag: 'CSidebarNavItem',
            name: 'Create KRA',
            to: '/dashboard/pms/manage-kra',
            route_key: "pms_manage_kra",
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      },
      {
            show: true,
            _tag: 'CSidebarNavItem',
            name: 'My Team',
            to: '/dashboard/pms/my-team',
            route_key: "pms_my_team",
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      },
      {
            show: true,
            _tag: 'CSidebarNavItem',
            name: 'My KRA',
            to: '/dashboard/pms/my-kpi',
            route_key: "pms_my_kpi",
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      },
      {
            show: true,
            _tag: 'CSidebarNavItem',
            name: 'Team Ranking',
            to: '/dashboard/pms/team-ranking',
            route_key: "pms_team_ranking",
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      },
      {
            show: true,
            _tag: 'CSidebarNavItem',
            name: 'Team-Hierarchy',
            to: '/dashboard/pms/team-hierarchy',
            route_key: "pms_team_hierarchy",
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      },
      {
            show: true,
            _tag: 'CSidebarNavItem',
            name: 'KPI Guidelines',
            to: '/dashboard/pms/kpi-guidelines',
            route_key: "pms_kpi_guidelines",
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      },
      {
            show: true,
            _tag: 'CSidebarNavItem',      
            name: 'Probation Reviews',
            to: '/dashboard/pms/probation-reviews',
            route_key: "probation_reviews",
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      },
      {
            show: true,
            _tag: 'CSidebarNavItem',      
            name: 'Admin Probation Reviews',
            to: '/dashboard/pms/admin-probation-review',
            route_key: "admin_probation_review",
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      },
      {
        show: true,
        _tag: 'CSidebarNavItem',
        name: 'Employee Probation',
        to: '/dashboard/pms/employee_probation',
        route_key: 'employee_probation',
        icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
      },
]
let routeObjReq = [];

if (pms_nav && pms_nav.length) {
  pms_nav.map((item, index) => {
    arrayRout.find((val, index) => {
      // console.log("pms_nav", item)
      // console.log("array_route", val)
      if (item.route_key == val.route_key) {
        routeObjReq.push(item);
      }
    });
  });
}
// if (arrayRout && arrayRout.length) {
//       arrayRout.map((item, index) => {
//             pms_nav.find((val, index) => {
//                   if (item.route_key == val.route_key) {
//                         routeObjReq.push(val)
//                   }
//             })
//       });
// }

pms_nav = routeObjReq;
// console.log("item is", pms_nav)
export default pms_nav;
