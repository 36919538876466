// import { all, call, put, select, take } from 'redux-saga/effects';
import {
  take,
  put,
  call,
  all,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { commonActions as actions, commonService } from './index';

function* handleGetSampleRequest() {
  
}

function* rootCommonSaga() {
  yield all([
    handleGetSampleRequest(),
  ]);
}

export default rootCommonSaga;
