


import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
    CInputRadio,
    CTextarea
} from '@coreui/react'


import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import AssesmentForm from './assesmentForm';



const ViewInterviewStatus = (props) => {
    const { candidate, isInterviewStatusFun } = props
    let api = new API()

    const [active, setActive] = useState(0)
    const [assesmentDetails, setAssesmentDetails] = useState()

    useEffect(() => {
        candidateAssesmentDetails()
    }, [])

    const candidateAssesmentDetails = async () => {
        // console.log("candidate details is", candidate)
        let data = {
            candidate_id: candidate.id,
            mrf_id: candidate.mrf_id,
            mrf_applying_for: candidate.mrf_applying_for
        }
        // console.log(data)
        let result = await api.get(config.candidateAssesmentDetails, true, data)
        if (result && result.code == 200) {
            setAssesmentDetails(result.data)
        }
    }

    const backButton = () => {
        isInterviewStatusFun()
        // console.log("((((((((((((", candidate)
    }

    return (
        <div className="">
            <div className="container">
                <h3 className="text-uppercase text-center mt-3 mb-4"> <u>Interview Status</u></h3>

                <CRow>
                    <CCol xs="12" >
                        <CCard>
                            <CCardHeader>
                                HR Select:
                            </CCardHeader>
                            <CCardBody>


                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Candidate Id</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>

                                            <th scope="col">DOB</th>
                                            <th scope="col">Designation</th>


                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <th scope="row">1</th>
                                            <td>{candidate && candidate.id}</td>
                                            <td>{candidate && candidate.name}</td>

                                            <td>{candidate && candidate.email}</td>
                                            <td>{candidate && candidate.dob}</td>
                                            <td>{candidate && candidate.designation}</td>


                                            <CRow>


                                                <CCol xs="12" md="6">
                                                    {/* <CButton shape="pill" className="btn btn-success mr-0 mr-2" >View</CButton> */}
                                                    {/* <CButton shape="pill" block shape="pill" className="btn btn-success mr-0" onClick={isInterviewStatusFun}>Back</CButton> */}
                                                </CCol>




                                            </CRow>
                                        </tr>


                                    </tbody>
                                </table>
                                <CRow>
                                    <CCol xs="12" >
                                        <CCard>
                                            <CCardHeader>
                                                HR Select:
                                            </CCardHeader>
                                            <CCardBody>
                                                <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                                    <CNav variant="tabs">
                                                        {assesmentDetails && assesmentDetails.map((interviewAssesment, index) => (

                                                            <CNavItem>
                                                                <CNavLink>
                                                                    Interview Round {interviewAssesment.interview_round}
                                                                </CNavLink>
                                                            </CNavItem>
                                                        ))}

                                                    </CNav>

                                                    <CTabContent>
                                                        {assesmentDetails && assesmentDetails.map((interviewAssesment, index) => (
                                                            <>
                                                                <CTabPane>
                                                                    <div className="container col-md-12 mt-4" style={{ minHeight: "300px" }}>
                                                                        <h3 align="center">INTERVIEW DETAILS</h3>
                                                                 
                                                                        <AssesmentForm interviewAssesment={interviewAssesment}/>
                                                                    </div>
                                                                </CTabPane>
                                                            </>
                                                        ))}
                                                        {/* <CTabPane>
                                                            <div className="container" style={{ minHeight: "300px" }}>

                                                                <div className="container col-md-12 mt-4" style={{ minHeight: "300px" }}>
                                                                    <h3 align="center">Interview Details</h3>
                                                                    <button onClick={candidateAssesmentDetails}>get</button>
                                                                    <AssesmentForm />

                                                                </div>
                                                            </div>
                                                        </CTabPane>

                                                        <CTabPane>
                                                            <div className="container" style={{ minHeight: "300px" }}>

                                                                <div className="container col-md-12 mt-4" style={{ minHeight: "300px" }}>
                                                                    <h3 align="center">Interview Details</h3>
                                                                    <button onClick={candidateAssesmentDetails}>get</button>
                                                                    <AssesmentForm />

                                                                </div>
                                                            </div>
                                                        </CTabPane> */}

                                                    </CTabContent>
                                                </CTabs>
                                                <CCardFooter>
                                                    {/* <CButton color="success" onClick={backButton}>Back</CButton>{' '} */}

                                                </CCardFooter>

                                            </CCardBody>
                                        </CCard>

                                    </CCol>
                                </CRow>




                                <CCardFooter>
                                    <CButton data-toggle="tooltip" data-placement="top" title="Back" color="success" onClick={backButton}><i className="fa fa-arrow-left" aria-hidden="true"></i>  </CButton>{' '}

                                </CCardFooter>

                            </CCardBody>
                        </CCard>

                    </CCol>
                </CRow>



            </div>

        </div>

    )
}
export default ViewInterviewStatus







