

import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import Modal from './modal'
import ModalAccessManagement from './modal_access_management'
import ModalSendingWelcomeMail from './modal_sending_welcome_mail'
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'
import swal from 'sweetalert'
import { toast, ToastContainer } from 'react-toastify';

const CandidateAccessManagment = (props) => {
    const { candidateAccessManagement, newCandidateAccessManagement } = props
    
    let api = new API()

    const [CandidateRecord, setCandidateRecord] = useState()
    const [active, setActive] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpen1, setIsModalOpen1] = useState(false)
    const [pendingRequisition, setPendingRequisition] = useState()
    const [isCreateCondidateModalOpen, setIsCreateCondidateModalOpen] = useState(false)
    const [candidateDetails, setCandidateDetails] = useState()
    const [activeKey, setActiveKey] = useState(1)
    const [large, setLarge] = useState(false)
    const [candidate, setCandidate] = useState()
    const [finalJoined, setFinalJoined] = useState()
    const [generatedEmpId, setGeneratedEmpId] = useState()
    const [sendEmailModal, setSendEmailModal] = useState(false)
    const [selectedCandidate, setSelectedCandidate] = useState([])
    useEffect(() => {
        getFinalJoinedCandidate()
        // console.log("LLLLLLLLLLLLLLLLLLLLLLLLLLL", candidateAccessManagement)
    }, [candidateAccessManagement])
    const getFinalJoinedCandidate = async () => {

        let result = await api.get(config.totalCandidateJoined)
        if (result && result.code == 200) {
            // console.log("pending requisition", result)

            setFinalJoined(result.data)
        } else {
            // console.log("something wrong")
        }
    }



    const isViewModalOpen = (candidate) => {
        setIsModalOpen(!isModalOpen)
        setCandidateDetails(candidate)
    }
    const isViewModalOpen1 = (candidate) => {
        setIsModalOpen1(!isModalOpen1)
        setCandidateDetails(candidate)
    }


    const sendOfferLatter = (candidate) => {
        alert("offer later sending")
        setLarge(!large)
        setCandidate(candidate)
    }
    const addEmployeeEmpId = async (id,emp_id,email_type) => {
        let data = {
            emp_user_id: id,
            emp_id: emp_id,
            email_type : email_type
        }
        swal({
            content: true,
            title: "Are you sure?",
            text: `Are you sure to add Emp_ID`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.updateEmpIdByHR, data)
                if (result && result.code == 200) {
                    toast.success(result.message)
                    setIsModalOpen1(false)
                    newCandidateAccessManagement()
                } else {
                    toast.warning(result && result.message)
                }
            }
        })
    }
    const generateEmployeeId = async (type) => {
        let data = {
            type: type
        }
       
        let result = await api.get(config.generateEmployeeId, true, data)
        if (result && result.code == 200 && result.data) {
            setGeneratedEmpId(result.data.generatedEmpId)
        } else {
            toast.warning("Unable to generate please enter manually")
        }
    }
    const sendWelcomeMailFun = async (data) => {
        setSendEmailModal(!sendEmailModal)
        setSelectedCandidate(data)
    }
    return (
        <div className="">
            <div className="container">
                <h3 className="text-uppercase text-center mt-3 mb-4"> <u>New Candidate Access Management</u></h3>
                <CRow>
                    <CCol xs="12" >
                        <CCard>
                            <CCardBody>
                                <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink>
                                                Pending Candidate Emp Id
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink>
                                                Assigned Candidate Emp Id
                                            </CNavLink>
                                        </CNavItem>
                                        <CNavItem>
                                            <CNavLink>
                                                Welcome Mail
                                            </CNavLink>
                                        </CNavItem>

                                    </CNav>
                                    <CTabContent>
                                        <CTabPane>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <>
                                                            <th scope="col">#</th>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Emp ID</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Designation</th>
                                                            <th scope="col">MRF Id</th>
                                                            <th scope="col">MRF Applying For</th>
                                                            <th scope="col">Overall Interview Status</th>
                                                        </>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {candidateAccessManagement && candidateAccessManagement.filter(candidate => candidate.emp_id_created_status == 0).map((data, index) => (
                                                        <tr>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{data.id}</td>
                                                            <td>{data.emp_id}</td>
                                                            <td>{data.name}</td>
                                                            <td>{data.email}</td>
                                                            <td>{data.designation}</td>
                                                            <td>{data.mrf_id}</td>
                                                            <td className="letter">{data.mrf_applying_for == "vacancy" ? "New Hiring" : data.mrf_applying_for}</td>
                                                            <td>{data.overall_interview_status == 1 ? "Selected" : "Not Selected"}</td>
                                                            <td>
                                                                <CButton data-toggle="tooltip" title="View" className="btn btn-info mr-0" onClick={() => isViewModalOpen(data)} ><i className="fa fa-eye" aria-hidden="true"></i></CButton>
                                                            </td>
                                                            <td>
                                                                <CButton className="btn btn-danger mr-0" onClick={() => isViewModalOpen1(data)}>Add Emp ID</CButton>
                                                            </td>
                                                        </tr>
                                                    ))}


                                                </tbody>
                                            </table>
                                        </CTabPane>
                                        <CTabPane>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <>
                                                            <th scope="col">#</th>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Emp ID</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Designation</th>
                                                            <th scope="col">MRF Id</th>
                                                            <th scope="col">MRF Applying For</th>
                                                            <th scope="col">Overall Interview Status</th>
                                                        </>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {candidateAccessManagement && candidateAccessManagement.filter(candidate => candidate.emp_id_created_status == 1).map((data, index) => (
                                                        <tr>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{data.id}</td>
                                                            <td>{data.emp_id}</td>
                                                            <td>{data.name}</td>
                                                            <td>{data.email}</td>
                                                            <td>{data.designation}</td>
                                                            <td>{data.mrf_id}</td>
                                                            <td className="letter">{data.mrf_applying_for == "vacancy" ? "New Hiring" : data.mrf_applying_for}</td>
                                                            <td>{data.overall_interview_status == 1 ? "Selected" : "Not Selected"}</td>
                                                            {/* <td>
                                        <CButton data-toggle="tooltip" title="View"   className="btn btn-info mr-0" onClick={()=>isViewModalOpen(data)} ><i className="fa fa-eye" aria-hidden="true"></i></CButton>
                                        </td>
                                        <td>
                                        <CButton  className="btn btn-danger mr-0" onClick={()=>isViewModalOpen1(data)}>Add Emp ID</CButton>
                                        </td> */}
                                                        </tr>
                                                    ))}


                                                </tbody>
                                            </table>
                                        </CTabPane>
                                        <CTabPane>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <>
                                                            <th scope="col">#</th>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Emp ID</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Designation</th>
                                                            <th scope="col">MRF Id</th>
                                                            <th scope="col">MRF Applying For</th>
                                                            <th scope="col">Overall Interview Status</th>
                                                        </>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {candidateAccessManagement && candidateAccessManagement.filter(candidate => candidate.emp_id_created_status == 1 && candidate.email_id_created_status == 1 && candidate.send_welcome_letter == 0).map((data, index) => (
                                                        <tr>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{data.id}</td>
                                                            <td>{data.emp_id}</td>
                                                            <td>{data.name}</td>
                                                            <td>{data.email}</td>
                                                            <td>{data.designation}</td>
                                                            <td>{data.mrf_id}</td>
                                                            <td className="letter">{data.mrf_applying_for == "vacancy" ? "New Hiring" : data.mrf_applying_for}</td>
                                                            <td>{data.overall_interview_status == 1 ? "Selected" : "Not Selected"}</td>
                                                            <td>
                                                                <CButton data-toggle="tooltip" title="View" className="btn btn-info mr-0" onClick={() => isViewModalOpen(data)} ><i className="fa fa-eye" aria-hidden="true"></i></CButton>
                                                            </td>
                                                            <td>
                                                                <CButton className="btn btn-danger mr-0" onClick={() => sendWelcomeMailFun(data)}>Send Welcome Mail</CButton>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </CTabPane>
                                    </CTabContent>
                                </CTabs>
                                <CCardFooter>
                                    <CButton data-toggle="tooltip" data-placement="top" title="Back" color="success" ><i className="fa fa-arrow-left" aria-hidden="true"></i></CButton>{' '}
                                </CCardFooter>
                            </CCardBody>
                        </CCard>

                    </CCol>
                </CRow>

            </div>
            <Modal isModalOpen={isModalOpen} message="Ops Selected Candidate Details" isViewModalOpen={isViewModalOpen}
                candidateDetails={candidateDetails} />


            <ModalAccessManagement isModalOpen={isModalOpen1} message="Generate Candidate Employee ID" isViewModalOpen={isViewModalOpen1}
                candidateDetails={candidateDetails} addEmployeeEmpId={addEmployeeEmpId}
                generatedEmpId={generatedEmpId} generateEmployeeId={generateEmployeeId} />
            
            <ModalSendingWelcomeMail 
            isModalOpen={sendEmailModal}
            isViewModalOpen={sendWelcomeMailFun}
            setSendEmailModal={setSendEmailModal}
            selectedCandidate={selectedCandidate}/>

            <ToastContainer />
        </div>
    )
}
export default CandidateAccessManagment







