import React from 'react';
import { convertDate } from 'src/utils/common';
import { NoRecords } from 'src/reusable';
import { CDataTable} from '@coreui/react';
import { isPermission } from 'src/utils/common';
const CoffApprovalList = props => {
  const { data, type, renderDataStatus, renderDataActionBtn } = props;
  
  const fields = [
    {
      label: 'S.No.',
      key: 's_no',
      sorter: false,
      filter: true,
    },
    {
      label: 'Employee ID',
      key: 'emp_id',
      sorter: false,
      filter: true,
    },
    {
      label: 'Employee Name',
      key: 'emp_name',
      sorter: true,
      filter: true,
    },
    {
      label: 'Comp Off Date',
      key: 'date',
      sorter: false,
      filter: false,
    },
    {
      label: 'Comp Off Status',
      key: 'comp_off_status',
      sorter: false,
      filter: false,
    },
    {
      label: 'Approval Status',
      key: 'status',
      sorter: false,
      filter: true,
    },
    {
      label: 'Created Date',
      key: 'created_at',
      sorter: false,
      filter: false,
    },
    {
      label: '',
      key: 'action',
      sorter: true,
      filter: true,
    }
  ];

  return (
    <CDataTable
      items={data}
      fields={fields}
      tableFilter
      itemsPerPageSelect
      itemsPerPage={10}
      hover
      sorter
      pagination
      scopedSlots={{
        s_no: (item,index) => (
          <td>
            <b>{index + 1}</b>
          </td>
        ),
        status: item => <td>{renderDataStatus(item, type)}</td>,
        date: item => (
          <td>
            {convertDate(item.date, 'YYYY-MM-DD')}
          </td>
        ),
        created_at: item => (
          <td>
            {convertDate(item.created_at, 'YYYY-MM-DD')}
          </td>
        ),
        action: item => <td>{isPermission('approve_reject_leave_request') && <>{renderDataActionBtn(item, type)}</>}</td>,
      }}
    />
  );
};

export default CoffApprovalList;
