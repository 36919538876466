import React from 'react';
import {
  CDropdownToggle,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react';

const StatusDropdownMenu = props => {
  const {
    onClick,
    toggleBtnColor,
    toggleBtnText,
    dropdownItems,
    activeTab,
    isShowCounts,
    totalRecords,
  } = props;


  const renderItems = () => {
    return dropdownItems.map((val, key) => {
   
      return (
        <>
          <CDropdownItem
            key={key}
            onClick={onClick}
            data-status={val.value}
            data-type={activeTab || ''}
          >
            {val.label}
          </CDropdownItem>
          {dropdownItems.length > key + 1 && <CDropdownItem divider />}
        </>
      );
    });
  };

  const getToggleBtnText = () => {
    const totalRec = isShowCounts ? `(${totalRecords})` : '';
    const obj = dropdownItems.filter(item => item.value === toggleBtnText);
    return obj?.length ? `${obj[0].label} Records ${totalRec}` : toggleBtnText;
  };
  return (
    <CDropdown className="mt-2">
      <CDropdownToggle caret color={toggleBtnColor || 'info'}>
        {getToggleBtnText() || 'Filter by status'}
      </CDropdownToggle>
      <CDropdownMenu>{renderItems()}</CDropdownMenu>
    </CDropdown>
  );
};

export default StatusDropdownMenu;
