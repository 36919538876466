import React, { useState, lazy, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {
    CBadge, CNav,
    CInputRadio,
    CNavItem,
    CNavbar,
    CListGroup, CListGroupItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CTabs,
    CButton,
    CTextarea,
    CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
    CWidgetBrand,
    CButtonGroup,
    CWidgetIcon,
    CCard,
    CLink,
    CCardBody,
    CCardFooter,
    CInputFile,
    CCardHeader,
    CCol,
    CSwitch,
    CCollapse,
    CProgress,
    CProgressBar,
    CRow,
    CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
    CCallout
} from '@coreui/react'
import OnboaridngDashboard from './onboarding_dashobard/onboarding_dashobard';

const OnboardingEmployeeDashboard = (props) => {

    useEffect(() => {

    }, [])
    return (
        <div>
            {/* <h2>Onboarding Employee</h2> */}
            <OnboaridngDashboard/>
        </div>
    )
}
export default OnboardingEmployeeDashboard