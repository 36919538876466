

import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {

    CCol,
    CForm,
    CFormGroup,
    CLabel,
    
    CInputRadio,
    CTextarea
    } from '@coreui/react'

import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'




const AssesmentForm = (props) => {
    const { interviewAssesment } = props
    let api = new API()


    useEffect(() => {
        // console.log("___________________________++++++", interviewAssesment)
    }, [])

    return (
        <div>
           <CForm encType="multipart/form-data" className="form-horizontal" >
           <CFormGroup row>
  <CCol md="3">
    <CLabel>Interview Taken By</CLabel>
  </CCol>
  <CCol md="9">
   <CLabel>{interviewAssesment.assesment_taken_by_name}</CLabel>

  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel>Status</CLabel>
  </CCol>
  <CCol md="9">
   <CLabel>{interviewAssesment.interview_selection_status == 1 ? "Selected" : "Not Selected"}</CLabel>

  </CCol>
</CFormGroup>

<CFormGroup row>
  <CCol md="3">
    <CLabel>Demonstrates Respect</CLabel>
  </CCol>
  <CCol md="9">
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_respect" checked={interviewAssesment && interviewAssesment.demonstrates_respect==0 ? true : false} name="demonstrates_respect" value="0" />
      <CLabel className="mr-3" variant="custom-checkbox" htmlFor="demonstrates_respect">0</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_respect2" checked={interviewAssesment && interviewAssesment.demonstrates_respect==1 ? true : false} name="demonstrates_respect" value="1" />
      <CLabel className="mr-3" variant="custom-checkbox" htmlFor="demonstrates_respect2">1</CLabel>
    </CFormGroup>

  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel>Demonstrates Integrity</CLabel>
  </CCol>
  <CCol md="9">
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_integrity" checked={interviewAssesment && interviewAssesment.demonstrates_integrity==0 ? true : false} name="demonstrates_integrity" value="0" />
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_integrity">0</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_integrity2" checked={interviewAssesment && interviewAssesment.demonstrates_integrity==1 ? true : false} name="demonstrates_integrity" value="1" />
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_integrity2">1</CLabel>
    </CFormGroup>
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel>Demonstrates Simplicity</CLabel>
  </CCol>
  <CCol md="9">

    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_simplicity1" name="demonstrates_simplicity" value="1" checked={interviewAssesment && interviewAssesment.demonstrates_simplicity==1 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_simplicity1">1</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_simplicity2" name="demonstrates_simplicity" value="2" checked={interviewAssesment && interviewAssesment.demonstrates_simplicity==2 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_simplicity2">2</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_simplicity3" name="demonstrates_simplicity" value="3" checked={interviewAssesment && interviewAssesment.demonstrates_simplicity==3 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_simplicity3">3</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_simplicity4" name="demonstrates_simplicity" value="4" checked={interviewAssesment && interviewAssesment.demonstrates_simplicity==4 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_simplicity4">4</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_simplicity5" name="demonstrates_simplicity" value="5" checked={interviewAssesment && interviewAssesment.demonstrates_simplicity==5 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_simplicity5">5</CLabel>
    </CFormGroup>
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel>Demonstrates Excellence</CLabel>
  </CCol>
  <CCol md="9">
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_excellence1" name="demonstrates_excellence" value="1" checked={interviewAssesment && interviewAssesment.demonstrates_excellence==1 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_excellence1">1</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_excellence2" name="demonstrates_excellence" value="2" checked={interviewAssesment && interviewAssesment.demonstrates_excellence==2 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_excellence2">2</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_excellence3" name="demonstrates_excellence" value="3" checked={interviewAssesment && interviewAssesment.demonstrates_excellence==3 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_excellence3">3</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_excellence4" name="demonstrates_excellence" value="4" checked={interviewAssesment && interviewAssesment.demonstrates_excellence==4 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_excellence4">4</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="demonstrates_excellence5" name="demonstrates_excellence" value="5" checked={interviewAssesment && interviewAssesment.demonstrates_excellence==5 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="demonstrates_excellence5">5</CLabel>
    </CFormGroup>
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel>Communication Skills (Spoken)</CLabel>
  </CCol>
  <CCol md="9">
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="communication_skills1" name="communication_skills" value="1" checked={interviewAssesment && interviewAssesment.communication_skills==1 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="communication_skills1">1</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="communication_skills2" name="communication_skills" value="2" checked={interviewAssesment && interviewAssesment.communication_skills==2 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="communication_skills2">2</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="communication_skills3" name="communication_skills" value="3" checked={interviewAssesment && interviewAssesment.communication_skills==3 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="communication_skills3">3</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="communication_skills4" name="communication_skills" value="4" checked={interviewAssesment && interviewAssesment.communication_skills==4 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="communication_skills4">4</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="communication_skills5" name="communication_skills" value="5" checked={interviewAssesment && interviewAssesment.communication_skills==5 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="communication_skills5">5</CLabel>
    </CFormGroup>
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel>Adaptability</CLabel>
  </CCol>
  <CCol md="9">
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="adaptability1" name="adaptability" value="1" checked={interviewAssesment && interviewAssesment.adaptability==1 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="adaptability1">1</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="adaptability2" name="adaptability" value="2" checked={interviewAssesment && interviewAssesment.adaptability==2 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="adaptability2">2</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="adaptability3" name="adaptability" value="3" checked={interviewAssesment && interviewAssesment.adaptability==3 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="adaptability3">3</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="adaptability4" name="adaptability" value="4" checked={interviewAssesment && interviewAssesment.adaptability==4 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="adaptability4">4</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="adaptability5" name="adaptability" value="5" checked={interviewAssesment && interviewAssesment.adaptability==5 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="adaptability5">5</CLabel>
    </CFormGroup>
  </CCol>
</CFormGroup>

<CFormGroup row>
  <CCol md="3">
    <CLabel>Motivation</CLabel>
  </CCol>
  <CCol md="9">
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="motivation1" name="motivation" value="1" checked={interviewAssesment && interviewAssesment.motivation==1 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="motivation1">1</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="motivation2" name="motivation" value="2" checked={interviewAssesment && interviewAssesment.motivation==2 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="motivation2">2</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="motivation3" name="motivation" value="3" checked={interviewAssesment && interviewAssesment.motivation==3 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="motivation3">3</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="motivation4" name="motivation" value="4" checked={interviewAssesment && interviewAssesment.motivation==4 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="motivation4">4</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="motivation5" name="motivation" value="5" checked={interviewAssesment && interviewAssesment.motivation==5 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="motivation5">5</CLabel>
    </CFormGroup>
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel>Problem Solving</CLabel>
  </CCol>
  <CCol md="9">
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="problem_solving1" name="problem_solving" value="1" checked={interviewAssesment && interviewAssesment.problem_solving==1 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="problem_solving1">1</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="problem_solving2" name="problem_solving" value="2" checked={interviewAssesment && interviewAssesment.problem_solving==2 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="problem_solving2">2</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="problem_solving4" name="problem_solving" value="3" checked={interviewAssesment && interviewAssesment.problem_solving==3 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="problem_solving4">3</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="problem_solving4" name="problem_solving" value="4" checked={interviewAssesment && interviewAssesment.problem_solving==4 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="problem_solving4">4</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="problem_solving5" name="problem_solving" value="5" checked={interviewAssesment && interviewAssesment.problem_solving==5 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="problem_solving5">5</CLabel>
    </CFormGroup>
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel>Attitude for learning new skills</CLabel>
  </CCol>
  <CCol md="9">
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="attitude_for_learning_new_skills1" name="attitude_for_learning_new_skills" value="1" checked={interviewAssesment && interviewAssesment.attitude_for_learning_new_skills==1 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="attitude_for_learning_new_skills1">1</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="attitude_for_learning_new_skills2" name="attitude_for_learning_new_skills" value="2" checked={interviewAssesment && interviewAssesment.attitude_for_learning_new_skills==2 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="attitude_for_learning_new_skills2">2</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="attitude_for_learning_new_skills3" name="attitude_for_learning_new_skills" value="3" checked={interviewAssesment && interviewAssesment.attitude_for_learning_new_skills==3 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="attitude_for_learning_new_skills3">3</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="attitude_for_learning_new_skills4" name="attitude_for_learning_new_skills" value="4" checked={interviewAssesment && interviewAssesment.attitude_for_learning_new_skills==4 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="attitude_for_learning_new_skills4">4</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="attitude_for_learning_new_skills5" name="attitude_for_learning_new_skills" value="5" checked={interviewAssesment && interviewAssesment.attitude_for_learning_new_skills==5 ? true : false}/>
      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="attitude_for_learning_new_skills5">5</CLabel>
    </CFormGroup>
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel >Technical Skills(if any)</CLabel>
  </CCol>
  <CCol xs="12" md="9">
    <CTextarea value={interviewAssesment.technical_skills} 
      rows="2"
      name="technical_skills"
      placeholder="Skills..." required
    />
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel >Languages Known</CLabel>
  </CCol>
  <CCol xs="12" md="9">
    <CTextarea value={interviewAssesment.languages_known} 
      rows="2"
      name="languages_known"
      placeholder="Languages..." required
    />
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel >Comment</CLabel>
  </CCol>
  <CCol xs="12" md="9">
    <CTextarea value={interviewAssesment.comments} 
      name="comments"
      rows="3"
      placeholder="Justification..." required
    />
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel>Score</CLabel>
  </CCol>
  <CCol xs="12" md="9">
    <p className="form-control-static">{interviewAssesment.score}%</p>
  </CCol>
</CFormGroup>
<CFormGroup row>
  <CCol md="3">
    <CLabel><strong>Overall Evaluation</strong></CLabel>
  </CCol>
  <CCol md="9">
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="overall_evaluation1"  name="overall_evaluation" value="strong" checked={interviewAssesment && interviewAssesment.overall_evaluation=="strong" ? true : false}/>
      <CLabel variant="custom-checkbox" htmlFor="overall_evaluation1">Strong </CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="overall_evaluation2" name="overall_evaluation" value="can_be_consider" checked={interviewAssesment && interviewAssesment.overall_evaluation=="can_be_consider" ? true : false}/>
      <CLabel variant="custom-checkbox" htmlFor="overall_evaluation2">Can be considered</CLabel>
    </CFormGroup>
    <CFormGroup variant="custom-radio" inline>
      <CInputRadio custom id="overall_evaluation3" name="overall_evaluation" value="reject" checked={interviewAssesment && interviewAssesment.overall_evaluation=="reject" ? true : false}/>
      <CLabel variant="custom-checkbox" htmlFor="overall_evaluation3">Reject</CLabel>
    </CFormGroup>

  </CCol>
</CFormGroup>
</CForm>
            {/* <CForm encType="multipart/form-data" className="form-horizontal" >
              
              <CFormGroup row>
                  <CCol md="3">
                    <CLabel>Demonstrates Respect</CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel className=" mr-3" selected  variant="custom-checkbox" htmlFor="inline-radio1">0</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio2">1</CLabel>
                    </CFormGroup>
                    
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="3">
                    <CLabel>Demonstrates Integrity</CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio1">0</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio2">1</CLabel>
                    </CFormGroup>
                   
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="3">
                    <CLabel>Demonstrates Simplicity</CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio1">1</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio2">2</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3"  variant="custom-checkbox" htmlFor="inline-radio3">3</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">5</CLabel>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="3">
                    <CLabel>Demonstrates Excellence</CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio1">1</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio2">2</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3"  variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">5</CLabel>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="3">
                    <CLabel>Communication Skills (Spoken)</CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio1">1</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio2">2</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3"  variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">5</CLabel>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="3">
                    <CLabel>Adaptability</CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio1">1</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio2">2</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3"  variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">5</CLabel>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                
                <CFormGroup row>
                  <CCol md="3">
                    <CLabel>Motivation</CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio1">1</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio2">2</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3"  variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">5</CLabel>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="3">
                    <CLabel>Problem Solving</CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio1">1</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio2">2</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3"  variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">5</CLabel>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="3">
                    <CLabel>Attitude for learning new skills</CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio1">1</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio2">2</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3"  variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">4</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel className=" mr-3" variant="custom-checkbox" htmlFor="inline-radio3">5</CLabel>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
              <CCol md="3">
                <CLabel >Technical Skills(if any)</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CTextarea
                  rows="2"
                  placeholder="Skills..." required
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel >Languages Known</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CTextarea
                  rows="2"
                  placeholder="Languages..." required
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel >Comment</CLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CTextarea
                  rows="3"
                  placeholder="Justification..." required
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
                  <CCol md="3">
                    <CLabel><strong>Overall Evaluation</strong></CLabel>
                  </CCol>
                  <CCol md="9">
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio1" name="inline-radios" value="option1" />
                      <CLabel variant="custom-checkbox" htmlFor="inline-radio1">Strong </CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio2" name="inline-radios" value="option2" />
                      <CLabel variant="custom-checkbox" htmlFor="inline-radio2">Can be considered</CLabel>
                    </CFormGroup>
                    <CFormGroup variant="custom-radio" inline>
                      <CInputRadio custom id="inline-radio3" name="inline-radios" value="option3" />
                      <CLabel variant="custom-checkbox" htmlFor="inline-radio3">Reject</CLabel>
                    </CFormGroup>
                   
                  </CCol>
                </CFormGroup>
                </CForm> */}

        </div>

    )
}
export default AssesmentForm







