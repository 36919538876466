import { combineReducers } from 'redux';
import { commonReducer } from './common';
import { messageReducer } from './messages';
import { attendanceReducer } from './attendance';
import { mqttReducer } from './mqtt';

const appReducer = combineReducers({
  common: commonReducer,
  messages: messageReducer,
  mqtt: mqttReducer,
  attendance: attendanceReducer,
});

const rootReducer = (state, action) => {
  const { type, payload } = action;
  let newState;

  if (type === 'APP_INIT') {
    newState = { ...state, internal: {} };
  } else if (type === 'RELOAD_STORE') {
    newState = { ...payload };
  } else if (type === 'CLEAR_INTERNAL_STORE') {
    newState = { routing: { ...state.routing } };
  }

  return appReducer(newState || state, action);
};

export default rootReducer;
