import React, { useState, useEffect } from 'react'
import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CCol, CForm, CBadge, CFormGroup, CDataTable, CLabel, CSwitch, CInputFile, CRow, CCollapse } from '@coreui/react';
import SendOfferLaterModal from './common/SendOfferLaterModal';
import ModalPopup from './common/modal';
import CondidateModal from './condidate/modal';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import { isPermission } from 'src/utils/common';

const ListOfCloseMrf = (props) => {
    const { getCountAssignRequisition } = props
    let api = new API()
    const [large, setLarge] = useState(false)
    const [mrfRecord, setMrfRecord] = useState()
    const [candidate, setCandidate] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [details, setDetails] = useState([]);
    const [listOfCloseMrf, setListOfCloseMrf] = useState([])

    useEffect(() => {
        selectedCandidateFun()
    }, [])
    const selectedCandidateFun = async (candidate) => {
        let result = await api.get(config.getAllClosedMRFForHRManagerDashboard)
        if (result && result.code == 200) {          
            setListOfCloseMrf(result.data.Vacancy)
            return (result.data).length
        }
        else {     
            // console.log(result.message)
            setListOfCloseMrf()
        }
    }
 


    const isModalOpen = (condidate) => {
        setLarge(!large)
        setCandidate(condidate)
    }
    const isViewModalOpen = (condidate) => {
        setIsOpen(!isOpen)
        setCandidate(condidate)
    }
    const createNewCondidate = (candidate) => {
        setIsModal(!isModal)
    }

    const fields = [
        { label: "MRF ID", key: "id", _style: { width: "5%" } },
        { label: "Applying for", key: "applying_for", _style: { width: "10%" } },
        { label: "MRF Raised By", key: "created_by_name", _style: { width: "10%" }, },
        { label: "MRF Raised At", key: "created_at", _style: { width: "10%" } },
        { label: "Location", key: "location", _style: { width: "10%" } },
        { label: "Designation", key: "designation", _style: { width: "10%" }, },

        {
            key: "show_details",
            label: "",
            _style: { width: "5%" },
            sorter: false,
            filter: false,
        },
    ];
     const toggleDetails = (index) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, index];
        }
        setDetails(newDetails);
    };
    
    return (
        <div className="">
        <div className="container">
       
        <h3 className="text-uppercase text-center mt-3 mb-4"><u> List Of Closed MRF</u></h3>
            <div className='card'>
            <CCardBody>
            <CDataTable
                            items={listOfCloseMrf && listOfCloseMrf.length > 0 && listOfCloseMrf.filter(mrf => {
                                // console.log(asset.is_assigned)
                                mrf.mrf_final_status = mrf.mrf_final_status == 0 ? "Pending" : "Complete"
                                if (mrf.applying_for == "vacancy") {
                                    mrf.applying_for = "New Hiring"
                                }
                                return mrf
                            })}
                            fields={fields}
                            // columnFilter
                            tableFilter
                            // footer
                            itemsPerPageSelect
                            itemsPerPage={20}
                            hover
                            sorter
                            pagination
                            scopedSlots={{
                                show_details: (item, index) => {
                                    return (
                                        <td className="py-2">
                                            <CButton
                                                color="primary"
                                                variant="outline"
                                                shape="square"
                                                size="sm"
                                                onClick={() => {
                                                    toggleDetails(index);
                                                }}
                                            >
                                                {details.includes(index) ? "Hide" : "Show"}
                                            </CButton>
                                        </td>
                                    );
                                },
                                details: (item, index) => {
                                    return (
                                        <CCollapse show={details.includes(index)}>
                                            <CCardBody>
                                                <h4>Joined Employee Details</h4>
                                                <p className="text-muted"> Employee Name : {}</p>
                                                <p className="text-muted"> Employee ID : {item.created_by_id}</p>
                                                <p className="text-muted"> Employee Email : {item.created_by_name}</p>
                                                <p className="text-muted"> Employee Deaprtment : {item.requisition_for_department}</p>
                                                <p className="text-muted"> Employee Designation : {item.designation}</p>
                                                <p className="text-muted"> Employee Reporting Manager : {item.reported_to}</p>
                                                {isPermission('view_closed_mrf_candidates') &&
                                                <CButton onClick={() => isViewModalOpen(item)}
                                                    size="sm"
                                                    color="info"
                                                    className="ml-1"
                                                >
                                                    View
                                                </CButton>
                                                }
                                            </CCardBody>
                                        </CCollapse>
                                    );
                                },
                            }}
                        />

</CCardBody>
</div>


            <SendOfferLaterModal isModalOpen={isModalOpen} large={large} candidate={candidate} />

            <ModalPopup isOpen={isOpen} isViewModalOpen={isViewModalOpen}
                candidate={candidate} popupFor={"selectedCondidate"}
                modalHeader="View Candidate" />

            <CondidateModal isModalOpen={createNewCondidate} isModal={isModal} />

         
        </div>
        </div>
    )
}
export default ListOfCloseMrf
