const getFomatedError = err => {
  const { code, message, name } = err;
  let error = {
    isError: true,
    errorCode: code,
    errorMessage: message,
    errorName: name,
  };
  return error;
};

export { getFomatedError };
