import React from 'react';
import { convertDate } from 'src/utils/common';
import { NoRecords } from 'src/reusable';
import { CDataTable} from '@coreui/react';
import { isPermission } from 'src/utils/common';
const TourApprovalList = props => {
  const { data, type, renderDataStatus, renderDataActionBtn ,getTourApproval} = props;

  const fields = [
    {
      label: 'S.No.',
      key: 's_no',
      sorter: true,
      filter: true,
    },
    {
      label: 'Employee ID',
      key: 'emp_id',
      sorter: true,
      filter: true,
    },
    {
      label: 'Email',
      key: 'email',
      sorter: true,
      filter: true,
    },
    {
      label: 'Date From',
      key: 'applied_from',
      sorter: true,
      filter: true,
    },
    {
      label: 'Date',
      key: 'applied_to',
      sorter: true,
      filter: true,
    },
    {
      label: 'Tour',
      key: 'visiting_destination',
      sorter: true,
      filter: true,
    },
    {
      label: 'Reason',
      key: 'reason',
      sorter: true,
      filter: true,
    },

    {
      label: 'Approval Status',
      key: 'status',
      sorter: true,
      filter: true,
    },
    {
      label: 'Created Date',
      key: 'created_at',
      sorter: true,
      filter: true,
    },
    {
      label: '',
      key: 'action',
      sorter: true,
      filter: true,
    }
  ];

  return (
    <CDataTable
      items={data}
      fields={fields}
      tableFilter
      itemsPerPageSelect
      itemsPerPage={10}
      hover
      sorter
      pagination
      scopedSlots={{
        s_no: (item,index) => (
          <td>
            <b>{index + 1}</b>
          </td>
        ),
        status: item => <td>{renderDataStatus(item, type)}</td>,
        date: item => (
          <td>
            {convertDate(item.date, 'YYYY-MM-DD')}
          </td>
        ),
        created_at: item => (
          <td>
            {convertDate(item.created_at, 'YYYY-MM-DD')}
          </td>
        ),
        action: item => <td>{isPermission('approve_reject_leave_request') && <>{renderDataActionBtn(item, type)}</>}</td>,
      }}
    />
  );
};

export default TourApprovalList;



// import React from 'react';
// import { convertDate } from 'src/utils/common';
// import { NoRecords } from 'src/reusable';
// import { isPermission } from 'src/utils/common';
// const TourApprovalList = props => {
//   const { data, type, renderDataStatus, renderDataActionBtn,getTourApproval } = props;
//   console.log(data,"data....")
//   let list = null;
//   if (data?.length) {
//     list = data.map((item, index) => {
//       return (
//         <tr key={index}>
//           <th scope="row">{index + 1}</th>
//           <td>{item.emp_id}</td>
//           <td>{item.email}</td>
//           <td>{convertDate(item?.applied_from)}</td>
//           <td>{convertDate(item?.applied_to)}</td>
//           <td>{item.visiting_destination}</td>
//           <td>{item?.reason || ''}</td>
//           <td>{renderDataStatus(item, type)}</td>
//           <td>{convertDate(item.created_at, 'YYYY-MM-DD')}</td>
//           <td>{isPermission('approve_reject_leave_request') && <>{renderDataActionBtn(item, type)}</>}</td>
//         </tr>
//       );
//     });
//   }
//   return (
//     <table className="table">
//       <thead>
//         <tr>
//           <th scope="col">#</th>
//           <th scope="col">Emp ID</th>
//           <th scope="col">Email</th>
//           <th scope="col">Date From</th>
//           <th scope="col">Date To</th>
//           <th scope="col">Tour To</th>
//           <th scope="col">Reason</th>
//           <th scope="col">Approval Status</th>
//           <th scope="col">Created Date</th>
//           <th scope="col">&nbsp;</th>
//         </tr>
//       </thead>
//       <tbody>{list || <NoRecords tag="tr" colSpan={10} />}</tbody>
//     </table>
//   );
// };

// export default TourApprovalList;
