

import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile,
    CCollapse,

} from '@coreui/react'
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl'
import ModalPopupReceivedRequisition from './modal'
import swal from 'sweetalert';
import JustificationModal from './justificationModal';
import Alert from 'src/alert/alert';
import Moment from "react-moment";
import moment from 'moment'
import { toast, ToastContainer } from 'react-toastify';
import { isPermission } from 'src/utils/common';

const ReceivedCondidate = (props) => {
    const { getCountReceivedRequisition } = props
    let api = new API()

    const [mrfRecord, setMrfRecord] = useState()
    const [assignedRequisition, setAssignedRequisition] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [receivedRequisition, setReceivedRequisition] = useState()
    const [isOpenJustification, setIsOpenJustification] = useState(false)
    const [selectedMrfDetails, setSelectedMrfDetails] = useState()
    const [isAlert, setIsAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [active, setActive] = useState(0)
    const [rejectedMrfRecord, setRejectedMrfRecord] = useState()
    const [inventoryDetails, setInventoryDetails] = useState();
    const [details, setDetails] = useState([]);

    useEffect(() => {
        mrfReceivedforHrManager()
        getRejectedMrfByHrManager()
    }, [])

    const sendCountReceivedRequisition = (count) => {
        // alert(count)
        getCountReceivedRequisition(count)
    }

    const mrfReceivedforHrManager = async () => {
        // let data = {
        //     type: "hr",
        //     mrfType : "replacement"
        // }
        let result = await api.get(config.getMrfReceivedforHrManager)
        if (result && result.code == 200) {

            let replacement = result.data.Replacements
            let vacancy = result.data.Vacancy
            let replacementAndVacancy = replacement.concat(vacancy)
         
            sendCountReceivedRequisition(replacementAndVacancy.length)
            setMrfRecord(replacementAndVacancy)
        } else {
            // console.log("something wrong")
        }
    }
    const getRejectedMrfByHrManager = async () => {
        // let data = {
        //     type: "hr",
        //     mrfType : "replacement"
        // }
        let result = await api.get(config.getRejectedMrfByHrManager)
        if (result && result.code == 200) {
            // console.log("receivedMrffor hr manager", result)
            // console.log("replacement and vacancy", result.data)
            let replacement = result.data.Rejected_Replacement
            let vacancy = result.data.Rejected_Vacancy
           

            let replacementAndVacancy = replacement.concat(vacancy)
            // console.log("www",replacementAndVacancy)
       
          

            // sendCountReceivedRequisition(replacementAndVacancy.length)
            setRejectedMrfRecord(replacementAndVacancy)
        } else {
            // console.log("something wrong")
        }
    }




    const isViewModalOpen = (received_Requisition) => {
        // console.log("vvv",received_Requisition)
        setIsModalOpen(!isModalOpen)
        setReceivedRequisition(received_Requisition)
    }
    const isJustificationModalOpen = (mrf) => {
        setSelectedMrfDetails(mrf)
        setIsOpenJustification(!isOpenJustification)
    }
const ApprovedMrfByManager=(mrf)=>{
    let data = {
        mrf_id: mrf && mrf.id,
        mrf_applying_for: mrf && mrf.applying_for==="Replacement"?"replacement":"New Hiring"?"vacancy":" "

       
    
    }
    swal({
        html: true,
        title: "Are you sure?",
        text: `Are you sure to approve this MRF`,
        icon: "success",
        buttons: ["No, cancel it!", "Approve"],
        dangerMode: true,
    }).then(async function (isConfirm) {
        if (isConfirm) {
            let result = await api.post(config.ApprovedMrfByManager, data)
            if (result && result.code == 200) {

                toast.success("Successfully Approved MRF")
                // isJustificationModalOpen(selectedMrfDetails)
            } else {
                isAlertToggle("TEST")
            }
        }
    })


}


    const rejectMrf = (juctificationComment) => {
        let data = {
            mrf_id: selectedMrfDetails && selectedMrfDetails.id,
            mrf_applying_for: selectedMrfDetails && selectedMrfDetails.applying_for,
            hr_rejection_comment: juctificationComment
        }
        // console.log("rejected data is", data)
        // return
        swal({
            html: true,
            title: "Are you sure?",
            text: `Are you sure to reject this MRF`,
            icon: "success",
            buttons: ["No, cancel it!", "Reject"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.rejectMrfByHrManager, data)
                if (result && result.code == 200) {

                    toast.success("Successfully Rejected MRF")
                    isJustificationModalOpen(selectedMrfDetails)
                } else {
                    toast.error(result && result.message)
                }
            }
        })
    }
    
    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert)
        setAlertMessage(msg)
    }
    const isAlertToggleClose = () => {
        setIsAlert(false)
    }

    const fields = [
        { label: "MRF ID", key: "id", _style: { width: "5%" } },
        { label: "Applying For", key: "applying_for", _style: { width: "10%" } },
        { label: "MRF Raised By", key: "created_by_name", _style: { width: "10%" }, },
        { label: "Head Of Department", key: "head_of_department", _style: { width: "10%" }, },
        { label: "Designation", key: "designation", _style: { width: "10%" } },
        { label: "Location", key: "location", _style: { width: "10%" } },
        { label: "Priority", key: "priority", _style: { width: "10%" } },
        {label: "Created At", key: "created_at", _style: { width: "10%" }},
        {
            key: "show_details",
            label: "",
            _style: { width: "5%" },
            sorter: false,
            filter: false,
        },
    ];
    const toggleDetails = (index) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, index];
        }
        setDetails(newDetails);
    };
    const getBadge = (status) => {
        switch (status) {
            case "Low":
                return "success";
            case "Normal":
                return "primary";
            case "High":
                    return "warning";
            case "Critical":
                return "danger";
            default:
                return "secondary";
        }
    };
    return (
        <div className="">

            <div className="container">
            <h3 className="text-uppercase text-center mt-3 mb-4"><u>List Of Received Requisition</u></h3>
                <CCard>
                    <CCardBody>


                        <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                            <CNav variant="tabs">
                                <CNavItem>
                                    <CNavLink>
                                        <b>Pending Requisition</b>
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink>
                                        <b>Rejected Requisition</b>
                                    </CNavLink>
                                </CNavItem>
                            </CNav>

                            <CTabContent>
                                <CTabPane>
                                    <CDataTable
                                        items={mrfRecord && mrfRecord.length > 0 && mrfRecord.filter(mrf => {
                                            return mrf
                                        })}
                                        fields={fields}
                                        tableFilter
                                        itemsPerPageSelect
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            priority: (item) => (
                                                <td>
                                                    <CBadge color={getBadge(item.priority)}>
                                                        {item.priority}
                                                    </CBadge>
                                                </td>
                                            ),
                                            show_details: (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            color="primary"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            onClick={() => {
                                                                toggleDetails(index);
                                                            }}
                                                        >
                                                            {details.includes(index) ? "Hide" : "Show"}
                                                        </CButton>
                                                    </td>
                                                );
                                            },
                                            details: (item, index) => {
                                                return (
                                                    <CCollapse show={details.includes(index)}>
                                                        <CCardBody>
                                                        <p className="text-muted">
                                                        MRF Type: {item.applying_for}
                                                        </p>
                                                            {isPermission('view_approved_requisition') &&
                                                            <CButton onClick={() => isViewModalOpen(item)}
                                                                size="sm"
                                                                color="primary"
                                                                className="ml-1"
                                                            >
                                                                View
                                                            </CButton>
                                                            }
                                                            {isPermission('approve_requisition_hr_manager') && <>
                                                            {item.is_approved_by_hr_manager==1?
                                                                <CButton  onClick={() => ApprovedMrfByManager(item)}
                                                                size="sm"
                                                                color="success"
                                                                className="ml-1"
                                                                disabled
                                                               
                                                            >
                                                                Approve
                                                            </CButton>:    <CButton  onClick={() => ApprovedMrfByManager(item)}
                                                                size="sm"
                                                                color="success"
                                                                className="ml-1"
                                                               
                                                            >
                                                                Approve
                                                            </CButton>
                                                            }
                                                            </>}
                                                        {isPermission('reject_requisition_hr_manager') &&
                                                            <CButton onClick={() => isJustificationModalOpen(item)}
                                                                size="sm"
                                                                color="danger"
                                                                className="ml-1"
                                                            >
                                                                Reject
                                                            </CButton>
                                                        }
                                                        </CCardBody>
                                                    </CCollapse>
                                                );
                                            },
                                        }}
                                    />
                                    {/* <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">ID</th>
                                        <th scope="col">Applying For</th>
                                        <th scope="col">MRF Raised By</th>
                                        <th scope="col">Head Of Department</th>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Priority</th>
                                        <th scope="col">Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mrfRecord && mrfRecord.map((mrf, index) => (

                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{mrf.id}</td>
                                            <td className="letter">{mrf.applying_for == "vacancy"
                                                ? "New Hiring" : mrf.applying_for}</td>

                                            <td>{mrf.created_by_name}</td>
                                          
                                            <td>{mrf.head_of_department}</td>
                                          <td>{mrf.designation}</td>
                                            <td>{mrf.priority}</td>
                                            <td>{
                                                <Moment format="YYYY/MM/DD">
                                                    {mrf.created_at}
                                                </Moment>}</td>

                                            <CRow>
                                                <CCol xs="6" md="6">
                                                    <CButton data-toggle="tooltip" data-placement="top" title="View" className="btn btn-info " onClick={() => isViewModalOpen(mrf)}><i className="fa fa-eye" aria-hidden="true"></i> </CButton>
                                                </CCol>
                                                <CCol xs="6" md="6">
                                                    <CButton data-toggle="tooltip" data-placement="top" title="Reject" className="btn btn-danger " onClick={() => isJustificationModalOpen(mrf)}><i className="fa fa-times" aria-hidden="true"></i> </CButton>
                                                </CCol>
                                            </CRow>
                                        </tr>
                                    ))}

                                </tbody>
                            </table> */}
                                </CTabPane>
                                <CTabPane>
                                    <CDataTable
                                        items={rejectedMrfRecord && rejectedMrfRecord.length > 0 && rejectedMrfRecord.filter(mrf => {
                                            // console.log(asset.is_assigned)
                                           
                                            // mrf.created_at = moment(mrf.created_at).format("DD-MM-YYYY")
                                            // if (mrf.applying_for == "vacancy") {
                                            //     mrf.applying_for = "New Hiring"
                                            // }
                                            return mrf
                                        })}
                                        fields={fields}
                                        // columnFilter
                                        tableFilter
                                        // footer
                                        itemsPerPageSelect
                                        itemsPerPage={5}
                                        hover
                                        sorter
                                        pagination
                                        scopedSlots={{
                                            priority: (item) => (
                                                <td>
                                                     <CBadge color={getBadge(item.priority)}>
                                                        {item.priority}
                                                    </CBadge>
                                                </td>
                                            ),
                                            show_details: (item, index) => {
                                                return (
                                                    <td className="py-2">
                                                        <CButton
                                                            color="primary"
                                                            variant="outline"
                                                            shape="square"
                                                            size="sm"
                                                            onClick={() => {
                                                                toggleDetails(index);
                                                            }}
                                                        >
                                                            {details.includes(index) ? "Hide" : "Show"}
                                                        </CButton>
                                                    </td>
                                                );
                                            },
                                            details: (item, index) => {
                                                return (
                                                    <CCollapse show={details.includes(index)}>
                                                        <CCardBody>
                                                            <h4>{item.username}</h4>
                                                            <p className="text-muted">
                                                                Remark: {item.hr_rejection_comment}
                                                            </p>
                                                            <p className="text-muted">
                                                                MRF Type: {item.applying_for}
                                                            </p>
                                                            {isPermission('view_approved_requisition') &&
                                                            <CButton onClick={() => isViewModalOpen(item)}
                                                                size="sm"
                                                                color="primary"
                                                                className="ml-1"
                                                            >
                                                                View
                                                            </CButton>
                                                            }
                                                        </CCardBody>
                                                    </CCollapse>
                                                );
                                            },
                                        }}
                                    />
                                    {/* <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">ID</th>
                                        <th scope="col">Applying For</th>
                                        <th scope="col">Head Of Department</th>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Priority</th>
                                        <th scope="col">Created At</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {rejectedMrfRecord && rejectedMrfRecord.map((mrf, index) => (
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{mrf.id}</td>
                                            <td className="letter">{mrf.applying_for == "vacancy"
                                                ? "New Hiring"
                                                : "Replacemrnt"}</td>


                                           
                                            <td>{mrf.head_of_department}</td>
                                            <td>{mrf.designation}</td>

                                            <td>{mrf.priority}</td>
                                            <td>{
                                                <Moment format="YYYY/MM/DD">
                                                    {mrf.created_at}
                                                </Moment>}</td>

                                            <CRow>
                                                <CCol xs="12" md="12">
                                                    <CButton data-toggle="tooltip" data-placement="top" title="view" className="btn btn-info " onClick={() => isViewModalOpen(mrf)}><i className="fa fa-eye" aria-hidden="true" ></i> </CButton>
                                                </CCol>

                                            </CRow>
                                        </tr>
                                    ))}
                                </tbody>
                            </table> */}

                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    </CCardBody>
                </CCard>
                <ModalPopupReceivedRequisition isModalOpen={isModalOpen} message={"Received Requisition"}
                    isViewModalOpen={isViewModalOpen}
                    approvedRequisition={receivedRequisition}
                    mrfReceivedforHrManager={mrfReceivedforHrManager} />

                <JustificationModal
                    isOpenJustification={isOpenJustification}
                    isJustificationModalOpen={isJustificationModalOpen}
                    rejectMrf={rejectMrf} />
            </div>
            <ToastContainer />
            <Alert isAlert={isAlert} isAlertToggle={isAlertToggle} alertMessage={alertMessage} isAlertToggleClose={isAlertToggleClose} />
        </div>

    )
}
export default ReceivedCondidate







