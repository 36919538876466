import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CForm,
  CFormGroup,
  CCol,
  CLabel,
} from '@coreui/react';
import DateRangePicker from 'src/views/common/dateRangePicker';
import { CustButton } from 'src/reusable';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import moment from 'moment';
import { isPermission } from 'src/utils/common';
const CallReports = props => {
  let api = new API();
  const { open, handleCancel, setReportModalOpen } = props;
  const [callEventsList, setCallEventsList] = useState(null);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [recruiterList, setRecruiterList] = useState();
  const [recruitername, setRecruitername] = useState('');
  const [reportType, setReportType] = useState('');

  useEffect(() => {
    getCallEventsList();
    getRecruiter();
  }, []);

  //
  const getCallEventsList = async () => {
    let data = {
      type: '',
    };
    let result = await api.get(config.getCallFeedbackEvents, true, data);
    if (result && result.code === 200) {
      setCallEventsList(result.data);
    } else {
    }
  };

  const getRecruiter = async () => {
    let result = await api.get(config.recruiterList);
    if (result && result.code === 200) {
      setRecruiterList(result.data);
    } else {
    }
  };

  // download report
  const downloadRecruiterReport = async () => {
    let data = {
      start_date: startDate,
      end_date: endDate,
      report_type: reportType,
      type: recruitername,
    };
    let result = await api.post(config.generateRecruiterCountLog, data);
    if (result && result.code === 200) {
      window.open(result.data.filePath, '_blank');
      toast.success(result.message);
      setReportModalOpen(false);
    } else {
    }
  };

  return (
    <div className="">
      <CModal show={open} onClose={handleCancel} size="lg">
        <CModalHeader closeButton>
          <CModalTitle>Export Reports</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <CForm
              action=""
              encType="multipart/form-data"
              className="form-horizontal"
            >
              <CFormGroup row>
                <CCol md="4">
                  <CLabel>
                    Select Date Range <span className="required-field">*</span>
                  </CLabel>
                </CCol>

                <CCol xs="12" md="8">
                  <DateRangePicker
                    xs="12"
                    md="8"
                    DateStart={setStartDate}
                    DateEnd={setEndDate}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="4">
                  <CLabel>
                    Select Report <span className="required-field">*</span>
                  </CLabel>
                </CCol>
                <CCol xs="12" md="8">
                  <select
                    type="select"
                    value={reportType}
                    onChange={e => setReportType(e.target.value)}
                    name="feedback_id"
                    placeholder=""
                    className="form-control"
                    required={true}
                  >
                    <option hidden>Select Report......</option>
                    {isPermission('call_summary') && 
                    <option value="RecruiterCallSummry">
                      Recruiter Call Summary
                    </option>
                    }
                    {isPermission('call_history') &&
                    <option value="RecruiterCallHistory">
                      Recruiter Call History
                    </option>
                    }
                  </select>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="4">
                  <CLabel>
                    Select Recruiter <span className="required-field">*</span>
                  </CLabel>
                </CCol>
                <CCol xs="12" md="8">
                  <select
                    type="select"
                    value={recruitername}
                    onChange={e => setRecruitername(e.target.value)}
                    name="feedback_id"
                    placeholder=""
                    className="form-control"
                    required={true}
                  >
                    <option hidden>Select Recruiter......</option>
                    <option value="all">All Recruiters</option>
                    {recruiterList &&
                      recruiterList.map(recruiter => (
                        // eslint-disable-next-line react/jsx-key
                        <option value={recruiter.id}>
                          {recruiter.first_name + ' ' + recruiter.last_name}
                        </option>
                      ))}
                  </select>
                </CCol>
              </CFormGroup>
            </CForm>
          </div>
        </CModalBody>

        <CModalFooter>
          <CustButton
            type="button"
            name="ok"
            text="Export"
            color="primary"
            onClick={downloadRecruiterReport}
            className="btn-loading btn-ladda mr-3"
            icon="fa-download"
            isDisabled={
              startDate && endDate && reportType && recruitername ? false : true
            }
          />
        </CModalFooter>
      </CModal>
      <ToastContainer />
    </div>
  );
};
export default CallReports;
