
import API from "src/utils/apiCalling";
import { config } from "src/utils/apiUrl";
import whatsAppConstant from '../utils/constantWhatsApp'

export const testWhatsappMessage = async (message) => {
  const api = new API()
  let panel_role = await (localStorage.getItem('user'))
  let data = {
    number: "91" + JSON.parse(panel_role)[0].mobile,
    type: "text",
    message: message,
    instance_id: whatsAppConstant.whatsappCredentials.instance_id,
    access_token: whatsAppConstant.whatsappCredentials.access_token,
  }
  // console.log("result whatsapp data is----------------------", data)
  // return
  let result = await api.WhatsAppPost(config.whatsAppApiSendMessage, true, data);
  if (result && result.status == "success") {
    // console.log("result is", result)
  } else {
    // console.log("result is not success", result)
  }
};


export const sendWhatsappMessage = async (message) => {
  // alert("insdie messagebird")
  const api = new API()
  let panel_role = await (localStorage.getItem('user'))
  let data = {
    to: "91" + JSON.parse(panel_role)[0].mobile,
    from: whatsAppConstant.whatsappCredentialsMessageBird.channel_id,
    type: "text",
    content: JSON.stringify({
      "text": message,
      "disableUrlPreview": false
    })
  }
  // console.log("result whatsapp data is----------------------", data)
  // return
  let result = await api.WhatsAppPostMessageBirdPost(config.whatsAppApiSendMessage2, data, whatsAppConstant.whatsappCredentialsMessageBird.Authorization);
  if (result && result.status == "accepted") {
    // console.log("result is for messagegrid whatsapp api", result)
  } else {
    // console.log("result is not success from messagegrid whatsapp", result)
  }
};

export const sendWhatsappMessageTemplate = async (message, params, templateName) => {
  // alert("insdie messagebird")
  const api = new API()
  let panel_role = await (localStorage.getItem('user'))
  let data = {
    mobile: "91" + JSON.parse(panel_role)[0].mobile,
    templateName: templateName,
    params: JSON.stringify(params)
    }
  // console.log("result whatsapp data is----------------------", data)
  // return
  let result = await api.post(config.whatsAppApiSendMessage2Template, data);
  if (result && result.status == "accepted") {
    // console.log("result is for messagegrid whatsapp api", result)
  } else {
    // console.log("result is not success from messagegrid whatsapp", result)
  }
};

export const sendWhatsappMessageTemplateMedia = async (message, params, templateName) => {
  // alert("insdie messagebird")
  const api = new API()
  let panel_role = await (localStorage.getItem('user'))
  let data = {
    mobile: "91" + JSON.parse(panel_role)[0].mobile,
    templateName: templateName,
    params: JSON.stringify(params)
    }
  // console.log("result whatsapp data is----------------------", data)
  // return
  let result = await api.post(config.whatsAppApiSendMessage2Template, data);
  if (result && result.status == "accepted") {
    // console.log("result is for messagegrid whatsapp api", result)
  } else {
    // console.log("result is not success from messagegrid whatsapp", result)
  }
};

export const updateTicketStatus=async (id,message)=>{
  const api=new API()
  let data={
    "ticket_id":id,
    "ticket_update_message":message
  }
  let result =await api.post(config.ticketUpdate,data)
  

}
export const getTicketHistoryById=async (id)=>{
  const api=new API()
  let data={
    "ticket_id":id
  }
  let result=await api.get(config.getTicketHistory,true,data)
  if(result.code===200){
    // console.log("ticket history",result)
    return result.data
  }
}
export const getHelpTopicOptions = async() => {
  const api=new API()

  let result = await api.get(config.all_help_topic)
  if(result && result.code === 200){
    return result.data
  }else{
    alert("Help topic API issue")
  }
}
export const getIssueTypeOptions = async(data) => {  
  const api=new API()

  let result = await api.get(config.get_all_ticket_issue_names,true,data)

  if(result && result.code === 200){
    return result.data
   
  }else{
    alert("Issue Type API issue")

  }
}
export const getAllSubIssueTypeOption=async(data)=>{
  const api=new API()

  let result=await api.get(config.get_all_ticket_issue_subtype_names,true,data)
  // console.log("subTYpe",result)
  if(result&&result.code===200){
   return result.data
  }else{
    alert("Sub Issue Type API issue")

  }
}
export const getAllEmployee = async () => {
  const api=new API()
  const result = await api.get(config.getAllEmployeeList)
  if (result && result.code === 200) {
      let watchers = result.data && result.data.length > 0 && result.data.filter((emp) => (
          emp.account_status === "active"
      ))
      let watchersArr = []
      watchers.map((item) => (
          watchersArr.push({ label: item.first_name, value: item.first_name, id: item.id })
      ))
      return watchersArr
  }


}
export const getCheckInCheckOutHistory=async (id,date)=>{
  let data={
    emp_user_id:id,
    date:date
  }
  const api=new API()
  const result=await api.get(config.get_attendance,true,data)
  console.log("history77",result)
  if(result&&result.code===200){
    return result.data
  }
}