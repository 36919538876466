import { CButton, CModal, CModalBody } from '@coreui/react';
import { useState } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import JustificationModal from 'src/views/ASSETS MANAGEMENT/justificationModal';
import swal from 'sweetalert';
import { toast, ToastContainer } from 'react-toastify';

const AssetsBtn = ({ item, reloadTasBox }) => {
  const api = new API();
  const [isOpenJustification, setIsOpenJustification] = useState(false);
  const [assetsDone, setAssetsDone] = useState(false);
  const [assetRequestData, setAssetRequestData] = useState();

  const approveReject = async (Data, status, message) => {
    let data = {
      id: Data && Data.id,
      status: status,
      comment: message,
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `Are you sure`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Ok'],
      dangerMode: true,
    }).then(async function(isConfirm) {
      if (isConfirm) {
        if (!message) {
          delete data.comment;
        }
        let result = await api.put(config.updateAssetReqStatus, data);
        if (result && result.code == 200) {
          setIsOpenJustification(false);
          setAssetsDone(!assetsDone);
          toast.success(result?.message);
          reloadTasBox();
        }
      }
    });
  };

  const isJustificationModalOpen = data => {
    setAssetRequestData(data);
    setIsOpenJustification(!isOpenJustification);
  };

  return (
    <>
      <div className="dropdown-menu">
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            approveReject(item, 2, '');
          }}
        >
          Approve
        </CButton>
        <div className="dropdown-divider"></div>
        <CButton
          color="secondary"
          className="dropdown-item"
          onClick={() => {
            isJustificationModalOpen(item);
          }}
        >
          Reject
        </CButton>
      </div>
      
      <JustificationModal
        isOpenJustification={isOpenJustification}
        isJustificationModalOpen={isJustificationModalOpen}
        assetRequestData={assetRequestData}
        FunCalling={approveReject}
      />
      <ToastContainer />
    </>
  );
};

export default AssetsBtn;
