import React, { useState } from 'react'
import API from 'src/utils/apiCalling'
import { config } from 'src/utils/apiUrl'
import {

  CModal,

  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,

  CButton,


} from '@coreui/react'

const ModalForEditSubInvestment = (props) => {
    const{isViewEditModalOpen,isEditOpen,subtypeInvestment}=props
    const[subTypeInvestment,setSubTypeInvestment]=useState({
        "subCategoriesName":subtypeInvestment&&subtypeInvestment.subCategoriesName,
        "isMaxLimit":subtypeInvestment&&subtypeInvestment.isMaxLimit,
        "maxLimit":subtypeInvestment&&subtypeInvestment.maxLimit*1
    })
    console.log("jsdjjdada",subtypeInvestment)
  return (
    <CModal
      show={isEditOpen}
      onClose={() => isViewEditModalOpen(!isEditOpen)}
      size="lg"
    >
      <CModalHeader closeButton>
        <CModalTitle><strong>Edit Investment</strong></CModalTitle>
      </CModalHeader>
      <CModalBody>
      <form>
  <div class="form-group row">
    <label for="name" className="col-sm-2 col-form-label">Name of Investment</label>
    <div className="col-sm-10">
      <input type="text" className="form-control" id="name" value={subTypeInvestment.subCategoriesName} placeholder="Sub type Investment.."/>
    </div>
  </div>
  <div className="form-group row">
    <label for="maxLimit" className="col-sm-2 col-form-label">Amount</label>
    <div className="col-sm-10">
      <input type="number" className="form-control" id="maxLimit" value={subTypeInvestment.maxLimit} placeholder="Max Amount"/>
    </div>
  </div>
  <fieldset className="form-group">
    <div className="row">
      <legend className="col-form-label col-sm-2 pt-0">Max Limit Exit</legend>
      <div className="col-sm-10">
        <div className="form-check">
          <input className="form-check-input" type="radio" name="isMaxLimit" id="isMaxLimit1" value="yes" 
        //   checked={subtypeInvestment.isMaxLimit===true?true:false}
          />
          <label className="form-check-label" for="isMaxLimit1">
            Yes
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" name="isMaxLimit" id="isMaxLimit2" value="no"
        //    checked={subtypeInvestment.isMaxLimit===false?true:false}
           />
          <label className="form-check-label" for="isMaxLimit2">
            NO
          </label>
        </div>
        
    </div>
    </div>
  </fieldset>
 
  {/* <div className="form-group row">
    <div className="col-sm-10">
      <button type="submit" className="btn btn-primary">Sign in</button>
    </div>
  </div> */}
</form>
     



      </CModalBody>
      <CModalFooter>
        <CButton color="success" onClick={() => isViewEditModalOpen(!isEditOpen)}>Close</CButton>{' '}
        

 
       
         <CButton type="button" className="btn btn-primary">Submit</CButton>

       

      </CModalFooter>
    </CModal>
  )
}

export default ModalForEditSubInvestment