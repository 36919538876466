import React, { useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalTitle,
  CModalHeader,
  CModalFooter,
  CButton,
} from '@coreui/react';
import { convertDate } from 'src/utils/common';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast } from 'react-toastify';
import { sweetAlertFun, swalWithTextarea } from '../../src/common/sweetAlert';

const ViewLeaveDateModal = ({
  viewLaeveModal,
  handleLeaveModal,
  leaveData,
  getLeaveForApproval,
}) => {
  const api = new API();
  const [leaveIds, setLeaveIds] = useState();
  const [reason, setReason] = useState('');
  // const [selectAll, setSelectAll] = useState(false);
  const handleLeaveIds = (e, item) => {
    let newArr = leaveIds && leaveIds.length > 0 ? [...leaveIds] : [];
    if (e.target.checked) {
      newArr.push({ id: item?.id });
      setLeaveIds(newArr);
    } else {
      let arr =
        newArr &&
        newArr.length > 0 &&
        newArr.filter(leave => leave?.id != item?.id);
        setLeaveIds(arr);
    }
  };
  const approveAndRejectLeave = async type => {
    if (reason) {
      let payload = {
        type: 'leave',
        is_approved: type === 'approve' ? 'approve' : 'reject',
        id: 1, //id is fixed not in used
        comment: reason,
        correction_data: JSON.stringify(leaveIds),
      };

      let result = await api.post(config.approveRejectAttendance, payload);
      if (result && result.code === 200) {
        handleLeaveModal();
        getLeaveForApproval();
        setReason('');
        setLeaveIds([]);
        toast(result && result?.message);
      } else {
        toast(result && result?.message);
      }
    } else {
      alert('Please enter reason.');
    }
  };
  const handleCancelLeave = async data1 => {
    let data = {
      id: data1.id,
      is_approved: 'cancel',
      comment: ''
    }
    console.log(data);
    const swalRes = await swalWithTextarea(
      `Are you sure you want to cancel leave`,
      '',
      'Enter Reason',
    );
    data.comment = swalRes;
    let result = await api.post(config.remove_applied_leave, data);
    if (result && result.code === 200) {
      getLeaveForApproval();
      handleLeaveModal();
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
  };

  function allLeaveStatus(arr) {
    let status = '';
    let p = 0;
    let a = 0;
        for (let i = 0; i < arr.length; i++) {
      if (arr[i].is_approve_status === 0 && arr[i].is_rejected_status === 0 && arr[i].is_cancel === 0) {
        p++;
      } else {
        a++;
      }
    }
    if (arr.length === a) {
      return (status = 'Approved');
    } else {
      return (status = 'Pending');
    }
  }

  let status =
    leaveData &&
    allLeaveStatus(leaveData && leaveData?.leaveDateArray) === 'Approved'
      ? true
      : false;
  const closeModal = () => {
    handleLeaveModal();
    setReason('');
  };
  return (
    <div>
      <CModal
        show={viewLaeveModal}
        onClose={() => closeModal()}
        size="lg"
        style={{ marginTop: '70px' }}
      >
        <CModalHeader closeButton>
          <CModalTitle>Approve and Reject Leave(s)</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Leave Date</th>
                  <th scope="col">Leave Type</th>
                  <th scope="col">Leave Status</th>
                  <th scope="col">
                    {/* <input
                  type="checkbox"
                  onChange={() => setSelectAll(!selectAll)}
                ></input> */}
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {leaveData?.leaveDateArray.map((item, index) => (
                  <tr key={item?.id}>
                    <th scope="row">{index + 1}</th>
                    <th>{leaveData?.employee_name}</th>
                    <td>{convertDate(item?.leave_date, 'DD-MM-YYYY')}</td>
                    <td>{item?.leave_type}</td>
                    <td>{item?.leave_status}</td>
                    <td>
                      {item?.is_approve_status == 1 ? (
                        <i
                          className="fa fa-check"
                          style={{ color: 'green' }}
                          aria-hidden="true"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Approved"
                        ></i>
                      ) : item?.is_rejected_status == 1 ? (
                        <i
                          className="fa fa-times"
                          style={{ color: 'red' }}
                          aria-hidden="true"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Rejected"
                        ></i>
                      ) : item?.is_cancel == 1 ? (
                        <i
                          className="fa fa-close"
                          style={{ color: 'blue' }}
                          aria-hidden="true"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Cancelled"
                        ></i>
                      ) : (
                        <input
                          type="checkbox"
                          onChange={e => handleLeaveIds(e, item)}
                          // checked={selectAll}
                        ></input>
                      )}
                    </td>
                    <td>
                    {(item?.is_approve_status == 1 && item.is_cancel != 1) &&
                       <CButton
                       style={{backgroundColor:'royalblue', color: 'white'}}
                          onClick={() => {
                            handleCancelLeave(item);
                          }}
                        >
                          Cancel
                        </CButton>
                    }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <form>
              <div className="form-group">
                <label for="exampleFormControlTextarea1">Comment</label>
                <textarea
                  onChange={e => setReason(e.target.value)}
                  disabled={status}
                  value={reason}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </form>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            disabled={status}
            className="btn btn-primary"
            onClick={() => approveAndRejectLeave('approve')}
          >
            Approve
          </CButton>
          <CButton
            disabled={status}
            onClick={() => approveAndRejectLeave('reject')}
            className="btn btn-danger"
          >
            Reject
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default ViewLeaveDateModal;
