import { toast } from 'react-toastify';

import { getLocalDataAsObject } from './CoustomStorage';
import axios from 'axios';
var messagebird = require('messagebird')('90FCaSwxf2CfgCtjdpdgTiFfc'); //live
// var messagebird = require('messagebird')('OpRCm8tINuyywsxIP1EV02Lq9');   //test

class API {
  //
  async get(url, haveParams = false, params = {}) {
    try {
      const headers = new Headers();
      headers.set('Content-Type', 'application/x-www-form-urlencoded');
      headers.set('x-auth-token', await this.getToken());

      const request = await fetch(
        `${url}${haveParams ? `?${new URLSearchParams(params)}` : ''}`,
        {
          method: 'GET',
          headers,
        },
      );
      const response = await request.json();
      return response;
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  }

  async post(url, body) {
    const data = Object.keys(body)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);
      })
      .join('&');
    try {
      const headers = new Headers();
      headers.set('Content-Type', 'application/x-www-form-urlencoded');
      headers.set('x-auth-token', await this.getToken());

      const request = await fetch(url, {
        method: 'POST',
        headers,
        body: data,
      });

      const response = await request.json();
      // console.log("ffffff", response);
      return response;
    } catch (error) {
      toast.warning('Please check your internet connectivity');
    }
  }

  async put(url, body) {
    const data = Object.keys(body)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);
      })
      .join('&');

    try {
      const headers = new Headers();
      headers.set('Content-Type', 'application/x-www-form-urlencoded');
      headers.set('x-auth-token', await this.getToken());

      const request = await fetch(url, {
        method: 'PUT',
        headers,
        body: data,
      });
      const response = await request.json();
      return response;
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  }

  //
  async delete(url, body) {
    try {
      const headers = new Headers();
      headers.set('Content-Type', 'application/json');

      headers.set('x-auth-token', await this.getToken());

      const request = await fetch(url, {
        method: 'DELETE',
        headers,
        body: JSON.stringify(body),
      });
      const response = await request.json();
      return response;
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  }
  async getToken() {
    const token = (await getLocalDataAsObject('token')) || '';

    return token;
  }
  
  async putFileFun(url, formData, msgdisplay) {
    try {
      const configdata = {
        headers: {
          'content-type': 'multipart/form-data',
          'x-auth-token': await this.getToken(),
        },
      };
      axios
        .put(url, formData, configdata)
        .then(response => {
          if (response.data.error === false) {
            msgDisplayFunc(msgdisplay, response.data.error, response.data.msg);
            return response;
          } else {
            msgDisplayFunc(msgdisplay, response.data.error, response.data.msg);
            return response;
          }
        })
        .catch(error => {});
    } catch (error) {
      return {
        success: false,
        error,
      };
      toast.warning('Please check your internet connectivity');
    }
  }

//
async putPostFileFun(url, formData, msgdisplay) {
    try {
      const configdata = {
        headers: {
          'content-type': 'multipart/form-data',
          'x-auth-token': await this.getToken(),
        },
      };
      let response = await axios.post(url, formData, configdata);
      if (response) {
        if (response.data.error === false) {
          msgDisplayFunc(msgdisplay, response.data.error, response.data.msg);
          return response.data;
        } else {
          msgDisplayFunc(msgdisplay, response.data.error, response.data.msg);
          return response.data;
        }
      }
    } catch (error) {
      return {
        success: false,
        error,
      };
      toast.warning('Please check your internet connectivity');
    }
  }

  //
  async postUpload(url, file) {
    const headers = new Headers();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('x-auth-token', await this.getToken());
    try {
      const request = await fetch(url, {
        method: 'post',
        body: file,
        headers,
      });
      const response = await request.json();
      return response;
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  }

  async WhatsAppPost(url, haveParams = false, params = {}) {
    try {
      const headers = new Headers();
      headers.set('Content-Type', 'application/x-www-form-urlencoded');
      headers.set('x-auth-token', await this.getToken());

      const request = await fetch(
        `${url}${haveParams ? `?${new URLSearchParams(params)}` : ''}`,
        {
          method: 'POST',
          headers,
        },
      );
      const response = await request.json();
      return response;
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  }
}

export const msgDisplayFunc = (msgdisplay, error, message) => {
  if (msgdisplay === true) {
    if (error === false) {
      toast.success(message);
      return 0;
    } else {
      toast.warning(message);
      return 0;
    }
  } else {
    return 0;
  }
};

export default API;
