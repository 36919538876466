import React, { useState, useEffect } from 'react';
import { TheFooter, TheHeader, TheSidebar } from 'src/containers';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import Loader from 'src/views/common/loader'
import './taskbox.css';
import moment from 'moment';
import ActionBtn from './ActionBtn';
import { isNull } from 'lodash';
import { Base_URI } from 'src/utils/apiUrl';
const DashBoard = () => {
  const [dynamicTabs, setDynamicTabs] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [tabContentList, setTabContentList] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [taskLoadingState, setTaskLoadingState] = useState(false);
  const api = new API();

  const taskListFun = async () => {
    let result = await api.get(config.getTaskBox);
    if (result && result.code === 200) {
      setDynamicTabs(result?.data?.tabs);
      if (result?.data?.tabs && result?.data?.tabs?.length) {
          callTaskApI(result.data.tabs[0]);
          setLoadingState(false);
      }
    }
  };
  const reloadTaskBox = async () => {
    let result = await api.get(config.getTaskBox);
    if (result && result.code === 200) {
      setDynamicTabs(result?.data?.tabs);
    }
  };

  const callTaskApI = async (tabs) => {
    console.log("tabs", tabs);
    setTaskLoadingState(true);
    let data = {
      type:tabs?.object_key
    }
    if(tabs?.is_sub_categories){
      data['subtype']=tabs.sub_categories[0]?.query_type;
    }
    setTabContentList([]);
    let result = await api.get(config.getTaskBox, true, data);
      if(result && result?.code===200){
        setTabContentList(result?.data?.values[tabs?.object_key]);
        setTaskLoadingState(false);
      }
    }
    
    const callSubTaskApI = async (subtype) => {
      setTaskLoadingState(true);
      let result = await api.get(config.getTaskBox, true, {type:'', subtype: subtype?.query_type});
        if(result && result?.code===200){
          setTabContentList(result?.data?.values[subtype?.object_key]);
          setTaskLoadingState(false);
        }
      }

  const getDataForBody = async () => {
    let result = await api.get(config.all_HRMS_status_details);
    if (result && result.code == 200) {
      setBodyData(result && result.data);
    }
  };

  const renderTab = () => {
    if(dynamicTabs?.length === 0){
      return null
    }
    return (
      dynamicTabs?.length > 0 &&
      dynamicTabs.map((tabs, index) => (
        <>
          {index === 0 ? (
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                id={index}
                href={`#${tabs.object_key}`}
                onClick={e => callTaskApI( tabs)}
              >
                <div className="iconGroup">
                  <img src={`../images/taskbox/${tabs.image}`} alt="" />
                </div>
                <div className="txtGroup">
                  <strong>{tabs?.title}</strong>
                  {tabs?.is_sub_categories !== 0 ? (
                    <div className="d-flex">
                      {tabs?.sub_categories?.length &&
                        tabs.sub_categories.map((subTab, index) => (
                          <>
                            {subTab.len !== 0 && (
                              <div>
                                <span>{subTab?.title}</span>
                                <b>{subTab?.len}</b>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  ) : (
                    <b>{tabs?.total_length}</b>
                  )}
                </div>
              </a>
            </li>
          ) : (
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                id={index}
                href={`#${tabs.object_key}`}
                onClick={e => callTaskApI( tabs)}
              >
                <div className="iconGroup">
                  <img src={`../images/taskbox/${tabs.image}`} alt="" />
                </div>
                <div className="txtGroup">
                  <strong>{tabs?.title}</strong>
                  {tabs?.is_sub_categories !== 0 ? (
                    <div className="d-flex">
                      {tabs?.sub_categories?.length &&
                        tabs.sub_categories.map((subTab, index) => (
                          <>
                            {subTab.len !== 0 && (
                              <div>
                                <span>{subTab?.title}</span>
                                <b>{subTab?.len}</b>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  ) : (
                    <b>{tabs?.total_length}</b>
                  )}
                </div>
              </a>
            </li>
          )}
        </>
      ))
    );
  };


  const renderTabsDiv = () => {
    if(dynamicTabs?.length === 0){
      return <b className='text-danger'>No Task Available</b>
    }
    return (
      dynamicTabs?.length > 0 &&
      dynamicTabs?.map((tabs, index) => {
        return (
          <>
                    <div
                className={`tab-pane fade ${index===0 ? 'show active' : ''}`}
                id={tabs.object_key}
                role="tabpanel"
                key={index}
              >
                <TabContentData tabs= {tabs} tabContentList={tabContentList}
                bodyData={bodyData} reloadTaskBox={reloadTaskBox} callSubTaskApI={callSubTaskApI}
                />
                {taskLoadingState === true &&<div style={{alignItems:'left'}}> <Loader /></div>}
              </div>
          </>
        );
      })
    );
  }

  useEffect(() => {
    getDataForBody();
    taskListFun();
  }, []);

  return (
    <>
      <div className="c-app c-default-layout">
        <TheSidebar />
        <div className="c-wrapper">
          <TheHeader />
          <div className="container mt-3">
            <div className="card border-0">
              <div className="card-body">
                <div className="name">
                  <h4>
                    {' '}
                    <strong>Overview</strong>
                  </h4>
                  <hr />
                  <ul className="nav nav-tabs mainTabGroup">
                    {renderTab()}</ul>
                  <div className="tab-content">
                 {renderTabsDiv()}
              </div>      
                </div>
              </div>
            </div>
            {loadingState === true &&<div style={{alignItems:'center'}}> <Loader /></div>}
          </div>
          <script
            src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js"
            type="text/javascript"
          ></script>
          <script
            src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js"
            type="text/javascript"
          ></script>
          <script
            src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js"
            integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
            crossOrigin="anonymous"
          ></script>
          <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
        </div>
      </div>
      <TheFooter />
      
    </>
  );
};
function TabContentData (props){
  const {bodyData, reloadTaskBox, callSubTaskApI} = props;
  let tabs = props.tabContentList;
  let val = props.tabs.is_sub_categories;
  let subCategories = props.tabs.sub_categories;
  return(<> { val===1 ? (<> <ul className="nav nav-tabs innerTab">
                      {subCategories?.length>0 &&
                        subCategories.map((subTab, index1) => { return(
                          <>
                            {subTab.len !== 0 && (
                              <>
                                {index1 === 0 ? (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link active"
                                      data-toggle="tab"
                                      href={`#${subTab.query_type}`}
                                      onClick={e=>callSubTaskApI( subTab)}
                                    >
                                      {subTab?.title}
                                    </a>
                                  </li>
                                ) : (
                                  <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      data-toggle="tab"
                                      href={`#${subTab.query_type}`}
                                      onClick={e=>callSubTaskApI( subTab)}
                                    >
                                      {subTab?.title}
                                    </a>
                                  </li>
                                )}
                              </>
                            )}
                          </>
)})}
                    </ul>
                    <div className="tab-content">
                      {subCategories?.length>0 &&
                        subCategories.map((subTab, index2) => { return(
                          <>
                            {index2 === 0 ? (
                              <>
                                <div
                                  className="tab-pane fade show active"
                                  id={subTab.query_type}
                                  key={index2}
                                >
                                  <div className="table-responsive taskTable">
                                    <table className="table">
                                      {tabs.length &&
                                        tabs.map(
                                          (data, index3) => (
                                            <>
                                              {subTab.query_type ===
                                                data.sub_name && (
                                                <tr>
                                                  <td>
                                                    <table className="w-100">
                                                      <tr>
                                                        <td width="70%">
                                                          <p>
                                                            {data?.query_type}
                                                          </p>
                                                          <div className="d-flex profileDiv">
                                                            <img
                                                              src={
                                                                data?.emp_img !==
                                                                  '' &&
                                                                !isNull(
                                                                  data?.emp_img,
                                                                )
                                                                  ? `${Base_URI}/employee_profile_picture/${data?.emp_img}`
                                                                  : `../images/logo.png`
                                                              }
                                                              alt=""
                                                              style={{
                                                                height: '35px',
                                                                width: '35px',
                                                              }}
                                                            />
                                                            <b>
                                                              {data?.emp_name}
                                                            </b>
                                                          </div>
                                                        </td>
                                                        {/* <td>
                                                  <strong>Priority</strong>
                                                  <span className="text-danger">Urgent</span>
                                                </td> */}
                                                        <td width="15%">
                                                          <strong>
                                                            Generated on
                                                          </strong>
                                                          <span>
                                                            {moment(
                                                              data?.created_at,
                                                            ).format(
                                                              'DD-MM-YYYY HH:mm A',
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td
                                                          className="text-center"
                                                          width="15%"
                                                        >
                                                          {tabs?.object_key !==
                                                            'interview_round' && (
                                                            <ActionBtn
                                                              item={data}
                                                              bodyData={
                                                                bodyData
                                                              }
                                                              reloadTaskBox={
                                                                reloadTaskBox
                                                              }
                                                              tabs={tabs}
                                                            />
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          ),
                                        )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="tab-pane fade"
                                  id={subTab.query_type}
                                  key={index2}
                                >
                                  <div className="table-responsive taskTable">
                                    <table className="table">
                                      {tabs?.length &&
                                        tabs.map(
                                          (data, index3) => (
                                            <>
                                              {subTab.query_type ===
                                                data.sub_name && (
                                                <tr>
                                                  <td>
                                                    <table className="w-100">
                                                      <tr>
                                                        <td width="70%">
                                                          <p>
                                                            {data?.query_type}
                                                          </p>
                                                          <div className="d-flex profileDiv">
                                                            <img
                                                              src={
                                                                data?.emp_img !==
                                                                  '' &&
                                                                !isNull(
                                                                  data?.emp_img,
                                                                )
                                                                  ? `${Base_URI}/employee_profile_picture/${data?.emp_img}`
                                                                  : `../images/logo.png`
                                                              }
                                                              alt=""
                                                              style={{
                                                                height: '35px',
                                                                width: '35px',
                                                              }}
                                                            />
                                                            <b>
                                                              {data?.emp_name}
                                                            </b>
                                                          </div>
                                                        </td>
                                                        {/* <td>
                                                  <strong>Priority</strong>
                                                  <span className="text-danger">Urgent</span>
                                                </td> */}
                                                        <td width="15%">
                                                          <strong>
                                                            Generated on
                                                          </strong>
                                                          <span>
                                                            {moment(
                                                              data?.created_at,
                                                            ).format(
                                                              'DD-MM-YYYY HH:mm A',
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td
                                                          className="text-center"
                                                          width="15%"
                                                        >
                                                          {tabs?.object_key !==
                                                            'interview_round' && (
                                                            <ActionBtn
                                                              item={data}
                                                              bodyData={
                                                                bodyData
                                                              }
                                                              reloadTaskBox={
                                                                reloadTaskBox
                                                              }
                                                              tabs={tabs}
                                                            />
                                                          )}
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          ),
                                        )}
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )})}
                    </div></>) : (<><div className="table-responsive taskTable">
                      <table className="table">
                        {tabs?.length>0  &&
                          tabs.map((data1, index) => { return(
                            <>
                                <tr>
                                  <td>
                                    <table className="w-100">
                                      <tr>
                                        <td width="70%">
                                          <p>{data1?.query_type}</p>
                                          <div className="d-flex profileDiv">
                                            <img
                                              src={
                                                data1?.emp_img !== '' &&
                                                !isNull(data1?.emp_img)
                                                  ? `${Base_URI}/employee_profile_picture/${data1?.emp_img}`
                                                  : `../images/logo.png`
                                              }
                                              alt=""
                                              style={{
                                                height: '35px',
                                                width: '35px',
                                              }}
                                            />
                                            <b>{data1?.emp_name}</b>
                                          </div>
                                        </td>
                                        <td width="15%">
                                          <strong>Generated on</strong>
                                          <span>
                                            {moment(data1?.created_at).format(
                                              'DD-MM-YYYY HH:mm A',
                                            )}
                                          </span>
                                        </td>
                                        <td className="text-center" width="15%">
                                          {tabs?.object_key !==
                                            'interview_round' && (
                                            <ActionBtn
                                              item={data1}
                                              bodyData={bodyData}
                                              reloadTaskBox={reloadTaskBox}
                                              tabs={tabs}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>  
                            </>)}
                          )}
                      </table>
                    </div> </>)} </>);

}
export default DashBoard;
