import React, { useState, lazy, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import {
  CBadge, CNav,
  CInputRadio,
  CNavItem,
  CNavbar,
  CListGroup, CListGroupItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
  CButton,
  CTextarea,
  CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
  CWidgetBrand,
  CButtonGroup,
  CWidgetIcon,
  CCard,
  CLink,
  CCardBody,
  CCardFooter,
  CInputFile,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CProgress,
  CProgressBar,
  CRow,
  CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
  CCallout
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { withRouter } from 'react-router-dom';
import OfferLater from '../../offer_letter/offerLatterCtc/offer_later'
import Ctc from '../../offer_letter/offerLatterCtc/ctc'
import swal from "sweetalert";
import { toast } from 'react-toastify';
const OfferLatterDetails = (props) => {
  let { getEmployeeDetailStatus, empStatus, empDetails,history } = props
  const api = new API()
 
  const [candidate, setCandidate] = useState(false)
  const [ctcResult, setCtcResult] = useState()


  useEffect(() => {
    getOnBoardingCtcOfferLatter()
  }, [])

  const getOnBoardingCtcOfferLatter = async () => {
    let data = {
      user_id: empDetails && empDetails.id
    }
    if (!data.user_id) {
      delete data.user_id
    }
    let result = await api.get(config.getCandidateCtcJoiningDetail, true, data)
    if (result && result.code == 200) {
      // console.log("succefull getting offer later status data", result.data)
      setCandidate(result.data[0])
      ctcCalculator(result.data[0].ctc)
    }
    else {
      // alert("worng")
    }
  }


  const ctcCalculator = async (ctc) => {
    let data = {
      ctc_amount:ctc
    }
    let result = await api.post(config.ctcGenerator, data)
    if (result && result.code == 200) {
      // console.log("ctc result is", result)

      setCtcResult(result.data)
    } else {
      // alert("something wrong")
    }
  }

  const OfferLetterDecline = async (event) => {
    // event.preventDefault()
    // setAccept(true)
    let data = {
      user_id: empDetails && empDetails.id
    }
    if (!data.user_id) {
      delete data.user_id
    }
    swal({
      html: true,
      title: "Are your sure to decline offer letter?",
      // text: `Are your Sure To Decline Offer Latter`,
      icon: "success",
      buttons: ["No, cancel it!", "Decline"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {

        let result = await api.post(config.declineOfferLetter, data)

        if (result && result.code === 200) {
          // alert("Offer Latter Declined")
          window.location.href = "/#/";
          localStorage.clear();
          sessionStorage.clear()
        }
        else {
          // alert("worng")
        }
      }
    })
  }

  const OfferLetterAccept = async (e) => {
    e.preventDefault()
    // setAccept(true)
    let data = {
      user_id: empDetails && empDetails.id
    }
    if (!data.user_id) {
      delete data.user_id
    }
    swal({
      html: true,
      title: "Are your sure to accept offer letter?",
      icon: "success",
      buttons: ["No, cancel it!", "Accept"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {

        let result = await api.post(config.acceptOfferLetter, data)
        if (result && result.code === 200) {
          toast.success("data submited successfully")
          // alert("data submited successfully")
          getEmployeeDetailStatus()
          // getURLroute()

        }
        else {
          // alert("worng")
        }
      }
    })
  }

  return (

    <div className="container-fluid">
      <div className="offer_latter_section text-center">
        <div className="card-body">
          {/* <OfferLater candidate={candidate} />
            <Ctc candidate={candidate} ctcResult={ctcResult} /> */}

          <h3>Offer letter has been sent to your registered email id.</h3>
          <h4 className="text-dark mt-3">Kindly proceed with your acceptance</h4><hr></hr>
          <strong>1. What will happen if I click the "Accept" button?</strong><br></br>
          <i>You have accepted this offer letter</i><br></br>
            <i>You will move to the next step where you need to submit necessary details and documents</i>

            <hr></hr>
            <strong>2. What will happen if I click the "Decline" button?</strong><br></br>
          <i>You have rejected this offer letter.</i><br></br>
            <i>This account will get disabled and you can't access this account anymore.</i>

        </div>
        {empStatus && empStatus.offer_letter_status == 1 ?
          <div>

          </div>
          :
          <div className="card-footer text-center">
            <button className="btn btn-primary m-3" onClick={(e)=>OfferLetterAccept(e)}>Accept</button>
            <button className="btn btn-danger m-3" onClick={OfferLetterDecline}>Decline</button>
            
          </div>
         

        }
      </div>
    </div>
  )
}
export default withRouter(OfferLatterDetails)
