import React, { useState } from "react";
import {

  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CButton,

} from "@coreui/react";
import swal from "sweetalert";
import OtpInput from 'react-otp-input';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { toast } from "react-toastify";

const AadharCardVerify = (props) => {

  const { isOpen1, isViewModalOpen1, processedVerifiedFun } = props;

  const [aadharNoEntered, setAadharNoEntered] = useState(false);
  const [aadharNumber, setAadharNumber] = useState();
  const [isMobileInAadharCard, setIsMobileInAadharCard] = useState(false);
  const [clientIdGetFormApi, setClientIdGetFormApi] = useState();
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  // const [otpNumberInput1, setOtpNumbersInput1] = useState();
  // const [otpNumberInput2, setOtpNumbersInput2] = useState();
  // const [otpNumberInput3, setOtpNumbersInput3] = useState();
  // const [otpNumberInput4, setOtpNumbersInput4] = useState();
  // const [otpNumberInput5, setOtpNumbersInput5] = useState();
  // const [otpNumberInput6, setOtpNumbersInput6] = useState();
  const [otp, setOtp] = useState()
  const [userVerifiedDetails, setUserVerifiedDetails] = useState();

  // let res = str1.concat(str2);
  // aadhar  otp generation api

  const generateAadharOTPbtn = async () => {
    swal({
      html: true,
      title: "Are you sure?",
      text: `Are you sure to submit details`,
      icon: "success",
      buttons: ["No, cancel it!", "Submit"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let data = {
          "id_number": aadharNumber
        }
        const response = await fetch("https://kyc-api.surepass.io/api/v1/aadhaar-v2/generate-otp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYzNTMzMzk2NywianRpIjoiMTQzNmY1MjQtMWY3Zi00MjQ2LWIzODgtYzY5MmRkZWI4NGE2IiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnFkZWdyZWVzQGFhZGhhYXJhcGkuaW8iLCJuYmYiOjE2MzUzMzM5NjcsImV4cCI6MTk1MDY5Mzk2NywidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInJlYWQiXX19.MaT1P2eR31wpoXKBwjG8yJkSmDGHzxDFb0zRCDaj7qg"
          },
          body: JSON.stringify(data)
        }).catch((error) => {
        });
        let result = await response.json();
        console.log("ihjdhjfjhihofdij", result);
        if (result && result?.data?.if_number == true && result?.data?.otp_sent == true) {
          setClientIdGetFormApi(result?.data?.client_id);
          setAadharNoEntered(true);
          setIsMobileInAadharCard(true);
        }
        //  if (result.data == null){
        //    setIsMobile(true);

        //   }
      }
    });
  };

  // aadhar  otp verify api

  const verifyAadharOTPbtn = async () => {
    swal({
      html: true,
      title: "Are you sure?",
      text: `Are you sure to submit details`,
      icon: "success",
      buttons: ["No, cancel it!", "Submit"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let otpNumbersConcate = otp;
        let data = {
          "client_id": clientIdGetFormApi,
          "otp": otpNumbersConcate
        }
        const response = await fetch("https://kyc-api.surepass.io/api/v1/aadhaar-v2/submit-otp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYzNTMzMzk2NywianRpIjoiMTQzNmY1MjQtMWY3Zi00MjQ2LWIzODgtYzY5MmRkZWI4NGE2IiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnFkZWdyZWVzQGFhZGhhYXJhcGkuaW8iLCJuYmYiOjE2MzUzMzM5NjcsImV4cCI6MTk1MDY5Mzk2NywidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInJlYWQiXX19.MaT1P2eR31wpoXKBwjG8yJkSmDGHzxDFb0zRCDaj7qg"
          },
          body: JSON.stringify(data)
        }).catch((error) => {
        });

        let result = await response.json();
        //   if (result && result.data.is_mobile == true){setIsMobile(true)}

        if (result && result.success == true) {
          toast.success(result && result.message_code)
          // alert(result && result.message_code)
          setUserVerifiedDetails(result.data)
          setIsMobileInAadharCard(false);
          setIsOtpVerified(true);
        } else {
          toast.error(result && result.message)
          // alert(result && result.message)
        }
      }
    });
  };

  const processedVerified = () => {
    processedVerifiedFun(true, userVerifiedDetails);
  }
  const handleChangeOTP = otp => {
    // alert(otp)
    setOtp(otp)
  }
  return (
    //   aadhar number enter screen start
    <div className="">

      {aadharNoEntered == false ? <>
        <div className="justify-content-center container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="head text-center">
                <div className="heading">
                  <span>Aadhaar verification Number</span>
                </div>
              </div>
              <div className="card1 text-center">
                <h5 className="m-0">
                  <img src="images\aadhar.svg" width="150px" />
                </h5>
                <span className="mobile-text">
                  You need to enter your Aadhaar number and click on proceed
                  Button
                </span>
                <div className="text-center m-5" style={{ margin: "auto" }}>

                  <div className="" style={{ width: "70%", margin:"auto" }}>
                    <label className="font-weight-bold m-2">
                      Aadhaar Number
                    </label>
                    <input style={{fontSize:"20px"}}
                      type="text"
                      className="form-control text-center"
                      
                      onChange={(e) => setAadharNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center">

                  <button type="button" className="aadhaar-btn" onClick={generateAadharOTPbtn} >
                    <i className="fa fa-download "></i>Proceed
                  </button>


                </div>
              </div>
            </div>
          </div>
        </div>
      </>
        :
        <></>}
      {/* //   aadhar number enter screen end */}
      {/* //   aadhar otp enter screen start */}
      {isMobileInAadharCard == true ? <>
        <div className="">
          <div className="justify-content-center container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="head text-center">
                  <div className="heading">
                    <span>Aadhaar Verification OTP</span>
                  </div>
                </div>
                <div className="card1 text-center">
                  <h5 className="m-0"><img src="images/password.png" width="150px" /></h5>
                  <span className="mobile-text">You need to enter OTP received on <b className="text-danger">Your Mobile Number</b> and click on proceed Button</span>
                  <div className="text-center m-2">
                    <b className="text-center">OTP</b>
                  </div>
                  {/* <div className="d-flex flex-row mt-5"> */}
                  {/* <input type="text" className="form-control" autofocus="" required onChange={(e) => { setOtpNumbersInput1(e.target.value) }} />
                    <input type="text" className="form-control" required onChange={(e) => { setOtpNumbersInput2(e.target.value) }} />
                    <input type="text" className="form-control" required onChange={(e) => { setOtpNumbersInput3(e.target.value) }} />
                    <input type="text" className="form-control" required onChange={(e) => { setOtpNumbersInput4(e.target.value) }} />
                    <input type="text" className="form-control" required onChange={(e) => { setOtpNumbersInput5(e.target.value) }} />
                    <input type="text" className="form-control" required onChange={(e) => { setOtpNumbersInput6(e.target.value) }} /> */}
                  <AvForm className="form-otp">
                    <OtpInput className="otp text-center"
                      value={otp}
                      onChange={handleChangeOTP}
                      numInputs={6}
                      isInputNum={true}
                    // separator={<span>-</span>}
                    />
                  </AvForm>
                  {/* </div> */}
                  <div className="text-center m-5">
                    <button type="button" className="aadhaar-btn" onClick={verifyAadharOTPbtn}>Verify OTP</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </> : <></>}
      {/* //   aadhar otp enter screen end */}
      {/* //    aadhar detail fatch screen  start */}
      {isOtpVerified == true ? <>
        <div className="">
          <div className="justify-content-center container-fluid">

            <div className="row">
              <div className="col-12">
                <div className="head text-center"><div className="heading"><span>Aadhaar Detalis</span></div></div>
                <div className="card1 text-center">

                  <h5 className="m-0"><img src="images/check.png" width="150px" /></h5>
                  <span className="mobile-text">Aadhaar Verification Detalis</span>
                  <div className="text-left m-5"></div>

                  <div className="main">
                    <div className="row">
                      <div className=" col-sm-4 text-left m-1">
                        <label className="font-weight-bold text-left m-2">Full Name</label><br />
                        <label className="font-weight-bold text-left m-2">Aadhaar Number</label><br />
                        <label className="font-weight-bold text-left m-2">Date of Birth </label><br />
                        <label className="font-weight-bold text-left m-2">Address</label><br />
                      </div>

                      <div className="col-sm-4 text-left m-1">
                        <label className="font-weight-bold text-left m-2">{userVerifiedDetails.full_name}</label><br />
                        <label className="font-weight-bold text-left m-2">{userVerifiedDetails.aadhaar_number}</label><br />
                        <label className="font-weight-bold text-left m-2">{userVerifiedDetails.dob}</label><br />
                        <label className="font-weight-bold text-left m-2">{userVerifiedDetails.address.landmark}, {userVerifiedDetails.address.loc}, {userVerifiedDetails.address.dist}, {userVerifiedDetails.address.state}-{userVerifiedDetails.zip}, {userVerifiedDetails.address.country}</label><br />
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button type="button" className="aadhaar-btn" onClick={processedVerified}><i className="fa fa-download"></i>Proceed</button>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </> : <></>}
      {/* //   aadhar detail fatch screen end */}


    </div>

  );
};

export default AadharCardVerify;
