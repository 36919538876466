/* eslint-disable no-lone-blocks */
import React from 'react';
import { CModal, CModalBody, CModalHeader, CModalFooter } from '@coreui/react';
import { getFileExtension, getFileSize } from 'src/utils/common';
import 'src/views/messages/style.css';

const ChatAttachmenstModal = props => {
  const {
    isFileAttachmentModalOpen,
    viewFileAttachmentModal,
    fileAttachment,
    handleSendMsgOnEnter,
    handleOnClickSendButton,
    handleOnChange,
    handleSelectAttachments,
    handleOnRemoveAttachment,
    messageText,
  } = props;

  const renderOptions = () => {
    return (
      <>
        {fileAttachment?.length !== 0 &&
          fileAttachment.map((file, index) => (
            <>
              <div className="uploadimagemodal d-flex align-items-start justify-content-between">
                <div className="d-flex align-items-start">
                  <figure className="m-0">
                    <div className="extensiondiv">
                      {getFileExtension(file?.name)}
                    </div>
                  </figure>
                  <div className="ps-3">
                    <span className="d-flex">{file?.name}</span>
                    <p className="fw-normal m-0">
                      {getFileSize(file)}
                    </p>
                  </div>
                </div>
                <button
                  className="border-0 bg-transparent"
                  onClick={e => handleOnRemoveAttachment(e, index)}
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              <hr></hr>
            </>
          ))}
      </>
    );
  };

  return (
    <CModal
      show={isFileAttachmentModalOpen}
      onClose={viewFileAttachmentModal}
      size="md"
      centered="true"
      className={'overlap-modal'} 
      backdrop={false}
    >
      <CModalHeader>Selected Files</CModalHeader>
      <CModalBody>
        <div className="heightcontent">{renderOptions()}</div>
        <div className="uploader">
          <input
            id="attachmentsFile"
            type="file"
            className="d-none"
            multiple
            name="attachments"
            style={{ display: 'none', background: '#ececec' }}
            onChange={handleSelectAttachments}
          ></input>
          <label className="uploadbtn" htmlFor="attachmentsFile">
            <img
              src="images/chat-img/cloud-computing.png"
              className="me-2"
              width="25"
              height="25"
              alt=""
            />
            Upload File
          </label>
        </div>
        <div className="form-floating">
          <input
            className="form-control"
            placeholder="Leave a comment here"
            id="floatingTextarea"
            name="message"
            type="text"
            value={messageText}
            onChange={handleOnChange}
            onKeyDown={handleSendMsgOnEnter}
          ></input>
          <label htmlFor="floatingTextarea">Add a caption</label>
        </div>
      </CModalBody>
      <CModalFooter>
        <button className="btn btn-primary">Cancel</button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleOnClickSendButton}
        >
          Send
        </button>
      </CModalFooter>
    </CModal>
  );
};
export default ChatAttachmenstModal;
