const textTypes = ['doc', 'docx', 'odt', 'rtf', 'txt'];
const audioTypes = ['mp3', 'wav', 'ogg', 'webm', 'm4a'];
const videoTypes = ['avi', 'mov', 'mp4', 'mpg', 'wmv'];
const thumbTypes = ['mov', 'mp4', 'wmv'];
const imageTypes = ['bmp', 'gif', 'jpg', 'png', 'svg', 'jpeg'];
const pageLayoutTypes = ['pdf'];
const spreadSheetTypes = ['xls', 'xlsx'];
const compresedFileTypes = ['7z', 'zip', 'rar', 'tar', 'gz'];
const dataFileTypes = ['csv', 'ppt', 'pptx'];
const textPreviewTypes = ['csv', 'txt', 'log'];
const officePreviewTypes = [
  'doc',
  'docx',
  'ppt',
  'pptx',
  'odt',
  'ods',
  'odp',
  ...spreadSheetTypes,
];
const otherFilesTypes = [
  'apk',
  'ipa',
  'exe',
  'mpeg',
  'wma',
  'flv',
  'm4a',
  'mkv',
  'psd',
];

const googlePreviewTypes = ['rtf'];

const appConstants = {
  version: '1.0.0',
  toastOptions: {
    autoClose: 5000, // miliseconds
    position: 'top-right',
  },
  noCam: 'no_cam',
  groupTypes: ['group', 'broadcast'],
  fileTypes: {
    textTypes,
    audioTypes,
    videoTypes,
    thumbTypes,
    imageTypes,
    pageLayoutTypes,
    spreadSheetTypes,
    compresedFileTypes,
    dataFileTypes,
    textPreviewTypes,
    officePreviewTypes,
    googlePreviewTypes,
    otherFilesTypes,
  },
  // attendanceStatus: {
  //   first_half_present: { short_name: 'FHP', txt_color_1: '#e55353' },
  //   second_half_present: { short_name: 'SHP', txt_color_1: '#e55353' },
  //   Sunday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   Second_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   Forth_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   Fourth_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   present: { short_name: 'P', txt_color_1: '#2eb85c'},
  //   PR: { short_name: 'P', txt_color_1: '#2eb85c'},
  //   AB: { short_name: 'A', txt_color_1: '#e55353' },
  //   PH: { short_name: 'PH', txt_color_1: '#2b7b8a' },
  //   PH_: { short_name: 'PH-P', txt_color_1: '#2b7b8a' },
  //   PH_FHP: { short_name: 'PH-FHP', txt_color_1: '#2b7b8a' },
  //   PH_SHP: { short_name: 'PH-SHP', txt_color_1: '#2b7b8a' },
  //   FHP: { short_name: 'FHP', txt_color_1: '#e55353' },
  //   SHP: { short_name: 'SHP', txt_color_1: '#e55353' },
  //   WO: { short_name: 'WO', txt_color_1: '#5c5959' },
  //   WO_P: { short_name: 'WO-P', txt_color_1: '#5c5959' },
  //   WO_FHP: { short_name: 'WO-FHP', txt_color_1: '#5c5959' },
  //   WO_SHP: { short_name: 'WO-SHP', txt_color_1: '#5c5959' },
  //   PL: { short_name: 'PL', txt_color_1: '#0b429c' },
  //   LW: { short_name: 'LW', txt_color_1: '#f79205' },
  //   CO: { short_name: 'CO', txt_color_1: '#fc8403' },
  //   TOD:{ short_name: 'TOD', txt_color_1: '#fc8403' },
  //   C_Off:{ short_name: 'C-off', txt_color_1: '#fc8403' },
  //   SSL:{ short_name: 'SSL', txt_color_1: '#fc8403' },
  //   FH_PL:{ short_name: 'FH-PL', txt_color_1: '#0b429c' },
  //   SH_PL:{ short_name: 'SH-PL', txt_color_1: '#0b429c' },
  // },
  attendanceStatus: {
    first_half_present: { short_name: 'FHP', txt_color_1: '#e55353' },
    second_half_present: { short_name: 'SHP', txt_color_1: '#e55353' },
    Sunday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
    Second_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
    Forth_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
    Fourth_Saturday_Off: { short_name: 'WO', txt_color_1: '#5c5959' },
    present: { short_name: 'P', txt_color_1: '#2eb85c'},
    PR: { short_name: 'P', txt_color_1: '#2eb85c'},
    AB: { short_name: 'A', txt_color_1: '#e55353' },
    PH: { short_name: 'PH', txt_color_1: '#2b7b8a' },
    PH_FH: { short_name: 'PH-FH', txt_color_1: '#e55353' },
    PH_SH: { short_name: 'PH-SH', txt_color_1: '#e55353' },
    PH_P: { short_name: 'PH-P', txt_color_1: '#2b7b8a' },
    PH_FHP: { short_name: 'PH-FHP', txt_color_1: '#2b7b8a' },
    PH_SHP: { short_name: 'PH-SHP', txt_color_1: '#2b7b8a' },
    FHP: { short_name: 'FHP', txt_color_1: '#e55353' },
    SHP: { short_name: 'SHP', txt_color_1: '#e55353' },
    WO: { short_name: 'WO', txt_color_1: '#5c5959' },
    WO_P: { short_name: 'WO-P', txt_color_1: '#5c5959' },
    WO_FHP: { short_name: 'WO-FHP', txt_color_1: '#5c5959' },
    WO_SHP: { short_name: 'WO-SHP', txt_color_1: '#5c5959' },
    PL: { short_name: 'PL', txt_color_1: '#0b429c' },
    LW: { short_name: 'LWP', txt_color_1: '#f79205' },
    LWP: { short_name: 'LWP', txt_color_1: '#f79205' },
    CO: { short_name: 'CO', txt_color_1: '#fc8403' },
    TOD:{short_name: 'TOD', txt_color_1: '#fc8403' },
    C_Off:{short_name: 'C-off', txt_color_1: '#fc8403' },
    FH_SSL:{short_name: 'FH_SSL', txt_color_1: '#fc8403' },
    SH_SSL:{short_name: 'SH_SSL', txt_color_1: '#fc8403' },
    FH_PL:{short_name: 'FH-PL', txt_color_1: '#0b429c' },
    SH_PL:{short_name: 'SH-PL', txt_color_1: '#0b429c' },
  },
  validations: {
    minLength: 'Min $1 character required',
    maxLength: 'Max $1 character required',
    allFieldReq: 'All fields are required',
    fieldReq: '$FIELD is required',
    emailReq: 'Email id is required',
    emailInvalid: 'Email address invalid',
    inavlidInOutTime: 'Check In should be less than Check Out time',
  },
  travelBy: [
    { id: 1, label: 'Air', is_sub_options: false },
    { id: 2, label: 'Bus', is_sub_options: false },
    { id: 3, label: 'Rail', is_sub_options: false },
    { id: 4, label: 'Car', is_sub_options: true },
    { id: 5, label: 'Two Wheeler', is_sub_options: true },
  ],

  travelByType: [
    { id: 1, label: 'Petrol', isVisible: true },
    { id: 2, label: 'Diesel', isVisible: false },
    { id: 3, label: 'CNG', isVisible: false },
    { id: 4, label: 'Electric', isVisible: true },
  ],
  location: [
    { id: 1, label: 'Jodhpur', value: 'Jodhpur'},
    { id: 2, label: 'Jaipur', value: 'Jaipur' },
    { id: 3, label: 'Udaipur', value: 'Udaipur' },
    { id: 4, label: 'Barmer', value: 'Barmer' },
    { id: 4, label: 'Neemrana', value: 'Neemrana' },
    { id: 4, label: 'Sikar', value: 'Sikar' },
    { id: 4, label: 'Ajmer', value: 'Ajmer' },

  ],
  gender: [
    {id: 1, label:"Male",value:"M"},
    {id: 2, label:"Female",value:"F"},
    {id: 3, label:"Other",value:"Other"}
  ],
  accountStatus: [
    { id: 1, label: 'Active', value: 'active'},
    { id: 2, label: 'Inactive', value: 'inactive' },
  ],
  month: [
    { id: 1, label: 'One', value: '1'},
    { id: 2, label: 'Two', value: '2'},
    { id: 3, label: 'Three', value: '3'},
    { id: 4, label: 'Four', value: '4'},
    { id: 5, label: 'Five', value: '5'},
    { id: 6, label: 'Six', value: '6'},
    { id: 7, label: 'Seven', value: '7'},
    { id: 8, label: 'Eight', value: '8'},
    { id: 9, label: 'Nine', value: '9'},
    { id: 10, label: 'Ten', value: '10'},
    { id: 11, label: 'Eleven', value: '11'},
    { id: 12, label: 'Twelve', value: '12'},
  ],
  company:{
    name:"Unique Builders",
    logo:'images/UB_logo.png'
  }
};
export default appConstants;
