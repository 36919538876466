
import React, { useState, useEffect } from 'react'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import PDFViewer from 'pdf-viewer-reactjs'


const ModalViewImage = (props) => {
    const { isOpen, isViewModalOpen, imagePath } = props

    useEffect(() => {

    }, [])

    return (
        <div className="">

            <CModal
                show={isOpen}
                onClose={() => isViewModalOpen(!isOpen)}
                size="lg"
            >

               
                <CModalBody>
                    <CButton className="float-right" color="danger" onClick={() => isViewModalOpen(!isOpen)}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}
                    <div className="viewImage">
                        {imagePath && imagePath.includes(".pdf") ?

                            <PDFViewer
                                document={{
                                    url: imagePath,
                                  
                                    
                                }}
                            />
                            :
                            <img src={imagePath} alt="image" width="500px" height="400px"></img>
                        }
                    </div>
                </CModalBody>
                {/* <CModalFooter>
                    <CButton color="danger" onClick={() => isViewModalOpen(!isOpen)}><i className="fa fa-times" aria-hidden="true"></i></CButton>{' '}

                </CModalFooter> */}
            </CModal>
        </div>
    )
}
export default ModalViewImage







