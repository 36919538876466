import React, { useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
} from '@coreui/react';
import { CustButton } from 'src/reusable';
import { CallFeedbackAddModal, CallRecordingPlay } from './index';
import moment from 'moment';

const CallHistoryModal = props => {
  const { open, handleCancel, candidateCallHistory } = props;

  const [selectedItem, setSelectedItem] = useState(null);
  const [feedbackAddModalOpen, setFeedbackAddModalOpen] = useState(false);
  const [recordingModalOpen, setRecordingModalOpen] = useState(false);

  const toggleFeedbackModal = (data = null) => {
    setSelectedItem(data);
    setFeedbackAddModalOpen(!feedbackAddModalOpen);
  };

  const toggleRecording = (data = null) => {
    setSelectedItem(data);
    setRecordingModalOpen(!recordingModalOpen);
  };

  const renderCallHistory = () => {
    if (candidateCallHistory?.length) {
      const list = candidateCallHistory.map((item, index) => {
        const cdrParsedData = item?.json_data_cdr
          ? JSON.parse(item.json_data_cdr)
          : null;
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            {/* <td>{item.trans_id}</td> */}
            <td>{item.cli}</td>
            <td>{item.digits_1}</td>
            <td>{item.access_number}</td>
            <td>
              {cdrParsedData?.direction === 'INBOUND' ? (
                <div style={{ color: 'green' }}>INBOUND</div>
              ) : cdrParsedData?.direction === 'OUTBOUND' ? (
                <div style={{ color: 'red' }}>OUTBOUND</div>
              ) : (
                'INITIATED'
              )}
            </td>
            <td>{cdrParsedData?.duration || 0}</td>
            <td>
              {cdrParsedData == null
                ? ''
                : moment(cdrParsedData?.start_time).format('HH:mm:ss A') +
                  ' / ' +
                  moment(cdrParsedData?.end_time).format('HH:mm:ss A')}
            </td>
            <td>{cdrParsedData?.status || ''}</td>
            <td>{item.category_key}</td>
            <td>
              {item.feedback_value === 'null' ? 'N/A' : item.feedback_value}
            </td>
            <td>{item.feedback_remark}</td>
            <td>{moment(item.created_at).format('DD-MM-YYYY')}</td>
            <td>
              <CustButton
                title="Feedback"
                type="button"
                name="feedback"
                size="sm"
                color={item?.feedback_id ? 'secondary' : 'warning'}
                onClick={() => toggleFeedbackModal(item)}
                className={'btn-loading btn-ladda'}
                icon="fa-solid fa-comments"
                isDisabled={item?.feedback_id || false}
                isLoading={false}
              />
              <br />
              <br />
              {item?.json_data_cdr && cdrParsedData?.recording_url && (
                <CustButton
                  title="Play"
                  type="button"
                  name="recording"
                  size="sm"
                  color="primary"
                  onClick={() => toggleRecording(cdrParsedData?.recording_url)}
                  className="btn-loading btn-ladda"
                  icon="fa-solid fa-play"
                  isDisabled={false}
                  isLoading={false}
                />
              )}
            </td>
          </tr>
        );
      });

      return <tbody>{list}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <th
              colSpan={13}
              style={{ textAlign: 'center', color: 'red', fontSize: '15px' }}
            >
              No Records Found
            </th>
          </tr>
        </tbody>
      );
    }
  };

  return (
    <div className="">
      <CModal show={open} onClose={handleCancel} size="xl" backdrop={false}>
        <CModalHeader closeButton>
          <CModalTitle>Call History</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {/* <CButton
            className="float-right"
            color="danger"
            onClick={() => handleCancel()}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </CButton> */}
          <div>
            <table className="table table-bordered table-condensed table-responsive">
              <thead>
                <tr>
                  <th>S.No</th>
                  {/* <th>Trans ID</th> */}
                  <th>Call From</th>
                  <th>Call To</th>
                  <th>Access No.</th>
                  <th>Call Type</th>
                  <th>Call Duration</th>
                  <th>Call Time (Start/End)</th>
                  <th>Call Status</th>
                  <th>Feedback Event</th>
                  <th>Feedback Value</th>
                  <th>Remark</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              {renderCallHistory()}
            </table>
          </div>
        </CModalBody>

        <CModalFooter>
          <CustButton
            type="button"
            name="ok"
            text="Close"
            color="danger"
            onClick={handleCancel}
            className="btn-loading btn-ladda mr-3"
            icon=""
            isDisabled={false}
            isLoading={false}
          />
        </CModalFooter>
      </CModal>

      <CallFeedbackAddModal
        open={feedbackAddModalOpen}
        handleCancel={toggleFeedbackModal}
        dropdownList={null}
        selectedItem={selectedItem}
        setFeedbackAddModalOpen={setFeedbackAddModalOpen}
      />

      <CallRecordingPlay
        open={recordingModalOpen}
        handleCancel={toggleRecording}
        selectedItem={selectedItem}
        setRecordingModalOpen={setRecordingModalOpen}
      />
    </div>
  );
};
export default CallHistoryModal;
