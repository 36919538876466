import { createReducer } from 'redux-act';
import actions from './mqttActions';

const initialState = {
  isMqttInstancePending: false,
  mqttClientInstance: null,
  mqttClientConnectStatus: null,
  mqttClientMessagePayload: null,
};

//
const handleMqttClientInstanceRequest = state => {
  return {
    ...state,
    ...{
      isMqttInstancePending: true,
      mqttClientInstance: null,
    },
  };
};
const handleMqttClientInstance = (state, payload) => {
  return {
    ...state,
    ...{
      isMqttInstancePending: false,
      mqttClientInstance: payload,
    },
  };
};
const handleMqttClientConnectStatus = (state, payload) => {
  console.log('--handleMqttClientConnectStatus--',payload);
  return {
    ...state,
    ...{
      mqttClientConnectStatus: payload,
    },
  };
};

//
const handleMqttClientOnMessage = (state, payload) => {
  console.log('--handleMqttClientOnMessage--', payload);
  return {
    ...state,
    ...{
      mqttClientMessagePayload: payload,
    },
  };
};

// clear existing data
const handleClearMqttStore = (state, payload) => {
  switch (payload) {
    case 'HANDLE_CLEAR':
      return {
        ...state,
        ...{
          isMqttInstancePending: false,
          mqttClientInstance: null,
        },
      };
    case 'CLEAR_MESSAGE_PAYLOAD':
      return {
        ...state,
        ...{
          mqttClientMessagePayload: null,
        },
      };
    default:
      return state;
  }
};
export default createReducer(
  {
    [actions.mqttClientInstanceRequest]: handleMqttClientInstanceRequest,
    [actions.mqttClientInstance]: handleMqttClientInstance,
    [actions.mqttClientConnectStatus]: handleMqttClientConnectStatus,
    [actions.mqttClientOnMessage]: handleMqttClientOnMessage,

    [actions.clearMqttStore]: handleClearMqttStore,
  },
  initialState,
);
