import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import { getLocalDataAsObject } from '../../utils/CoustomStorage';
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CFormGroup,
    CSelect,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'
import { isPermission } from 'src/utils/common'
import swal from 'sweetalert'
import API from '../../utils/apiCalling';
import Alert from "src/alert/alert";
import { config } from '../../utils/apiUrl';
import { Base_URI } from '../../utils/apiUrl';
const ViewCandidate = (props) => {
    const { MRFData, view_selected } = props
    let api = new API()
    const [largeR, setLargeR] = useState(false)
    const [active, setActive] = useState(0)
    const [mrfRecord, setMrfRecord] = useState()
    const [createdCandidate, setCreatedCandidate] = useState()
    const [candidateDetails, setCandidateDetails] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [candidate, setCandidate] = useState()
    const [isInterviewStatus, setIsInterviewStatus] = useState(false)
    const [InterviewRoundDetails, setInterviewRoundDetails] = useState()
    const [alertMessage, setAlertMessage] = useState("");
    const [isAlert, setIsAlert] = useState(false);
    const [ReasonRef, setReasonRef] = useState({});
    const [email, setemail] = useState('');
    const isAlertToggle = (msg) => {
        setIsAlert(!isAlert);
        setAlertMessage(msg);
    };
    const isAlertToggleClose = () => {
        setIsAlert(false);
    }
    useEffect(() => {
        getMrfRecord()
    }, [])
    
    useEffect(() => {
        getCreatedCandidate()
    }, [])

    const getCreatedCandidate = async () => {
        let data = {
            mrf_id: MRFData && MRFData.mrf_id,
            mrf_applying_for: MRFData && MRFData.applying_for == "New Hiring" ? "vacancy" : "replacement"
        }
        let result = await api.get(config.getAllCandidate,true, data)
        if (result && result.code == 200) {
            setCreatedCandidate(result.data)
        }
        else {
            
        }
    }

    const backButton = () =>{
        view_selected()
    }

    const onChangeInputRejectReason = (e) => {
        const { name, value } = e.target;
        setReasonRef({
            ...ReasonRef,
            [name]: value,
        });

    };

    const getMrfRecord = async () => {
        let result11 = await api.get(config.profile)
        if (result11) {
            setemail(result11.data[0].email)
        }
    }

    const updateReason = async (mrf_id) => {
        const formData = {
            "mrf_id": mrf_id,
            "rejectReason": ReasonRef.rejectReason,
        }
        swal({
            content: true,
            title: "Are you sure?",
            text: `Are you sure to reject`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.updateRejectMrf, formData, true)
                if (result && result.code == 200) {
                    isAlertToggle(result && result.message);
                    setLargeR(!largeR);
                } else {
                    isAlertToggle(result && result.message);
                    setLargeR(!largeR);
                }
            }
        })
    }

    const updateShortList = async (candidate) => {
        const formData = {
            "id": candidate.id,
            "candidate_created_by": email,
            "created_by": getLocalDataAsObject('user')[0].id,
        }
        swal({
            content: true,
            title: "Are you sure?",
            text: `Are you sure to HR Shortlist ${candidate.email}`,
            icon: "success",
            buttons: ["No, cancel it!", "Submit"],
            dangerMode: true,
        }).then(async function (isConfirm) {
            if (isConfirm) {
                let result = await api.post(config.updateHrShortList, formData, true)
                if (result && result.code == 200) {
                    isAlertToggle(result && result.message);
                } else {
                    isAlertToggle(result && result.message);
                }
            }
        })

    }


    return (
        <div className="">
            <div className="container">
            <h3 className="text-uppercase text-center mt-3 mb-4"> <u>Profile Bank Panel</u></h3>
                <CRow>
                    <CCol xs="12" >
                        <CCard>
                            <CCardBody>
                                <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink>
                                                Candidate
                                            </CNavLink>
                                        </CNavItem>
                                    </CNav>
                                    <CTabContent>
                                        <CTabPane>
 
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Candidate ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile No.</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Job Type</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {createdCandidate && createdCandidate.map((candidate, index) => (
                            <tr>
                                <td>{candidate.id}</td>
                                <td>{candidate.name}</td>
                                <td>{candidate.email}</td>
                                <td>{candidate.mobile}</td>
                                <td>{candidate.designation}</td>
                                <td>{candidate.emp_name}</td>
                                <td>{candidate.job_type}</td>
                                <td>
                                <CRow>
                                <CCol xs="12" md="3" style={{ marginRight: "7px" }}>
                                <>
                                {(isPermission('hr_shortlist') || isPermission('reject_candidate')) && 
                                <div className="btn-group">
                                    <button type="button" title="action" className="btn btn-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-list" aria-hidden="true"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                         {isPermission('hr_shortlist') &&
                                    <a className="dropdown-item" onClick={() => updateShortList(candidate)}>HR Shortlist</a>
                        }
                                    <div className="dropdown-divider"></div>
                                     {isPermission('reject_candidate') &&
                                    <CButton color="secondary" className="dropdown-item" onClick={() => setLargeR(!largeR)}>Reject</CButton>
                                   }
                                    </div>
                                </div>
}
                                </>
                            </CCol>
                            {isPermission('download_resume_profile_bank') &&
                        <CCol xs="12" md="2">
                            <a href={`${Base_URI}/candidate_resume/` + candidate.candidate_resume_filename} target="_blank"> <CButton data-toggle="tooltip" data-placement="Download" title="Download Resume" className="btn btn-success " ><i className="fa fa-download" aria-hidden="true"></i></CButton></a>
                        </CCol>
                        }
                        <CModal show={largeR} onClose={() => setLargeR(!largeR)} size="lg" color="success" >
                        <CModalHeader style={{ marginTop: "28px" }} closeButton>
                            <CModalTitle>Please Write Reason<br /></CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <CForm encType="multipart/form-data" className="form-horizontal" >
                            <CFormGroup row>
                        <CCol md="12">
                            <CLabel >Reason<span className="text-danger"> *</span></CLabel>
                            <CInput id="rejectReason" name="rejectReason" placeholder="Enter your reject reason" onChange={onChangeInputRejectReason} required />
                        </CCol>
                            </CFormGroup>
                            </CForm>
                        </CModalBody>
                        <CModalFooter>
                            <CButton color="success" onClick={() => updateReason(candidate.id)} >Submit</CButton>{' '}
                            <CButton color="secondary" onClick={() => setLargeR(!largeR)}>Back</CButton>
                        </CModalFooter>
                    </CModal>
                            <Alert
                                isAlert={isAlert}
                                isAlertToggle={isAlertToggle}
                                alertMessage={alertMessage}
                                isAlertToggleClose={isAlertToggleClose}
                            />
                        </CRow>
                        </td>
                            </tr>
                        ))}

                    </tbody>
                </table>

                </CTabPane>
            </CTabContent>
                                </CTabs>
                                <CCardFooter>
                                    <CButton data-toggle="tooltip" data-placement="top" title="Back" color="success" onClick={backButton}><i className="fa fa-arrow-left" aria-hidden="true"></i> </CButton>{' '}

                                </CCardFooter>
           
                            </CCardBody>
                        </CCard>

                    </CCol>
                </CRow>

            </div>

    
        </div>

    )
}
export default ViewCandidate
