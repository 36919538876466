import { createReducer } from 'redux-act';
import actions from './messageActions';

const initialState = {
  isChatPopupOpen: false,

  isGetMessagesPending: false,
  getMessagesRes: null,
  getMessagesResError: null,

  isGetConnectedUsersPending: false,
  getConnectedUsersRes: null,
  getConnectedUsersResError: null,

  isGetUnreadMessageCountsPending: false,
  getUnreadMessageCountsRes: null,
  getUnreadMessageCountsResError: null,
};

//
const handleToggleChatPopup = (state, payload) => {
  return {
    ...state,
    ...{
      isChatPopupOpen: payload,
    },
  };
};

//
const handleGetMessagesRequest = state => {
  return {
    ...state,
    ...{
      isGetMessagesPending: true,
      getMessagesRes: null,
      getMessagesResError: null,
    },
  };
};
const handleGetMessagesSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      isGetMessagesPending: false,
      getMessagesRes: payload,
      getMessagesResError: null,
    },
  };
};
const handleGetMessagesError = (state, payload) => ({
  ...state,
  ...{
    isGetMessagesPending: false,
    getMessagesRes: null,
    getMessagesResError: payload,
  },
});

//
const handleGetConnectedUsersRequest = state => {
  return {
    ...state,
    ...{
      isGetConnectedUsersPending: true,
      getConnectedUsersRes: null,
      getConnectedUsersResError: null,
    },
  };
};
const handleGetConnectedUsersSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      isGetConnectedUsersPending: false,
      getConnectedUsersRes: payload,
      getConnectedUsersResError: null,
    },
  };
};
const handleGetConnectedUsersError = (state, payload) => ({
  ...state,
  ...{
    isGetConnectedUsersPending: false,
    getConnectedUsersRes: null,
    getConnectedUsersResError: payload,
  },
});

//
const handleGetConnectedGroupsRequest = state => {
  return {
    ...state,
    ...{
      isGetConnectedGroupsPending: true,
      getConnectedGroupsRes: null,
      getConnectedGroupsResError: null,
    },
  };
};
const handleGetConnectedGroupsSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      isGetConnectedGroupsPending: false,
      getConnectedGroupsRes: payload,
      getConnectedGroupsResError: null,
    },
  };
};
const handleGetConnectedGroupsError = (state, payload) => ({
  ...state,
  ...{
    isGetConnectedGroupsPending: false,
    getConnectedGroupsRes: null,
    getConnectedGroupsResError: payload,
  },
});

//
const handleGetUnreadMessageCountsRequest = state => {
  return {
    ...state,
    ...{
      isGetUnreadMessageCountsPending: true,
      getUnreadMessageCountsRes: null,
      getUnreadMessageCountsResError: null,
    },
  };
};
const handleGetUnreadMessageCountsSuccess = (state, payload) => {
  return {
    ...state,
    ...{
      isGetUnreadMessageCountsPending: false,
      getUnreadMessageCountsRes: payload,
      getUnreadMessageCountsResError: null,
    },
  };
};
const handleGetUnreadMessageCountsError = (state, payload) => ({
  ...state,
  ...{
    isGetUnreadMessageCountsPending: false,
    getUnreadMessageCountsRes: null,
    getUnreadMessageCountsResError: payload,
  },
});

// clear existing data
const clearMessageStore = (state, payload) => {
  switch (payload) {
    case 'HANDLE_CLEAR':
      return {
        ...state,
        ...{
          isGetMessagesPending: false,
          getMessagesRes: null,
          getMessagesResError: null,
        },
      };
    case 'CLEAR_IS_CHAT_POPUP_OPEN':
      return {
        ...state,
        ...{
          isChatPopupOpen: false,
        },
      };
    default:
      return state;
  }
};
export default createReducer(
  {
    [actions.toggleChatPopup]: handleToggleChatPopup,

    [actions.getMessagesRequest]: handleGetMessagesRequest,
    [actions.getMessagesSuccess]: handleGetMessagesSuccess,
    [actions.getMessagesError]: handleGetMessagesError,

    [actions.getConnectedUsersRequest]: handleGetConnectedUsersRequest,
    [actions.getConnectedUsersSuccess]: handleGetConnectedUsersSuccess,
    [actions.getConnectedUsersError]: handleGetConnectedUsersError,

    [actions.getConnectedGroupsRequest]: handleGetConnectedGroupsRequest,
    [actions.getConnectedGroupsSuccess]: handleGetConnectedGroupsSuccess,
    [actions.getConnectedGroupsError]: handleGetConnectedGroupsError,

    [actions.getUnreadMessageCountsRequest]: handleGetUnreadMessageCountsRequest,
    [actions.getUnreadMessageCountsSuccess]: handleGetUnreadMessageCountsSuccess,
    [actions.getUnreadMessageCountsError]: handleGetUnreadMessageCountsError,

    [actions.handleClearMessageStore]: clearMessageStore,
  },
  initialState,
);
