/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CSwitch,
  CCollapse,
  CRow,
} from '@coreui/react';

import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import BgvAuthorizationConsent from './other/bgv_authorization_consent';
import { toast, ToastContainer } from 'react-toastify';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import { Cookies } from 'react-cookie';

const PolicyDetails = props => {
  const api = new API();
  const { getEmployeeDetailStatus, empStatus, empDetails } = props;
  const [accordion, setAccordion] = useState(0);
    const [policy1, setPolicy1] = useState(false);
  const [policy2, setPolicy2] = useState(false);
  const [policy3, setPolicy3] = useState(false);
  const [policy4, setPolicy4] = useState(false);
  const [policy5, setPolicy5] = useState(false);
  const [policy6, setPolicy6] = useState(false);
  const [policy7, setPolicy7] = useState(false);
  const [policy8, setPolicy8] = useState(false);
  const [policy9, setPolicy9] = useState(false);
  const [policy10, setPolicy10] = useState(false);
  const [policy11, setPolicy11] = useState(false);
  const [policy12, setPolicy12] = useState(false);
  const [policy13, setPolicy13] = useState(false);
  const [policy14, setPolicy14] = useState(false);
  const [policy15, setPolicy15] = useState(false);
  const [policy16, setPolicy16] = useState(false);
  const [policy17, setPolicy17] = useState(false);
  const [policy18, setPolicy18] = useState(false);
  const [allPolicyAccept, setAllPolicyAccept] = useState(true);
  const [userSign, setUserSign] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [policyData, setPolicyData] = useState('');
  const [userID, setUserID] = useState('');

  useEffect(() => {
    let userData = getLocalDataAsObject('user');
    if (userData && userData.length > 0) {
      setUserEmail(userData[0].email);
      setUserID(userData[0].id);
    }
  }, []);
  const Policyaccepted = async () => {
    if (!userSign) {
      toast.warning('Please sign or attach image');
      return;
    }
    let data = {};
    if (empDetails && empDetails.id) {
      data = {
        empPolicyAccept: allPolicyAccept,
        user_id: empDetails && empDetails.id,
        sign: userSign,
      };
    } else {
      data = {
        empPolicyAccept: allPolicyAccept,
        sign: userSign,
      };
    }

    let result = await api.post(config.employeePolicy, data);
    if (result && result.code === 200) {
      toast.success('Policy Submitted Successfully');
      getEmployeeDetailStatus();
      setTimeout(() => {
        window.location.href = '/#/';
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        Cookies.remove('token');
        Cookies.remove('user');
      }, "2000");
     
    } else {
      toast.error(result && result.message);
    }
  };

  const dataForPolicy = async () => {
    let data = {
      emp_user_id: empDetails?.id ? empDetails?.id : userID,
    };
    let result = await api.get(config.allDataforPolicy, true, data);
    if (result && result.code === 200) {
      setPolicyData(result.data[0]);
    } else {
      toast.error(result && result.message);
    }
  };
  useEffect(() => {
    dataForPolicy();
  }, [userID, empDetails]);

  const acceptpolicy = (e, id) => {
    switch (id) {
      case 1:
        setPolicy1(!policy1);
        break;
      case 2:
        setPolicy2(!policy2);
        break;
      case 3:
        setPolicy3(!policy3);
        break;
      case 4:
        setPolicy4(!policy4);
        break;
      case 5:
        setPolicy5(!policy5);
        break;
      case 6:
        setPolicy6(!policy6);
        break;
      case 7:
        setPolicy7(!policy7);
        break;
      case 8:
        setPolicy8(!policy8);
        break;
      case 9:
        setPolicy9(!policy9);
        break;
      case 10:
        setPolicy10(!policy10);
        break;
      case 11:
        setPolicy11(!policy11);
        break;
      case 12:
        setPolicy12(!policy12);
        break;
      case 13:
        setPolicy13(!policy13);
        break;
      case 14:
        setPolicy14(!policy14);
        break;
      case 15:
        setPolicy15(!policy15);
        break;
      case 16:
        setPolicy16(!policy16);
        break;
      case 17:
        setPolicy17(!policy17);
        break;
      case 18:
        setPolicy18(!policy18);
        break;
      default:
      // console.log("something went wrong")
    }
    // isAllPolicyAccept()
  };

  const getSignFun = sign => {
    setUserSign(sign);
  };

  const sendOtp = async () => {
    let data = {
      email: userEmail,
      type: 'other',
      subject : 'OTP Verification for Policy'
    };
    let result = await api.post(config.sendOtp, data);
    // console.log("Result>>>>>>>>>", result)
    if (result && result.code === 200) {
      alert(result.message);
      setIsOtpSent(true);
    } else {
      alert(result && result.message);
    }
  };
  const verifyOTP = async () => {
    let data = {
      email: userEmail,
      otp: otp,
    };
    let result = await api.post(config.verifyOtp, data);
    if (result && result.code === 200) {
      alert(result.message);
      setIsVerified(true);
    } else {
      alert(result && result.message);
    }
  };

  return (
    <CRow>
      <CCol xl="12">
        {true && (
          <CCard>
            <div className="text-center">
              <strong>Company Policies</strong>
              <br />
              <small>
                {' '}
                Kindly read all the policies by clicking respective one's
              </small>
            </div>

            <CCardBody>
              <div id="accordion">
                <CCard className="mb-0">
                  <CCardHeader id="headingOne">
                    <CButton
                      block
                      color=""
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 0 ? null : 0)}
                    >
                      <h5 className="m-0 p-0 text-black">
                        Accountability of an employee
                        <i
                          className={
                            accordion === 0
                              ? 'fa fa-angle-double-up fa-lg text-black'
                              : 'fa fa-angle-double-down fa-lg text-black'
                          }
                          style={{ float: 'right' }}
                        ></i>
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 0}>
                    <CCardBody>
                      We at Unique Group believe to operate our business in the most
                      ethical and professional way and expect our employees to -
                      <ul>
                        {' '}
                        <li style={{listStyleType:"disc"}}>
                        Deal on behalf of the Company with professionalism,
                          honesty, integrity as well as high moral and ethical
                          standards.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                        Never compromise our values, no matter how strong the
                          internal or external pressure may be to perform, meet
                          goals.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                        Be a role modelby communicating with others and acting
                          in a manner consistent withour core values.{' '}
                        </li>
                        <li style={{listStyleType:"disc"}}>
                        Adhere, adopt and follow the practices in business
                          ethics. Many of these practices attract legal requirements.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                        Violation of laws can cause significant damage to an
                          individual or the company thus an employee should
                          seriously carry accountability and comply.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 1)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 1 ? null : 1)}
                    >
                      <h5 className="m-0 p-0 ">
                        HSW Policy{' '}
                        <i
                          className={
                            accordion === 1
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 1}>
                    <CCardBody>
                      This is the statement of general policy and arrangements
                      for all our employees emphasizing in their health and
                      safety. This will be a Day-to-day responsibility for
                      ensuring this policy is put into practice, and
                      non-adherence to the HSW policy would lead to strict
                      action and might call for termination from the service
                      with immediate effect.
                      <br />
                      <strong>Essentials while driving/riding- </strong>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          Every employee is required to wear helmet if he/she is
                          on a two wheeler.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          Every employee is required to have the seat belt of
                          the car fasten while driving.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          Avoid the use of cell phones while driving/riding a
                          vehicle.
                        </li>
                        <li style={{listStyleType:"disc"}}> Avoid over speeding of vehicles.</li>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          Follow the traffic rules while driving/riding vehicle.
                        </li>
                      </ul>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 2)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingThree">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 2 ? null : 2)}
                    >
                      <h5 className="m-0 p-0 ">
                        Conflict of Interest{' '}
                        <i
                          className={
                            accordion === 2
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 2}>
                    <CCardBody>
                      <strong>A conflict of interest occurs when-</strong>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          <strong>Personal interest-</strong> The personal
                          interest of any member of the Board of Directors or
                          the Senior Management interferes or appears to
                          interfere in any way with the interest of the Company.
                          Although this duty does not prevent them from engaging
                          in personal transactions and investments, it does
                          demand that they avoid situations where conflict of
                          interest might occur or appear to occur.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          <strong>Involvement/Interference -</strong> The
                          Non-Executive Directors and the Senior Management are
                          expected to devote their attention to the business
                          interest of the Company. They are prohibited from
                          engaging in any activity that interferes with their
                          performance or responsibilities to the Company or
                          otherwise is in conflict with or prejudicial to the
                          Company.{' '}
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          <strong>Business Interests -</strong> If any member of
                          the Board of Directors or the Senior Management
                          considers investing in securities issued by the
                          Company's customer, supplier or competitor, they
                          should ensure that these investments do not compromise
                          their responsibilities to the Company. Many factors
                          including the size and nature of the investment; their
                          ability to influence the Company's decisions, their
                          access to confidential information of the Company, or
                          of the other entity, and the nature of the
                          relationship between the Company and the customer,
                          supplier or competitor should be considered in
                          determining whether a conflict exists. Additionally,
                          they should disclose to the Company any interest that
                          they have which may conflict with business of the
                          Company.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 3)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingFour">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 3 ? null : 3)}
                    >
                      <h5 className="m-0 p-0 ">
                        Information Confidentiality{' '}
                        <i
                          className={
                            accordion === 3
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 3}>
                    <CCardBody>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          Employees come across a number of confidential
                          information which may take many forms. They must take
                          proper care of such information and ensure that it is
                          not misused in any way which is detrimental to the
                          Company’s business or used for own commercial benefit.
                          They must also not disclose actions or activities
                          relating to our business operations to outsiders,
                          unless they concern or impact them.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          Not to disclose business strategies / operations to
                          anyone.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          Not to take away critical document / information
                          either physically or through emails.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          Company's Email, Internal and Intranet are primarily
                          use for company's business and not for personal gains.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          The Company has procedure for entering into
                          confidentiality agreements with various vendors, which
                          shall be executed before any information is shared
                          with them. An employee is expected to accept only such
                          information that is necessary to accomplish the
                          purpose of receiving it, and not for any other
                          purpose.
                        </li>
                      </ul>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 4)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 4 ? null : 4)}
                    >
                      <h5 className="m-0 p-0 ">
                        Company’s Assets{' '}
                        <i
                          className={
                            accordion === 4
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 4}>
                    <CCardBody>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          Ensure use of facilities and amenities provided to him
                          / her by the company with care.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          Return the company property while leaving the company
                          on discharge / termination / resignation.{' '}
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 5)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 5 ? null : 5)}
                    >
                      <h5 className="m-0 p-0 ">
                        Expenses Report{' '}
                        <i
                          className={
                            accordion === 5
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 5}>
                    <CCardBody>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          Each employee will conduct Company's business with
                          integrity, in compliance with applicable laws and
                          rules, and in a manner, that excludes personal gain.{' '}
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          As per policy, employees would be reimbursed for
                          expenses that are necessary and incurred when
                          travelling and/or entertaining on authorized Company
                          business
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 6)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 6 ? null : 6)}
                    >
                      <h5 className="m-0 p-0 ">
                        General Code of Conduct{' '}
                        <i
                          className={
                            accordion === 6
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 6}>
                    <CCardBody>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          Good judgment, to ensure the interests, safety and
                          welfare of customers, dealers, employees, and other
                          stakeholders and to maintain a co-operative,
                          efficient, positive, harmonious and productive work
                          environment.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          While discharging duties employee must act honestly
                          and with due diligence, which an ordinary person is
                          expected to behave in his/her own business.{' '}
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          These standards need to be applied while working in
                          the premises of the Company, at off-site locations
                          where the business is being conducted whether in India
                          or abroad, at Company-Sponsored business and social
                          events, or at any other place whether they act as
                          representatives of the Company.{' '}
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          To meet our responsibilities to employees, customers
                          and investors, the Company must maintain a healthy and
                          productive work environment. Substance abuse, or
                          selling, manufacturing, distributing, possessing,
                          using or being under the influence of illegal drugs
                          and alcohol while at work is absolutely prohibited.{' '}
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          Maintain and help the Company in maintaining highest
                          degree of Corporate Governance Practices
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          Shall not communicate with any member of press or
                          publicity media or any other outside agency on matters
                          concerning the Company, except through the designated
                          spokespersons or otherwise authorized.{' '}
                        </li>
                      </ul>
                      <strong>Policy for bribery- </strong>
                      <br />
                      It is our policy to conduct all our business in an honest
                      and ethical manner. We are committed to acting
                      professionally, fairly & with integrity .This includes a
                      prohibition against both direct bribery and indirect
                      bribery.
                      <br />
                      Definition of bribery- Payments in-kind including any
                      non-cash items of value such as travel, hospitality,
                      entertainment, employment opportunities, and gifts are
                      considered as bribe. Associates must ensure that they have
                      read and understood this policy and, must at all times
                      comply with the terms and conditions of this policy.
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 7)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 7 ? null : 7)}
                    >
                      <h5 className="m-0 p-0 ">
                        Sexual Harassment{' '}
                        <i
                          className={
                            accordion === 7
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 7}>
                    <CCardBody>
                      <strong>
                        Sexual harassment would mean and include any of the
                        following-
                      </strong>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          Unwelcome sexual advances, requests or demand for
                          sexual favours, either explicitly or implicitly, in
                          return for employment, promotion, examination or
                          evaluation of a person towards any company activity
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          Eve teasing, innuendos and taunts, physical
                          confinement against one’s will and likely to intrude
                          upon one’s privacy{' '}
                        </li>{' '}
                        <li style={{listStyleType:"disc"}}>
                          Act or conduct by a person in authority which creates
                          the environment at workplace hostile or intimidating
                          to a person belonging to the other sex.
                        </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 8)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 8 ? null : 8)}
                    >
                      <h5 className="m-0 p-0 ">
                        Misconduct{' '}
                        <i
                          className={
                            accordion === 8
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 8}>
                    <CCardBody>
                      If, at any time, the employee is found to be guilty of any
                      misconduct, the Management may impose the following,
                      singly or in combination.
                      <ul>
                        <li style={{listStyleType:"disc"}}>Issuing warning letter </li>
                        <li style={{listStyleType:"disc"}}>
                          Dismissal from service, the service of the employee
                          shall come to end and stand terminated with effect
                          from the date of dismissal.
                        </li>
                      </ul>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 9)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 9 ? null : 9)}
                    >
                      <h5 className="m-0 p-0 ">
                        Abandonment of Service{' '}
                        <i
                          className={
                            accordion === 9
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 9}>
                    <CCardBody>
                      <ul>
                      <li style={{listStyleType:"disc"}}>
                      If an employee absents himself without prior permission
                      and / or prior sanction of leave or overstays beyond the
                      period of leave originally granted or subsequently
                      extended for 10 or more working days, he will be deemed to
                      have voluntarily abandoned service. The employee will be
                      given an opportunity to explain his absence / overstay.
                      The decision taken by the Management subsequently will be
                      communicated to the employee.</li> 
                      <li style={{listStyleType:"disc"}}>
                      You must inform the
                      management regarding your resignation 30 days in advance
                      and have to serve the notice period for 30 days. Failure
                      to do so will initiate a legal action and recovery
                      process.
                      </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 10)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 10 ? null : 10)}
                    >
                      <h5 className="m-0 p-0 ">
                        Other Employment, Business{' '}
                        <i
                          className={
                            accordion === 10
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 10}>
                    <CCardBody>
                    <ul>
                    <li style={{listStyleType:"disc"}}>
                      No employee, while in the service of the company, can
                      accept other employment, or undertake any work or service,
                      direct or indirect, honorary or otherwise, without
                      previous written permission from the Management. No
                      employee shall carry on any business on his own account
                      while he is in the service of the Company. 
                      </li>
                      <li style={{listStyleType:"disc"}}>No
                      employee shall have any trade or business interest with
                      relations specified in the annexure, who in turn have
                      connection with the Company as suppliers, dealers, agents
                      or contractors, without prior consent or approval in
                      writing by the management.{' '}
                      </li>
                      </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 11)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 11 ? null : 11)}
                    >
                      <h5 className="m-0 p-0 ">
                        Dress-Code Policy{' '}
                        <i
                          className={
                            accordion === 11
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 11}>
                    <CCardBody>
                      <table style={{ border: '1px solid', width: '100%'  }}>
                        <tr>
                          <th style={{ border: '1px solid'}}>&nbsp;Days </th>
                          <th style={{ border: '1px solid'}}>&nbsp;Dress Code for Male Employees </th>
                          <th style={{ border: '1px solid'}}>&nbsp;Dress Code for Female Employees </th>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid'}}>&nbsp;Mon- Thu </td>
                          <td style={{ border: '1px solid'}}>&nbsp;Business Formals, Smart Casuals, Denims </td>
                          <td style={{ border: '1px solid'}}>&nbsp;Business Formals, Smart Casuals, Denims </td>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid'}}>&nbsp;Fri & Sat </td>
                          <td style={{ border: '1px solid'}}>&nbsp;Company provided T-shirts, Smart Casuals,Denims </td>
                          <td style={{ border: '1px solid'}}>&nbsp;Company provided T-shirts, Smart Casuals,Denims </td>
                        </tr>
                        <tr>
                          <td style={{ border: '1px solid'}}>&nbsp;Not Allowed </td>
                          <td style={{ border: '1px solid'}}>&nbsp;
                          T-Shirts with obscene or provoking slogans, Sleeveless T-shirts, &nbsp;Rubber slippers, Floaters 
                          </td>
                          <td style={{ border: '1px solid'}}>&nbsp;
                          T-Shirts with obscene or provoking slogans, Sleeveless T-shirts, &nbsp;Rubber slippers, Floaters 
                          </td>
                        </tr>
                      </table>{' '}
                      <strong>
                        ** Employees on field should have formal attire. **{' '}
                      </strong>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 12)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 12 ? null : 12)}
                    >
                      <h5 className="m-0 p-0 ">
                        Channels of Communication{' '}
                        <i
                          className={
                            accordion === 12
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 12}>
                    <CCardBody>
                      To assist you with seeking advice or reporting concerns,
                      the firm has established several “channels of
                      communication.” These are formal systems designed to
                      ensure that your question or concern is addressed
                      completely and accurately. You are free to choose the
                      channel you are most comfortable with while raising a
                      concern or seeking an advice.
                      <ul>
                        <li style={{listStyleType:"disc"}}>Immediate Supervisor </li>
                        <li style={{listStyleType:"disc"}}>HOD’s </li>
                        <li style={{listStyleType:"disc"}}>Director </li>
                      </ul>{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 13)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 13 ? null : 13)}
                    >
                      <h5 className="m-0 p-0 ">
                        Breach of Code{' '}
                        <i
                          className={
                            accordion === 13
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 13}>
                    <CCardBody>
                      The Company intends to prevent the occurrence of conduct
                      not in compliance with this Code of Conduct, applicable
                      laws and other policies, procedures, and guidelines
                      prepared by the Company. The allegations of non-compliance
                      or reported violations with the Code of Conduct will be
                      investigated whenever necessary and evaluated at proper
                      level(s). Those found to be in violation of this Code of
                      Conduct are subject to appropriate actions up to and
                      including termination of employment / future business with
                      the company. Criminal misconduct may be referred to the
                      appropriate legal authorities as per Law.{' '}
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 14)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 14 ? null : 14)}
                    >
                      <h5 className="m-0 p-0 ">
                        Confirmation Process{' '}
                        <i
                          className={
                            accordion === 14
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 14}>
                    <CCardBody>
                      Probation is the process of managing performance to
                      integrate new employees into the department or Office.
                      Probation is in effect an extension of the selection
                      process. The probationary process must be operated in an
                      open, clear and supportive manner so as to ensure that
                      “full picture” of the employee’s suitability for the
                      employment is obtained.
                      <br /> &#10004; At Unique Group, every new employee will be
                      on probation for the period of 180 days from the date he
                      or she joins the company. <br /> &#10004; After 180 days,
                      there will be an Interim appraisal of the employee. <br />{' '}
                      &#10004; The interim appraisal of probation will be
                      conducted by the line manager. The purpose of these
                      reviews is to allow the manager to assess whether the
                      employee{' '}
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          meets the conditions set out in the probationary
                          contract{' '}
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          Work has been performed to a satisfactory standard{' '}
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          Sick leave record is satisfactory and does not exceed
                          the limits set down for the probationary period{' '}
                        </li>
                        <li style={{listStyleType:"disc"}}> Attendance, punctuality and conduct are good </li>
                      </ul>{' '}
                      &#10004; If the appraisal result is satisfactory then
                      status of the employee will be changed to Confirmed
                      employee. <br /> &#10004; In case the appraisal rating is
                      not satisfactory then the employee will be put on a
                      Performance Improvement Plan for 60 days. <br /> &#10004;
                      The objective of PIP is to advise the employee that
                      management would like to help the employee to come up the
                      learning curve. In these 60 days, the line manager would
                      help the employee understanding the KRA’s and objectives
                      and give necessary training. <br /> &#10004; The
                      performance of the employee will be reviewed on weekly
                      basis and feedback will be shared to ensure improvement.
                      <br /> &#10004; At the end of the PIP period if the
                      ratings of the employee are improved then employee will be
                      duly confirmed else the services will be terminated.<br/><br/>{' '}
                   
                    <table style={{ border: '1px solid', width: '100%'}}>
                      <tr>
                        <th style={{ border: '1px solid'}}>&nbsp;Legend </th>
                        <th style={{ border: '1px solid'}}>&nbsp;Meaning </th>
                        <th style={{ border: '1px solid'}}>&nbsp;Implication on Appraisal</th>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid'}}>&nbsp;OS </td>
                        <td style={{ border: '1px solid'}}>&nbsp;Outstanding </td>
                        <td style={{ border: '1px solid'}}>&nbsp;Confirmed </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid'}}>&nbsp;VG</td>
                        <td style={{ border: '1px solid'}}>&nbsp;Very Good</td>
                        <td style={{ border: '1px solid'}}>&nbsp;Confirmed </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid'}}>&nbsp;G</td>
                        <td style={{ border: '1px solid'}}>&nbsp;Good </td>
                        <td style={{ border: '1px solid'}}>&nbsp;Confirmed </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid'}}>&nbsp;ME</td>
                        <td style={{ border: '1px solid'}}>&nbsp;Meet Expectation</td>
                        <td style={{ border: '1px solid'}}>&nbsp;Confirmed </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid'}}>&nbsp;NI</td>
                        <td style={{ border: '1px solid'}}>&nbsp;Need Improvement</td>
                        <td style={{ border: '1px solid'}}>&nbsp;Unconfirmed </td>
                      </tr>
                    </table>{' '}<br/>
                    <ul>
                    <strong>
                      Timings Policy:- </strong>
                    <li style={{listStyleType:"disc"}}><strong>Office Timings:</strong>{' '}
                    Standard punch in time is between 9:30 AM to 09:40 AM and punch out time is between 6:30 PM to 7:00 PM. 
                    Total working hours are 9 hours which includes 1 hour of breaks.</li>
                    <li style={{listStyleType:"disc"}}><strong>Lunch: </strong> One break for 30 minutes.</li>
                    <li style={{listStyleType:"disc"}}><strong> Tea Breaks: </strong>Two breaks for 15 minutes each.</li>
                    </ul>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 15)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 15 ? null : 15)}
                    >
                      <h5 className="m-0 p-0 ">
                        Leave Policy{' '}
                        <i
                          className={
                            accordion === 15
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 15}>
                    <CCardBody>
                      <ol>
                        <li><u>POLICY STATEMENT</u></li>
                        <ul>
                        <li style={{listStyleType:"disc"}}>
                        All employees are entitled to leave in accordance with the relevant agreements and 
                        statutory provisions where the attached practices conflict with employment law for an employee, 
                        or group of employees, the law will take precedence. 
                        Leave for full-time employees will be <b>24 days per annum</b>, which is divided into 
                        <b>15 days of Privilege Leave, 04 days of Casual Leave and 5 days of Sick Leave, plus 
                        gazette public holidays</b> in the workplace jurisdiction. 
                        </li>
                        </ul><br/>
                        <li><u>PURPOSE OF LEAVE</u></li>
                        <ul>
                          <li style={{listStyleType:"disc"}}>The different types of leaves given under the policy are:</li>
                          <ol><li style={{listStyleType:"lower-alpha"}}>Casual Leave (CL)</li>
                          <li style={{listStyleType:"lower-alpha"}}>Sick Leave (SL)</li>
                          <li style={{listStyleType:"lower-alpha"}}>Privilege Leave (PL)</li></ol>
                          <li style={{listStyleType:"disc"}}>Employees who are appointed during the course of the year shall be entitled to the above leaves on a pro-rata basis.</li>
                        </ul><br/>
                      <li><u>LEAVE YEAR AND APPLICABILITY</u></li>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                        Sanctioning of leave are at Management discretion based on the exigencies of business or seriousness of the case.
                        </li>
                        <li style={{listStyleType:"disc"}}>
                        Leave year is from 1st January to 31st December.
</li>
<li style={{listStyleType:"disc"}}>
Eligible leave is credited to the employees on the 1st of January every year.
</li>
<li style={{listStyleType:"disc"}}>
A maximum of <b>08 days of Privilege leave</b> can be carried forward to the next year (this is only from the PL balance). 
</li>
                      </ul>
                      </ol>
                     
                      <b>Note:-</b>  Please note we will implement these leaves with effect from Jan-24. Currently we are giving only 24 Days PL /Annual.
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 16)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 16 ? null : 16)}
                    >
                      <h5 className="m-0 p-0 ">
                        NDA (Non-Disclosure Agreement){' '}
                        <i
                          className={
                            accordion === 16
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 16}>
                    <CCardBody>
                      <b className="text-left">Date :</b>{' '}
                      {policyData?.inserted_at ? policyData?.inserted_at : moment().format("DD-MM-YYYY")}
                      <br />
                      <br />
                      <h5 className="text-center" style={{ color: 'black' }}>
                        <u>Non-Disclosure Agreement</u>
                      </h5>
                      <br />
                      This Agreement is entered into this date{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData?.joining_date ? policyData?.joining_date : '_____'}
                      </b>{' '}
                      between <b>Unique Group</b>, a Company
                      registered in India, having its registered office at  Unique Destination, 4th Floor, Tonk Rd, 
                      near Lakshmi Mandir Crossing, Jaipur, Rajasthan-302015 (hereinafter called the
                      ‘Company’) and Mr./Ms./Mrs.{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.first_name + ' ' + policyData.last_name}
                      </b>{' '}
                      , son/daughter of{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.emp_father_name}
                      </b>{' '}
                      an Indian inhabitant residing at{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.address || '_________'}
                      </b>{' '}
                      (herein after referred to as “Employee”) of the other part
                      on the below Terms and Conditions.
                      <br />
                      While Unique Group is in business of Business consulting you
                      have been hired for a specific project which involves
                      critical client business knowhow and access to lot of
                      confidential knowledge about Unique Group as well as client
                      business critical data .<br />
                      This undertaking is signed to ensure the responsibilities
                      of both the parties understood well and adhered to, basis
                      below points :-
                      <br />
                      <b>1. Data/Information Confidentiality : </b>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          During and after employment, you will maintain
                          information confidentiality and will never disclose
                          any confidential data which belongs to company or its
                          clients outside Unique Group .
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          You will abide the Data Security policy and any breach
                          will be dealt as per company policy and intellectual
                          policy guidelines of Unique Group and client .
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          Sharing or using of any confidential data for benefit
                          of self/ colleagues/ relatives or acquaintances for
                          any monitory or non-monitory benefits would be
                          considered as a breach of this undertaking .
                        </li>
                      </ul>
                      <b>2. Adherence to Company Policy : </b>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          {' '}
                          By accepting the employment, you will adhere to all
                          the company policies and any breach will be dealt as
                          per company policies. You must adhere to all HR
                          policies, IT policies, Operations policies and Finance
                          policies. Also, the terms and conditions mentioned in
                          your offer letter, appointment letter and code of
                          conduct must be strictly adhered and any breach will
                          be dealt as per company policies .
                        </li>
                      </ul>
                      <b>3. Relationship Disclosure : </b>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          If any of your relatives or family members are working
                          with client or its service providers or joins later in
                          future, you must disclose it to the Unique Group HR
                          Department in written through an email with immediate
                          effect .
                        </li>
                        <li style={{listStyleType:"disc"}}>
                          It is given and understood that in case Unique Group finds
                          out through any other source about such relationship
                          or use of any such relationship for the benefit of
                          other it would be dealt with very strictly and legal
                          action can be taken against both .{' '}
                        </li>
                      </ul>
                      This agreement is governed by and shall be construed in
                      accordance with the laws of Indian Penal code. All the
                      disputes arising out of or in connection with this
                      appointment will be the exclusive jurisdiction of courts
                      at Jaipur only.
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 17)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <CCard className="mb-0">
                  <CCardHeader id="headingTwo">
                    <CButton
                      block
                      color="link"
                      className="text-left m-0 p-0"
                      onClick={() => setAccordion(accordion === 17 ? null : 17)}
                    >
                      <h5 className="m-0 p-0 ">
                        Data Consent Form{' '}
                        <i
                          className={
                            accordion === 17
                              ? 'fa fa-angle-double-up fa-lg '
                              : 'fa fa-angle-double-down fa-lg '
                          }
                          style={{ float: 'right' }}
                        ></i>{' '}
                      </h5>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === 17}>
                    <CCardBody>
                      <h5 className="text-center" style={{ color: 'black' }}>
                        <u>Data Subject Consent Form</u>
                      </h5>
                      <br />I{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.first_name + ' ' + policyData.last_name}
                      </b>{' '}
                      have joined Unique Group as{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.designation}
                      </b>{' '}
                      from{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.joining_date}
                      </b>{' '}
                      and I give my consent on the below mentioned points.
                      <br />
                      <br />
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          I have read and understood the employee code
                          of conduct of Unique Group and will
                          abide by all the policies and code of conduct of the
                          organization.
                        </li>
                      </ul>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          I authorize Unique Group
                          to share my personal data which includes my name,
                          address, email address, telephone number and any other
                          personal data collected by Unique Group with the third-party
                          contractors/vendors for background verification, ID
                          card printing, gifting, magazine or any other document
                          printing purposes.
                        </li>
                      </ul>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          I authorize Unique Group to use
                          my photograph on their website, social media handles,
                          magazines, events or activities or for any other
                          official purposes.
                        </li>
                      </ul>
                      <ul>
                        <li style={{listStyleType:"disc"}}>
                          I am aware that anytime if I want to
                          withdraw my consent from processing my personal
                          information w.r.t – point no 3. I have to mark the
                          mail to - <b>hr@uniquegroup.in</b>.
                        </li>
                      </ul>
                      <br />
                      Name of Individual providing Consent :{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData?.first_name + ' ' + policyData?.last_name}
                      </b>
                      <br /> <br />
                      Address of Individual providing Consent :{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData.address || ''}
                      </b>{' '}
                      <br /> <br />
                      Date of Signature :{' '}
                      <b style={{ color: '#f57729' }}>
                        {policyData?.inserted_at ? policyData?.inserted_at : moment().format("DD-MM-YYYY")}
                      </b>
                    </CCardBody>
                    <CCardFooter>
                      I have thoroughly read the instruction and accepted the
                      policy.
                      <br />
                      {empStatus && empStatus.emp_policy_status !== 1 && (
                        <CSwitch
                          className={'mx-1'}
                          variant={'3d'}
                          color={'success'}
                          onChange={e => acceptpolicy(e, 18)}
                          labelOn={'\u2713'}
                          labelOff={'\u2715'}
                        />
                      )}
                    </CCardFooter>
                  </CCollapse>
                </CCard>
                <BgvAuthorizationConsent
                  getSignFun={getSignFun}
                  empDetails={empDetails}
                  policyData={policyData}
                                  />
              </div>
              <br />
            </CCardBody>
            <CCardFooter>
              {/* {!isVerified ? (
                <>
                  {!isOtpSent &&
                    empStatus &&
                    empStatus.emp_policy_status !== 1 && (
                      <button
                        type="button"
                        disabled={
                          policy1 & policy2 & policy3 & policy4 &&
                          policy5 &
                            policy6 &
                            policy7 &
                            policy8 &
                            policy9 &
                            policy10 &
                            policy11 &
                            policy12 &
                            policy13 &
                            policy14 &
                            policy15 &
                            policy16 &
                            policy17 &
                            policy18
                            ? false
                            : true
                        }
                        onClick={sendOtp}
                        className="btn btn-primary mr-3 mt-2 float-right"
                      >
                        Verify
                      </button>
                    )}
                  {isOtpSent && (
                    <>
                      <div className="row">
                        <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            value={otp}
                            onChange={e => setOtp(e.target.value)}
                            placeholder="Enter Otp"
                          />
                        </div>
                        <div className="col-md-3">
                          {empStatus && empStatus.emp_policy_status !== 1 && (
                            <button
                              type="button"
                              disabled={
                                policy1 & policy2 & policy3 & policy4 &&
                                policy5 &
                                  policy6 &
                                  policy7 &
                                  policy8 &
                                  policy9 &
                                  policy10 &
                                  policy11 &
                                  policy12 &
                                  policy13 &
                                  policy14 &
                                  policy15 &
                                  policy16 &
                                  policy17 &
                                  policy18
                                  ? false
                                  : true
                              }
                              onClick={verifyOTP}
                              className="btn btn-primary mr-3 mt-2 float-right"
                            >
                              Verify & Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : ( */}
                <>
                  {empStatus && empStatus.emp_policy_status !== 1 && (
                    <button
                      type="button"
                      disabled={
                        policy1 & policy2 & policy3 & policy4 &&
                        policy5 &
                          policy6 &
                          policy7 &
                          policy8 &
                          policy9 &
                          policy10 &
                          policy11 &
                          policy12 &
                          policy13 &
                          policy14 &
                          policy15 &
                          policy16 &
                          policy17 &
                          policy18
                          ? false
                          : true
                      }
                      onClick={Policyaccepted}
                      className="btn btn-primary mr-3 mt-2 float-right"
                    >
                      Accept
                    </button>
                  )}
                </>
              {/* )} */}
            </CCardFooter>
          </CCard>
        )}
      </CCol>
      <ToastContainer style={{ marginTop:"40px"}} />
    </CRow>
  );
};
export default PolicyDetails;
