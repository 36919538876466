import { getFomatedError } from '../../services/ApiService';
import { config } from '../../../utils/apiUrl';
import API from '../../../utils/apiCalling';
const api = new API();

const getMessages = params => {
  let apiRes = api
    .post(config.getMessages, params)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });

  return apiRes;
};

const getConnectedUsers = params => {
  let apiRes = api
    .get(config.getUserConnections, true, params)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });

  return apiRes;
};

const getConnectedGroups = params => {
  let apiRes = api
    .post(config.getUserGroups, params)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });

  return apiRes;
};

const getUnreadMessageCounts = params => {
  let apiRes = api
    .get(config.getUnreadMessageCounts, true, params)
    .then(res => {
      return res;
    })
    .catch(error => {
      return getFomatedError(error);
    });

  return apiRes;
};

export default {
  getMessages,
  getConnectedUsers,
  getConnectedGroups,
  getUnreadMessageCounts,
};
