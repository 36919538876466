import React, { useState, useEffect } from 'react';
import Dashboard from 'src/views/dashboard/Dashboard';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CModal,
  CTabs,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CBadge,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CSelect,
  CFormGroup,
  CInputGroupAppend,
  CInputGroup,
  CLabel,
  CInputCheckbox,
  CSwitch,
  CFormText,
  CInput,
  CInputFile,
} from '@coreui/react';

import Moment from 'react-moment';

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
// import API from '../../utils/apiCalling';
// import { config } from '../../utils/apiUrl';

const ViewSelectedCondidateDetails = props => {
  const { candidate } = props;
  // let api = new API()
  // const [large, setLarge] = useState(false)
  useEffect(() => {}, []);

  return (
    <div className="">
      <CForm
        action=""
        encType="multipart/form-data"
        className="form-horizontal"
      >
        <CRow>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Candidate Id</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.id}
                  name="id"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Name</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.name}
                  name="name"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Mobile</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.mobile}
                  name="mobile"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>DOB</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.dob}
                  name="dob"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Designation</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.designation}
                  name="designation"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Technology Knowledge</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.technology_knowledge}
                  name="technology_knowledge"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <hr />
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Experience</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.experience}
                  name="experience"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>MRF Id</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.mrf_id}
                  name="mrf_id"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol md="6">
            <CFormGroup row>
              <CCol md="3">
                <CLabel>MRF Applying For</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={
                    candidate && candidate.mrf_applying_for == 'vacancy'
                      ? 'New Hiring'
                      : candidate && candidate.mrf_applying_for
                  }
                  name="mrf_applying_for"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>

            <CFormGroup row>
              <CCol md="3">
                <CLabel>Candidate Created By</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.candidate_created_by}
                  name="candidate_created_by"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>

            <CFormGroup row>
              <CCol md="3">
                <CLabel>Pincode</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.pincode}
                  name="pincode"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Address</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.address}
                  name="address"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>

            <CFormGroup row>
              <CCol md="3">
                <CLabel>MRF Raised By</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.mrf_raised_by}
                  name="mrf_raised_by"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>

            <CFormGroup row>
              <CCol md="3">
                <CLabel>Overall Interview Status</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={
                    candidate && candidate.overall_interview_status == 0
                      ? 'Incomplete'
                      : 'Complete'
                  }
                  name="overall_interview_status"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Send Interview Letter Status</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={
                    candidate && candidate.send_interview_letter_status == 0
                      ? 'No'
                      : 'Yes'
                  }
                  name="send_interview_letter_status"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Status</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CInput
                  value={candidate && candidate.status}
                  name="status"
                  placeholder=""
                  disabled
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="3">
                <CLabel>Created At</CLabel>
              </CCol>

              <CCol xs="12" md="9">
                <label>
                  {
                    <Moment format="YYYY/MM/DD">
                      {candidate && candidate.created_at}
                    </Moment>
                  }{' '}
                </label>
              </CCol>
            </CFormGroup>
          </CCol>
        </CRow>

        <CCardFooter>
          {/* <CButton type="button" className="btn btn-primary mr-3 mt-2">Load Details</CButton> */}
        </CCardFooter>
      </CForm>
    </div>
  );
};
export default ViewSelectedCondidateDetails;
