import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CCol,
  CFormGroup,
  CFormText,
  CLabel,
} from '@coreui/react';
import { Button } from 'reactstrap';
import { AvForm, AvInput, AvField } from 'availity-reactstrap-validation';
import React, { useState } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import { toast, ToastContainer } from 'react-toastify';

const AddSoftware = props => {
  const { isOpen1, isViewModalOpen1 } = props;
  const api = new API();
  const [isLicensed, setIsLicensed] = useState(false);
  const [softwareData, setSoftwareData] = useState({
    software_name: '',
    software_company: '',
    is_license_required: 0,
    expiry_date: '',
    license_key: '',
    license_username: '',
  });

  const onInputChange = e => {
    let { name, value } = e.target;
    if (name === 'is_license_required') {
      setIsLicensed(e.target.checked);
      let val = e.target.checked === true ? 1 : 0;
      setSoftwareData({ ...softwareData, [name]: val });
    } else {
      setSoftwareData({ ...softwareData, [name]: value });
    }
  };

  const submitSoftwareDetails = async () => {
    let payload = {
      software_name: softwareData?.software_name || '',
      software_company: softwareData?.software_company || '',
      is_license_required: softwareData?.is_license_required || 0,
      expiry_date: softwareData?.expiry_date || '',
      license_key: softwareData?.license_key || '',
      license_username: softwareData?.license_username || '',
    };
    let result = await api.post(config.saveSoftwares, payload);
    if (result && result.code === 200) {
      toast.success('Software Added Successfully');
    } else {
      console.log('err while saving software', result);
    }
  };

  return (
    <div className="">
      <CModal
        show={isOpen1}
        onClose={() => isViewModalOpen1(!isOpen1)}
        size="lg"
      >
        <CModalHeader closeButton>
          <CModalTitle>Add Software</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <></>
          <AvForm
            action=""
            method="post"
            encType="multipart/form-data"
            className="form-horizontal"
            onValidSubmit={submitSoftwareDetails}
          >
            <CFormGroup row>
              <CCol md="4">
                <CLabel htmlFor="">Software Name</CLabel>
              </CCol>
              <CCol xs="12" md="8">
                <AvInput
                  name="software_name"
                  id="Software Name"
                  onChange={e => onInputChange(e)}
                  required
                />
                <CFormText>Please enter software name</CFormText>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="4">
                <CLabel htmlFor="">Software Company</CLabel>
              </CCol>
              <CCol xs="12" md="8">
                <AvInput
                  name="software_company"
                  id="Software Company"
                  onChange={e => onInputChange(e)}
                />
                <CFormText>Please enter software company</CFormText>
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol md="4">
                <CLabel htmlFor="">License</CLabel>
              </CCol>
              {/* <CCol md="4">
                            <AvField
                              type="checkbox"
                              name="is_license_required"
                              id="Unlicensed"
                              value={0}
                              onChange={e => onInputChange(e)}
                              label="Unlicensed"
                            ></AvField>
                          </CCol> */}
              <CCol md="8">
                <AvField
                  type="checkbox"
                  name="is_license_required"
                  id="Licensed"
                  value={1}
                  onChange={e => onInputChange(e)}
                  label="Licensed"
                ></AvField>
              </CCol>
            </CFormGroup>
            {isLicensed && (
              <>
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel htmlFor="">License Expiry Date</CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <AvInput
                      type="date"
                      name="expiry_date"
                      id="Expiry Date"
                      onChange={e => onInputChange(e)}
                      required
                    />
                    <CFormText>Please enter license expiry date</CFormText>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel htmlFor="">License Key</CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <AvInput
                      name="license_key"
                      id="License Key"
                      onChange={e => onInputChange(e)}
                    />
                    <CFormText>Please enter license key</CFormText>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="4">
                    <CLabel htmlFor="">License Username</CLabel>
                  </CCol>
                  <CCol xs="12" md="8">
                    <AvInput
                      name="license_username"
                      id="License Username"
                      onChange={e => onInputChange(e)}
                    />
                    <CFormText>Please enter license username</CFormText>
                  </CCol>
                </CFormGroup>
              </>
            )}
          </AvForm>
        </CModalBody>
        <CModalFooter>
          <Button className="mr-2" type="submit" size="md" color="primary">
            Save Software
          </Button>
        </CModalFooter>
      </CModal>

      <ToastContainer />
    </div>
  );
};
export default AddSoftware;
