import React, { useState,useEffect,useRef} from 'react';
import API from 'src/utils/apiCalling';
// import ReactEmoji from 'react-emoji';
import {  MDBCard,MDBCardBody,} from "mdb-react-ui-kit";
import { config } from '../utils/apiUrl';
import form from 'src/views/ASSETS MANAGEMENT/EMP_Asset_Management/undertaking_form/form';
import moment from "moment";

const MainChat = (props) => {
  let { hide, sendMessage,friend,currentUser,messages,roomId,navigate,sendFile} = props


  
  
  const receiver=roomId.split("_").filter((id)=>id!=currentUser.id)[0]
  // console.log("messages 123",messages)

  const [message, setMessage] = useState("");
  const[thumbNailUrl,setThumbNailUrl]=useState("")
  const[imageUrl,setImageUrl]=useState("")
  const[attachFile,setAttachFile]=useState([])
  const [showClick, setShowClick] = useState(false)
  const [messagess, setMessagess] = useState([{sender:{name:"abc",id:5},msg:"hello"},{sender:{name:"def",id:6},msg:"heyy"}]);

  const divRef = useRef(null);
  const saveFile=async(e)=>{
    let formData=new FormData()
    for(let i=0;i<e.target.files.length;i++){ 
      formData.append("image",e.target.files[i])
    } 
    // const fileArr=Object.values(files).map((item)=>URL.createObjectURL(item))
    let result= await api.putPostFileFun(config.fileMsg,formData)
    // console.log("ghgh",result)
    if(result&&result.error===false){
      sendFile(friend.name,result?.data);
      // console.log("hello attach",result&&result.data&&result.data.length>0&&result.data[0].thumbnailUrl)
      setThumbNailUrl(result&&result.data&&result.data.length>0&&result.data[0].thumbnailUrl)
      setImageUrl(result&&result.data&&result.data.length>0&&result.data[0].url)

    }else{
      alert("OOps")
    }
   
  }
 console.log("message", messages, messagess, roomId);

  const api = new API();

  const handleChange = event => {
    
    setMessage(event.target.value);
  };

  const getMessages = async () => {
    let data={
      id:roomId
    }
    let result = await api.get(config.chat,true,data)

    if (result) {
      // console.log(result&&result.length>0&&result[0].message,"messsage chat")
      setMessagess(result&&result.length>0&&result[0]?.message)
      
    }

  }

  useEffect(() => {
    getMessages()
  }, [])

  useEffect(()=>{
    divRef.current.scrollIntoView({ behavior: 'smooth' });
  },[])

  function messageSend(e) {
    if (e.key === 'Enter') {
      
      sendMessage(e,friend.name)
      setMessage(''); 
      // getMessages();
    }
  }
  return (
<div >

          <MDBCard id="chat1" style={{ borderRadius: "15px" }}>
          <div className="chatheader py-2 d-flex align-items-center top-0" style={{backgroundColor:"rgba(235,124,69,255 )",borderTopRightRadius:"15px",borderTopLeftRadius:"15px"}}>
              <div className="profile-image"><i className="bi bi-chevron-left p-0 mr-2" onClick={navigate} />
              <span>
              <img src="../images/logo.png"  className="m-0" alt="" style={{height:"35px",width:"35px"}} />
              </span>
              </div>
              <div>
                <h4 className='pl-3 mt-2 text-white'> {friend.name}</h4>
                {/* <small className="text-secondary">Online</small> */}
              </div>
              {/* <button onClick={hide} className="bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#addmember"><i className="bi bi-plus-circle-fill bg-transparent text-orange" /></button>
              <a ><i className="bi text-white bi-search " /></a> */}
            </div>
            <MDBCardBody style={{overflow:"auto",height:"22rem"}}>
            
            {messagess && messagess.length > 0 && messagess.map((message, index) =>(<>
           { message.sender['id']!=currentUser.id&&message.sender['room']==roomId&&(<div className="justify-content-start"><div className="d-flex flex-row justify-content-start">
              
                <div
                  className="p-3 ms-3"
                  key={index}
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "rgba(235,124,69,255 )",
                  }}
                >
                   {(message?.isAttachment==1) ?
                  <a href={message.msg} target="_blank"><img src={message.msg} height="50px" width="50px"></img></a>
                  :
                  <p className="small mb-0 text-white">
                  {message.msg}
                 </p>
                  }
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start mb-4" style={{fontSize:"70%", paddingLeft:"5%"}}>
                <small className="small mb-0 text-black">
                  {message.date} {message.time}
                 </small>
              </div>
              </div>
            )}
            { message.sender['id']==currentUser.id &&(<div className="justify-content-end"><div className="d-flex flex-row justify-content-end">
                <div
                  className="p-3 me-3 border"
                  key={index}
                  style={{ borderRadius: "15px", backgroundColor: "rgba(48,76,158,255)" }}
                >
                    {(message?.isAttachment==1) ?
                  <a href={message.msg} target="_blank"><img src={message.msg} height="50px" width="50px"></img></a>
                  :
                  <p className="small mb-0 text-white">
                  {message.msg}
                 </p>
                  }
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end mb-4" style={{fontSize:"70%", paddingRight:"5%"}}>
                <small className="small mb-0 text-black">
                  {message.date} {message.time}
                 </small>
              </div>
              </div> 
            )}
              </>
              ))}
              {messages && messages.length > 0 && messages.map((message, index) =>(<>
           { message.id!=currentUser.id&&message?.roomId==roomId&&(<div className="justify-content-start"><div className="d-flex flex-row justify-content-start">
                <div
                  className="p-3 ms-3"
                  key={index}
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "rgba(235,124,69,255 )",
                  }}
                >
                  {message.attachment=='' ?
                  <p className="small mb-0 text-white">
                    {message.text}
                  </p>
                  :
                  <a href={message.attachment} target="_blank"><img src={message.attachment} width="50px" height="40px"/></a>
                }
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start mb-4" style={{fontSize:"70%", paddingLeft:"5%"}}>
                <small className="small mb-0 text-black">
                {message.date} {message.time}
                 </small>
              </div>
              </div>
            )}
            { message&&message?.roomId?.split("_").filter((id)=>id!=currentUser.id)[0]==friend.id && message.id == currentUser.id &&( <div className="justify-content-end"><div className="d-flex flex-row justify-content-end">
                <div
                  className="p-3 me-3 border"
                  key={index}
                  style={{ borderRadius: "15px", backgroundColor: "rgba(48,76,158,255)" }}
                >
                 {message.attachment=='' ?
                  <p className="small mb-0 text-white">
                    {message.text}
                  </p>
                  :
                  <a href={message.attachment} target="_blank"><img src={message.attachment} width="50px" height="40px"/></a>

                }
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end mb-4" style={{fontSize:"70%", paddingRight:"5%"}}>
              <small className="small mb-0 text-black">
              {message.date} {message.time}
               </small>
            </div>
            </div>
            )}
              </>
              ))}
              <div ref={divRef} className="py-2"></div>
            </MDBCardBody>
           
          </MDBCard>
          <div className="chatfooter d-flex align-items-center  justify-content-between "  style={{ borderBottomRightRadius:"15px",borderBottomLeftRadius:"15px"}}>
              {/* <button className="border-0 bg-transparent"><i className="text-secondary bi bi-paperclip" /></button> */}
              <input type="text" className="form-control border-0" value={message} onInput={handleChange} onKeyDown={messageSend} placeholder="Type Something" />


             
              <label htmlFor="upload-file">
              <i className="fa fa-2x mr-2 fa-paperclip"  style={{color:"gray"}} aria-hidden="true"></i>
              <input type="file" onChange={(e)=>saveFile(e)} id="upload-file"  style={{ display: 'none' }} multiple/>

              </label>
             

              {/* <button className="border-0 bg-transparent" data-bs-toggle="modal" data-bs-target="#sendaudio"><i className="text-secondary bi bi-mic" /></button> */}
            </div>
</div>
  )
}

export default MainChat