


import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
 
    CTabs,
 
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
 
} from '@coreui/react'


import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import ModalCandidate from './modal'
import ViewInterviewStatus from './viewInterviewStatus';


const ViewSelectedInProgress = (props) => {
    const { MRFData, view_selected } = props
    let api = new API()

    const [active, setActive] = useState(0)
    const [mrfRecord, setMrfRecord] = useState()
    const [createdCandidate, setCreatedCandidate] = useState()
    const [candidateDetails, setCandidateDetails] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpen2, setIsModalOpen2] = useState(false)
    const [candidate, setCandidate] = useState()
    const [isInterviewStatus, setIsInterviewStatus] = useState(false)
    
    useEffect(() => {
        getCreatedCandidate()
    }, [])

    const getCreatedCandidate = async () => {

        let data = {
            mrf_id: MRFData && MRFData.mrf_id,
            mrf_applying_for: MRFData && MRFData.applying_for
        }

        let result = await api.get(config.getCreatedCandidate, true, data)
        if (result && result.code == 200) {
            // console.log("get interview status candidate", result)
            // alert(result.message)
            setCreatedCandidate(result.data)
        }
        else {
           
        }
    }
    const condidateFullDetails = (candidate) => {
        setCandidateDetails(candidate)
        setIsModalOpen(!isModalOpen)
    }

    const sendInterviewLatter = (candidate) => {
        setCandidateDetails(candidate)
        setIsModalOpen2(!isModalOpen2)
    }

    const selectCondidate = async (candidate) => {

        let data = {
            candidate_id: candidate.id
        }
        let result = await api.post(config.selectCandidate, data)
        // console.log("condidate selectd", result)
        if (result && result.code == 200) {
            alert("selected")
            getCreatedCandidate()
        }
        else {
          
        }
    }

    const backButton = () =>{
        view_selected()
    }

    const isInterviewStatusFun = (candidate) => {
        setCandidate(candidate)
        setIsInterviewStatus(!isInterviewStatus)
    }
    return (
        <div className="">
            <div className="container">
                <h3 className="text-uppercase text-center mt-3 mb-4"> <u>Interveiw Inprogress</u></h3>
               { isInterviewStatus == false ?
                <CRow>
                    <CCol xs="12" >
                        <CCard>
                            <CCardHeader>
                               Interveiw Status
                        </CCardHeader>
                            <CCardBody>
                                <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
                                    <CNav variant="tabs">
                                        <CNavItem>
                                            <CNavLink>
                                                Status
                                            </CNavLink>
                                        </CNavItem>
                                        {/* <CNavItem>
                                            <CNavLink>
                                                HR Reject
                    
                                            </CNavLink>
                                        </CNavItem> */}

                                    </CNav>

                                    <CTabContent>
                                        <CTabPane>
               
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Candidate Id</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            
                            <th scope="col">DOB</th>
                         <th scope="col">Designation</th>
                         <th scope="col">Interview Status</th>


                        </tr>
                    </thead>
                    <tbody>
                        {createdCandidate && createdCandidate.map((candidate, index) => (
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{candidate.id}</td>
                                <td>{candidate.name}</td>
                             
                                <td>{candidate.email}</td>
                                <td>{candidate.dob}</td>
                                <td>{candidate.designation}</td>
                                <td>{candidate.overall_interview_status == 0 ? <span style={{color:"red"}}>Pending</span> : <span style={{color:"blue"}}>Complete</span>}</td>
                             

                                <CRow>
                                    <CCol xs="12" md="6">
                                        <CButton  data-toggle="tooltip" data-placement="top" title="View Full details"  className="btn btn-info ml-4 pr-auto" onClick={() => condidateFullDetails(candidate)}><i className="fa fa-eye " aria-hidden="true"></i> </CButton>
                                    </CCol>
                                
                                 
                                
                                      <CCol xs="12" md="6">
                                            <CButton data-toggle="tooltip" data-placement="top" title="View Status" className="btn btn-success ml-2 pr-auto" onClick={() => isInterviewStatusFun(candidate)}>
<i className="fa fa-info" aria-hidden="true"></i></CButton>
                                        </CCol>
                              
                                  
                                </CRow>
                            </tr>
                        ))}

                    </tbody>
                </table>

                </CTabPane>
{/* 
                <CTabPane>
                    <h5> No Rejected Candidate</h5>
                </CTabPane> */}
                                       
                                        </CTabContent>
                                </CTabs>
                                <CCardFooter>
                                    <CButton data-toggle="tooltip" data-placement="top" title="Back" color="success" onClick={backButton}><i className="fa fa-arrow-left" aria-hidden="true"></i></CButton>{' '}

                                </CCardFooter>
           
                            </CCardBody>
                        </CCard>

                    </CCol>
                </CRow>
                :
                <ViewInterviewStatus candidate={candidate} 
                isInterviewStatusFun={isInterviewStatusFun}/> 
}

            </div>
            <ModalCandidate condidateFullDetails={condidateFullDetails}
                isModalOpen={isModalOpen}
                message={"Candidate Full Details"}
                candidateDetails={candidateDetails} />



               
        </div>

    )
}
export default ViewSelectedInProgress







