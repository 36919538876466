import React, { useEffect, useState } from "react";

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CLabel,
  CFormText,
  CInput,
  CRow,
} from "@coreui/react";
import API from "src/utils/apiCalling";
import { config } from "src/utils/apiUrl";
// import { Color } from "@progress/kendo-drawing";
import {
  getLocalDataAsObject,
  setLocalDataAsObject,
} from "src/utils/CoustomStorage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { encryptValue } from 'src/utils/common';
// import { reduceRight } from 'core-js/core/array';
const ChangePassword = (props) => {
  let { isViewModalOpen } = props;
  const api = new API();
  const [oldPassword, setOldpassword] = useState([])
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [changePassword, setchangePassword] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });   
  const resetFunc = () => {
    setchangePassword({
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
    });
  };

  useEffect(() => {
    setOldpassword(getLocalDataAsObject("user"))
    // toast.success("data submited successfully")
  },[]);
  const onChangeConfirmPassword = (e) => {
    const { name, value } = e.target;

    setchangePassword({
      ...changePassword,
      [name]: value,
    });
  };

  const Changepassword = async (event) => {
    event.preventDefault();

    //alert("hh")

    // console.log("-----",changePassword.oldpassword , changePassword.confirmpassword)
    let data = {
      old_password: oldPassword[0].random_string,
      new_password: changePassword.newpassword,
      confirm_password: changePassword.confirmpassword,
    };

    let result = await api.post(config.changepassword, data);
    if (result && result.code == 200) {
      let data = getLocalDataAsObject("user");
      console.log("Hello", data)
      // console.log("8888888888888", data[0])
      data[0].is_password_changed = 1;
      // console.log("999999999999999", data)
      await setLocalDataAsObject("user", encryptValue(JSON.stringify(data)));
      setLocalDataAsObject("user", data);
      // console.log("updated vlaue is________________________", getLocalDataAsObject("user"))
      toast.success(result.message);
      // alert("Password  ")
      isViewModalOpen && isViewModalOpen();
    } else {
      toast.error(result && result.message);
    }
  };
  return (
    <>
    <CRow>
      <CCol xl="12">
        <CCard>
          <CCardHeader>
            <big>Change Password</big>
            <br />
            <small> Kindly fill the fields to change your password</small>
          </CCardHeader>
          <CCardBody>
            <CForm
              action=""
              method="post"
              encType="multipart/form-data"
              className="form-horizontal"
            >
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>Current Password</CLabel>
                </CCol>
                <CCol xs="12" md="9" className="position-relative eyeContt2">
                  <CInput
                    type={passwordShown2 ? "text" : "password"}
                    id="oldpassword"
                    value={oldPassword[0]?.random_string}
                    onChange={onChangeConfirmPassword}
                    name="oldpassword"
                    placeholder="Enter Current Password.."
                    autoComplete="current-password"
                    required
                    readOnly
                  />
                  {/* <CFormText>Please enter your current password</CFormText> */}
                  <i class="fa fa-eye" aria-hidden="true" onClick={()=>setPasswordShown2(passwordShown2 ? false : true)}></i>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol md="3">
                  <CLabel>New Password</CLabel>
                </CCol>
                <CCol xs="12" md="9" className="position-relative eyeContt2">
                  <CInput
                    type={passwordShown ? "text" : "password"}
                    id="newpassword"
                    value={changePassword.newpassword}
                    onChange={onChangeConfirmPassword}
                    name="newpassword"
                    placeholder="Enter New Password.."
                    autoComplete="current-password"
                    required
                  />
                  <i class="fa fa-eye" aria-hidden="true" onClick={()=>setPasswordShown(passwordShown ? false : true)}></i>
                  <CFormText>Please enter your new password</CFormText>
                </CCol>
              </CFormGroup>
              <CFormGroup row position-relative eyeContt1>
                <CCol md="3">
                  <CLabel>Confirm New Password..</CLabel>
                </CCol>
                <CCol xs="12" md="9" className="position-relative eyeContt2">
                  <CInput
                    type={passwordShown1 ? "text" : "password"}
                    id="confirmpassword"
                    value={changePassword.confirmpassword}
                    onChange={onChangeConfirmPassword}
                    name="confirmpassword"
                    placeholder="Confirm New Password.."
                    autoComplete="current-password"
                    required
                  />
                  <i class="fa fa-eye" aria-hidden="true" onClick={()=>setPasswordShown1(passwordShown1 ? false : true)}></i>
                  <CFormText>Please re-enter your new password</CFormText>
                </CCol>
              </CFormGroup>
            </CForm>
          </CCardBody>
          <CCardFooter>
            <CButton
              type="submit"
              className="btn btn-primary mr-3 mt-2"
              onClick={Changepassword}
            >
              Submit
            </CButton>
            <CButton
              type="reset"
              onClick={resetFunc}
              className="btn btn-danger mt-2"
            >
              {" "}
              Reset
            </CButton>
          
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <ToastContainer />
     </>
  );
};

export default ChangePassword;
