import React, { useEffect, useState } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import appConstants from 'src/core/configs/Constants.config';
import { toastAlert, diffBetweenTimes } from 'src/utils/common';
import { ToastContainer } from 'react-toastify';
import { CustButton } from 'src/reusable';

const ApprovalConfirmModal = props => {
  const api = new API();
  const {
    title,
    isShow,
    closeConfirmModal,
    handleOnSubmit,
    data,
    currentSelectedAction,
    isLoading,
  } = props;

  const [comment, setComment] = useState('');
  const [approveForStatus, setApproveStatus] = useState();
  const [commentErrorMsg, setCommentErrorMsg] = useState('');

  const diffActualTime = diffBetweenTimes(
    data?.actual_check_in_time || 0,
    data?.actual_check_out_time || 0,
  );
  const diffCorrectionTime = diffBetweenTimes(
    data?.corrected_check_in_time || 0,
    data?.corrected_check_out_time || 0,
  );
  const diffTotalTime = diffBetweenTimes(
    `${diffActualTime?.diffHours || 0}:${diffActualTime?.diffMinutes || 0}`,
    `${diffCorrectionTime?.diffHours || 0}:${diffCorrectionTime?.diffMinutes ||
      0}`,
  );

  const handleOnChange = e => {
    const { value } = e.target;
    if (value && commentErrorMsg) {
      setCommentErrorMsg('');
    }
    setComment(e.target.value);
  };

  const handleOnRightBtnClick = () => {
    if (data.type === 'attendance_correction') {
      if (currentSelectedAction === 'reject' && !comment) {
        setCommentErrorMsg('Reason field is required');
        return;
      }
      const params = {
        type: data.type,
        is_approved: currentSelectedAction,
        id: data.id,
        comment: comment ? comment : currentSelectedAction === 'reject' ? 'rejected' : 'approved',
        // approve_for:
        //   currentSelectedAction === 'approve' ? approveForStatus : null,
        correction_data: JSON.stringify([
          {
            id: data.id,
            approve_for:
              currentSelectedAction === 'approve' ? approveForStatus : null,
            comment: '',
          }])
      };
      handleOnSubmit(params);
    }
  };

  const formatValue = val => {
    return `${val < 0 ? '-' : ''} ${
      Math.abs(val) < 10 ? `0${Math.abs(val)}` : Math.abs(val)
    }`;
  };
  return (
    <div>
      <CModal
        show={isShow}
        onClose={closeConfirmModal}
        size="lg"
        backdrop={false}
      >
        <CModalHeader closeButton>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <td colSpan={2}>
                    <strong>Attendance Date:&nbsp;</strong>
                    {data?.date || 'N/A'}
                  </td>
                  <td colSpan={2}>
                    <strong>Correction of:&nbsp;</strong>
                    <span className="text-warning">
                      {`${formatValue(diffTotalTime.diffHours)}:${formatValue(
                        diffTotalTime.diffMinutes,
                      )}`}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th>Actual Time</th>
                  <th>Correction Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Check In</th>
                  <td>{data?.actual_check_in_time || 'N/A'}</td>
                  <td>{data?.corrected_check_in_time || 'N/A'}</td>
                </tr>
                <tr>
                  <th>Check Out</th>
                  <td>{data?.actual_check_out_time || 'N/A'}</td>
                  <td>{data?.corrected_check_out_time || 'N/A'}</td>
                </tr>
                <tr>
                  <th>Duration</th>
                  <td className="text-danger">
                    <strong>
                      {`${formatValue(diffActualTime.diffHours)}:${formatValue(
                        diffActualTime.diffMinutes,
                      )}`}
                    </strong>
                  </td>
                  <td className="text-success">
                    <strong>
                      {`${formatValue(
                        diffCorrectionTime.diffHours,
                      )}:${formatValue(diffCorrectionTime.diffMinutes)}`}
                    </strong>
                  </td>
                </tr>
                {currentSelectedAction === 'approve' && (
                  <tr>
                    <th>Approve For</th>
                    <td>
                      <select
                        className="form-control"
                        onChange={e => setApproveStatus(e.target.value)}
                      >
                        <option disabled>Select</option>
                        <option value="full_day">Full Day</option>
                        <option value="first_half_present">
                          First Half Day
                        </option>
                        <option value="second_half_present">
                          Second Half Day
                        </option>
                      </select>
                    </td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* {currentSelectedAction === 'reject' && ( */}
          <form>
            <div className="form-group">
              <label htmlFor="formGroupExampleInput">
                Reason <span className="required-field">*</span>
              </label>
              <textarea
                className="form-control"
                name="comment"
                value={comment}
                onChange={handleOnChange}
                id="comment"
                rows="3"
              ></textarea>
              <span className="error-msg">{commentErrorMsg}</span>
            </div>
          </form>
          {/* )} */}
        </CModalBody>
        <CModalFooter>
          <CustButton
            name="save-ar"
            text="Submit"
            color="primary"
            onClick={handleOnRightBtnClick}
            className="btn-loading btn-ladda"
            isDisabled={false}
            isLoading={isLoading}
          />
        </CModalFooter>
      </CModal>
      <ToastContainer />
    </div>
  );
};

export default ApprovalConfirmModal;
