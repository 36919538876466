


import React, { useState, useEffect } from 'react'
import Dashboard from 'src/views/dashboard/Dashboard'
import {
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CModal,
    CTabs,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CBadge,
    CDataTable,
    CButton,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CForm,
    CSelect,
    CFormGroup,
    CInputGroupAppend,
    CInputGroup,
    CLabel,
    CInputCheckbox,
    CSwitch,
    CFormText,
    CInput,
    CInputFile
} from '@coreui/react'

// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';



const CreateNewCondidate = (props) => {
    const { assignedRequisition } = props
    let api = new API()

    const [condidateDetails, setCondidateDetails] = useState({
        name: "",
        email: "",
        dob: "",
        designation: "",
        mobile: "",
        address: "",
        pincode: "",
        experience: "",
        technology_knlowledge: "",
        mrf_id: "",
        mrf_applying_for: "",
        mrf_raised_by: "",
        created_by: ""
    })

    useEffect(() => {

    }, [])

    const createNewCondidate = async () => {
        let data = {
            name: condidateDetails.name,
            email: condidateDetails.email,
            dob: condidateDetails.dob,
            designation: condidateDetails.designation,
            mobile: condidateDetails.mobile,
            address: condidateDetails.address,
            pincode: condidateDetails.pincode,
            experience: condidateDetails.experience,
            technology_knowledge: condidateDetails.technology_knowledge,
            mrf_id: condidateDetails.mrf_id,
            mrf_applying_for: condidateDetails.mrf_applying_for,
            mrf_raised_by: condidateDetails.mrf_raised_by,
            created_by: condidateDetails.created_by
        }
        let result = await api.post(config.createCondidatebyHr, data)
        if (result && result.code == 200) {
            // console.log("submited")
        }
    }
const onChangeFunction = (e) =>{
    console.log(e.target.value)
    console.log(e.target.name)
    setCondidateDetails({...condidateDetails,
    [e.target.name] : e.target.value
    })
    // console.log("_________________________", condidateDetails)
}


    return (
        <div className="">

            <AvForm action="" encType="multipart/form-data" className="form-horizontal">
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Name</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                        <AvInput value={condidateDetails.name} onChange={onChangeFunction} name="name" placeholder="Enter Name"  required/>
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Email</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.email} onChange={onChangeFunction} name="email" placeholder="Enter Email" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Dob</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.dob} onChange={onChangeFunction} name="dob" placeholder="Enter DOB" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Designation</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.designation} onChange={onChangeFunction} name="designation" placeholder="Enter Designation" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Mobile</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.mobile} onChange={onChangeFunction} name="mobile" placeholder="Enter Mobile" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Address</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.address} onChange={onChangeFunction} name="address" placeholder="Enter address" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Pincode</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.pincode} onChange={onChangeFunction} name="pincode" placeholder="Enter Pincode" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Experience</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.experience} onChange={onChangeFunction} name="experience" placeholder="Enter Experience" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Technolgy Knowledge</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.technology_knowledge} onChange={onChangeFunction} name="technology_knowledge" placeholder="Enter Technology Knowledge" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Mrf Id</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.mrf_id} onChange={onChangeFunction} name="mrf_id" placeholder="Enter Mrf Id" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Mrf Applying For</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.mrf_applying_for} onChange={onChangeFunction} name="mrf_applying_for" placeholder="MRF Applying For" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >MRF Raised By</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.mrf_raised_by} onChange={onChangeFunction} name="mrf_raised_by" placeholder="MRF Raised By" />
                    </CCol>
                </CFormGroup>
                <CFormGroup row>
                    <CCol md="3">
                        <CLabel >Created By</CLabel>
                    </CCol>


                    <CCol xs="12" md="9">
                    <AvInput required value={condidateDetails.created_by} onChange={onChangeFunction} name="created_by" placeholder="Created By" />
                    </CCol>
                </CFormGroup>

                <CCardFooter>
                    {/* <CButton type="button" onClick={ctcFullDetails} className="btn btn-primary mr-3 mt-2">Submit</CButton> */}

                </CCardFooter>
            </AvForm>
        </div>

    )
}
export default CreateNewCondidate







