import React, { useState, lazy, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import {
    CBadge, CNav,
    CInputRadio,
    CNavItem,
    CNavbar,
    CListGroup, CListGroupItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CTabs,
    CButton,
    CTextarea,
    CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle,
    CWidgetBrand,
    CButtonGroup,
    CWidgetIcon,
    CCard,
    CLink,
    CCardBody,
    CCardFooter,
    CInputFile,
    CCardHeader,
    CCol,
    CSwitch,
    CCollapse,
    CProgress,
    CProgressBar,
    CRow,
    CLabel, CInput, CForm, CFormGroup, CFormText, CSelect,
    CCallout
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { DocsLink } from 'src/reusable'
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import './stepper.css'


const StepperMrfHrManager = (props) => {
    const { receivedRequisition } = props
    let api = new API()
    useEffect(() => {
    }, [])
  
    return (
        <div>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2">
                        <h4 id="heading">Raised MRF Status</h4>
                         
                        <div className="card px-0 pt-2 pb-0 mt-4 mb-2" style={{ width: "100%", margin: "auto" }}>
                            <form id="msform">
                                <ul id="progressbar1">
                                    <li className={receivedRequisition && receivedRequisition.submitted_status == 1 ? "active" : ""} id="employee"><strong>MRF Assigned</strong></li>
                                    <li className={receivedRequisition && receivedRequisition.reported_to_status == 1 ? "active" : ""} id="manager"><strong>Assigned For Interview</strong></li>
                                    <li className={receivedRequisition && receivedRequisition.hr_status == 1 ? "active" : ""} id="manager"><strong>Candidate Selected</strong></li>
                                    <li className={receivedRequisition && receivedRequisition.assigned_to_recruiter_status == 1 ? "active" : ""} id="admin"><strong>Offer Letter Sent</strong></li>
                                    <li className={receivedRequisition && receivedRequisition.candidate_interview_ongoing_status == 1 ? "active" : ""} id="it"><strong>Accept Offer Letter</strong></li>
                                    <li className={receivedRequisition && receivedRequisition.candidate_offer_letter_accepted_status == 1 ? "active" : ""} id="finance"><strong>Joined</strong></li>
                                </ul>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
export default StepperMrfHrManager
