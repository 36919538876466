import React from 'react';
import CIcon from '@coreui/icons-react';

let employee = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-clipboard',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Taskbox',
    to: '/taskboard',
    icon: 'cil-clipboard',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Attendance Management',
    route: '/dashboard/attendance-management',
    icon: 'cil-clock',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Attendance Dashboard',
        to: '/dashboard/attendance-management',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Leave Management',
        to: '/dashboard/leave-management',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Attendance Summary',
      //   to: '/dashboard/attendance-management/attendance-summary',
      // },
      {
        _tag: 'CSidebarNavItem',
        name: 'Holiday List',
        to: '/dashboard/attendance-management/holiday',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'View Other Attendance',
        to: '/dashboard/attendance-management/view-other-attendance',
      },
    ],
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Instant Messenger',
  //   to: '/messages',
  //   icon: 'cil-chat-bubble',
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'My Profile',
    to: '/dashboard/my_info',
    icon: 'cil-user',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'MRF Form',
    to: '/dashboard/mrf_manager',
    icon: 'cil-people',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Interview Panel',
    to: '/dashboard/interview',
    icon: 'cil-speech',
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Announcement',
  //   to: '/dashboard/announcement',
  //   icon: 'cil-speech',
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'Employee Referral',
    to: '/dashboard/referral',
    icon: 'cil-user-follow',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'My Assets',
    to: '/dashboard/asset-management/employee-assets-management',
    icon: 'cil-laptop',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Assets Management',
    to: '/dashboard/asset-management/itsupport-assets-management',
    icon: 'cil-devices',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Ticket Managment System',
    to: '/dashboard/ticket-management-system',
    icon: 'cil-notes',
    // _children: [
    //   {
    //     _tag: "CSidebarNavItem",
    //     name: "TMS",
    //     to: "/dashboard/ticket-management-system"
    //   }
    //   {
    //     _tag: 'CSidebarNavItem',
    //     name: 'Payroll Details',
    //     to: '/dashboard/payroll/payroll-payslip-details',
    //   },
    // ]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Performance Management',
    to: '/dashboard/pms',
    icon: 'cil-badge',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Facility Management',
    to: '/dashboard/fms',
    icon: 'cil-bookmark',
  },
  // {
  //   _tag: 'CSidebarNavItem',
    //   name: 'Reimbursement',
    //   to: '/dashboard/reimbursement',
    //   icon: 'cil-credit-card',
  // },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Travel & Claim',
    route: '/dashboard/travel',
    icon: 'cil-flight-takeoff',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Travel&Claim Dashboard',
        to: '/dashboard/travel',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Travel Request',
      //   to: '/dashboard/travel/travel-request',
      // },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Payroll',
    route: '/dashboard/payroll',
    icon: 'cil-money',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard/payroll',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Separation',
    to: '/dashboard/separation',
    icon: 'cil-input',
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Survey',
  //   to: '/admin-dashboard/survey/index',
  //   icon: 'cil-pencil',
  // },

  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'My Profile',
  //   to: '/dashboard/my_info',
  //   icon: 'cil-pencil',
  // },
  // induction ,,,,,,,,,,,,,,,,,,,,,,,,,
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Induction Training',
  //   route: '/training',
  //   icon: 'cil-cursor',

  //child element.......................

  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Training1',
  //       to: '/dashboard/training/training1',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Training2',
  //       to: '/dashboard/training/training2',
  //     },
  //   ],
  // },
];
export default employee;
