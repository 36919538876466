import {
  take,
  put,
  call,
  all,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { attendanceActions as actions, attendanceService } from './index';

function* handleGetAttendanceBasicDetails() {
  const action = yield take(actions.getAttendanceBasicDetailsRequest.getType());
  const dataParams = action.payload;
  let res;
  try {
    res = yield call(attendanceService.getAttendanceBasicDetails, dataParams);
    console.log("res", res);
    if (res.isError) {
      yield put(actions.getAttendanceBasicDetailsError(res));
      //history.push('/home');
    } else {
      yield put(actions.getAttendanceBasicDetailsSuccess(res));
    }
  } catch (error) {
    yield put(actions.getAttendanceBasicDetailsError());
  }
}

function* handleGetAttendanceBasicDetailsWatcher() {
  yield takeEvery(
    actions.getAttendanceBasicDetailsRequest,
    handleGetAttendanceBasicDetails,
  );
}

function* rootAttendanceSaga() {
  yield all([
    handleGetAttendanceBasicDetails(),
    handleGetAttendanceBasicDetailsWatcher(),
  ]);
}

export default rootAttendanceSaga;
